<template>
  <div></div>
</template>

<script>
const _ = require('lodash');
const dayjs = require('dayjs');
import * as turf from '@turf/turf';
import { scaleLinear } from 'd3-scale';

const COLORS = {
  2: '#0000ff',
  3: '#00ff00',
  4: '#ffff00',
  5: '#ff0000',
  6: '#ff00ff',
};

const BEARING = {
  N: 0,
  NE: 45,
  E: 90,
  SE: 135,
  S: 180,
  SW: -135,
  W: -90,
  NW: -45,
  symmetric: 0,
  NA: 0,
};

export default {
  props: ['map', 'layer', 'mapIndex'],
  mounted() {
    // wait for map init
    this.$nextTick(async () => {
      await this.create();
      this.update();
    });
  },
  destroyed() {
    this.delete();
  },
  data() {
    return {
      stormSavedPolygon: null,
      stormSavedMarker: null,
      galeSavedPolygon: null,
      galeSavedMarker: null,
      checkTimeout: null,
      getStatusSuccess: 0,
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    version() {
      return this.layer.version;
    },
    visible() {
      return this.layer.visible[this.mapIndex];
    },
    inspectCount() {
      return this.layer.inspectCount;
    },
    date() {
      return this.$store.state.map.date;
    },
    speed() {
      return this.$store.state.map.speed;
    },
    sceneUpdate() {
      return this.$store.state.layer.sceneUpdate;
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode;
    },
    layersTyphoon() {
      return this.$store.state.layer.layers.filter((l) => l.type === 'typhoon');
    },
    datesDatasource() {
      try {
        return _.union(this.$db[this.layer.dataTyphoon].dates);
      } catch {
        return [];
      }
    },
    layers() {
      if (this.numberOfMap == 1) {
        return this.$store.state.layer.layers.filter((l) => l.visible[0] && l.type != 'image');
      } else {
        return this.$store.state.layer.layers.filter((l) => (l.visible[0] || l.visible[1]) && l.type != 'image');
      }
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap;
    },
    checkLayerTyphoonVisible() {
      try {
        if (!this.layers || !this.layers.length) return false;
        for (let i = 0; i < this.layers.length; i++) {
          if (this.layers[i].type != 'typhoon') {
            if (this.numberOfMap == 1 && this.layers[i].visible[0]) {
              return true;
            }
            if (this.numberOfMap == 2 && (this.layers[i].visible[0] || this.layers[i].visible[1])) {
              return true;
            }
          }
        }
      } catch {}
      return false;
    },
  },
  watch: {
    getStatusSuccess() {
      let status = true;
      const date = _.cloneDeep(this.date[0]);
      try {
        if (this.layers && this.layers.length) {
          if (this.numberOfMap == 1) {
            for (let i = 0; i < this.layers.length; i++) {
              if (!this.$layerStatus[this.layers[i].id] || !this.$layerStatus[this.layers[i].id][date] || (this.$layerStatus[this.layers[i].id] && !this.$layerStatus[this.layers[i].id][date][0])) {
                status = false;
                break;
              }
            }
          } else if (this.numberOfMap == 2) {
            for (let i = 0; i < this.layers.length; i++) {
              if (!this.$layerStatus[this.layers[i].id] || !this.$layerStatus[this.layers[i].id][date] || (this.$layerStatus[this.layers[i].id] && (!this.$layerStatus[this.layers[i].id][this.date[0]][0] || !this.$layerStatus[this.layers[i].id][this.date[0]][1]))) {
                status = false;
                break;
              }
            }
          }
        }
      } catch {}
      if (status) {
        this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', false);
        this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', false);
      }
    },
    async version() {
      if (this.checkTimeout) clearTimeout(this.checkTimeout);
      this.checkTimeout = setTimeout(async () => {
        this.delete();
        await this.create();
        this.update();
        // if (!this.sceneUpdate) this.inspect();
      }, 100);
    },
    async visible() {
      this.delete();
      await this.create();
      this.update();
    },
    async countLiveMode() {
      this.delete();
      await this.create();
      this.update();
    },
    date(date, prev) {
      this.update(prev);
    },
    inspectCount() {
      this.inspect();
    },
  },
  methods: {
    async create() {
      if (!this.visible) return;

      if (!this.layer.dataTyphoon) return;
      //if (!this.map.getPane(this.layer.id)) this.map.createPane(this.layer.id);

      let leafletLayer = L.featureGroup();
      leafletLayer.id = this.layer.id;
      leafletLayer.type = 'typhoon';

      let dates = this.$db[this.layer.dataTyphoon].dates;
      let records = await this.getAllTyphoons(this.layer.dataTyphoon);
      let classes = records.map((record) => record.classes);
      let lat = records.map((record) => record.lat);
      let lng = records.map((record) => record.lng);
      let wind = records.map((record) => record.wind);
      let pressure = records.map((record) => record.pressure);
      let stormDirection = records.map((record) => record.stormDirection);
      let stormMinorRadius = records.map((record) => record.stormMinorRadius);
      let stormMajorRadius = records.map((record) => record.stormMajorRadius);
      let galeDirection = records.map((record) => record.galeDirection);
      let galeMinorRadius = records.map((record) => record.galeMinorRadius);
      let galeMajorRadius = records.map((record) => record.galeMajorRadius);
      let colors = classes.map((c) => COLORS[c]);

      let stormRadius = dates.map((date, index) => ((stormMinorRadius[index] + stormMajorRadius[index]) / 2) * 1852);
      let stormCenter = dates.map((date, index) => turf.destination(turf.point([lng[index], lat[index]]), ((stormMajorRadius[index] - stormMinorRadius[index]) / 2) * 1.852, BEARING[stormDirection[index]]));
      let stormLat = stormCenter.map((p) => turf.getCoord(p)[1]);
      let stormLng = stormCenter.map((p) => turf.getCoord(p)[0]);

      let galeRadius = dates.map((date, index) => ((galeMinorRadius[index] + galeMajorRadius[index]) / 2) * 1852);
      let galeCenter = dates.map((date, index) => turf.destination(turf.point([lng[index], lat[index]]), ((galeMajorRadius[index] - galeMinorRadius[index]) / 2) * 1.852, BEARING[galeDirection[index]]));
      let galeLat = galeCenter.map((p) => turf.getCoord(p)[1]);
      let galeLng = galeCenter.map((p) => turf.getCoord(p)[0]);

      leafletLayer.dates = dates.map((d) => new Date(d).getTime());
      leafletLayer.colorScale = scaleLinear().domain(leafletLayer.dates).range(colors);
      leafletLayer.latScale = scaleLinear().domain(leafletLayer.dates).range(lat);
      leafletLayer.lngScale = scaleLinear().domain(leafletLayer.dates).range(lng);

      leafletLayer.stormRadiusScale = scaleLinear().domain(leafletLayer.dates).range(stormRadius);
      leafletLayer.stormLatScale = scaleLinear().domain(leafletLayer.dates).range(stormLat);
      leafletLayer.stormLngScale = scaleLinear().domain(leafletLayer.dates).range(stormLng);
      stormDirection.forEach((direction, index) => {
        if (direction !== 'NA' && leafletLayer.stormStart === undefined) leafletLayer.stormStart = leafletLayer.dates[index];
        if (direction !== 'NA') leafletLayer.stormEnd = leafletLayer.dates[index];
      });

      leafletLayer.galeRadiusScale = scaleLinear().domain(leafletLayer.dates).range(galeRadius);
      leafletLayer.galeLatScale = scaleLinear().domain(leafletLayer.dates).range(galeLat);
      leafletLayer.galeLngScale = scaleLinear().domain(leafletLayer.dates).range(galeLng);
      galeDirection.forEach((direction, index) => {
        if (direction !== 'NA' && leafletLayer.galeStart === undefined) leafletLayer.galeStart = leafletLayer.dates[index];
        if (direction !== 'NA') leafletLayer.galeEnd = leafletLayer.dates[index];
      });
      let animateOuter = L.polygon([], { color: this.layer.typhoon.gale.color, weight: +this.layer.typhoon.gale.weight, fillColor: this.layer.typhoon.gale.color, interactive: false });
      //let animateOuter = L.polygon([], { pane: this.layer.id, color: this.layer.typhoon.gale.color, weight: +this.layer.typhoon.gale.weight, fillColor: this.layer.typhoon.gale.color, interactive: false });
      animateOuter.type = 'animateOuter';
      animateOuter.addTo(leafletLayer);
      let animateInner = L.polygon([], { color: this.layer.typhoon.storm.color, weight: +this.layer.typhoon.storm.weight, fillColor: this.layer.typhoon.storm.color, interactive: false });
      //let animateInner = L.polygon([], { pane: this.layer.id, color: this.layer.typhoon.storm.color, weight: +this.layer.typhoon.storm.weight, fillColor: this.layer.typhoon.storm.color, interactive: false });
      animateInner.type = 'animateInner';
      animateInner.addTo(leafletLayer);

      lat.forEach((l, index) => {
        let latlng = [lat[index], lng[index]];
        let previousLatlng = index === 0 ? [lat[0], lng[0]] : [lat[index - 1], lng[index - 1]];
        let line = L.polyline([latlng, previousLatlng], { fill: false, stroke: false, color: this.layer.typhoon.line.color, weight: +this.layer.typhoon.line.weight });
        //let line = L.polyline([latlng, previousLatlng], { pane: this.layer.id, fill: false, stroke: false, color: this.layer.typhoon.line.color, weight: +this.layer.typhoon.line.weight });
        line.type = 'line';
        line.date = new Date(dates[index]).getTime();
        line.addTo(leafletLayer);
      });
      let animateLine = L.polyline([], { color: this.layer.typhoon.line.color, weight: +this.layer.typhoon.line.weight });
      //let animateLine = L.polyline([], { pane: this.layer.id, color: this.layer.typhoon.line.color, weight: +this.layer.typhoon.line.weight });
      animateLine.type = 'animateLine';
      animateLine.addTo(leafletLayer);

      lat.forEach((l, index) => {
        let latlng = [lat[index], lng[index]];
        let date = new Date(dates[index]).getTime();
        let radiusMultiplier = index + 1 === lat.length || dayjs(dates[index]).format('YYYYMMDD') !== dayjs(dates[index + 1]).format('YYYYMMDD') ? 2 : 1;

        let marker = L.circleMarker(latlng, { fill: false, stroke: false, radius: +this.layer.typhoon.icon.radius * radiusMultiplier, weight: 2, color: '#ffffff', fillColor: colors[index], fillOpacity: 1 });
        //let marker = L.circleMarker(latlng, { pane: this.layer.id, fill: false, stroke: false, radius: +this.layer.typhoon.icon.radius * radiusMultiplier, weight: 2, color: '#ffffff', fillColor: colors[index], fillOpacity: 1 });
        marker.type = 'marker';
        marker.date = date;
        marker.addTo(leafletLayer);

        marker.latlng = latlng;
        marker.summary = { date: dates[index], wind: wind[index], pressure: pressure[index], storm: (stormMajorRadius[index] + stormMinorRadius[index]) / 2, gale: (galeMajorRadius[index] + galeMinorRadius[index]) / 2 };
        marker.on('click', (ev) => {
          L.DomEvent.stopPropagation(ev);
          if (this.layer.popup.multiShow === true) {
            const storeData = {
              data: { layerId: this.layer.id, clickId: date, clickUid: undefined, source: 'map', multiShow: true, latlng: marker.latlng, pin: true },
              mapIndex: this.mapIndex,
            };
            this.$store.commit('map/SET_CLICKING', storeData);
          } else {
            const storeData = {
              data: { layerId: this.layer.id, clickId: date, clickUid: undefined, source: 'map', multiShow: false, latlng: marker.latlng, pin: true },
              mapIndex: this.mapIndex,
            };
            this.$store.commit('map/SET_CLICKING', storeData);
          }
        });
      });

      let animateMarker = L.circleMarker([lat[0], lng[0]], { fill: false, stroke: false, radius: +this.layer.typhoon.icon.radius * 2, weight: 2, color: '#ffffff', fillColor: 'black', fillOpacity: 1, interactive: false });
      //let animateMarker = L.circleMarker([lat[0], lng[0]], { pane: this.layer.id, fill: false, stroke: false, radius: +this.layer.typhoon.icon.radius * 2, weight: 2, color: '#ffffff', fillColor: 'black', fillOpacity: 1, interactive: false });
      animateMarker.type = 'animateMarker';
      animateMarker.addTo(leafletLayer);

      leafletLayer.intervalId = setInterval(() => {
        if (leafletLayer.timeScale) {
          let time = leafletLayer.timeScale(new Date().getTime());
          if (time !== leafletLayer.now) {
            leafletLayer.now = time;
            this.animate();
          }
        }
      }, 1000 / 48);

      leafletLayer.addTo(this.map);
      this.$emit("emitBringToBackLayerStore")
      this.leafletLayer = leafletLayer;
    },
    async update(prev = []) {
      if (!this.checkLayerTyphoonVisible) {
        this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', true);
      }
      const datetemp = _.cloneDeep(this.date[0]);
      if (!this.$layerStatus[this.layer.id]) this.$layerStatus[this.layer.id] = {};
      if (!this.$layerStatus[this.layer.id][datetemp]) this.$layerStatus[this.layer.id][datetemp] = [false, false];

      if (!this.leafletLayer) {
        this.$layerStatus[this.layer.id][datetemp][this.mapIndex] = true;
        this.getStatusSuccess++;
        return;
      }

      this.$layerStatus[this.layer.id][datetemp][this.mapIndex] = false;
      // Build time domain
      let now = new Date().getTime();
      let isBackward = !prev.length || this.date[0] <= prev[0];
      let domainDuration = isBackward ? 1 : 1000 / this.speed;
      let domain = [now, now + domainDuration];

      // Build time range and set scale
      let date = new Date(this.date[0]).getTime();
      this.leafletLayer.timeScale = scaleLinear().domain(domain).range([date, date]).clamp(true);
      // if (!this.leafletLayer.now) {
      //   this.leafletLayer.timeScale = scaleLinear().domain(domain).range([date, date]).clamp(true);
      // } else {
      //   let oldStatus;
      //   if (this.leafletLayer.now < this.leafletLayer.dates[0] || (this.leafletLayer.now > this.leafletLayer.dates[this.leafletLayer.dates.length - 1] && !this.layer.typhoon.trail.keep)) {
      //     oldStatus = 'hiding';
      //   } else if (this.leafletLayer.now > this.leafletLayer.dates[this.leafletLayer.dates.length - 1] && this.layer.typhoon.trail.keep) {
      //     oldStatus = 'keeping';
      //   } else {
      //     oldStatus = 'running';
      //   }

      //   let newStatus;
      //   if (date < this.leafletLayer.dates[0] || (date > this.leafletLayer.dates[this.leafletLayer.dates.length - 1] && !this.layer.typhoon.trail.keep)) {
      //     newStatus = 'hiding';
      //   } else if (date > this.leafletLayer.dates[this.leafletLayer.dates.length - 1] && this.layer.typhoon.trail.keep) {
      //     newStatus = 'keeping';
      //   } else {
      //     newStatus = 'running';
      //   }

      //   if (oldStatus !== newStatus || (newStatus === 'running' && oldStatus === 'running')) {
      //     this.leafletLayer.timeScale = scaleLinear().domain(domain).range([this.leafletLayer.now, date]).clamp(true);
      //   } else {
      //     this.leafletLayer.timeScale = undefined;
      //   }
      // }
      this.$layerStatus[this.layer.id][datetemp][this.mapIndex] = true;
      this.getStatusSuccess++;
    },
    animate() {
      let now = this.leafletLayer.now;

      let status;
      if (now < this.leafletLayer.dates[0] || (now > this.leafletLayer.dates[this.leafletLayer.dates.length - 1] && !this.layer.typhoon.trail.keep)) {
        status = 'hiding';
      } else if (now > this.leafletLayer.dates[this.leafletLayer.dates.length - 1] && this.layer.typhoon.trail.keep) {
        status = 'keeping';
      } else {
        status = 'running';
      }

      // tìm điểm dừng gần nhất trước vị trí hiện tại
      let nearest = -1;
      for (let i = 0; i < this.leafletLayer.dates.length; i++) {
        if (this.leafletLayer.dates[i] < now) {
          nearest = this.leafletLayer.dates[i];
        }
      }

      this.leafletLayer.eachLayer((layer) => {
        if (layer.type === 'animateOuter') {
          if (status === 'running') {
            layer.setStyle({ opacity: 1, fillOpacity: 0.5 });

            this.buildGaleSaved(nearest, this.leafletLayer);

            if (this.galeSavedMarker !== null) {
              let outerPolygon = turf.clone(this.galeSavedPolygon);

              // additional polygon to merge with saved
              if (this.galeSavedMarker !== this.leafletLayer.galeEnd) {
                let quad = this.getQuad(this.leafletLayer.galeLatScale(now), this.leafletLayer.galeLngScale(now), this.leafletLayer.galeRadiusScale(now), this.leafletLayer.galeLatScale(this.galeSavedMarker), this.leafletLayer.galeLngScale(this.galeSavedMarker), this.leafletLayer.galeRadiusScale(this.galeSavedMarker));
                if (quad) outerPolygon = turf.union(outerPolygon, quad);
                let circle = this.getCircle(this.leafletLayer.galeLatScale(now), this.leafletLayer.galeLngScale(now), this.leafletLayer.galeRadiusScale(now));
                outerPolygon = turf.union(outerPolygon, circle);
              }

              layer.setLatLngs(
                turf
                  .getCoords(outerPolygon)[0]
                  .slice(1)
                  .map((c) => [c[1], c[0]])
              );
            } else {
              // special case: stand at start
              let outerPolygon = this.getCircle(this.leafletLayer.galeLatScale(this.leafletLayer.galeStart), this.leafletLayer.galeLngScale(this.leafletLayer.galeStart), this.leafletLayer.galeRadiusScale(this.leafletLayer.galeStart));
              if (this.leafletLayer.now === this.leafletLayer.galeStart) {
                layer.setLatLngs(
                  turf
                    .getCoords(outerPolygon)[0]
                    .slice(1)
                    .map((c) => [c[1], c[0]])
                );
              } else {
                layer.setLatLngs([]);
              }
            }
          } else if (status === 'keeping') {
            layer.setStyle({ opacity: 0.5 * +this.layer.typhoon.trail.opacity, fillOpacity: 0.2 * +this.layer.typhoon.trail.opacity });

            this.buildGaleSaved(this.leafletLayer.galeEnd, this.leafletLayer);

            let outerPolygon = turf.clone(this.galeSavedPolygon);

            layer.setLatLngs(
              turf
                .getCoords(outerPolygon)[0]
                .slice(1)
                .map((c) => [c[1], c[0]])
            );
          } else {
            layer.setLatLngs([]);
          }
        }

        if (layer.type === 'animateInner') {
          if (status === 'running') {
            layer.setStyle({ opacity: 1, fillOpacity: 0.5 });

            this.buildStormSaved(nearest, this.leafletLayer);

            if (this.stormSavedMarker !== null) {
              let innerPolygon = turf.clone(this.stormSavedPolygon);

              // additional polygon to merge with saved
              if (this.stormSavedMarker !== this.leafletLayer.stormEnd) {
                let quad = this.getQuad(this.leafletLayer.stormLatScale(now), this.leafletLayer.stormLngScale(now), this.leafletLayer.stormRadiusScale(now), this.leafletLayer.stormLatScale(this.stormSavedMarker), this.leafletLayer.stormLngScale(this.stormSavedMarker), this.leafletLayer.stormRadiusScale(this.stormSavedMarker));
                if (quad) innerPolygon = turf.union(innerPolygon, quad);
                let circle = this.getCircle(this.leafletLayer.stormLatScale(now), this.leafletLayer.stormLngScale(now), this.leafletLayer.stormRadiusScale(now));
                innerPolygon = turf.union(innerPolygon, circle);
              }

              layer.setLatLngs(
                turf
                  .getCoords(innerPolygon)[0]
                  .slice(1)
                  .map((c) => [c[1], c[0]])
              );
            } else {
              // special case: stand at start
              let innerPolygon = this.getCircle(this.leafletLayer.stormLatScale(this.leafletLayer.stormStart), this.leafletLayer.stormLngScale(this.leafletLayer.stormStart), this.leafletLayer.stormRadiusScale(this.leafletLayer.stormStart));
              if (this.leafletLayer.now === this.leafletLayer.stormStart) {
                layer.setLatLngs(
                  turf
                    .getCoords(innerPolygon)[0]
                    .slice(1)
                    .map((c) => [c[1], c[0]])
                );
              } else {
                layer.setLatLngs([]);
              }
            }
          } else if (status === 'keeping') {
            layer.setStyle({ opacity: 0.5 * +this.layer.typhoon.trail.opacity, fillOpacity: 0.2 * +this.layer.typhoon.trail.opacity });

            this.buildStormSaved(this.leafletLayer.stormEnd, this.leafletLayer);

            let innerPolygon = turf.clone(this.stormSavedPolygon);

            layer.setLatLngs(
              turf
                .getCoords(innerPolygon)[0]
                .slice(1)
                .map((c) => [c[1], c[0]])
            );
          } else {
            layer.setLatLngs([]);
          }
        }

        if (layer.type === 'line') {
          if (status === 'running') {
            layer.setStyle({ fill: true, stroke: true, opacity: layer.date < now ? 0.2 : 0 });
          } else if (status === 'keeping') {
            layer.setStyle({ fill: true, stroke: true, opacity: 0.2 * +this.layer.typhoon.trail.opacity });
          } else {
            layer.setStyle({ fill: false, stroke: false });
          }
        }

        if (layer.type === 'animateLine') {
          if (status === 'running') {
            if (nearest === -1) {
              layer.setLatLngs([]);
            } else {
              layer.setLatLngs([
                [this.leafletLayer.latScale(now), this.leafletLayer.lngScale(now)],
                [this.leafletLayer.latScale(nearest), this.leafletLayer.lngScale(nearest)],
              ]);
            }
          } else {
            layer.setLatLngs([]);
          }
        }

        if (layer.type === 'marker') {
          if (status === 'running') {
            layer.setStyle({ fill: true, stroke: true, opacity: layer.date < now ? 0.2 : 0, fillOpacity: layer.date < now ? 0.2 : 0 });
          } else if (status === 'keeping') {
            layer.setStyle({ fill: true, stroke: true, opacity: 0.2 * +this.layer.typhoon.trail.opacity, fillOpacity: 0.2 * +this.layer.typhoon.trail.opacity });
          } else {
            layer.setStyle({ fill: false, stroke: false });
          }
        }

        if (layer.type === 'animateMarker') {
          if (status === 'running') {
            layer.setStyle({ fill: true, stroke: true, fillColor: this.leafletLayer.colorScale(now) });
            layer.setLatLng([this.leafletLayer.latScale(now), this.leafletLayer.lngScale(now)]);
          } else {
            layer.setStyle({ fill: false, stroke: false });
          }
        }
      });
    },
    delete() {
      if (this.leafletLayer) {
        clearInterval(this.leafletLayer.intervalId);
        this.leafletLayer.remove();
      }
    },
    inspect() {
      try {
        if (!this.leafletLayer) return;
        let zoomNow = 20;
        let zoomMax = 20;
        let bound = this.leafletLayer.getBounds();

        if (this.map) {
          try {
            let zoomFullAll = this.map.getBoundsZoom(bound);
            zoomNow = zoomFullAll >= zoomMax ? zoomMax : zoomFullAll;
          } catch {}
          this.map.fitBounds(bound, {
            animate: true,
            duration: 0.4,
            maxZoom: zoomNow,
          });
        }
      } catch {}
    },
    buildStormSaved(nearest, leafletLayer) {
      if (nearest < leafletLayer.stormStart) {
        this.stormSavedMarker = null;
        this.stormSavedPolygon = null;
        return;
      }

      if (nearest > leafletLayer.stormEnd) {
        nearest = leafletLayer.stormEnd;
      }

      if (this.stormSavedMarker === null || this.stormSavedMarker > nearest) {
        // rebuild
        let savedPolygon = this.getCircle(leafletLayer.stormLatScale(leafletLayer.stormStart), leafletLayer.stormLngScale(leafletLayer.stormStart), leafletLayer.stormRadiusScale(leafletLayer.stormStart));
        for (let index = 0; index < leafletLayer.dates.length; index++) {
          let date = leafletLayer.dates[index];
          if (date > leafletLayer.stormStart && date <= nearest) {
            let prev = leafletLayer.dates[index - 1];
            let quad = this.getQuad(leafletLayer.stormLatScale(date), leafletLayer.stormLngScale(date), leafletLayer.stormRadiusScale(date), leafletLayer.stormLatScale(prev), leafletLayer.stormLngScale(prev), leafletLayer.stormRadiusScale(prev));
            if (quad) savedPolygon = turf.union(savedPolygon, quad);
            let circle = this.getCircle(leafletLayer.stormLatScale(date), leafletLayer.stormLngScale(date), leafletLayer.stormRadiusScale(date));
            savedPolygon = turf.union(savedPolygon, circle);
          }
        }
        this.stormSavedPolygon = savedPolygon;
        this.stormSavedMarker = nearest;
      } else {
        // append
        let savedPolygon = turf.clone(this.stormSavedPolygon);
        for (let index = 0; index < leafletLayer.dates.length; index++) {
          let date = leafletLayer.dates[index];
          if (date > this.stormSavedMarker && date <= nearest) {
            let prev = leafletLayer.dates[index - 1];
            let quad = this.getQuad(leafletLayer.stormLatScale(date), leafletLayer.stormLngScale(date), leafletLayer.stormRadiusScale(date), leafletLayer.stormLatScale(prev), leafletLayer.stormLngScale(prev), leafletLayer.stormRadiusScale(prev));
            if (quad) savedPolygon = turf.union(savedPolygon, quad);
            let circle = this.getCircle(leafletLayer.stormLatScale(date), leafletLayer.stormLngScale(date), leafletLayer.stormRadiusScale(date));
            savedPolygon = turf.union(savedPolygon, circle);
          }
        }
        this.stormSavedPolygon = savedPolygon;
        this.stormSavedMarker = nearest;
      }
    },
    buildGaleSaved(nearest, leafletLayer) {
      if (nearest < leafletLayer.galeStart) {
        this.galeSavedMarker = null;
        this.galeSavedPolygon = null;
        return;
      }

      if (nearest > leafletLayer.galeEnd) {
        nearest = leafletLayer.galeEnd;
      }

      if (this.galeSavedMarker === null || this.galeSavedMarker > nearest) {
        // rebuild
        let savedPolygon = this.getCircle(leafletLayer.galeLatScale(leafletLayer.galeStart), leafletLayer.galeLngScale(leafletLayer.galeStart), leafletLayer.galeRadiusScale(leafletLayer.galeStart));
        for (let index = 0; index < leafletLayer.dates.length; index++) {
          let date = leafletLayer.dates[index];
          if (date > leafletLayer.galeStart && date <= nearest) {
            let prev = leafletLayer.dates[index - 1];
            let quad = this.getQuad(leafletLayer.galeLatScale(date), leafletLayer.galeLngScale(date), leafletLayer.galeRadiusScale(date), leafletLayer.galeLatScale(prev), leafletLayer.galeLngScale(prev), leafletLayer.galeRadiusScale(prev));
            if (quad) savedPolygon = turf.union(savedPolygon, quad);
            let circle = this.getCircle(leafletLayer.galeLatScale(date), leafletLayer.galeLngScale(date), leafletLayer.galeRadiusScale(date));
            savedPolygon = turf.union(savedPolygon, circle);
          }
        }
        this.galeSavedPolygon = savedPolygon;
        this.galeSavedMarker = nearest;
      } else {
        // append
        let savedPolygon = turf.clone(this.galeSavedPolygon);
        for (let index = 0; index < leafletLayer.dates.length; index++) {
          let date = leafletLayer.dates[index];
          if (date > this.galeSavedMarker && date <= nearest) {
            let prev = leafletLayer.dates[index - 1];
            let quad = this.getQuad(leafletLayer.galeLatScale(date), leafletLayer.galeLngScale(date), leafletLayer.galeRadiusScale(date), leafletLayer.galeLatScale(prev), leafletLayer.galeLngScale(prev), leafletLayer.galeRadiusScale(prev));
            if (quad) savedPolygon = turf.union(savedPolygon, quad);
            let circle = this.getCircle(leafletLayer.galeLatScale(date), leafletLayer.galeLngScale(date), leafletLayer.galeRadiusScale(date));
            savedPolygon = turf.union(savedPolygon, circle);
          }
        }
        this.galeSavedPolygon = savedPolygon;
        this.galeSavedMarker = nearest;
      }
    },
    getCircle(lat, lng, radius) {
      // trả về turf polygon gồm 360 point với tâm và bán kính đã cho
      // tọa độ mỗi point làm tròn đến thập phân thứ 5 (tránh bug union) !important
      let center = turf.point([lng, lat]);
      let points = [];
      for (let i = 0; i <= 360; i++) {
        points.push(turf.destination(center, radius / 1000, i));
      }
      let latlngs = points.map((p) => turf.getCoord(p).map((n) => +n.toFixed(5)));
      return turf.polygon([latlngs]);
    },
    getQuad(lat, lng, radius, prevLat, prevLng, prevRadius) {
      // trả về hình bình hành tạo bởi 2 tiếp tuyến với tâm và bán kính đã cho
      // (hoặc null nếu như vòng tròn lồng nhau) !important
      // tọa độ mỗi point làm tròn đến thập phân thứ 5 (tránh bug union) !important
      try {
        let center = turf.point([lng, lat]);
        let prevCenter = turf.point([prevLng, prevLat]);
        let distance = turf.distance(center, prevCenter) * 1000;
        let routeAngle = Math.round(turf.bearing(center, prevCenter));
        let tangentAngle;
        if (radius === prevRadius) {
          tangentAngle = 90;
        } else if (radius > prevRadius) {
          tangentAngle = Math.round((Math.acos((radius - prevRadius) / distance) / Math.PI) * 180);
        } else {
          tangentAngle = Math.round((Math.asin((prevRadius - radius) / distance) / Math.PI) * 180) + 90;
        }
        let tangent1 = turf.destination(center, radius / 1000, routeAngle - tangentAngle);
        let tangent2 = turf.destination(center, radius / 1000, routeAngle + tangentAngle);
        let prevTangent1 = turf.destination(prevCenter, prevRadius / 1000, routeAngle - tangentAngle);
        let prevTangent2 = turf.destination(prevCenter, prevRadius / 1000, routeAngle + tangentAngle);
        let latlngs = [tangent1, tangent2, prevTangent2, prevTangent1, tangent1].map((p) => turf.getCoord(p).map((n) => +n.toFixed(5)));
        return turf.polygon([latlngs]);
      } catch (error) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
