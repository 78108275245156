<template>
  <div class="datasource-item" :style="{ backgroundColor: selecting === datasource.id ? 'rgba(255, 148, 57, 0.4)' : 'transparent' }">
    <span :title="$t('move')" class="d-flex align-items-center"><feather-icon icon="MenuIcon" class="handle" style="margin: 0 6px 0 1px; min-width: 14px" /></span>
    <PointIcon v-if="datasource.type === 'point'" style="width: 14px; height: 14px; margin-left: 5px; flex-shrink: 0" />
    <Datasource1Icon v-if="datasource.type === 'timeseries'" style="width: 14px; height: 14px; margin-left: 5px; flex-shrink: 0" />
    <ImageIcon v-if="datasource.type === 'image'" style="width: 14px; height: 14px; margin-left: 5px; flex-shrink: 0" />
    <MultiIcon v-if="datasource.type === 'multi'" style="width: 14px; height: 14px; margin-left: 5px; flex-shrink: 0" />
    <TyphoonIcon v-if="datasource.type === 'typhoon'" style="width: 14px; height: 14px; margin-left: 5px; flex-shrink: 0" />
    <NetcdfIcon v-if="datasource.type === 'netcdf'" style="width: 14px; height: 14px; margin-left: 5px; flex-shrink: 0" />
    <div class="datasource-name" :title="datasource.name">{{ datasource.name }}</div>
    <div class="d-flex options-datasource" :id="`${datasource.id}-option-datasource`" @click.stop="clOptionDatasouce(datasource)">
      <b-badge :title="$t('Options')" style="font-weight: 400; cursor: pointer; font-size: 80%" variant="light-secondary">
        <span slot="reference" class="d-flex align-items-center cursor-pointer">
          <feather-icon icon="MoreHorizontalIcon" style="cursor: pointer; min-width: 14px" />
        </span>
      </b-badge>
    </div>
  </div>
</template>

<script>
const _ = require('lodash')
import Vue from 'vue'
import axios from 'axios'
const fs = require('fs')
import { ECOPLOT_API } from '@/constants/urls'
// import fs from 'fs-extra';
// import DatatableModal from './tools/DatatableModal.vue';
// import NetcdfInformationModal from './modal/NetcdfInformationModal.vue';
// import DatasourceItemDeleteConfirm from './tools/DatasourceItemDeleteConfirm.vue';
import PointIcon from '/static/images/layer-config/radio/type-point.svg'
import Datasource1Icon from '/static/images/layer-config/prepend/datasource1.svg'
import ImageIcon from '/static/images/layer-config/radio/type-image.svg'
import TyphoonIcon from '/static/images/layer-config/radio/type-typhoon.svg'
import MultiIcon from '/static/images/layer-config/radio/type-multi.svg'
import NetcdfIcon from '/static/images/layer-config/radio/type-netcdf.svg'
import { STORAGE_MODE } from '@/constants/repository.js'
import FileMixin from '@/mixins/FileMixin.js'
import { formatBytes } from '@/utilities/DatasourceUtility.js'
export default {
  props: ['datasource', 'selecting'],
  components: { PointIcon, Datasource1Icon, ImageIcon, TyphoonIcon, MultiIcon, NetcdfIcon },
  mixins: [FileMixin],
  data() {
    return {
      funcInterVal: null,
    }
  },
  beforeDestroy() {
    try {
      if (this.funcInterVal) {
        clearInterval(this.funcInterVal)
        this.funcInterVal = null
      }
    } catch {}
  },
  computed: {
    isAwsSource() {
      return this.datasource.storage_mode == STORAGE_MODE.DYNAMODB || this.datasource.storage_mode == STORAGE_MODE.S3
    },
    liveMode() {
      if (this.datasource) {
        return this.datasource.live
      }
      return false
    },
    timeRefreshLiveMode() {
      return this.$store.state.settings.liveModeRefresh
    },
  },
  watch: {
    liveMode: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateDataLiveMode()
        }
      },
    },
    timeRefreshLiveMode() {
      this.updateDataLiveMode()
    },
  },
  methods: {
    clOptionDatasouce(datasource) {
      let data = {
        datasource: datasource,
        show: true,
      }
      this.$store.commit('map/SET_DATASOURCE_OPTION_ACTIVE', data)
    },
    updateDataLiveMode() {
      if (this.liveMode) {
        if (this.funcInterVal) {
          clearInterval(this.funcInterVal)
          this.funcInterVal = null
        }
        this.funcInterVal = setInterval(async () => {
          await this.loadDatasourceFromAwsS3(this.$S3Client, this.datasource)
        }, Number(this.timeRefreshLiveMode))
      } else {
        if (this.funcInterVal) {
          clearInterval(this.funcInterVal)
          this.funcInterVal = null
        }
      }
    },
    loadDatasourceFromAwsS3(S3Client, datasource) {
      return new Promise(async (resolve, reject) => {
        try {
          try {
            if (!this.liveMode || !this.$S3Client || !datasource || !datasource.bucket || !datasource.keyBucket) {
              resolve()
              return
            }
            Vue.prototype.$startTimerRedraw = performance.now()
            let key = datasource.keyBucket
            let checkImg = key.indexOf('.jpg') > -1 || key.indexOf('.png') > -1 ? true : false
            const data = {
              METHODS: 'loadFile',
              S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
              S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
              bucket: datasource.bucket,
              checkImg,
              key,
            }
            const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
            let file
            let keySplit = key.split('/')
            if (checkImg) {
              if (response && response.data && response.data.status == 200 && response.data.file_content) {
                const blob = this.dataURItoBlob(response.data.file_content)
                file = new File([blob], keySplit[keySplit.length - 1], { type: 'image/jpeg' })
              }
            } else {
              if (response && response.status == 200) {
                const blob = response.request.response
                file = new File([blob], keySplit[keySplit.length - 1], { type: 'text/plain' })
              }
            }

            if (!this.liveMode || !this.$S3Client || !file) {
              resolve()
              return
            }
            let indexDatasourceLive = -1
            const datasourceStore = this.$store.state.datasource.datasources
            if (!datasourceStore || !datasourceStore.length) {
              resolve()
              return
            }
            for (let i = 0; i < datasourceStore.length; i++) {
              if (datasourceStore[i].id == datasource.id) {
                indexDatasourceLive = i
                break
              }
            }
            if (indexDatasourceLive < 0 || datasourceStore[indexDatasourceLive].sizeOrigin == file.size) {
              resolve()
              return
            } else {
              if (!this.$db[datasource.id]) this.$db[datasource.id] = {}
              let datasourceRead = await this.determineTypeAndReadData(file, { id: datasource.id })
              await this.updateDatasourceLiveMode(datasourceRead)
              datasourceRead.live = true
              datasourceRead.size = formatBytes(file.size)
              datasourceRead.sizeOrigin = file.size
              datasourceRead.bucket = datasource.bucket
              datasourceRead.keyBucket = datasource.keyBucket
              datasourceRead.storage_mode = STORAGE_MODE.S3
              this.$store.commit('datasource/UPDATE_DATASOURCE', datasourceRead)
              this.$store.commit('settings/SET_COUNT_LIVEMODE')
            }
          } catch {}
        } catch {}
        resolve(true)
      })
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/jpeg' })
    },
  },
}
</script>

<style scoped lang="scss">
.datasource-item {
  display: flex;
  height: 30px;
  align-items: center;
  border: 1px dashed transparent;
}
.datasource-item .datasource-name {
  margin: 0;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}
.datasource-item .datasource-icon {
  min-width: 14px;
}
.datasource-item .folder-name {
  background-color: transparent !important;
  border-color: transparent !important;
  margin-right: auto;
  width: 150px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
}
.datasource-item .folder-name:hover,
.datasource-item .folder-name:focus {
  border-color: #404656 !important;
}
.handle {
  cursor: grab;
}
</style>

<style lang="scss">
@import '@/@core/scss/vue/libs/vue-context.scss';
.options-datasource {
  span.badge {
    cursor: pointer;
  }
}
</style>
