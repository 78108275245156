<template>
  <div class="context-menu" :style="`${styleContextMenu}`">
    <ul>
      <li>
        <button @click.stop="actionNewFolder" v-if="showNewFolder">
          <span class="menu-icon"><feather-icon icon="PlusCircleIcon" size="12" /></span>
          <span>{{ $t('new_folder') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionCopy" :class="{ dis: disableCopy, ena: !disableCopy }" v-if="showCopy">
          <span class="menu-icon"><feather-icon icon="CopyIcon" size="12" /></span>
          <span>{{ $t('copy_style') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionPaste" :class="{ dis: disablePaste, ena: !disablePaste }" v-if="showPaste">
          <span class="menu-icon"><feather-icon icon="ClipboardIcon" size="12" /></span>
          <span>{{ $t('paste_style') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionDeleteOption" :class="{ dis: disableDeleteOption, ena: !disableDeleteOption }" v-if="showDeleteOption">
          <span class="menu-icon"><feather-icon icon="DeleteIcon" size="12" /></span>
          <span>{{ $t('delete_option') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionRename" class="ena" v-if="showRename">
          <span class="menu-icon"><feather-icon icon="TagIcon" size="12" /></span>
          <span>{{ $t('rename') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionUpload" class="ena" v-if="showUpload">
          <span class="menu-icon"><feather-icon icon="UploadIcon" size="12" /></span>
          <span>{{ $t('upload') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionDelete" class="ena" v-if="showDelete">
          <span class="menu-icon"><feather-icon icon="TrashIcon" size="12" /></span>
          <span>{{ $t('delete') }}</span>
        </button>
      </li>
      <li>
        <button @click.stop="actionDownload" class="ena" v-if="showDownload">
          <span class="menu-icon"><feather-icon icon="DownloadIcon" size="12" /></span>
          <span>{{ $t('download') }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    idMenu: {
      type: String,
      default: 'id',
    },
    idActive: {
      type: String,
      default: 'id',
    },
    disableCopy: {
      type: Boolean,
      default: false,
    },
    disablePaste: {
      type: Boolean,
      default: true,
    },
    showNewFolder: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
    showPaste: {
      type: Boolean,
      default: false,
    },
    showDeleteOption: {
      type: Boolean,
      default: false,
    },
    disableDeleteOption: {
      type: Boolean,
      default: true,
    },
    showRename: {
      type: Boolean,
      default: false,
    },
    showUpload: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    styleContextMenu: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    actionCopy() {
      this.$emit('actionCopy');
    },
    actionPaste() {
      this.$emit('actionPaste');
    },
    actionNewFolder() {
      this.$emit('actionNewFolder');
    },
    actionRename() {
      this.$emit('actionRename');
    },
    actionUpload() {
      this.$emit('actionUpload');
    },
    actionDownload() {
      this.$emit('actionDownload');
    },
    actionDelete() {
      this.$emit('actionDelete');
    },
    actionDeleteOption() {
      this.$emit('actionDeleteOption');
    },
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  position: fixed;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 180px;
  min-width: 140px;
  border-radius: 2px;
  transition: none;
  background: #fff;
  z-index: 10;
  ul {
    padding: 0;
    padding-left: 0px !important;
    margin: 0.1rem 0;
    li {
      list-style: none;
      cursor: pointer;
      line-height: 1.8rem;
      button {
        text-align: left;
        border: none;
        width: 100%;
        background: inherit;
        color: inherit;
        &.ena {
          opacity: 1;
          &:hover {
            background: rgba(130, 134, 139, 0.12);
            color: black;
          }
        }
        &.dis {
          opacity: 0.6;
          cursor: not-allowed;
        }
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
  .menu-icon {
    display: inline-flex;
    width: 24px;
  }
  button {
    display: flex;
    align-items: center;
    span {
      white-space: nowrap;
    }
  }
}
.dark-layout {
  .context-menu {
    background: #161d31;
    ul {
      li {
        button {
          &.ena {
            &:hover {
              background: rgba(130, 134, 139, 0.12);
              color: #fbf0f0;
            }
          }
        }
      }
    }
    .menu-icon {
      display: inline-flex;
      width: 24px;
    }
  }
}
</style>
