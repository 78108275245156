<template>
  <div style="padding: 10px" class="scenes-container">
    <div class="scenes-container-content" style="width: 101%; flex-wrap: wrap; position: relative; display: flex">
      <div class="image-viewport" style="position: relative; flex: 0 0 50%; height: 140px" :style="{ 'padding-right': index % 2 == 0 ? '5px' : '0px', 'padding-left': index % 2 == 0 ? '0px' : '5px' }" v-for="(scene, index) in scenes" :key="index">
        <div class="delete-button btn-flat-danger" :title="$t('delete')" @click="deleteImage(scene.id)">
          <feather-icon icon="Trash2Icon" size="16" class="cursor-pointer text-danger" />
        </div>
        <div class="image-background" @click="useConfigInScene(scene)" :style="{ backgroundImage: `url(${scene.imageScene})` }"></div>
        <div class="input-name-scene">
          <b-form-input class="input-name" @change="setNameScene($event, scene.id)" :value="scene.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
export default {
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {
    scenes() {
      return this.$store.state.map.scenes;
    },
  },
  methods: {
    setNameScene(name, id) {
      this.$store.commit('map/UPDATE_SCENE', { name, id });
    },
    deleteImage(id) {
      this.$store.commit('map/DELETE_SCENE', { id });
    },
    useConfigInScene(scene) {
      if (scene.numberOfMap) {
        let numberOfMap = _.cloneDeep(scene.numberOfMap);
        this.$store.commit('map/SET_NUMBER_MAP', numberOfMap);
      }

      if (scene.lockMaps !== undefined) {
        let lockMaps = _.cloneDeep(scene.lockMaps);
        this.$store.commit('map/SCENE_SET_LOCK_MAPS', lockMaps);
      }

      //setTime
      this.$store.commit('map/UPDATE_DATE', scene.date);

    
      setTimeout(() => {
        for (let i = 0; i < scene.layers.length; i++) {
          let layer = _.cloneDeep(scene.layers[i]);
          if (layer.type === 'netcdf') continue;
          //set config
          delete layer.dataPoint;
          delete layer.dataImage;
          delete layer.dataMulti;
          delete layer.dataNetcdf;
          delete layer.dataTimeseries;
          delete layer.dataTyphoon;
          delete layer.groupData;
          delete layer.isGroup;
          delete layer.inspectCount;
          delete layer.pin;
          delete layer.version;
          delete layer.updateStyle;
          delete layer.visible;
          delete layer.name;
          layer.sceneUpdate = true
          this.$store.commit('layer/UPDATE_LAYER', layer);
        }
      }, 300);

      setTimeout(() => {
        if (scene.clicking) {
          let clicking = _.cloneDeep(scene.clicking);
          this.$store.commit('map/SET_CLICKING_OPENFILE', clicking);
        }
      }, 700);

      setTimeout(() => {
        if (scene.mapTiles) {
          let mapTiles = _.cloneDeep(scene.mapTiles);
          this.$store.commit('map/SET_MAP_TILES', mapTiles);
        }

        for (let i = 0; i < scene.layers.length; i++) {
          let layer = _.cloneDeep(scene.layers[i]);
          let visible = _.cloneDeep(layer.visible);
          this.$store.commit('layer/CHECK_VISIBLE_SCENE', { id: layer.id, visible: visible });
        }

        //set Camera
        try {
          for (let i = 0; i < scene.positionCamera.length; i++) {
            let map = document.getElementById('leaflet-map-' + i)._leaflet_map;
            map.flyToBounds(L.latLngBounds(scene.positionCamera[i]), { duration: 0.4 });
          }
        } catch (error) {}
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.image-viewport {
  position: relative;
  height: 100%;
  width: 100%;
  // padding: 2px;
  border-radius: 0.428rem;
  overflow: hidden;
  transition: border-color 0.2s ease;
}
.image-viewport:hover {
  border-color: #939bb3 !important;
}

.delete-button {
  right: 5px;
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s ease;
}
.image-viewport:hover .delete-button {
  opacity: 1 !important;
}
.image-background {
  border-radius: 5px;
  width: 100%;
  height: 123px;
  background-repeat: no-repeat;
  border: 1px solid;
  border-color: transparent;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.input-name-scene {
  height: 17px;
  width: 100%;
}
.input-name-scene .input-name {
  background: transparent !important;
  padding: 0px 10px;
  height: 17px;
  border: 0px;
  // margin-top: 3px;
  text-align: center;
  font-size: 12px;
  box-shadow: none !important;
}
.scenes-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 30px - 90.3px - 1rem - 36px - 10px);
  padding: 10px 0;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 2.5px;
  }
}
</style>
