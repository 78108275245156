<template></template>

<script>
const _ = require('lodash')
const CORNERS_IMAGE = [
  { lat: 40.68896903762437, lng: 135.00000000000003 },
  { lat: 40.68896903762437, lng: 140.84472656250003 },
  { lat: 37.274052809979075, lng: 135.00000000000003 },
  { lat: 37.274052809979075, lng: 140.84472656250003 },
]

export default {
  props: ['map', 'layer', 'mapIndex'],
  mounted() {
    // wait for map init
    this.$nextTick(async () => {
      await this.create()
    })
  },
  destroyed() {
    this.delete()
  },
  data() {
    return {
      bounds: null,
    }
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    version() {
      return this.layer.version
    },
    visible() {
      return this.layer.visible[this.mapIndex]
    },
    inspectCount() {
      return this.layer.inspectCount
    },
    getImagePos() {
      return this.$store.state.layer.getImagePos
    },
    sceneUpdate() {
      return this.$store.state.layer.sceneUpdate
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    layersImageStoreFirst() {
      try {
        let layers = this.$store.state.layer.layers
        if (layers && layers.length) {
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].type == 'image' && layers[i].visible[this.mapIndex]) {
              return layers[i]
            }
          }
        }
      } catch {}
    },
  },
  watch: {
    async version() {
      try {
        if (this.leafletLayer) this.bounds = _.cloneDeep(this.leafletLayer.getCorners())
      } catch {}
      this.delete()
      await this.create()
      // if (!this.sceneUpdate) this.inspect();
    },
    async visible() {
      try {
        if (this.leafletLayer) this.bounds = _.cloneDeep(this.leafletLayer.getCorners())
      } catch {}
      this.delete()
      await this.create()
    },
    inspectCount() {
      this.inspect()
    },
    numberOfMap() {
      try {
        if (this.numberOfMap == 2) this.$store.commit('layer/SET_IMAGE_POSITION', { id: this.layer.id, corners: JSON.stringify(this.leafletLayer.getCorners()) })
      } catch {}
    },
    getImagePos() {
      try {
        this.$store.commit('layer/SET_IMAGE_POSITION', { id: this.layer.id, corners: JSON.stringify(this.leafletLayer.getCorners()) })
      } catch {}
    },
    async countLiveMode() {
      try {
        if (this.leafletLayer) this.bounds = _.cloneDeep(this.leafletLayer.getCorners())
      } catch {}
      this.delete()
      await this.create()
    },
    layersImageStoreFirst: {
      deep: true,
      handler() {
        let editable = true
        try {
          if (this.layersImageStoreFirst) {
            if (this.layersImageStoreFirst.id == this.layer.id) {
              editable = true
            } else {
              editable = false
            }
          } else {
            editable = true
          }
        } catch {}
        if (this.leafletLayer) {
          try {
            if (editable) {
              this.leafletLayer.editing.enable()
            } else {
              this.leafletLayer.editing.disable()
            }
          } catch {}
        }
      },
    },
  },
  methods: {
    async create() {
      if (!this.visible) return

      if (!this.layer.dataImage) return
      let corners
      try {
        corners = this.bounds ? JSON.parse(JSON.stringify(this.bounds)) : this.layer.image.corners && this.layer.image.corners[0] ? JSON.parse(this.layer.image.corners) : _.cloneDeep(CORNERS_IMAGE)
      } catch {
        corners = this.bounds ? JSON.parse(JSON.stringify(this.bounds)) : this.layer.image.corners && this.layer.image.corners[0] ? JSON.parse(this.layer.image.corners) : CORNERS_IMAGE
      }
      let data = await this.getImage(this.layer.dataImage)
      let editable = true
      try {
        if (!this.layersImageStoreFirst) {
          editable = true
        } else {
          if (this.layersImageStoreFirst && this.layersImageStoreFirst.id == this.layer.id) {
            editable = true
          } else {
            editable = false
          }
        }
      } catch {}
      //Create pane to containt image
      let leafletLayer = L.distortableImageOverlay(data, {
        actions: [L.ScaleAction, L.DistortAction, L.RotateAction, L.FreeRotateAction, L.LockAction],
        mode: 'scale',
        zIndex: 101,
        editable,
        corners,
      })
      leafletLayer.id = this.layer.id
      leafletLayer.type = 'image'
      // problem with leaflet-distortableimage: wait to set opacity
      setTimeout(() => {
        leafletLayer.setOpacity(+this.layer.image.opacity)
      }, 0)
      leafletLayer.addTo(this.map)
      this.$emit('emitBringToBackLayerStore')
      this.leafletLayer = leafletLayer
    },
    delete() {
      if (this.leafletLayer) {
        this.leafletLayer.remove()
      }
    },
    inspect() {
      try {
        if (!this.leafletLayer) return
        let zoomNow = 20
        let zoomMax = 20
        let bound = this.leafletLayer.getBounds()
        if (this.map) {
          try {
            let zoomFullAll = this.map.getBoundsZoom(bound)
            zoomNow = zoomFullAll >= zoomMax ? zoomMax : zoomFullAll
          } catch {}
          this.map.fitBounds(bound, {
            animate: true,
            duration: 0.4,
            maxZoom: zoomNow,
          })
        }
      } catch {}
    },
  },
}
</script>

<style>
.leaflet-image-layer {
  pointer-events: all;
}
/* more beautiful CSS for toolbar */
.leaflet-popup-toolbar {
  border: none;
}
.leaflet-popup-toolbar .selected-mode {
  border: none !important;
}
.leaflet-popup-toolbar li:not(:last-child) .selected-mode {
  border-right: 1px solid #ccc !important;
}
.leaflet-toolbar-tip {
  width: 12px;
  height: 12px;
  margin: -6px auto 0;
  border: none;
  border-radius: 0;
}
</style>
