<template>
  <div>
    <div class="layer-toolbar">
      <div class="layer-toolbar-left">
        <feather-icon icon="LayersIcon" style="margin-right: 5px" size="16" />
        <span style="padding: 0 5px">{{ $t('layers') }}</span>
      </div>
    </div>
    <div class="btn-action-layer">
      <b-button variant="primary" :title="$t('add_layer')" class="btn-sm text-center w-100 mb-0-5rem" @click="showAddMultiLayer">{{ $t('add_layer') }}</b-button>
      <b-button variant="primary" :title="$t('add_folder')" class="btn-sm text-center mb-0-5rem" @click="addGroup"><feather-icon icon="FolderPlusIcon" /></b-button>
    </div>

    <div class="layer-list" ref="refLayerList">
      <GroupLayers v-model="groups" @openContextMenu="openContextMenuRight" />
      <ContextMenuTool @actionPaste="actionPaste" @actionCopy="actionCopy" @actionDeleteOption="actionDeleteOption" :disableCopy="false" :disablePaste="contextMenuRight.disablePaste" openCdisablePaste="contextMenuRight.disablePaste" :disableDeleteOption="contextMenuRight.disableDeleteOption" :showCopy="contextMenuRight.actionCopy" :showPaste="true" :showDeleteOption="true" :styleContextMenu="styleContextMenu" :class="`context-menu-right-tool ${showContextmenuLayer ? 'vis-opa' : ''}`" />
    </div>

    <AddMultiLayerModal ref="addMultiLayer" />
  </div>
</template>

<script>
const _ = require('lodash')
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import { DEFAULT_LAYER, DEFAULT_GROUP } from '@/constants/default-layer.js'
import GroupLayers from './GroupLayers.vue'
import AddMultiLayerModal from './AddMultiLayerModal.vue'
import ContextMenuTool from './tools/ContextMenuTool.vue'
import { ACTION_LOG } from '@/constants/actionLog'

export default {
  directives: { Ripple },
  components: { GroupLayers, AddMultiLayerModal, ContextMenuTool },
  data() {
    return {
      showContextmenuLayer: false,
      styleContextMenu: `left:0px; top:0px`,
      contextMenuRight: {
        actionCopy: false,
        disablePaste: false,
        disableDeleteOption: false,
        layerCopy: null,
        method: 'layer',
      },
    }
  },
  mounted() {
    let self = this
    const option_group = this.$refs.refLayerList
    try {
      document.addEventListener('mousedown', (event) => {
        if (!option_group.contains(event.target) || event.which !== 3) {
          setTimeout(() => {
            self.showContextmenuLayer = false
          }, 150)
        }
      })
    } catch {
      self.showContextmenuLayer = false
    }
  },
  computed: {
    groups: {
      get() {
        return this.$store.state.layer.groups
      },
      set(value) {
        this.$store.commit('layer/SET_GROUPS', value)
      },
    },
    layers() {
      return this.$store.state.layer.layers
    },
    layerChangePosition() {
      return this.$store.state.layer.layerChangePosition
    },
    contextMenuCopy() {
      return this.$store.state.map.contextMenu.layerCopy
    },
  },
  watch: {
    groups: {
      deep: true,
      handler(val) {
        if (!this.layerChangePosition) return
        let originaLayer = []
        for (let i = 0; i < val.length; i++) {
          if (!val[i].isGroup) originaLayer.push(val[i])
          else {
            for (let j = 0; j < val[i].groupData.length; j++) {
              originaLayer.push(val[i].groupData[j])
            }
          }
        }
        this.$store.commit('layer/SET_LAYERS', originaLayer)
        this.$store.commit('layer/SET_STATUS_LAYER_MOVE', false)
      },
    },
    layers() {
      for (let i = 0; i < this.groups.length; i++) {
        if (this.groups[i].isGroup) {
          for (let j = 0; j < this.groups[i].groupData.length; j++) {
            let layer = this.layers.find((layer) => layer.id === this.groups[i].groupData[j].id)
            if (layer) this.groups[i].groupData.splice(j, 1, layer)
            else this.groups[i].groupData.splice(j, 1)
          }
        } else {
          //update layer: change option
          let layer = this.layers.find((layer) => layer.id === this.groups[i].id)
          if (layer) this.groups.splice(i, 1, layer)
          else this.groups.splice(i, 1)
        }
      }
    },
  },
  methods: {
    addLayer() {
      let layer = _.merge({}, { ...DEFAULT_LAYER, name: this.$i18n.t('new_layer') }, { id: uuidv4() })
      this.$store.commit('layer/ADD_GROUP', layer)
      this.$store.commit('layer/ADD_LAYER', layer)
      this.log.info({ message: `${this.$t('add_layer')}: '${this.$i18n.t('new_layer')}'`, id: ACTION_LOG[15].id })
    },

    addGroup() {
      let groupLayer = _.merge({}, { ...DEFAULT_GROUP, name: this.$i18n.t('new_group'), isGroup: true }, { id: uuidv4() })
      this.$store.commit('layer/ADD_GROUP', groupLayer)
      let messageTitle = 'add_group'
      let messageLog = 'new_group'
      let message = this.$t(messageTitle) + ': ' + this.$t(messageLog)
      this.log.info({ message, id: ACTION_LOG[10].id, messageTitle, messageLog })
      // this.log.info({ message: `${this.$t('add_group')}: '${this.$i18n.t('new_group')}'`, id: ACTION_LOG[10].id });
    },
    showAddMultiLayer() {
      this.$refs.addMultiLayer.open()
    },
    openContextMenuRight({ method, layer, event }) {
      try {
        if (method === 'group') {
          this.contextMenuRight = {
            actionCopy: false,
            disablePaste: !Object.keys(this.contextMenuCopy).length || this.contextMenuCopy == {},
            disableDeleteOption: Object.keys(this.contextMenuCopy).length == 0 || this.contextMenuCopy == {},
            layerCopy: layer,
            method,
          }
        }
        if (method === 'layer') {
          this.contextMenuRight = {
            actionCopy: true,
            disablePaste: !Object.keys(this.contextMenuCopy).length || this.contextMenuCopy == {} || this.contextMenuCopy.type !== layer.type,
            disableDeleteOption: Object.keys(this.contextMenuCopy).length == 0 || this.contextMenuCopy == {},
            layerCopy: layer,
            method,
          }
        }
        const DomSideBarRight = document.querySelector('.map-container-right').getBoundingClientRect()
        const contextMenu = document.querySelector(`.context-menu-right-tool`).getBoundingClientRect()
        let bottomHeight = DomSideBarRight.top + DomSideBarRight.height
        let rightWidth = DomSideBarRight.left + DomSideBarRight.width
        let top = bottomHeight - event.y > contextMenu.height + 10 ? event.y - DomSideBarRight.top : bottomHeight - (contextMenu.height + 10) - DomSideBarRight.top
        let left = rightWidth - event.x > contextMenu.width + 10 ? event.x - DomSideBarRight.left : rightWidth - (contextMenu.width + 10) - DomSideBarRight.left
        this.styleContextMenu = `left:${left}px; top:${top}px`
        this.showContextmenuLayer = true
      } catch {
        this.showContextmenuLayer = false
      }
    },
    actionPaste() {
      if (this.contextMenuRight.method == 'layer') {
        let layer = this.contextMenuRight.layerCopy
        if (!(layer.type === this.contextMenuCopy.type)) return
        let netcdfCache
        if (layer.type == 'netcdf') {
          netcdfCache = _.cloneDeep(layer.netcdf.netcdfCache)
        }
        layer = _.merge(layer, this.contextMenuCopy)
        if (layer.type == 'netcdf' && netcdfCache) {
          layer.netcdf.netcdfCache = netcdfCache
        }
        layer.visible = _.cloneDeep(this.contextMenuCopy.visible)
        layer.version++
        layer.updateStyle++
        this.$store.commit('layer/UPDATE_LAYER_FROM_COPYPASTE', layer)
      }
      if (this.contextMenuRight.method == 'group') {
        let group = this.contextMenuRight.layerCopy
        for (let i = 0; i < group.groupData.length; i++) {
          if (!(group.groupData[i].type === this.contextMenuCopy.type)) continue
          let netcdfCache
          if (group.groupData[i].type == 'netcdf') {
            netcdfCache = _.cloneDeep(group.groupData[i].netcdf.netcdfCache)
          }
          group.groupData[i] = _.merge(group.groupData[i], this.contextMenuCopy)
          if (group.groupData[i].type == 'netcdf' && netcdfCache) {
            group.groupData[i].netcdf.netcdfCache = netcdfCache
          }
          group.groupData[i].visible = _.cloneDeep(this.contextMenuCopy.visible)
          group.groupData[i].version++
          group.groupData[i].updateStyle++
        }
      }
    },
    actionCopy() {
      if (Object.keys(this.contextMenuRight.layerCopy).length && this.contextMenuRight.method == 'layer') {
        let layerCopy = _.cloneDeep(this.contextMenuRight.layerCopy)
        // delete full datasource, item, localtion
        let arrCopyDelete = ['dataImage', 'dataMulti', 'dataNetcdf', 'dataPoint', 'dataTimeseries', 'dataTyphoon', 'id', 'item', 'name', 'version', 'updateStyle']
        for (let i = 0; i < arrCopyDelete.length; i++) {
          try {
            delete layerCopy[arrCopyDelete[i]]
          } catch {}
        }
        if (layerCopy.type == 'netcdf') delete layerCopy.netcdf.netcdfCache
        this.$store.commit('map/UPDATE_CONTEXTMENU', { layerCopy })
      }
    },
    actionDeleteOption() {
      this.$store.commit('map/UPDATE_CONTEXTMENU', { layerCopy: {} })
    },
  },
}
</script>

<style scoped lang="scss">
.layer-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0 8px;
  line-height: 1.2;
  font-size: 1.285rem;
  &-left {
    display: flex;
    align-items: center;
  }
}
.layer-list {
  padding: 0 8px;
}
.btn-action-layer {
  padding: 0 8px;
  display: flex;
  gap: 3px;
}
</style>
