<template>
  <div class="btn btn-sm layer-hover" :class="this.activeLayer === this.layerName ? 'layer-active btn-outline-primary' : ''" @click="updateActiveLayer" @keydown.space="updateActiveLayer">
    <img class="layer-hover-img" :src="require(`/static/images/background-layer/${this.layerName}.png`)" alt="" />
    <div v-if="this.activeLayer === this.layerName" class="layer-hover-icon-active">
      <svg class="w-6 h-6" width="13px" height="13px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="checkmark-outline" fill-rule="nonzero">
            <path d="M31.1442786,171.840796 C5.2779518,146.858262 -5.09578082,109.862896 4.01023318,75.0738981 C13.1162472,40.2848999 40.2848999,13.1162472 75.0738981,4.01023318 C109.862896,-5.09578082 146.858262,5.2779518 171.840796,31.1442786 C209.549474,70.1869539 209.010186,132.247241 170.628714,170.628714 C132.247241,209.010186 70.1869539,209.549474 31.1442786,171.840796 Z" id="Shape" fill="#397bff"></path>
            <polygon id="Path" fill="#fff" points="66.6666667 89.4527363 89.5522388 112.437811 132.338308 69.6517413 146.268657 83.7810945 89.5522388 140.298507 52.7363184 103.482587 66.6666667 89.3532338"></polygon>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'activeLayer',
    event: 'onUpdateLayer',
  },
  props: ['layerName', 'activeLayer'],
  methods: {
    updateActiveLayer() {
      this.$emit('onUpdateLayer', this.layerName)
    },
  },
}
</script>
<style scoped lang="scss">
.layer-hover {
  transition: 500ms all;
  position: relative;
  overflow: hidden;
  height: 60px;
  width: 60px;
  padding: 0.5px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &-icon-active {
    position: absolute;
    top: 3px;
    right: 3px;
  }
  &-img {
    width: calc(100% + 1px);
    height:calc(100% + 1px);
    object-fit: cover;
    border-radius: 5px;
  }
}
.dark-layout {
  .layer-hover {
    border: 1px solid #404656;
    &-img {
      // filter: brightness(1) invert(1);
    }
  }
}
.layer-active {
  box-shadow: 0 8px 25px -8px #397bff;
}

.layer-hover:hover {
  cursor: pointer;
  box-shadow: 0 8px 25px -8px #397bff;
  border: 1px solid #397bff !important;
}
</style>
