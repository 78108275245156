<template>
  <div>
    <b-modal :title="$t('add_layer')" centered :hide-footer="true" ref="addLayersModal" :body-class="'custom-body tools-body'" modal-class="add-multi-layer-modal" @show="resetState">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between flex-grow-1">
          <div>
            <h4 class="modal-title">{{ $t('add_layer') }}</h4>
          </div>
        </div>
        <button type="button" class="close" :title="$t('close')" @click="close">&times;</button>
      </template>

      <b-card-text>
        <section id="faq-tabs">
          <b-tabs pills>
            <b-tab @click="typeAdd(categoryObj.value)" v-for="(categoryObj, index) in optionSetting" :key="categoryObj.title" :active="!index">
              <template #title>
                <dynamic-icon :icon="categoryObj.icon" :style="{ width: '18px', height: '18px', marginRight: '5px' }" />
                <span class="font-weight-bold">{{ $t(categoryObj.title) }}</span>
              </template>
              <!-- Timeseries------------------------------------------ -->
              <div v-if="categoryObj.value === 'timeseries'" class="d-flex justify-content-between">
                <div style="width: 250px">
                  <b-form-group>
                    <div class="grid-select-container1 background form-control custom-scrollbar form-select">
                      <div v-for="option in optionLocation" :key="option.value" class="gridLocation text-over-ellip" :id="`${option.value}`">
                        {{ option.label }}
                      </div>
                    </div>
                  </b-form-group>
                </div>

                <div style="width: 330px">
                  <b-form-group>
                    <div class="grid-select-container background form-control custom-scrollbar form-select">
                      <div v-for="file in optionTimeseries" :key="file.value" class="grid text-over-ellip" :id="`${file.value}`">
                        {{ file.label }}
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <!-- Typhoons------------------------------------------ -->
              <div v-if="categoryObj.value === 'typhoon'">
                <b-form-group>
                  <div class="grid-select-container background form-control custom-scrollbar form-select">
                    <div v-for="file in optionTyphoon" :key="file.value" class="grid" :id="`${file.value}`">
                      {{ file.label }}
                    </div>
                  </div>
                </b-form-group>
              </div>

              <!-- Images------------------------------------------ -->
              <div v-if="categoryObj.value === 'image'">
                <b-form-group>
                  <div class="grid-select-container background form-control custom-scrollbar form-select">
                    <div v-for="file in optionImage" :key="file.value" class="grid" :id="`${file.value}`">
                      {{ file.label }}
                    </div>
                  </div>
                </b-form-group>
              </div>

              <!-- Multi------------------------------------------ -->
              <div v-if="categoryObj.value === 'multi'" class="d-flex justify-content-between">
                <div style="width: 250px">
                  <b-form-group>
                    <!-- <vue-select :options="optionMultiLocation" class="select-size" v-model="selectedMultiLocation" /> -->
                    <div class="grid-select-container2 background form-control custom-scrollbar form-select">
                      <div v-for="option in optionMultiLocation" :key="option.value" class="gridMultiLocation text-over-ellip" :id="`${option.value}`">
                        {{ option.label }}
                      </div>
                    </div>
                  </b-form-group>
                </div>

                <div style="width: 330px">
                  <b-form-group>
                    <div class="grid-select-container background form-control custom-scrollbar form-select">
                      <div v-for="file in optionTimeseries" :key="file.value" class="grid text-over-ellip" :id="`${file.value}`">
                        {{ file.label }}
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="mb-1">
                <label>{{ $t('template').toUpperCase() }}</label>
                <div v-if="categoryObj.value === 'timeseries'" class="custom-scrollbar" style="height: 195px; overflow: auto">
                  <!-- Circle  -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in circleColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                  <!-- Triangle  -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in triangleColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                  <!-- Square  -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in squareColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                  <!-- Spike  -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in spikeColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                </div>

                <div v-if="categoryObj.value === 'typhoon'" class="custom-scrollbar" style="height: 195px; overflow: auto">
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in typhoonColorConfig" :key="index" :layer-name="locale === 'en' ? option.name : 'DefaultJP'" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                </div>
                <div v-if="categoryObj.value === 'image'" class="custom-scrollbar" style="height: 195px; overflow: auto">
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in imageColorConfig" :key="index" :layer-name="locale === 'en' ? option.name : 'DefaultJP'" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                </div>
                <div v-if="categoryObj.value === 'multi'" class="custom-scrollbar" style="height: 195px; overflow: auto">
                  <!-- Circle Shape  -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in circleShapeColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                  <!-- Triangle Shape -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in triangleShapeColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                  <!-- Square Shape -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in squareShapeColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                  <!-- Spike Shape -->
                  <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                    <PrefixLayer v-for="(option, index) in spikeShapeColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </section>
      </b-card-text>

      <div class="d-flex justify-content-between align-items-center">
        <b-form-checkbox v-model="addToGroup">{{ $t('add_folder') }}</b-form-checkbox>
        <div>
          <b-button :disabled="typeLayer === 'timeseries' ? loadingCreate || !selectedIdLocation : typeLayer === 'netcdf' ? loadingCreate || (selectedNetCDF && selectedNetCDF.length === 0) : typeLayer === 'typhoon' ? loadingCreate || (selectedTyphoon && selectedTyphoon.length === 0) : typeLayer === 'image' ? loadingCreate || (selectedImage && selectedImage.length === 0) : loadingCreate || !selectedIdMultiLocation" class="upload-image-sidebar" @click="createLayers" variant="primary">
            <b-spinner v-if="loadingCreate" style="width: 15px; height: 15px" />
            <span v-if="!loadingCreate"> {{ $t('create_layers') }} </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
const _ = require('lodash')
import { v4 as uuidv4 } from 'uuid'
import SelectionArea from '@/scripts/viselect/viselect.js'
import { DEFAULT_LAYER, DEFAULT_GROUP } from '@/constants/default-layer.js'
import PrefixLayer from './PrefixLayer.vue'
import { GRADIENT_COLOR, FILL_COLOR, FLAT_COLOR, GOURAUD_COLOR, LINE_COLOR, CIRCLE, TRIANGLE, SQUARE, SPIKE, CIRCLE_SHAPE, TRIANGLE_SHAPE, SQUARE_SHAPE, SPIKE_SHAPE, TYPHOON, IMAGE } from '@/constants/template-layer'
import i18n from '@/libs/i18n'

export default {
  components: { PrefixLayer },
  created() {
    this.selection = null
    this.selectionLocation = null
    this.selectionMultiLocation = null
  },
  mounted() {
    this.createSelectArea()
    this.createSelectLocation()
    this.createSelectMultiLocation()
  },
  data() {
    return {
      selectedLocation: null,
      selectedMultiLocation: null,
      selectedIdMultiLocation: null,
      selectedIdLocation: null,
      selectedIdTimeseries: [],
      selectedIdMultiTimeseries: [],
      selectedIdTyphoon: [],
      selectedIdImage: [],
      addToGroup: false,
      loadingCreate: false,
      typeLayer: 'timeseries',
      activeLayer: '',
      gradientColorConfig: GRADIENT_COLOR,
      fillColorConfig: FILL_COLOR,
      flatColorConfig: FLAT_COLOR,
      gouraudColorConfig: GOURAUD_COLOR,
      lineColorConfig: LINE_COLOR,
      circleColorConfig: CIRCLE,
      triangleColorConfig: TRIANGLE,
      squareColorConfig: SQUARE,
      spikeColorConfig: SPIKE,
      circleShapeColorConfig: CIRCLE,
      triangleShapeColorConfig: TRIANGLE,
      squareShapeColorConfig: SQUARE,
      spikeShapeColorConfig: SPIKE,
      typhoonColorConfig: TYPHOON,
      imageColorConfig: IMAGE,
      layerCommit: _.cloneDeep(DEFAULT_LAYER),
    }
  },

  computed: {
    optionSetting() {
      return [
        { title: 'point', icon: 'layer-config/radio/type-point', value: 'timeseries' },
        { title: 'shape', icon: 'layer-config/radio/type-multi', value: 'multi' },
        { title: 'typhoon', icon: 'layer-config/radio/type-typhoon', value: 'typhoon' },
        { title: 'image', icon: 'layer-config/radio/type-image', value: 'image' },
      ]
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    optionLocation() {
      return this.datasources.filter((d) => ['point'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
    optionTimeseries() {
      return this.datasources.filter((d) => ['timeseries'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
    selectedLocations() {
      let arraySelectedLocation = []
      let objFile = this.datasources.filter((d) => ['point'].includes(d.type)).find((d) => d.id === this.selectedIdLocation)
      if (objFile) arraySelectedLocation.push({ value: objFile.id, label: objFile.name, type: objFile.type })

      return arraySelectedLocation
    },
    selectedTimeseries() {
      let arraySelectedTimeseries = []
      for (let i = 0; i < this.selectedIdTimeseries.length; i++) {
        let objFile = this.datasources.filter((d) => ['timeseries'].includes(d.type)).find((d) => d.id === this.selectedIdTimeseries[i])
        if (objFile) arraySelectedTimeseries.push({ value: objFile.id, label: objFile.name, type: objFile.type })
      }
      return arraySelectedTimeseries
    },

    optionTyphoon() {
      return this.datasources.filter((d) => ['typhoon'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
    selectedTyphoon() {
      let arraySelectedTyphoon = []
      for (let i = 0; i < this.selectedIdTyphoon.length; i++) {
        let objFile = this.datasources.filter((d) => ['typhoon'].includes(d.type)).find((d) => d.id === this.selectedIdTyphoon[i])
        if (objFile) arraySelectedTyphoon.push({ value: objFile.id, label: objFile.name, type: objFile.type })
      }
      return arraySelectedTyphoon
    },

    optionImage() {
      return this.datasources.filter((d) => ['image'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
    selectedImage() {
      let arraySelectedImage = []
      for (let i = 0; i < this.selectedIdImage.length; i++) {
        let objFile = this.datasources.filter((d) => ['image'].includes(d.type)).find((d) => d.id === this.selectedIdImage[i])
        if (objFile) arraySelectedImage.push({ value: objFile.id, label: objFile.name, type: objFile.type })
      }
      return arraySelectedImage
    },

    optionMultiLocation() {
      return this.datasources.filter((d) => ['multi'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
    selectedMultiLocations() {
      let arraySelectedMultiLocation = []
      let objFile = this.datasources.filter((d) => ['multi'].includes(d.type)).find((d) => d.id === this.selectedIdMultiLocation)
      if (objFile) arraySelectedMultiLocation.push({ value: objFile.id, label: objFile.name, type: objFile.type })

      return arraySelectedMultiLocation
    },
    // optionMultiTimeseries() {
    //   return this.datasources.filter((d) => ['timeseries'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }));
    // },
    selectedMultiTimeseries() {
      let arraySelectedMultiTimeseries = []
      for (let i = 0; i < this.selectedIdMultiTimeseries.length; i++) {
        let objFile = this.datasources.filter((d) => ['timeseries'].includes(d.type)).find((d) => d.id === this.selectedIdMultiTimeseries[i])
        if (objFile) arraySelectedMultiTimeseries.push({ value: objFile.id, label: objFile.name, type: objFile.type })
      }
      return arraySelectedMultiTimeseries
    },
    locale() {
      return i18n.locale
    },
  },

  methods: {
    fconUpdateLayer(config) {
      if (this.typeLayer === 'timeseries') this.layerCommit = _.merge(this.layerCommit, { fillColor: { mode: config.mode, colors: config.colors }, shape: { shapeType: config.shapeType } })
      if (this.typeLayer === 'multi') this.layerCommit = _.merge(this.layerCommit, { fillColor: { mode: config.mode, colors: config.colors }, shape: { shapeType: config.shapeType } })
    },
    configLayerDefaut() {
      if (this.typeLayer === 'timeseries') this.activeLayer = 'Circle'
      if (this.typeLayer === 'typhoon') this.activeLayer = this.locale === 'en' ? 'Default' : 'DefaultJP'
      if (this.typeLayer === 'image') this.activeLayer = this.locale === 'en' ? 'Default' : 'DefaultJP'
      if (this.typeLayer === 'multi') this.activeLayer = 'Circle'
    },
    typeAdd(type) {
      if (this.typeLayer !== type) {
        this.selectedLocation = null
        this.selectedMultiLocation = null
        this.typeLayer = type
        this.addToGroup = false
        this.selectedIdMultiLocation = null
        this.selectedIdLocation = null
        this.selectedIdTimeseries = []
        this.selectedIdMultiTimeseries = []
        this.selectedIdTyphoon = []
        this.selectedIdImage = []
        this.createSelectArea()
        this.configLayerDefaut()
        this.layerCommit = _.cloneDeep(DEFAULT_LAYER)
        this.createSelectLocation()
        this.createSelectMultiLocation()
      }
    },
    createSelectMultiLocation() {
      if (this.selectionMultiLocation) {
        this.selectionMultiLocation.clearSelection()
        this.selectionMultiLocation.destroy()
        this.selectionMultiLocation = null
      }
      this.selectionMultiLocation = new SelectionArea({
        selectionAreaClass: 'selection-area',
        selectables: ['.grid-select-container2 .gridMultiLocation'],
        boundaries: ['.grid-select-container2'],
      })

      this.selectionMultiLocation
        .on('start', ({ store, event }) => {
          if (!event.metaKey) {
            let gridCells = document.querySelectorAll('.gridMultiLocation.selected')
            for (const el of gridCells) {
              el.classList.remove('selected')
            }
            this.selectionMultiLocation.clearSelection()
          }
        })
        .on(
          'move',
          ({
            store: {
              changed: { added, removed },
            },
          }) => {
            if (added.length != 1) {
              let gridCells = document.querySelectorAll('.gridMultiLocation.selected')
              for (const el of gridCells) {
                el.classList.remove('selected')
              }
            } else {
              added[0].classList.add('selected')
            }
            for (const el of removed) {
              el.classList.remove('selected')
            }
          }
        )
        .on('stop', ({ store: { stored } }) => {
          if (stored.length == 1) {
            let ids = stored.map((div) => div.id)
            this.selectedIdMultiLocation = ids[0]
          } else {
            this.selectedIdMultiLocation = null
          }
        })
      let gridCells = document.querySelectorAll('.gridMultiLocation.selected')
      for (const el of gridCells) {
        el.classList.remove('selected')
      }
    },
    createSelectLocation() {
      if (this.selectionLocation) {
        this.selectionLocation.clearSelection()
        this.selectionLocation.destroy()
        this.selectionLocation = null
      }
      this.selectionLocation = new SelectionArea({
        selectionAreaClass: 'selection-area',
        selectables: ['.grid-select-container1 .gridLocation'],
        boundaries: ['.grid-select-container1'],
      })

      this.selectionLocation
        .on('start', ({ store, event }) => {
          if (!event.metaKey) {
            let gridCells = document.querySelectorAll('.gridLocation.selected')
            for (const el of gridCells) {
              el.classList.remove('selected')
            }
            this.selectionLocation.clearSelection()
          }
        })
        .on(
          'move',
          ({
            store: {
              changed: { added, removed },
            },
          }) => {
            if (added.length != 1) {
              let gridCells = document.querySelectorAll('.gridLocation.selected')
              for (const el of gridCells) {
                el.classList.remove('selected')
              }
            } else {
              added[0].classList.add('selected')
            }

            // for (const el of added) {
            //   el.classList.add('selected');
            // }
            for (const el of removed) {
              el.classList.remove('selected')
            }
          }
        )
        .on('stop', ({ store: { stored } }) => {
          if (stored.length == 1) {
            let ids = stored.map((div) => div.id)
            this.selectedIdLocation = ids[0]
          } else {
            this.selectedIdLocation = null
          }
        })
      let gridCells = document.querySelectorAll('.gridLocation.selected')
      for (const el of gridCells) {
        el.classList.remove('selected')
      }
    },
    createSelectArea() {
      if (this.selection) {
        this.selection.clearSelection()
        this.selection.destroy()
        this.selection = null
      }
      this.selection = new SelectionArea({
        // selectionAreaClass: 'selection-area',
        selectables: ['.grid-select-container .grid'],
        boundaries: ['.grid-select-container'],
      })
        .on('start', ({ store, event }) => {
          if (!event.ctrlKey && !event.metaKey) {
            let gridCells = document.querySelectorAll('.grid.selected')
            for (const el of gridCells) {
              el.classList.remove('selected')
            }
            // if(event.type == "mousemove") this.selection.clearSelection(false);
            // else this.selection.clearSelection();
            this.selection.clearSelection()
          }
        })
        .on(
          'move',
          ({
            store: {
              changed: { added, removed },
            },
          }) => {
            for (const el of added) {
              el.classList.add('selected')
            }
            for (const el of removed) {
              el.classList.remove('selected')
            }
          }
        )
        .on('stop', ({ store: { stored } }) => {
          let ids = stored.map((div) => div.id)
          if (this.typeLayer === 'timeseries') this.selectedIdTimeseries = ids
          if (this.typeLayer === 'typhoon') this.selectedIdTyphoon = ids
          if (this.typeLayer === 'image') this.selectedIdImage = ids
          if (this.typeLayer === 'multi') this.selectedIdMultiTimeseries = ids
        })
      let gridCells = document.querySelectorAll('.grid.selected')
      for (const el of gridCells) {
        el.classList.remove('selected')
      }
    },
    open() {
      this.$refs.addLayersModal.show()
    },
    close() {
      this.$refs.addLayersModal.hide()
    },

    resetState() {
      this.selectedLocation = null
      this.selectedMultiLocation = null
      this.addToGroup = false
      this.selectedIdLocation = null
      this.selectedIdTimeseries = []
      this.selectedIdMultiTimeseries = []
      this.selectedIdMultiLocation = null
      this.selectedIdTyphoon = []
      this.selectedIdImage = []
      this.typeLayer = 'timeseries'
      this.activeLayer = 'Circle'
      this.layerCommit = _.cloneDeep(DEFAULT_LAYER)
    },

    createLayers() {
      this.loadingCreate = true
      if (this.typeLayer === 'timeseries') {
        // loop create layer Timeseries
        if (this.addToGroup) {
          let groupLayer = _.merge({}, { ...DEFAULT_GROUP, name: this.$i18n.t('new_group'), isGroup: true }, { id: uuidv4() })
          if (this.selectedTimeseries.length != 0) {
            for (let i = 0; i < this.selectedTimeseries.length; i++) {
              let itemSelected
              try {
                for (let key in this.$db[this.selectedTimeseries[i].value].items) {
                  if (key != 'LONGITUDE' && key != 'LATITUDE') {
                    itemSelected = key
                    break
                  }
                }
              } catch {}
              let layer = _.merge({}, { ...this.layerCommit, name: this.selectedTimeseries[i].label }, { id: uuidv4(), dataPoint: this.selectedLocations[0].value, dataTimeseries: this.selectedTimeseries[i].value, type: this.selectedLocations[0].type, item: itemSelected })
              groupLayer.groupData.push(layer)
              this.$store.commit('layer/ADD_LAYER', layer)
            }
          } else {
            let nameLayer = this.$i18n.t('new_layer')
            try {
              let nameTemp = this.selectedLocations[0].label
              let idxLastDot = nameTemp.lastIndexOf('.')
              nameLayer = this.$i18n.t(nameTemp.slice(0, idxLastDot))
            } catch {}
            let layer = _.merge({}, { ...this.layerCommit, name: nameLayer }, { id: uuidv4(), dataPoint: this.selectedLocations[0].value, type: this.selectedLocations[0].type })
            groupLayer.groupData.push(layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
          this.$store.commit('layer/ADD_GROUP', groupLayer)
        } else {
          if (this.selectedTimeseries.length != 0) {
            for (let i = 0; i < this.selectedTimeseries.length; i++) {
              let itemSelected
              try {
                for (let key in this.$db[this.selectedTimeseries[i].value].items) {
                  if (key != 'LONGITUDE' && key != 'LATITUDE') {
                    itemSelected = key
                    break
                  }
                }
              } catch {}
              let layer = _.merge({}, { ...this.layerCommit, name: this.selectedTimeseries[i].label }, { id: uuidv4(), dataPoint: this.selectedLocations[0].value, dataTimeseries: this.selectedTimeseries[i].value, type: this.selectedLocations[0].type, item: itemSelected })
              this.$store.commit('layer/ADD_GROUP', layer)
              this.$store.commit('layer/ADD_LAYER', layer)
            }
          } else {
            let nameLayer = this.$i18n.t('new_layer')
            try {
              let nameTemp = this.selectedLocations[0].label
              let idxLastDot = nameTemp.lastIndexOf('.')
              nameLayer = this.$i18n.t(nameTemp.slice(0, idxLastDot))
            } catch {}
            let layer = _.merge({}, { ...this.layerCommit, name: nameLayer }, { id: uuidv4(), dataPoint: this.selectedLocations[0].value, type: this.selectedLocations[0].type })
            this.$store.commit('layer/ADD_GROUP', layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
        }
      } else if (this.typeLayer === 'netcdf') {
        // loop create layer netcdf
        if (this.addToGroup) {
          let groupLayer = _.merge({}, { ...DEFAULT_GROUP, name: this.$i18n.t('new_group'), isGroup: true }, { id: uuidv4() })
          for (let i = 0; i < this.selectedNetCDF.length; i++) {
            let itemSelected
            try {
              for (let key in this.$db[this.selectedNetCDF[i].value].items) {
                if (key != 'LONGITUDE' && key != 'LATITUDE') {
                  itemSelected = key
                  break
                }
              }
            } catch {}
            let layer = _.merge({}, { ...this.layerCommit, name: this.selectedNetCDF[i].label }, { id: uuidv4(), dataNetcdf: this.selectedNetCDF[i].value, type: this.selectedNetCDF[i].type, item: itemSelected })
            groupLayer.groupData.push(layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
          this.$store.commit('layer/ADD_GROUP', groupLayer)
        } else {
          for (let i = 0; i < this.selectedNetCDF.length; i++) {
            let itemSelected
            try {
              for (let key in this.$db[this.selectedNetCDF[i].value].items) {
                if (key != 'LONGITUDE' && key != 'LATITUDE') {
                  itemSelected = key
                  break
                }
              }
            } catch {}
            let layer = _.merge({}, { ...this.layerCommit, name: this.selectedNetCDF[i].label }, { id: uuidv4(), dataNetcdf: this.selectedNetCDF[i].value, type: this.selectedNetCDF[i].type, item: itemSelected })
            this.$store.commit('layer/ADD_GROUP', layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
        }
      } else if (this.typeLayer === 'typhoon') {
        // loop create layer typhoon
        if (this.addToGroup) {
          let groupLayer = _.merge({}, { ...DEFAULT_GROUP, name: this.$i18n.t('new_group'), isGroup: true }, { id: uuidv4() })
          for (let i = 0; i < this.selectedTyphoon.length; i++) {
            let layer = _.merge({}, { ...this.layerCommit, name: this.selectedTyphoon[i].label }, { id: uuidv4(), dataTyphoon: this.selectedTyphoon[i].value, type: this.selectedTyphoon[i].type })
            groupLayer.groupData.push(layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
          this.$store.commit('layer/ADD_GROUP', groupLayer)
        } else {
          for (let i = 0; i < this.selectedTyphoon.length; i++) {
            let layer = _.merge({}, { ...this.layerCommit, name: this.selectedTyphoon[i].label }, { id: uuidv4(), dataTyphoon: this.selectedTyphoon[i].value, type: this.selectedTyphoon[i].type })
            this.$store.commit('layer/ADD_GROUP', layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
        }
      } else if (this.typeLayer === 'image') {
        // loop create layer image
        if (this.addToGroup) {
          let groupLayer = _.merge({}, { ...DEFAULT_GROUP, name: this.$i18n.t('new_group'), isGroup: true }, { id: uuidv4() })
          for (let i = 0; i < this.selectedImage.length; i++) {
            let layer = _.merge({}, { ...this.layerCommit, name: this.selectedImage[i].label }, { id: uuidv4(), dataImage: this.selectedImage[i].value, type: this.selectedImage[i].type })
            groupLayer.groupData.push(layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
          this.$store.commit('layer/ADD_GROUP', groupLayer)
        } else {
          for (let i = 0; i < this.selectedImage.length; i++) {
            let layer = _.merge({}, { ...this.layerCommit, name: this.selectedImage[i].label }, { id: uuidv4(), dataImage: this.selectedImage[i].value, type: this.selectedImage[i].type })
            this.$store.commit('layer/ADD_GROUP', layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
        }
      } else {
        // loop create layer multi data
        if (this.addToGroup) {
          let groupLayer = _.merge({}, { ...DEFAULT_GROUP, name: this.$i18n.t('new_group'), isGroup: true }, { id: uuidv4() })
          if (this.selectedMultiTimeseries.length != 0) {
            for (let i = 0; i < this.selectedMultiTimeseries.length; i++) {
              let itemSelected
              try {
                for (let key in this.$db[this.selectedMultiTimeseries[i].value].items) {
                  if (key != 'LONGITUDE' && key != 'LATITUDE') {
                    itemSelected = key
                    break
                  }
                }
              } catch {}
              let layer = _.merge({}, { ...this.layerCommit, name: this.selectedMultiTimeseries[i].label }, { id: uuidv4(), dataMulti: this.selectedMultiLocations[0].value, dataTimeseries: this.selectedMultiTimeseries[i].value, type: this.selectedMultiLocations[0].type, item: itemSelected })
              groupLayer.groupData.push(layer)
              this.$store.commit('layer/ADD_LAYER', layer)
            }
          } else {
            let nameLayer = this.$i18n.t('new_layer')
            try {
              let nameTemp = this.selectedMultiLocations[0].label
              let idxLastDot = nameTemp.lastIndexOf('.')
              nameLayer = this.$i18n.t(nameTemp.slice(0, idxLastDot))
            } catch {}
            let layer = _.merge({}, { ...this.layerCommit, name: nameLayer }, { id: uuidv4(), dataMulti: this.selectedMultiLocations[0].value, type: this.selectedMultiLocations[0].type })
            groupLayer.groupData.push(layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
          this.$store.commit('layer/ADD_GROUP', groupLayer)
        } else {
          if (this.selectedMultiTimeseries.length != 0) {
            for (let i = 0; i < this.selectedMultiTimeseries.length; i++) {
              let dataTimeseriesShape = this.selectedMultiTimeseries[i].value
              // let dataTimeseries;
              // if (this.selectedMultiTimeseries[i].type && this.selectedMultiTimeseries[i].type=='multi') {
              //   dataTimeseries = null;
              // } else {
              //   dataTimeseries = this.selectedMultiTimeseries[i].value;
              // }

              let itemSelected
              try {
                for (let key in this.$db[this.selectedMultiTimeseries[i].value].items) {
                  if (key != 'LONGITUDE' && key != 'LATITUDE') {
                    itemSelected = key
                    break
                  }
                }
              } catch {}
              let layer = _.merge({}, { ...this.layerCommit, name: this.selectedMultiTimeseries[i].label }, { id: uuidv4(), dataMulti: this.selectedMultiLocations[0].value, dataTimeseries: this.selectedMultiTimeseries[i].value, dataTimeseriesShape, type: this.selectedMultiLocations[0].type, item: itemSelected })
              this.$store.commit('layer/ADD_GROUP', layer)
              this.$store.commit('layer/ADD_LAYER', layer)
            }
          } else {
            let nameLayer = this.$i18n.t('new_layer')
            try {
              let nameTemp = this.selectedMultiLocations[0].label
              let idxLastDot = nameTemp.lastIndexOf('.')
              nameLayer = this.$i18n.t(nameTemp.slice(0, idxLastDot))
            } catch {}
            let layer = _.merge({}, { ...this.layerCommit, name: nameLayer }, { id: uuidv4(), dataMulti: this.selectedMultiLocations[0].value, type: this.selectedMultiLocations[0].type })
            this.$store.commit('layer/ADD_GROUP', layer)
            this.$store.commit('layer/ADD_LAYER', layer)
          }
        }
      }
      this.createSelectArea()
      this.loadingCreate = false
      this.close()
    },
  },
}
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
}
.grid-row {
  display: grid;
  grid-gap: 1px;
  flex-wrap: wrap;
  width: 400px;
  height: 40px;
  /* border-radius: 0.5rem; */
  user-select: none;
  margin-top: 1px;
}
.grid-select-container,
.grid-select-container1,
.grid-select-container2 {
  margin-bottom: 10px;
}
.grid-select-container .grid,
.grid-select-container1 .gridLocation,
.grid-select-container2 .gridMultiLocation {
  display: block;
  width: 100%;
  height: 2.714rem;
  padding: 0.438rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  opacity: 0.75;
  cursor: pointer;
  user-select: none;
  margin-bottom: 5px;
}

.grid-select-container .grid:hover,
.grid-select-container1 .gridLocation:hover,
.grid-select-container2 .gridMultiLocation:hover {
  opacity: 0.75;
  background: #397bff;
  color: #fff;
}

.grid-select-container .grid.selected,
.grid-select-container1 .gridLocation.selected,
.grid-select-container2 .gridMultiLocation.selected {
  background: #397bff;
  opacity: 1;
  color: #fff;
}

.background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<style lang="scss">
.selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
  z-index: 9999;
}

.add-multi-layer-modal .box-selected-netcdf {
  background-color: transparent;
  height: auto;
  min-height: 35px;
  padding: 0.438rem;
  padding-bottom: 0px;
}
.add-multi-layer-modal .form-select {
  background-color: transparent;
  // height: 379px;
  height: 324px;
  overflow: auto;
  padding: 0.438rem;
}

.add-multi-layer-modal .label-select-file {
  margin-right: 0.438rem;
  margin-bottom: 0.438rem;
}

.dark-layout .add-multi-layer-modal .form-select,
.dark-layout .add-multi-layer-modal .box-selected-netcdf,
.dark-layout .add-multi-layer-modal .grid-select-container .grid,
.dark-layout .add-multi-layer-modal .grid-select-container1 .gridLocation,
.dark-layout .add-multi-layer-modal .grid-select-container2 .gridMultiLocation {
  border-color: #404656 !important;
  color: #fff;
}

.add-multi-layer-modal .modal-body.custom-body {
  color: inherit !important;
}

.add-multi-layer-modal .modal-dialog {
  max-width: 650px;
}

// .add-multi-layer-modal .modal-content {
//   border-radius: 1rem;
// }

.add-multi-layer-modal .tools-body {
  border-radius: 1rem;
  padding: 1.4rem;
}

.add-multi-layer-modal .header {
  font-size: 30px;
  line-height: 1;
  text-align: center;
  margin-bottom: 5rem;
  position: relative;
  color: #397bff;
  font-weight: 600;
}
.add-multi-layer-modal .header::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  height: 4px;
  width: 80px;
  background-color: #397bff;
  transform: translateX(-50%);
}

.add-multi-layer-modal .tool-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.add-multi-layer-modal .tool-group:last-child {
  margin-bottom: 0;
}

.add-multi-layer-modal .tool-icon {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
}

.add-multi-layer-modal .tool-name {
  font-size: 20px;
  line-height: 1;
  color: #5e5873;
  font-weight: 500;
}
.box-selected-netcdf {
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 2.5px;
  }
}

.nav-pills .nav-link.active img,
.dark-layout .nav-pills .nav-link img {
  filter: invert(1);
}

.text-over-ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
