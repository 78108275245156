<template>
  <button type="button" class="palette-picker-container">
    <b-form-input :placeholder="placeholder" :size="size" readonly style="opacity: 1" />
    <div class="palette-picker">
      <div class="live-edit">
        <b-form-group>
          <ColorPicker :value="colors[0]" @input="colors.splice(0, 1, $event)" placeholder="Color 1" size="sm" :key="colorPickerKey" :left="'-20px'" />
        </b-form-group>
        <b-form-group>
          <ColorPicker :value="colors[1]" @input="colors.splice(1, 1, $event)" placeholder="Color 2" size="sm" :key="colorPickerKey" :left="'-20px'" />
        </b-form-group>
        <b-form-group>
          <ColorPicker :value="colors[2]" @input="colors.splice(2, 1, $event)" placeholder="Color 3" size="sm" :key="colorPickerKey" :left="'-20px'" />
        </b-form-group>
        <b-form-group>
          <ColorPicker :value="colors[3]" @input="colors.splice(3, 1, $event)" placeholder="Color 4" size="sm" :key="colorPickerKey" :left="'-20px'" />
        </b-form-group>
        <b-form-group>
          <ColorPicker :value="colors[4]" @input="colors.splice(4, 1, $event)" placeholder="Color 5" size="sm" :key="colorPickerKey" :left="'-20px'" />
        </b-form-group>
        <b-form-group>
          <ColorPicker :value="colors[5]" @input="colors.splice(5, 1, $event)" placeholder="Color 6" size="sm" :key="colorPickerKey" :left="'-20px'" />
        </b-form-group>
      </div>
      <div class="sample-palette">
        <div class="custom-scrollbar">
          <div class="sample-palette-item" v-for="(sample, index) in samples" :key="index" :style="{ background: `linear-gradient(to right, ${sample[0]} 0%, ${sample[0]} 16.67%, ${sample[1]} 16.67%, ${sample[1]} 33.33%, ${sample[2]} 33.33%, ${sample[2]} 50%, ${sample[3]} 50%, ${sample[3]} 66.67%, ${sample[4]} 66.67%, ${sample[4]} 83.33%, ${sample[5]} 83.33%, ${sample[5]} 100%)` }" @click="applySample(sample)"></div>
        </div>
      </div>
    </div>
    <div class="palette-display" :style="{ background: `linear-gradient(to right, ${colors[0]} 0%, ${colors[0]} 16.67%, ${colors[1]} 16.67%, ${colors[1]} 33.33%, ${colors[2]} 33.33%, ${colors[2]} 50%, ${colors[3]} 50%, ${colors[3]} 66.67%, ${colors[4]} 66.67%, ${colors[4]} 83.33%, ${colors[5]} 83.33%, ${colors[5]} 100%)` }"></div>
  </button>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ColorPicker from './ColorPicker.vue';
import { PALETTE_PICKER } from '@/constants/colors';

export default {
  props: ['placeholder', 'size', 'value'],
  components: { ColorPicker, VuePerfectScrollbar },
  data() {
    return {
      colors: this.value,
      samples: PALETTE_PICKER,
      colorPickerKey: 1,
    };
  },
  watch: {
    colors(colors) {
      this.$emit('input', colors);
    },
    value(colors) {
      this.colors = colors;
    },
  },
  methods: {
    applySample(sample) {
      this.colors = sample.slice();
      this.colorPickerKey++;
    },
  },
};
</script>

<style>
.palette-picker-container .ColorPicker-container input {
  border-radius: 0.357rem !important;
}
</style>

<style scoped>
.palette-picker-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: default;
  outline: none;
}

.palette-picker-container .palette-picker {
  position: absolute;
  right: 0;
  top: 100%;
  display: none;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 8px 16px rgb(0 0 0 / 15%);
  padding: 15px 0;
  width: 300px;
}

.palette-picker-container:focus .palette-picker {
  display: flex;
}

.palette-picker-container:focus-within .palette-picker {
  display: flex;
}

.palette-picker .live-edit {
  width: 50%;
  padding: 0 5px 0 15px;
}
.palette-picker .live-edit .form-group:last-child {
  margin-bottom: 0;
}

.palette-picker .sample-palette {
  width: 50%;
  height: 250px;
}

.palette-picker .custom-scrollbar {
  height: 100%;
  padding: 0 15px 0 5px;
  overflow-y: auto;
}

.palette-picker .sample-palette-item {
  height: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.palette-picker .sample-palette-item:first-child {
  margin-top: 0;
}

.palette-display {
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 12px;
  right: 12px;
  pointer-events: none;
}
</style>
