<template>
  <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="dropdown-select" :dropup="dropdown && dropdown == 'dropup'" ref="dropdownSelect" @show="showDropdown" @hide="hideDropdown">
    <template #button-content class="btn-select">
      <feather-icon class="feather-instagram" icon="LayersIcon" size="16" />
      <div class="btn-select-text" style="height: 15px;" v-if="selected && selected != ''">{{ change[name] }}</div>
      <div class="btn-select-text" style="height: 15px;" v-else>{{ placeholder }}</div>
      <div class="btn-select-feather">
        <feather-icon v-if="selected && selected != ''" icon="XIcon" size="12" @click.stop="delSelected"/>
        <feather-icon class="btn-select-feather-after" :class="{ rotate: showDropdownSelect == true }" icon="ChevronRightIcon" size="12" />
      </div>
    </template>
    <b-dropdown-item :title="$t('layer') + `: ${item[name]}`" v-if="dataSelect && dataSelect.length" v-for="(item, index) in dataSelect" :key="item[keyitem]" @click.stop="clDropdownItem(item, index)" :class="{ active: selected == item[keyitem] }">
      <div :style="{ opacity: item.visible ? 1 : 0.5 }" class="dropdown-item-text">{{ item[name] }}</div>
      <feather-icon v-if="selected == item[keyitem]" icon="CheckIcon" size="12" />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Ripple from 'vue-ripple-directive';
export default {
  directives: { Ripple },
  props: ['value', 'placeholder', 'name', 'keyitem', 'active', 'dropdown'],
  data() {
    return {
      selected: '',
      change: {},
      dataSelect: [],
      showDropdownSelect: false,
    };
  },
  computed: {
    layerSelected() {
      return this.$store.state.map.layerSelected;
    },
  },
  mounted() {
    if (this.active) {
      this.selected = this.active;
    }
    this.dataSelect = this.value;
  },
  watch: {
    value() {
      this.dataSelect = this.value;
      try {
        if (this.value.length && this.active) {
          for (let i = 0; i < this.value.length; i++) {
            if (this.active == this.value[i][this.keyitem]) {
              this.change = {
                [this.name]: this.value[i][this.name],
                [this.keyitem]: this.value[i][this.keyitem],
              };
              break;
            }
          }
        }
      } catch {}
    },
    layerSelected(layerSelected) {
      try {
        if (this.value.length && layerSelected) {
          for (let i = 0; i < this.value.length; i++) {
            if (layerSelected == this.value[i][this.keyitem]) {
              this.change = {
                [this.name]: this.value[i][this.name],
                [this.keyitem]: this.value[i][this.keyitem],
              };
              break;
            }
          }
        }
      } catch {}
    },
    active() {
      this.selected = this.active;
    },
    change: {
      handler() {
        this.$emit('change', this.change);
      },
    },
  },
  methods: {
    delSelected() {
      this.$refs.dropdownSelect.hide(true);
      this.selected = '';
      this.change = {
        [this.name]: '',
        [this.keyitem]: '',
      };
    },
    clDropdownItem(item) {
      this.selected = item[this.keyitem];
      this.change = {
        [this.name]: item[this.name],
        [this.keyitem]: this.selected,
      };
    },
    showDropdown() {
      this.showDropdownSelect = true;
    },
    hideDropdown() {
      this.showDropdownSelect = false;
    },
  },
};
</script>
<style scoped>
/* .btn-select-text {
  height: 15px;
} */
</style>

<style lang="scss">
.dropdown-select {
  .dropdown-menu {
    padding: 0;
    transform: translate3d(0px, 0px, 0px);
    border-radius: 8px;
    overflow-x: hidden;
    li {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: inherit;
        padding: 0 1rem 0 0;
      }
      &.active,
      &:active {
        color: #fff;
        background: #397bff;
      }
    }
  }
  .dropdown-item-text {
    color: inherit;
  }
  button.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 4px 4px 6px;
    min-width: 153px;
    border: inherit !important;
    &::after {
      display: none !important;
    }
    &:hover {
      box-shadow: none !important;
    }
  }
  .btn-select {
    &-text {
      flex: 1;
      text-align: left;
    }
    &-feather {
      margin-left: 2px;
      &-after {
        transition: all linear 0.2s;
        &.rotate {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>
