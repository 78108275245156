<template>
  <div class="ai-container">
    <section class="chat-app-window h-100">
      <div class="active-chat h-100 d-flex" style="flex-direction: column; justify-content: space-between">
        <div class="box-chat">
          <div class="chat-navbar">
            <header class="chat-header">
              <div class="d-flex align-items-center">
                <!-- <span style="margin-right: 5px">
                  <svg id="fi_2068998" enable-background="new 0 0 512 512" height="24" viewBox="0 0 512 512" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m181 301c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15s15-6.716 15-15v-30c0-8.284-6.716-15-15-15z"></path>
                    <path d="m331 361c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15s-15 6.716-15 15v30c0 8.284 6.716 15 15 15z"></path>
                    <path d="m272 106h164c8.284 0 15-6.716 15-15s-6.716-15-15-15h-164c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
                    <path
                      d="m512 176v-111c0-35.841-29.159-65-65-65h-186c-35.841 0-65 29.159-65 65v116h-20c-54.827 0-100.809 38.57-112.255 90h-2.745v-92.58c17.459-6.192 30-22.865 30-42.42 0-24.813-20.187-45-45-45s-45 20.187-45 45c0 19.555 12.541 36.228 30 42.42v94.821c-17.977 5.901-31 22.833-31 42.759v60c0 24.813 20.187 45 45 45h18.527c11.069 51.929 57.291 91 112.473 91h160c55.182 0 101.404-39.071 112.473-91h18.527c24.813 0 45-20.187 45-45v-60c0-24.813-20.187-45-45-45h-18.751c-2.331-10.48-6.115-20.577-11.247-30h9.998c35.841 0 65-29.159 65-65zm-286-111c0-19.299 15.701-35 35-35h186c19.299 0 35 15.701 35 35v111c0 19.299-15.701 35-35 35h-176c-2.329 0-4.625.542-6.708 1.583l-38.292 19.146zm-180 56c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15zm-16 255v-60c0-8.271 6.729-15 15-15h16v90h-16c-8.271 0-15-6.729-15-15zm452-60v60c0 8.271-6.729 15-15 15h-16v-90h16c8.271 0 15 6.729 15 15zm-61-20v101c0 46.869-38.131 85-85 85h-160c-46.869 0-85-38.131-85-85v-101c0-46.869 38.131-85 85-85h20v45c0 11.132 11.742 18.4 21.708 13.416l56.833-28.416h126.241c13.038 15.344 20.218 34.804 20.218 55z"
                    ></path>
                    <path d="m272 166h164c8.284 0 15-6.716 15-15s-6.716-15-15-15h-164c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
                    <path d="m211 406c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15z"></path>
                  </svg>
                </span> -->
                <h5 class="mb-0">{{ $t('chat_bot') }}</h5>
              </div>
              <div class="new-thread-chatpgt">
                <span :title="$t(`new_thread_chat`)" @click.stop="newThread">+</span>
              </div>
            </header>
          </div>

          <div class="chat-log-container" :style="{ maxHeight: `calc(100% - ${maxHeigtBoxChat}px)` }">
            <vue-perfect-scrollbar ref="refChatLogPS" class="user-chats scroll-area">
              <div class="chats">
                <div v-for="(msgGrp, index) in formattedChatLog" :key="index" class="chat" :class="{ 'chat-left': msgGrp.senderId === 'chatgpt' }">
                  <div class="chat-body">
                    <div :title="msgData.time" v-for="(msgData, index) in msgGrp.messages" :class="msgData.type == 'image' ? `chat-content-image chat-content-${msgGrp.senderId}` : ''" :key="index" class="chat-content">
                      <img v-if="msgData.type == 'image' && msgData.images && msgData.images.length" v-for="srcImg in msgData.images" :src="srcImg" class="tool-icon" />
                      <p v-if="msgData.type != 'image'" v-html="msgData.msg"></p>
                      <feather-icon v-if="msgData.type == 'image'" @click.stop="downloadImg(msgData.images, msgGrp.senderId)" class="x-icon download-image-chat" icon="ArrowDownIcon" size="14" />
                    </div>
                  </div>
                </div>
                <div v-show="isTyping" class="chat chat-left">
                  <div class="chat-body">
                    <div class="chat-content" style="height: 41px; overflow: hidden; width: 38px">
                      <p class="ellipsis">...</p>
                    </div>
                  </div>
                </div>
              </div>
            </vue-perfect-scrollbar>
          </div>
        </div>
         <!-- <button @click="readImgae">read image</button> -->
        <!-- <input type="file" ref="inputImage" @change="handleImgChange" /> -->
        <div class="d-flex input-openai">
          <!-- <div class="input-file" v-if="imageInput && imageInput.length">
            <div class="input-file-image">
              <img v-for="src in imageInput" :src="src" class="tool-icon" />
              <div
                class="btn-del-img"
                @click="
                  () => {
                    imageInput = [];
                  }
                "
              >
                x
              </div>
            </div>
          </div>
          <b-input-group class="input-group-merge form-send-message mr-1">
            <div class="ip-upload" :title="$t('upload_files')" :class="isTyping ? `disable-div` : ``" @click="uploadImage">+</div>
            <input type="file" accept=".jpg,.png,.jpeg" class="ip-dnone" ref="ipUploadImgae" @change="handleImgChange" />
            <b-form-input class="ip-enter-messages" :maxlength="100000" :disabled="isTyping ? true : false" style="border: 1px solid rgba(115, 103, 240, 0.24)" @keyup.enter="sendMessenger" v-model="chatInputMessage" :placeholder="$t('enter_your_message')" />
          </b-input-group>
          <b-button style="white-space: nowrap" :disabled="isTyping ? true : false" variant="primary" @click="sendMessenger" type="submit"> {{ $t('send') }} </b-button> -->
          <div class="input-file d-flex justify-content-between align-items-end" :style="{ bottom: bottomImg }">
            <div class="input-file-image" v-if="imageInput && imageInput.length" ref="refImage">
              <img v-for="src in imageInput" :src="src" class="tool-icon" />
              <div
                class="btn-del-img"
                @click="
                  () => {
                    imageInput = []
                  }
                "
              >
                x
              </div>
            </div>
            <div class="input-file-suggest d-flex justify-content-start align-items-start" ref="refHelp">
              <span class="suggest-item" @click.stop="addHelpMessages($t('describe_this_picture'))">{{ $t('describe_this_picture') }}</span>
              <span class="suggest-item" @click.stop="addHelpMessages($t('tell_me_more_about'))">{{ $t('tell_me_more_about') }}</span>
              <span class="suggest-item" @click.stop="addHelpMessages($t('analyze_the_statistical_trend_of'))">{{ $t('analyze_the_statistical_trend_of') }}</span>
              <span class="icon-helps-messages">
                <feather-icon icon="HelpCircleIcon" size="20" :title="$t('Suggest messages ')" />
              </span>
            </div>
          </div>
          <div class="div-textarea-chatgpt">
            <b-form-textarea @keydown.enter.prevent="handleEnter" :disabled="isTyping ? true : false" :spellcheck="false" v-model="chatInputMessage" size="sm" class="textarea-chatgpt" ref="refTextareaChatgpt" :style="{ padding: ' 4px 1.6rem 4px 8px !important', maxHeight: !chatInputMessage || (chatInputMessage && chatInputMessage.length < 55) ? `32px` : 'inherit' }" :placeholder="$t('ask_me_anything')" rows="0" max-rows="2"></b-form-textarea>
            <div class="btn-upload-send-chatgpt d-flex justify-content-between align-items-center" :class="isTyping ? `disable-div` : ``">
              <div class="d-flex justify-content-center align-items-center">
                <input type="file" accept=".jpg,.png,.jpeg" class="ip-dnone" ref="ipUploadImgae" @change="handleImgChange" />
                <feather-icon @click.stop="uploadImage" icon="ImageIcon" :class="isTyping ? `disable-div` : ``" size="14" />
              </div>
              <div class="d-flex justify-content-center align-items-center btn-send-messges">
                <span class="limit-messages-chat">{{ chatInputMessage.length }}/10000</span>
                <feather-icon icon="Navigation2Icon" :title="$t('enter_your_message')" @click.stop="sendMessenger" size="14" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ASSISTANT_ID } from '@/constants/openai';
const fs = require('fs')
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
const axios = require('axios')
const API_HUGGINGFACE = [
  {
    id: 0,
    url: 'https://api-inference.huggingface.co/models/runwayml/stable-diffusion-v1-5',
    model: 'stable-diffusion-v1-5',
    name: 'runwayml',
  },
  {
    id: 1,
    url: 'https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-2-1',
    model: 'stable-diffusion-2-1',
    name: 'stabilityai',
  },
  {
    id: 2,
    url: 'https://api-inference.huggingface.co/models/CompVis/stable-diffusion-v1-4',
    model: 'stable-diffusion-v1-4',
    name: 'CompVis',
  },
  {
    id: 3,
    url: 'https://api-inference.huggingface.co/models/prompthero/openjourney-v4',
    model: 'openjourney-v4',
    name: 'prompthero',
  },
  {
    id: 4,
    url: 'https://api-inference.huggingface.co/models/prompthero/openjourney',
    model: 'openjourney',
    name: 'prompthero',
  },
  {
    id: 5,
    url: 'https://api-inference.huggingface.co/models/eimiss/EimisAnimeDiffusion_1.0v',
    model: 'EimisAnimeDiffusion_1.0v',
    name: 'eimiss',
  },
]
const randomResponses = ["I'm afraid I don't have the answer for this right now. Maybe try a different idea?"]
export default {
  components: {
    VuePerfectScrollbar,
  },
  props: ['activeComponent'],
  data() {
    return {
      chatInputMessage: '',
      formattedChatLog: [],
      isTyping: false,
      messageschat: [],
      // assitant: null,
      threadAssitant: null,
      imageInput: [],
      imageUrl: [],
      nameImgSave: 'img',
      API_KEY: null,
      TOKEN_API_HUGGINGFACE: null,
      bottomImg: `calc(100% + 4px + 32px)`,
      maxHeigtBoxChat: 100,
    }
  },
  async mounted() {
    try {
      if (process.env.VUE_APP_OPENAI_API_KEY) {
        this.API_KEY = process.env.VUE_APP_OPENAI_API_KEY
      }
      if (process.env.VUE_APP_TOKEN_API_HUGGINGFACE) {
        this.TOKEN_API_HUGGINGFACE = process.env.VUE_APP_TOKEN_API_HUGGINGFACE
      }
    } catch {}
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    changeHeightElementHelpAndImage() {
      return `${this.locale}-${this.imageInput}-${this.chatInputMessage}`
    },
  },
  watch: {
    changeHeightElementHelpAndImage() {
      setTimeout(() => {
        let heightTemp = 100
        try {
          let refHelpgetBoundingHeight = 0
          try {
            refHelpgetBoundingHeight = this.$refs.refHelp.getBoundingClientRect().height
          } catch {}
          let refImagepgetBoundingHeight = 0
          try {
            refImagepgetBoundingHeight = this.$refs.refImage.getBoundingClientRect().height
          } catch {}
          let heightInputHelp = refHelpgetBoundingHeight < refImagepgetBoundingHeight ? refImagepgetBoundingHeight : refHelpgetBoundingHeight
          let elementChatGetBoundingHeight = 5
          try {
            elementChatGetBoundingHeight = this.$refs.refTextareaChatgpt.$el.getBoundingClientRect().height
          } catch {
            elementChatGetBoundingHeight = 32
          }
          heightTemp = elementChatGetBoundingHeight + 20 + heightInputHelp
        } catch {}
        this.maxHeigtBoxChat = heightTemp
        return heightTemp
      }, 120)
    },
    activeComponent(activeComponent) {
      if (activeComponent) {
        this.createThread()
      }
    },
    chatInputMessage(chatInputMessage) {
      setTimeout(() => {
        try {
          let elementChatGetBounding = this.$refs.refTextareaChatgpt.$el.getBoundingClientRect()
          this.bottomImg = `calc(100% + 4px + ${elementChatGetBounding.height}px`
        } catch {
          this.bottomImg = `calc(100% + 4px + 32px)`
        }
      }, 120)
    },
  },
  methods: {
    handleEnter() {
      this.sendMessenger()
    },
    addHelpMessages(messages) {
      this.chatInputMessage = messages
    },
    downloadImg(arrImage, from = 'ecoplot') {
      if (!arrImage || !arrImage.length) return
      for (let i = 0; i < arrImage.length; i++) {
        let nameImg = `${this.nameImgSave}_${i}.png`
        var link = document.createElement('a')
        let url = arrImage[i]
        link.href = url
        link.download = nameImg
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    async createImageFromHuggingFace(prompt) {
      let results = []
      for (let i = 0; i < API_HUGGINGFACE.length; i++) {
        try {
          let url = API_HUGGINGFACE[i].url
          const response = await fetch(url, {
            headers: { Authorization: `Bearer ${this.TOKEN_API_HUGGINGFACE}` },
            method: 'POST',
            body: JSON.stringify({ inputs: prompt }),
          })
          if (response.status == 200) {
            const result = await response.blob()
            let urlCreator = window.URL || window.webkitURL
            let imageUrl = urlCreator.createObjectURL(result)
            results.push(imageUrl)
          }
        } catch {}
      }
      return results
    },
    uploadImage() {
      this.$refs.ipUploadImgae.value = null
      this.$refs.ipUploadImgae.click()
    },
    handleImgChange(event) {
      if (!event || !event.target || !event.target.files || !event.target.files.length) return
      let file = event.target.files[0]
      const fileReader = new FileReader()
      fileReader.onload = async (e) => {
        const base64Image = e.target.result
        this.imageInput = [base64Image]
      }

      fileReader.readAsDataURL(file)
    },
    async createThread() {
      this.formattedChatLog = []
      // if (this.threadAssitant) return
      try {
        // let assitant = await axios.post(
        //   'https://api.openai.com/v1/assistants',
        //   {
        //     instructions: 'Hello! I am a virtual assistant from Sinka company. How can I assist you today?',
        //     name: 'Ecoplot cloud',
        //     tools: [{ type: 'code_interpreter' }],
        //     model: 'gpt-4-1106-preview',
        //   },
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: `Bearer ${this.API_KEY}`,
        //       'OpenAI-Beta': 'assistants=v1',
        //     },
        //   }
        // )
        // this.assitant = assitant.data
        // this.threadAssitant = await openai.beta.threads.create();
        let threads = await axios.post(
          'https://api.openai.com/v1/threads',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.API_KEY}`,
              'OpenAI-Beta': 'assistants=v1',
            },
          }
        )
        this.threadAssitant = threads.data
      } catch {}
    },
    async newThread() {
      this.threadAssitant = null
      this.createThread()
    },
    async delay(ms) {
      return new Promise((resolve) => setTimeout(() => resolve(), ms))
    },
    scrollToBottom() {
      setTimeout(() => {
        const container = document.querySelector('.ai-container .user-chats.scroll-area')
        document.querySelector('.ai-container .user-chats.scroll-area').scrollTop = container.scrollHeight
      }, 0)
    },
    async sendMessenger() {
      if (!this.threadAssitant) {
        this.createThread()
      }
      this.isTyping = true
      if (this.chatInputMessage === '') {
        this.isTyping = false
        return
      }
      let messegerRespon
      let time = ''
      let messeger = ''
      let imagesApi = []
      try {
        messeger = this.chatInputMessage
        let createImg = false
        this.chatInputMessage = ''
        this.nameImgSave = 'img'
        if (messeger.indexOf('/img') > -1) {
          this.nameImgSave = messeger.replaceAll('/img ', '')
          messeger = messeger.replaceAll('/img ', 'Image: ')
          createImg = true
        }
        time = moment().format('YYYY/MM/DD HH:mm:ss')
        if (this.imageInput && this.imageInput.length) {
          this.formattedChatLog.push({
            senderId: 'ecoplot',
            messages: [
              {
                msg: messeger,
                images: this.imageInput,
                time: time,
                type: 'image',
              },
            ],
          })
        }
        this.formattedChatLog.push({
          senderId: 'ecoplot',
          messages: [
            {
              msg: messeger,
              images: [],
              time: time,
              type: 'text',
            },
          ],
        })
        this.scrollToBottom()
        try {
          if (createImg) {
            messegerRespon = null
            try {
              imagesApi = await this.createImageFromHuggingFace(messeger)
            } catch {}
          } else {
            if (this.imageInput.length) {
              const messageRes = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                  model: 'gpt-4-vision-preview',
                  messages: [
                    {
                      role: 'user',
                      content: [
                        { type: 'text', text: messeger },
                        {
                          type: 'image_url',
                          image_url: {
                            url: this.imageInput[0],
                          },
                        },
                      ],
                    },
                  ],
                  max_tokens: 200,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.API_KEY}`,
                  },
                }
              )
              messegerRespon = messageRes.data.choices[0].message.content
            } else {
              // const message = await openai.beta.threads.messages.create(this.threadAssitant.id, {
              //   role: 'user',
              //   content: messeger,
              // });
              let messageRes = await axios.post(
                `https://api.openai.com/v1/threads/${this.threadAssitant.id}/messages`,
                {
                  role: 'user',
                  content: messeger,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.API_KEY}`,
                    'OpenAI-Beta': 'assistants=v1',
                  },
                }
              )
              let run_thread = await axios.post(
                `https://api.openai.com/v1/threads/${this.threadAssitant.id}/runs`,
                {
                  assistant_id: ASSISTANT_ID,
                  instructions: 'Assistants SinKa VietNam',
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.API_KEY}`,
                    'OpenAI-Beta': 'assistants=v1',
                  },
                }
              )
              run_thread = run_thread.data
              let isRunComplelte = false
              let count = 0
              while (count < 50 && isRunComplelte == false) {
                // const run_thread_event = await openai.beta.threads.runs.retrieve(this.threadAssitant.id, run_thread.id)
                let run_thread_event = await axios.get(`https://api.openai.com/v1/threads/${this.threadAssitant.id}/runs/${run_thread.id}`, {
                  headers: {
                    Authorization: `Bearer ${this.API_KEY}`,
                    'OpenAI-Beta': 'assistants=v1',
                  },
                })
                run_thread_event = run_thread_event.data
                if (run_thread_event.status == 'completed') {
                  // const lisst_messages = await openai.beta.threads.messages.list(this.threadAssitant.id)
                  let lisst_messages = await axios.get(`https://api.openai.com/v1/threads/${this.threadAssitant.id}/messages`, {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${this.API_KEY}`,
                      'OpenAI-Beta': 'assistants=v1',
                    },
                  })
                  lisst_messages = lisst_messages.data
                  if (lisst_messages && lisst_messages.data && lisst_messages.data.length && lisst_messages.data[0].role == 'assistant') {
                    messegerRespon = lisst_messages.data[0].content[0].text.value
                    try {
                      messegerRespon = messegerRespon.replaceAll('↵', '<br>')
                    } catch {}
                  }
                  isRunComplelte = true
                }
                count++
                await this.delay(500)
              }
            }
          }
        } catch {
          await this.delay(2000)
          time = moment().format('YYYY/MM/DD HH:mm:ss')
          const randomIndex = Math.floor(Math.random() * randomResponses.length)
          messegerRespon = randomResponses[randomIndex]
        }
        if (messegerRespon) {
          this.formattedChatLog.push({
            senderId: 'chatgpt',
            messages: [
              {
                msg: messegerRespon,
                time: time,
              },
            ],
          })
          this.scrollToBottom()
        }
        if (imagesApi && imagesApi.length) {
          this.formattedChatLog.push({
            senderId: 'chatgpt',
            messages: [
              {
                images: imagesApi,
                time: time,
                type: 'image',
              },
            ],
          })
        }
      } catch (error) {}
      this.chatInputMessage = ''
      this.imageInput = []
      this.$refs.ipUploadImgae.value = ''
      this.isTyping = false
    },
  },
}
</script>
<style scoped lang="scss">
.ip-dnone {
  display: none !important;
}
.chat-content-image {
  background: transparent !important;
  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
  &.chat-content-chatgpt {
    img {
      margin-left: 2px;
      margin-bottom: 2px;
      border: solid 1px #b7b2b23d;
      border-radius: 4px;
    }
  }
  &.chat-content-ecoplot {
    img {
      margin-right: 2px;
    }
  }
}
.chat-content {
  position: relative;
  &:hover .x-icon {
    display: block;
  }
}
.x-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: black;
  cursor: pointer;
  display: none;
}
.disable-div {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #efefef;
}
.div-textarea-chatgpt {
  position: relative;
  width: 100%;
}
.textarea-chatgpt {
  resize: none;
  border-bottom: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 2.5px;
  }
  &:focus {
    outline: none;
    box-shadow: none !important;
    & + .btn-upload-send-chatgpt {
      border-color: #397bff !important;
    }
  }
  &:hover {
    box-shadow: none !important;
  }
}
.btn-upload-send-chatgpt {
  position: absolute;
  border: 1px solid #d8d6de;
  border-top: 0;
  width: 100%;
  border-bottom-left-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  padding: 4px 8px 4px 8px !important;
  &:focus {
    border-color: #397bff !important;
  }
  svg {
    cursor: pointer;
  }
}
.suggest-item {
  font-size: 13px;
  border: solid 0.1px #397bff;
  padding: 1px 4px;
  border-radius: 0.25rem;
  color: #397bff;
  margin: 1px;
  text-align: end;
  cursor: pointer;
}
.limit-messages-chat {
  font-size: 12px;
  margin-right: 10px;
}
.icon-helps-messages {
  width: 18px;
  height: 18px;
  color: #397bff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 4px;
  img {
    width: 18px !important;
    height: 18px !important;
    object-fit: cover;
  }
}
.dark-layout {
  .textarea-chatgpt {
    background-color: #1b243e !important;
  }
  .btn-upload-send-chatgpt {
    border: 1px solid #404656;
    border-top: 0;
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;
  }
  .suggest-item {
    border: solid 0.1px #404656;
  }
}
</style>
<style lang="scss">
.input-openai {
  position: relative;
  bottom: 30px;
  .input-file {
    width: 100%;
    position: absolute;
    bottom: calc(100% + 32px);
    z-index: 10;
    &-image {
      position: relative;
      min-height: 100px;
      border: solid 1px #ccc;
      border-radius: 4px;
      overflow: hidden;
      padding-top: 0px;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    &-suggest {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-left: 4px;
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
      margin-right: -1px;
    }
  }
  .btn-del-img {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: #dc1d1d;
    padding-bottom: 4px;
    &:hover {
      border: solid 1px #dc5e5e;
    }
  }
}
.input-group-merge {
  margin-right: 5px !important;
}
.ip-upload {
  border-radius: 3px 0 0 3px;
  border: solid 1px #ccc;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border-right: none;
  cursor: pointer;
}
.ai-container {
  max-height: calc(100vh - 30px - 90.3px - 2rem - 20px);
  height: 870px;
  padding: 0.5rem;
  overflow-y: auto;
  width: 350px;
}
.ai-container .box-chat {
  height: calc(100vh - 30px - 90.3px - 2rem - 90px);
}
.ai-container .chat-log-container {
  height: 100%;
  max-height: calc(100% - 126px);
  transition: all linear 0.2s;
}
.ai-container .chat-log-container section {
  height: 100%;
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  background-color: transparent !important;
}
.ai-container .chat-header {
  background: transparent !important;
  border: none !important;
  color: #5e5873;
  height: 30px !important;
  margin-bottom: 5px;
}
.ai-container .chat-header svg {
  fill: #5e5873;
}
.dark-layout .ai-container .chat-header {
  color: #b4b7bd;
}
.dark-layout .ai-container .chat-header svg {
  fill: #b4b7bd;
}
.ai-container .chat-app-window .chats .chat-body {
  margin: 10px 0px 0 0;
}
.ai-container .chat-app-window .chats .chat-body .chat-content,
.ai-container .chat-app-window .chats .chat-left .chat-body .chat-content {
  min-height: 40px;
  min-width: 36px;
  margin: 0px 0px 10px 0;
}
.ai-container .chat-app-window .chats .chat-left .chat-body .chat-content {
  background-image: linear-gradient(80deg, #7367f0, #9087f2);
  color: #fff;
}
.ai-container .ellipsis {
  display: inline-block;
  width: 0;
  overflow: hidden;
  animation: jumpEllipsis 2s infinite;
}
@keyframes jumpEllipsis {
  0%,
  40%,
  100% {
    width: 0;
  }
  20%,
  60% {
    width: 20px;
  }
}
.form-send-message {
  position: relative;
}

.textarea-chatgpt {
  width: 100%;
  position: absolute;
  bottom: 0px;
  min-height: 32px;
}
.new-thread-chatpgt {
  font-size: 20px;
  border: solid 0.5px #ccc;
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ip-enter-messages {
  background: transparent !important;
}
.dark-layout {
  .new-thread-chatpgt {
    border: solid 0.5px #7367f03d;
  }
  .ip-upload {
    border: solid 0.5px #7367f03d;
  }
  .download-image-chat {
    color: #d0d2d6;
  }
}
.btn-send-messges {
  svg {
    transform: rotate(90deg) scaleX(1.5);
  }
}

@import '@/@core/scss/base/pages/app-chat.scss';
@import '@/@core/scss/base/pages/app-chat-list.scss';
</style>
