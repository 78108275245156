export default {
  destroyed() {
    this.stopTimer();
  },
  data() {
    return {
      timer: '',
      interval: 1000,
      refreshTime: 0, // Watch 'refreshTime' for continuous execute
    }
  },
  methods: {
    startTimer() {
      clearInterval(this.timer);
      this.timer = setInterval(() => { 
        this.refreshTime++;
      }, this.interval);
    },
    stopTimer() {
      clearInterval(this.timer);
    }
  }
}