<template>
  <div :class="`tooltip-process-rate-container ${layer.tooltip.direction}`" v-if="position && textTooltip" :style="{ fontFamily: layer.tooltip.family, fontSize: layer.tooltip.size + 'px', color: layer.tooltip.color, borderColor: layer.tooltip.backgroundColor, opacity: layer.tooltip.opacity, backgroundColor: layer.tooltip.backgroundColor, top: position.y + 'px', left: position.x + 'px', '--data-color': layer.tooltip.backgroundColor }">
    <span>{{ textTooltip }}</span>
  </div>
</template>

<script>
const _ = require('lodash');
export default {
  props: ['map', 'moveCount', 'layer', 'leafletLayer', 'mapIndex'],
  computed: {
    textTooltip() {
      let item = ``;
      try {
        item = this.hovering.hoverId;
      } catch {}
      return `${item}`;
    },
    hovering() {
      try {
        return this.$store.state.map.hoveringProcessRate[this.mapIndex];
      } catch {}
    },
    location() {
      try {
        if (this.leafletLayer && this.leafletLayer._layers) {
          for (let key in this.leafletLayer._layers) {
            if (this.leafletLayer._layers[key].idxProcessRate != null && this.leafletLayer._layers[key].idxProcessRate == this.hovering.hoverId) {
              return this.leafletLayer._layers[key];
              break;
            }
          }
        }
      } catch {}
    },
    position() {
      this.moveCount;
      try {
        if (this.hovering && this.hovering.latlng) {
          return this.map.latLngToContainerPoint(this.hovering.latlng);
        }
        if (this.location) {
          return this.map.latLngToContainerPoint(this.location.latlng);
        }
      } catch {}
      return { x: 0, y: 0 };
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style>
.tooltip-process-rate-container {
  position: absolute;
  pointer-events: none;
  z-index: 401;
  padding: 6px;
  line-height: 1;
  border: 1px solid;
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.tooltip-process-rate-container::before {
  content: '';
  position: absolute;
  display: inline-block;
  border: 6px solid transparent;
}

.tooltip-process-rate-container.bottom {
  margin-top: 6px;
  transform: translate(-50%, 0);
}
.tooltip-process-rate-container.top {
  margin-top: -6px;
  transform: translate(-50%, -100%);
}
.tooltip-process-rate-container.left {
  margin-left: -6px;
  transform: translate(-100%, -50%);
}
.tooltip-process-rate-container.right {
  margin-left: 6px;
  transform: translate(0, -50%);
}

.tooltip-process-rate-container.bottom::before {
  top: -12px;
  left: 50%;
  margin-left: -6px;
  border-bottom-color: var(--data-color);
}
.tooltip-process-rate-container.top::before {
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-top-color: var(--data-color);
}
.tooltip-process-rate-container.left::before {
  left: 100%;
  top: 50%;
  margin-top: -6px;
  border-left-color: var(--data-color);
}
.tooltip-process-rate-container.right::before {
  left: -12px;
  top: 50%;
  margin-top: -6px;
  border-right-color: var(--data-color);
}
</style>
