<template>
  <b-modal :title="$t('delete_group')" centered ok-only ok-variant="danger" :ok-title="$t('delete')" ref="deleteLayer" :body-class="'custom-body'" @ok="deleteLayer">
    {{ $t('message_delete_group', { name: group.name }) }}
  </b-modal>
</template>

<script>
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  props: ['group'],
  methods: {
    open() {
      this.$refs.deleteLayer.show();
    },
    deleteLayer() {
      let items = '{layers:[';
      for (let i = 0; i < this.group.groupData.length; i++) {
        this.$store.commit('layer/DELETE_LAYER', this.group.groupData[i].id);
        if (i == 0) {
          items += this.group.groupData[i].name;
        } else {
          items += ',' + this.group.groupData[i].name;
        }
      }
      items += ']}';
      this.$store.commit('layer/DELETE_GROUP', this.group.id);
      let messageTitle = 'delete_group';
      let messageLog = `: '${this.group.name}' :${items}`;
      let message = this.$t(messageTitle) + messageLog;
      this.log.info({ message, id: ACTION_LOG[13].id, messageTitle, messageLog });
      // this.log.info({ message: `${this.$t('delete_group')}: '${this.group.name}' :${items}`, id: ACTION_LOG[13].id });
    },
  },
};
</script>
