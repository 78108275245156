<template>
  <b-dropdown-item link-class="d-flex align-items-center" @click="exportGif">
    <feather-icon size="16" icon="FilmIcon" class="mr-50" />
    <span>As GIF</span>
  </b-dropdown-item>
</template>

<script>
import fs from 'fs'
const path = require('path')
const { execFileSync } = require('child_process')
import { FFMPEG_EXE_PATH } from '@/constants/paths'
import CaptureMixin from '@/mixins/CaptureMixin.js'
import { BDropdownItem } from 'bootstrap-vue'

export default {
  props: ['walkPlayer', 'cancelWalk'],
  mixins: [CaptureMixin],
  components: { BDropdownItem },
  mounted() {
    document.onkeyup = (e) => {
      if (e.key !== 'Escape' || !this.isGifing) return
      this.cancel()
    }
  },
  computed: {
    isGifing() {
      return this.$store.state.ecoplot.isGifing
    },
  },
  methods: {
    exportGif() {
      this.$store.commit('ecoplot/SET_GIFING_STATUS', true)
      this.gifing()
        .then((gif) => {
          // save gif
          // dialog
          //   .showSaveDialog(getCurrentWindow(), {
          //     defaultPath: 'Map',
          //     filters: [{ name: 'GIF File', extensions: ['gif'] }],
          //   })
          //   .then(({ canceled, filePath }) => {
          //     if (canceled) return
          //     try {
          //       if (!filePath.endsWith('.gif')) filePath += '.gif'
          //       fs.writeFileSync(filePath, gif)
          //     } catch (error) {
          //       console.error('Error occur when export map as gif')
          //     }
          //   })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.$store.commit('ecoplot/SET_GIFING_STATUS', false)
        })
    },
  },
}
</script>
