<template>
  <div :idDatasourceValue="idDatasourceValue" :itemThreshold="itemThreshold">
    <b-modal size="lg" title="Threshold setting" centered ref="thresholdModal" :hide-footer="true" @show="eShowModal">
      <div class="table-container custom-scrollbar">
        <table class="threshold-level-container threshold-table">
          <tbody>
            <tr>
              <td class="text-secondary t-header">All</td>
              <td v-for="(level, index) in levels" :key="level.id">
                <b-form-input size="sm" type="number" :value="thresholdGeneral[index]" @input="updateValue('General', index, $event)" :lazy="true" />
              </td>
            </tr>
            <template v-for="(threshold, location) in thresholds">
              <tr :key="location" v-if="(individual && location !== 'General') || (!individual && location === 'General')">
                <td class="text-secondary t-header">{{ location }}</td>
                <td v-for="(level, index) in levels" :key="level.id">
                  <b-form-input size="sm" type="number" :value="threshold[index]" @input="updateValue(location, index, $event)" :lazy="true" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
const _ = require('lodash')

export default {
  props: ['value', 'levels', 'location', 'individual', 'layerOrigin', 'idDatasourceValue', 'itemThreshold'],
  data() {
    return {
      thresholds: this.value,
    }
  },
  async mounted() {
    this.getThresholds()
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    thresholdGeneral() {
      return this.thresholds['General']
    },
  },
  watch: {
    value() {
      if (JSON.stringify(this.thresholds) !== JSON.stringify(this.value)) {
        this.thresholds = this.value
        this.updateLocationValueByGeneral()
      }
    },
    thresholds(thresholds) {
      this.$emit('input', thresholds)
    },
    levels(levels, oldLevels) {
      if (levels.length === oldLevels.length) return

      let deletedIndexs = []
      for (let index = 0; index < oldLevels.length; index++) {
        if (!levels.some((l) => l.id === oldLevels[index].id)) {
          deletedIndexs.push(index)
        }
      }

      let newThreshold = {}

      for (const key in this.thresholds) {
        let threshold = [...this.thresholds[key]]
        if (threshold.length < levels.length) {
          threshold.push(...Array(levels.length - threshold.length).fill(''))
          newThreshold[key] = threshold
        } else if (threshold.length > levels.length) {
          threshold = threshold.filter((_v, i) => !deletedIndexs.includes(i))
          newThreshold[key] = threshold
        } else {
          newThreshold[key] = threshold
        }
      }

      this.thresholds = newThreshold
    },
    idDatasourceValue: {
      deep: true,
      handler() {
        this.thresholds = {
          General: ['', ''],
        }
        this.getThresholds()
      },
    },
    itemThreshold: {
      deep: true,
      handler() {
        this.thresholds = {
          General: ['', ''],
        }
        this.getThresholds()
      },
    },
  },
  methods: {
    openModal() {
      this.$refs.thresholdModal.show()
    },
    updateLocationValueByGeneral() {
      let newThresholds = _.merge({}, this.thresholds)
      const general = this.thresholds['General']
      const locations = Object.keys(this.thresholds).filter((o) => o !== 'General')

      for (const location of locations) {
        newThresholds[location] = [...general]
      }

      this.thresholds = newThresholds
    },
    updateValue(location, index, value) {
      let newThresholds = _.merge({}, this.thresholds)
      newThresholds[location][index] = value

      if (this.individual) {
        const locations = Object.keys(this.thresholds).filter((o) => o !== 'General')
        if (location === 'General') {
          for (const location of locations) {
            newThresholds[location][index] = value
          }
        }
      }

      this.thresholds = newThresholds
    },
    eShowModal() {
      this.getThresholds()
    },
    async getThresholds() {
      try {
        const metadata = this.$db[this.idDatasourceValue]
        if (metadata && metadata.columns && this.idDatasourceValue && this.itemThreshold) {
          let thresholds = {}
          const locations = Object.keys(metadata.columns)
            .filter((pair) => pair.endsWith(`*${this.itemThreshold}`))
            .map((pair) => pair.split('*')[0])
          if (locations && locations.length) {
            for (const location of locations) {
              if (!this.thresholds[location]) {
                thresholds[location] = [...Array(this.levels.length).fill('')]
              }
            }
          }
          this.thresholds = { ...this.thresholds, ...thresholds }
        } else {
          this.thresholds = {
            General: ['', ''],
          }
        }
      } catch {
        this.thresholds = {
          General: ['', ''],
        }
      }
    },
  },
}
</script>

<style scoped>
.table-container {
  max-height: 600px;
  overflow: auto;
}

table {
  min-width: 100%;
}

th {
  font-weight: bold;
  text-align: center;
  border: 1px solid;
  padding: 0 5px;
  line-height: 30px;
}

td {
  border: 1px solid;
  padding: 0;
}

td:first-child {
  padding: 0 5px;
}

input {
  border-color: transparent !important;
}
</style>
