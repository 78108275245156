<template>
  <div class="boxplot-chart-container" :style="{ borderColor: isSelecting ? source.customData.lineColor : 'transparent' }">
    <div class="boxplot-chart-name" :style="{ color: isSelecting ? source.customData.lineColor : chartTextColor, fontFamily: chartFontFamily, fontSize: chartTextSize + 'px' }" @click="clickChart(source.customName, source.customUid)">{{ source.customName }}</div>
    <div class="boxplot-chart-item" ref="chart"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist';
import { locale } from '@/scripts/plotly/locale.js';

const LAYOUT = {
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  showlegend: false,
  margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
  xaxis: { visible: false },
  yaxis: { visible: false },
  hovermode: 'y',
  hovermode: 'y unified',
};

const CONFIG = {
  displayModeBar: false,
  responsive: true,
};

export default {
  props: ['index', 'source', 'layerSelected', 'hovering', 'date', 'clicking', 'globalRange', 'blockZoomAll'],
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale);
    this.updateChart();
    // listen to div resize => chart resize
    new ResizeObserver(() => {
      if (document.getElementsByClassName('boxplot-chart-item')[0] && this.$refs.chart && this.$refs.chart.style.display !== 'none') Plotly.Plots.resize(this.$refs.chart);
    }).observe(this.$refs.chart);
  },
  data() {
    return {
      hoveringTrace: null,
    };
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers;
    },
    layer() {
      if (!this.layerSelected) return null;
      let layer = this.layers.find((l) => l.id === this.layerSelected);
      return layer;
    },
    isSelecting() {
      let isSelect = null;
      if (this.clicking) {
        for (let i = 0; i < this.clicking.length; i++) {
          if (this.clicking[i] && this.clicking[i].layerId === this.layerSelected && this.clicking[i].clickId === this.source.customName && this.clicking[i].clickUid === this.source.customUid) {
            isSelect = this.clicking[i];
            break;
          }
        }
      }
      return isSelect;
    },
    chartFontFamily() {
      return this.$store.state.settings.chartFontFamily;
    },
    chartTextColor() {
      return this.$store.state.settings.chartTextColor;
    },
    chartTextSize() {
      return this.$store.state.settings.chartTextSize;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    source: {
      deep: true,
      handler() {
        this.updateChart(true);
      },
    },
    locale() {
      this.updateChart();
    },
    hovering(hovering) {
      Plotly.Fx.hover(this.$refs.chart, []);

      if (!hovering || hovering.source !== 'map' || hovering.layerId !== this.layerSelected || hovering.hoverId !== this.source.customName || hovering.hoverUid !== this.source.customUid) return;

      Plotly.Fx.hover(this.$refs.chart, [{ curveNumber: 0, pointNumber: 0 }]);
    },
    hoveringTrace(hoveringTrace) {
      if (hoveringTrace) {
        let [id, uid] = hoveringTrace.split(' - ');

        //SET HOVERING FOR 2 MAP VIEW
        for (let i = 0; i < 2; i++) {
          const storeData = {
            data: { layerId: this.layerSelected, hoverId: id, hoverUid: uid === 'undefined' ? undefined : uid, source: 'sidebar' },
            mapIndex: i,
          };
          this.$store.commit('map/SET_HOVERING', storeData);
        }
      } else {
        for (let i = 0; i < 2; i++) {
          const storeData = {
            data: null,
            mapIndex: i,
          };
          this.$store.commit('map/SET_HOVERING', storeData);
        }
      }
    },
  },
  methods: {
    clickChart(id, uid) {
      let multiShow = false;
      multiShow = this.layer.popup.multiShow;

      //SET CLICKING FOR 2 MAP VIEW
      for (let i = 0; i < 2; i++) {
        const storeData = {
          data: { layerId: this.layerSelected, clickId: id, clickUid: uid, source: 'sidebar', multiShow: multiShow, pin: true },
          mapIndex: i,
        };
        this.$store.commit('map/SET_CLICKING', storeData);
      }
    },
    updateChart(isUpdate = false) {
      let _config = CONFIG;
      _config.locale = this.locale;
      if (isUpdate) {
        Plotly.react(this.$refs.chart, [{ ...this.source, line: { color: this.source.customData.chartColor } }], { ...LAYOUT }, _config);
      } else {
        Plotly.newPlot(this.$refs.chart, [{ ...this.source, line: { color: this.source.customData.chartColor } }], { ...LAYOUT }, _config).then(() => {
          this.$refs.chart.on('plotly_hover', (data) => {
            this.hoveringTrace = data.points[0].data.customName + ' - ' + data.points[0].data.customUid;
          });
          this.$refs.chart.on('plotly_unhover', (data) => {
            this.hoveringTrace = null;
          });
          if (this.$refs.chart.querySelector('.main-svg')) {
            this.$refs.chart.querySelectorAll('.main-svg')[0].style.zIndex = '1';
            this.$refs.chart.querySelectorAll('.main-svg')[this.$refs.chart.querySelectorAll('.main-svg').length - 1].style.zIndex = '100';
            this.$refs.chart.querySelector('.main-svg .ewdrag.drag.cursor-ew-resize').style.pointerEvents = 'none';
          }
        });
      }
    },
  },
  beforeDestroy() {
    Plotly.purge(this.$refs.chart);
  },
};
</script>

<style scoped>
.boxplot-chart-container {
  border: 2px dashed;
}
.boxplot-chart-name {
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}
.boxplot-chart-item {
  height: 58px;
}
</style>
<style>
.chart-container.boxplot-container .main-svg {
  height: 200px;
}
.chart-container.boxplot-container .main-svg .legend rect {
  fill-opacity: unset !important;
  fill: unset !important;
}
.chart-container.boxplot-container .main-svg .legend {
  fill: white !important;
}
.dark-layout .chart-container.boxplot-container .main-svg .legend {
  fill: #283046 !important;
  stroke: #fff;
}
.chart-container.boxplot-container .main-svg .hoverlayer .spikeline.crisp {
  stroke: unset !important;
}
.chart-container.boxplot-container .main-svg .ewdrag.drag.cursor-ew-resize .chart-container.boxplot-container .main-svg .cursor-e-resize,
.chart-container.boxplot-container .main-svg .cursor-e-resize,
.chart-container.boxplot-container rect.cursor-w-resize {
  cursor: default !important;
  pointer-events: none !important;
  display: none !important;
}
.boxplot-chart-container .plot-container.plotly .user-select-none.svg-container {
  z-index: unset !important;
}
</style>
