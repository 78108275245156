<template>
  <div :class="`tooltip-container ${layer.tooltip.direction}`" v-if="position && textTooltip" :style="{ fontFamily: layer.tooltip.family, fontSize: layer.tooltip.size + 'px', color: layer.tooltip.color, borderColor: layer.tooltip.backgroundColor, opacity: layer.tooltip.opacity, backgroundColor: layer.tooltip.backgroundColor, top: position.y + 'px', left: position.x + 'px', '--data-color': layer.tooltip.backgroundColor }">
    <span>{{ textTooltip }}</span>
  </div>
</template>

<script>
export default {
  props: ['map', 'moveCount', 'layer', 'leafletLayer', 'mapIndex'],
  computed: {
    idMultiSelected() {
      let id = 'ID'
      try {
        id = this.layer.idMultiSelected ? this.layer.idMultiSelected : 'ID'
      } catch {}
      return id
    },
    hovering() {
      return this.$store.state.map.hovering[this.mapIndex]
    },
    textTooltip() {
      let item = ``
      try {
        item = this.location.feature.properties[this.idMultiSelected]
      } catch {}
      try {
        if (!item || item == '') {
          item = this.hovering.idMultiSelected
        }
      } catch {}
      return `${item}`
    },
    location() {
      try {
        return this.leafletLayer.getLayers().find((l) => {
          return l.feature.properties[this.idMultiSelected] == this.hovering.hoverId && l.feature.properties.UID == this.hovering.hoverUid
        })
      } catch {}
    },
    layerOrigin() {
      try {
        const layers = this.$store.state.layer.layers
        if (layers) {
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].id == this.hovering.layerId) {
              return layers[i]
            }
          }
        }
      } catch {}
      return
    },
    position() {
      this.moveCount
      const self = this
      try {
        let useMarkerCluster
        try {
          useMarkerCluster = this.layerOrigin && this.layerOrigin.markerCluster && this.layerOrigin.markerCluster.using
        } catch {}
        if (this.hovering && this.hovering.source && this.hovering.source == 'sidebar' && useMarkerCluster && this.layerOrigin && this.layerOrigin.type && this.layerOrigin.type == 'point') {
          if (this.leafletLayer) {
            let allCluster
            allCluster = this.leafletLayer._parentGroup._featureGroup._layers
            if (allCluster && Object.keys(allCluster).length) {
              for (let key in allCluster) {
                try {
                  if (allCluster[key]._childCount) {
                    let allclusterChild = allCluster[key].getAllChildMarkers()

                    for (let k = 0; k < allclusterChild.length; k++) {
                      if (allclusterChild[k].feature.properties[self.idMultiSelected] == self.hovering.hoverId) {
                        if (allCluster[key].isSpiderfied) {
                          return self.map.latLngToContainerPoint(allclusterChild[k].getLatLng())
                        } else {
                          return self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                        }
                        break
                      }
                    }
                  } else {
                    if (allCluster[key].feature.properties[self.idMultiSelected] == self.hovering.hoverId) {
                      return self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                    }
                  }
                } catch {}
              }
            }
          }
        } else {
          if (this.layer.type == 'multi' && !this.location && !this.layer.dataTimeseries && this.hovering && this.hovering.latlngMultiSelected) {
            if (this.hovering && this.hovering.latLonSpider) {
              return this.map.latLngToContainerPoint([this.hovering.latLonSpider.lat, this.hovering.latLonSpider.lng])
            }
            return this.map.latLngToContainerPoint(this.hovering.latlngMultiSelected)
          }
          if (this.hovering && this.hovering.latLonSpider) {
            return this.map.latLngToContainerPoint([this.hovering.latLonSpider.lat, this.hovering.latLonSpider.lng])
          }
          return this.map.latLngToContainerPoint(this.location.latlng)
        }
      } catch {}
      return {
        y: 0,
        x: 0,
      }
    },
  },
}
</script>

<style>
.tooltip-container {
  position: absolute;
  pointer-events: none;
  z-index: 401;
  padding: 6px;
  line-height: 1;
  border: 1px solid;
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.tooltip-container::before {
  content: '';
  position: absolute;
  display: inline-block;
  border: 6px solid transparent;
}

.tooltip-container.bottom {
  margin-top: 6px;
  transform: translate(-50%, 0);
}
.tooltip-container.top {
  margin-top: -6px;
  transform: translate(-50%, -100%);
}
.tooltip-container.left {
  margin-left: -6px;
  transform: translate(-100%, -50%);
}
.tooltip-container.right {
  margin-left: 6px;
  transform: translate(0, -50%);
}

.tooltip-container.bottom::before {
  top: -12px;
  left: 50%;
  margin-left: -6px;
  border-bottom-color: var(--data-color);
}
.tooltip-container.top::before {
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-top-color: var(--data-color);
}
.tooltip-container.left::before {
  left: 100%;
  top: 50%;
  margin-top: -6px;
  border-left-color: var(--data-color);
}
.tooltip-container.right::before {
  left: -12px;
  top: 50%;
  margin-top: -6px;
  border-right-color: var(--data-color);
}
</style>
