<template>
  <div>
    <b-modal id="modal-aws-s3-config" ref="settingModalComponent" centered size="lg" :title="$t('S3_config')" hide-footer>
      <template #modal-header>
        <div class="d-flex align-items-center"><feather-icon icon="CloudDrizzleIcon" style="margin-right: 10px" />{{ $t('S3_config') }}</div>
        <button type="button" @click="$bvModal.hide('modal-aws-s3-config')" aria-label="Close" class="close">×</button>
      </template>

      <b-card-text>
        <b-form @submit.prevent>
          <!-- Access Key Config -->
          <b-row class="section-header">
            <b-col>
              <h4 class="text-primary">{{ $t('access_key') }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('access_key_id')" label-for="access_key_id" label-cols-md="3">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon :icon="showAwsAccessKeyId ? 'EyeIcon' : 'EyeOffIcon'" @click="showAwsAccessKeyId = !showAwsAccessKeyId" />
                  </b-input-group-prepend>
                  <b-form-input id="access_key_id" v-model="awsAccessKeyId" :type="showAwsAccessKeyId ? 'text' : 'password'" spellcheck="false" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('secret_access_key')" label-for="secret_access_key" label-cols-md="3">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon :icon="showSwsSecretAccessKey ? 'EyeIcon' : 'EyeOffIcon'" @click="showSwsSecretAccessKey = !showSwsSecretAccessKey" />
                  </b-input-group-prepend>
                  <b-form-input id="secret_access_key" v-model="awsSecretAccessKey" :type="showSwsSecretAccessKey ? 'text' : 'password'" spellcheck="false" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('encrypt_key')" label-for="encrypt_key" label-cols-md="3" class="d-flex align-items-center">
                <b-form-checkbox class="custom-checkbox-icon" id="encrypt_key" v-model="isEncrypted" switch>
                  <p class="mb-0">{{ $t('prevent_others_from_seeing_your_key') }}</p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="9" offset="3">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="changeKey" variant="primary" size="sm">{{ $t('change_key') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
const _ = require('lodash')
import CryptoJS from 'crypto-js'
const ALPHABET = 'ecoplotpro'
import Ripple from 'vue-ripple-directive'
import FileMixin from '@/mixins/FileMixin.js'
import TimerMixin from '@/mixins/TimerMixin'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: { ToastificationContent },
  mixins: [FileMixin, TimerMixin],
  directives: { Ripple },
  props: ['isActivate', 'locale'],
  data() {
    return {
      awsAccessKeyId: '',
      awsSecretAccessKey: '',
      showAwsAccessKeyId: false,
      showSwsSecretAccessKey: false,
      isEncrypted: false,
    }
  },
  mounted() {
    if (!this.$root.appSettings) this.$root.appSettings = {}
    try {
      this.$root.appSettings.awsAccessKeyIdTemp = this.$root.appSettings.awsAccessKeyId
      this.$root.appSettings.awsSecretAccessKeyTemp = this.$root.appSettings.awsSecretAccessKey
      this.$root.appSettings.isEncryptedTemp = this.$root.appSettings.isEncrypted
    } catch {
      this.$root.appSettings.awsAccessKeyIdTemp = ''
      this.$root.appSettings.awsSecretAccessKeyTemp = ''
      this.$root.appSettings.isEncryptedTemp = false
    }

    this.awsAccessKeyId = this.$root.appSettings.awsAccessKeyIdTemp
    this.awsSecretAccessKey = this.$root.appSettings.awsSecretAccessKeyTemp
    this.isEncrypted = this.$root.appSettings.isEncryptedTemp ? true : false
    this.setDataRootFromAppSettings()
  },
  watch: {
    isEncrypted() {
      // if (this.isEncrypted) {
      //   try {
      //     if (!this.isCryptoJSAESString(this.awsAccessKeyId)) {
      //       let idEncode = this.encryptAES(this.awsAccessKeyId)
      //       this.awsAccessKeyId = idEncode
      //     }
      //   } catch {}
      //   try {
      //     if (!this.isCryptoJSAESString(this.awsSecretAccessKey)) {
      //       let keyEncode = this.encryptAES(this.awsSecretAccessKey)
      //       this.awsSecretAccessKey = keyEncode
      //     }
      //   } catch {}
      //   this.$root.appSettings.awsAccessKeyIdTemp = this.awsAccessKeyId
      //   this.$root.appSettings.awsSecretAccessKeyTemp = this.awsSecretAccessKey
      //   this.$root.appSettings.isEncryptedTemp = this.isEncrypted
      // }
    },
  },
  methods: {
    setDataRootFromAppSettings() {
      try {
        if (this.isEncrypted) {
          try {
            this.$root.appSettings.awsAccessKeyId = this.decryptAES(this.awsAccessKeyId)
          } catch {
            this.$root.appSettings.awsAccessKeyId = ''
          }
          try {
            this.$root.appSettings.awsSecretAccessKey = this.decryptAES(this.awsSecretAccessKey)
          } catch {
            this.$root.appSettings.awsSecretAccessKey = ''
          }
        } else {
          this.$root.appSettings.awsAccessKeyId = this.awsAccessKeyId
          this.$root.appSettings.awsSecretAccessKey = this.awsSecretAccessKey
        }
        this.$root.appSettings.isEncrypted = this.isEncrypted
      } catch {
        this.$root.appSettings.awsAccessKeyId = ''
        this.$root.appSettings.awsSecretAccessKey = ''
        this.$root.appSettings.isEncrypted = this.isEncrypted ? true : false
      }
    },
    open() {
      try {
        this.awsAccessKeyId = this.$root.appSettings.awsAccessKeyIdTemp
        this.awsSecretAccessKey = this.$root.appSettings.awsSecretAccessKeyTemp
        this.isEncrypted = this.$root.appSettings.isEncryptedTemp ? true : false
      } catch {
        this.awsAccessKeyId = ''
        this.awsSecretAccessKey = ''
        this.isEncrypted = false
      }
      this.setDataRootFromAppSettings()
      this.$refs.settingModalComponent.show()
    },
    close() {
      this.$refs.settingModalComponent.hide()
    },
    changeKey() {
      if (this.locale === 'en') {
        this.$bvModal
          .msgBoxConfirm('Do you want to update AWS access key information?', {
            title: 'Update AWS access key',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (!value) return
            try {
              if (this.isEncrypted) {
                try {
                  if (!this.isCryptoJSAESString(this.awsAccessKeyId)) {
                    let idEncode = this.encryptAES(this.awsAccessKeyId)
                    this.awsAccessKeyId = idEncode
                  }
                } catch {}
                try {
                  if (!this.isCryptoJSAESString(this.awsSecretAccessKey)) {
                    let keyEncode = this.encryptAES(this.awsSecretAccessKey)
                    this.awsSecretAccessKey = keyEncode
                  }
                } catch {}
                this.$root.appSettings.awsAccessKeyIdTemp = this.awsAccessKeyId
                this.$root.appSettings.awsSecretAccessKeyTemp = this.awsSecretAccessKey
                this.$root.appSettings.isEncryptedTemp = this.isEncrypted
              }

              this.setDataRootFromAppSettings()
              this.$root.appSettings.awsAccessKeyIdTemp = this.awsAccessKeyId
              this.$root.appSettings.awsSecretAccessKeyTemp = this.awsSecretAccessKey
              this.$root.appSettings.isEncryptedTemp = this.isEncrypted ? true : false

              let appSettingsWrite = _.cloneDeep(this.$root.appSettings)
              try {
                appSettingsWrite.awsAccessKeyId = this.$root.appSettings.awsAccessKeyIdTemp
              } catch {}
              try {
                appSettingsWrite.awsSecretAccessKey = this.$root.appSettings.awsSecretAccessKeyTemp
              } catch {}
              try {
                appSettingsWrite.isEncrypted = this.$root.appSettings.isEncryptedTemp
              } catch {}
              try {
                localStorage.setItem('awsAccessKeyId', appSettingsWrite.awsAccessKeyId)
                localStorage.setItem('awsSecretAccessKey', appSettingsWrite.awsSecretAccessKey)
                localStorage.setItem('isEncrypted', appSettingsWrite.isEncrypted)
              } catch {}
              this.$emit('changeKeyAwsS3', {
                awsAccessKeyId: this.$root.appSettings.awsAccessKeyId,
                awsSecretAccessKey: this.$root.appSettings.awsSecretAccessKey,
              })
              this.$store.commit('ecoplot/UPDATE_COUNT_ACC_ASWS3_UPDATE')
              this.$toast({ component: ToastificationContent, props: { title: 'Key updated successfully', icon: 'KeyIcon', variant: 'success' } })
            } catch (err) {
              this.$toast({ component: ToastificationContent, props: { title: 'Key updated failed', text: err.message, icon: 'ArrowDownIcon', variant: 'danger' } })
            }
          })
      } else {
        this.$bvModal
          .msgBoxConfirm('AWS アクセスキー情報を更新しますか?', {
            title: 'AWSアクセスキーの更新',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'はい',
            cancelTitle: 'いいえ',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (!value) return
            try {
              this.setDataRootFromAppSettings()
              this.$root.appSettings.awsAccessKeyIdTemp = this.awsAccessKeyId
              this.$root.appSettings.awsSecretAccessKeyTemp = this.awsSecretAccessKey
              this.$root.appSettings.isEncryptedTemp = this.isEncrypted ? true : false

              let appSettingsWrite = _.cloneDeep(this.$root.appSettings)
              try {
                appSettingsWrite.awsAccessKeyId = this.$root.appSettings.awsAccessKeyIdTemp
              } catch {}
              try {
                appSettingsWrite.awsSecretAccessKey = this.$root.appSettings.awsSecretAccessKeyTemp
              } catch {}
              try {
                appSettingsWrite.isEncrypted = this.$root.appSettings.isEncryptedTemp
              } catch {}
              try {
                localStorage.setItem('awsAccessKeyId', appSettingsWrite.awsAccessKeyId)
                localStorage.setItem('awsSecretAccessKey', appSettingsWrite.awsSecretAccessKey)
                localStorage.setItem('isEncrypted', appSettingsWrite.isEncrypted)
              } catch {}
              this.$emit('changeKeyAwsS3', {
                awsAccessKeyId: this.$root.appSettings.awsAccessKeyId,
                awsSecretAccessKey: this.$root.appSettings.awsSecretAccessKey,
              })
              this.$store.commit('ecoplot/UPDATE_COUNT_ACC_ASWS3_UPDATE')
              this.$toast({ component: ToastificationContent, props: { title: 'キーが正常に更新されました', icon: 'KeyIcon', variant: 'success' } })
            } catch (err) {
              this.$toast({ component: ToastificationContent, props: { title: 'キーの更新に失敗しました', text: err.message, icon: 'ArrowDownIcon', variant: 'danger' } })
            }
          })
      }
    },
    encryptAES(text, password = 'ecoplotpro') {
      try {
        return CryptoJS.AES.encrypt(text, password).toString()
      } catch {}
      return
    },
    decryptAES(encryptedBase64, password = 'ecoplotpro') {
      try {
        const decrypted = CryptoJS.AES.decrypt(encryptedBase64, password)
        if (decrypted) {
          try {
            const str = decrypted.toString(CryptoJS.enc.Utf8)
            if (str.length > 0) {
              return str
            } else {
              return
            }
          } catch (e) {
            return
          }
        }
      } catch {}
      return
    },
    isCryptoJSAESString(encryptedBase64, password = 'ecoplotpro') {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedBase64, password)
        let bytesTemp = bytes.toString(CryptoJS.enc.Utf8)
        return bytesTemp !== null && bytesTemp.length > 0
      } catch (e) {
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.section-header {
  align-items: center;
  margin-bottom: 6px;
}
.section-header .title {
  margin-bottom: 0;
}

.detail-container {
  overflow: hidden;
  transition: max-height 0.25s;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.detail-item {
  display: flex;
  align-items: center;
  margin: 4px 0;
  min-height: 50px;
  padding: 0 1rem;
  border-radius: 0.357rem;
}
.detail-item .left-side {
  font-size: 16px;
  margin-right: auto;
  line-height: 1;
}
.detail-item .left-side > * {
  vertical-align: middle;
}
.detail-item .badge {
  font-size: 70%;
  margin-left: 7px;
}
.detail-container .btn.btn-icon > * {
  vertical-align: middle;
}
</style>
