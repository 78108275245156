import * as SHAPES from '@/constants/shapes'

const DEFAULT_SHAPE = SHAPES.CIRCLE

function drawCircle(ctx, point, radius) {
  radius = radius / 2

  const cx = point.x,
    cy = point.y

  ctx.beginPath()
  ctx.arc(cx, cy, radius, 0, 2 * Math.PI)
  ctx.closePath()
}

function drawHighLight(ctx, point, radius, weight = 0, color = 'red') {
  radius = radius / 2

  radius = radius + 3 + Number(weight)
  const cx = point.x,
    cy = point.y

  ctx.globalAlpha = 1
  ctx.lineWidth = 2

  ctx.beginPath()
  ctx.moveTo(cx - (1 * radius) / 2, cy + 1 * radius)
  ctx.lineTo(cx - 1 * radius, cy + 1 * radius)
  ctx.lineTo(cx - 1 * radius, cy + (1 * radius) / 2)

  ctx.moveTo(cx - 1 * radius, cy - (1 * radius) / 2)
  ctx.lineTo(cx - 1 * radius, cy - 1 * radius)
  ctx.lineTo(cx - (1 * radius) / 2, cy - 1 * radius)

  ctx.moveTo(cx + (1 * radius) / 2, cy - 1 * radius)
  ctx.lineTo(cx + 1 * radius, cy - 1 * radius)
  ctx.lineTo(cx + 1 * radius, cy - (1 * radius) / 2)

  ctx.moveTo(cx + (1 * radius) / 2, cy + 1 * radius)
  ctx.lineTo(cx + 1 * radius, cy + 1 * radius)
  ctx.lineTo(cx + 1 * radius, cy + (1 * radius) / 2)

  ctx.strokeStyle = color
  ctx.stroke()
}

function drawBalloon(ctx, point, radius) {
  radius = radius / 2

  const cx = point.x,
    cy = point.y

  ctx.beginPath()
  ctx.moveTo(cx, cy)
  ctx.lineTo(cx - 0.58 * radius, cy - radius)
  ctx.arc(cx, cy - 2 * radius, radius, -Math.PI * 1.161, Math.PI * 0.161)
  ctx.closePath()
}

function drawSquare(ctx, point, radius) {
  radius = radius / 2

  const cx = point.x,
    cy = point.y

  ctx.beginPath()
  ctx.moveTo(cx - (Math.sqrt(2) / 2) * radius, cy + (Math.sqrt(2) / 2) * radius)
  ctx.lineTo(cx - (Math.sqrt(2) / 2) * radius, cy - (Math.sqrt(2) / 2) * radius)
  ctx.lineTo(cx + (Math.sqrt(2) / 2) * radius, cy - (Math.sqrt(2) / 2) * radius)
  ctx.lineTo(cx + (Math.sqrt(2) / 2) * radius, cy + (Math.sqrt(2) / 2) * radius)
  ctx.closePath()
}

function drawSpike(ctx, point, radius, spikes) {
  radius = radius / 2

  const outerRadius = radius
  const innerRadius = radius / 2
  const cx = point.x
  const cy = point.y
  const step = Math.PI / spikes

  let rot = (Math.PI / 2) * 3
  let x = point.x
  let y = point.y

  ctx.beginPath()
  ctx.moveTo(cx, cy - outerRadius)

  for (let i = 0; i < spikes; i++) {
    x = cx + Math.cos(rot) * outerRadius
    y = cy + Math.sin(rot) * outerRadius
    ctx.lineTo(x, y)
    rot += step

    x = cx + Math.cos(rot) * innerRadius
    y = cy + Math.sin(rot) * innerRadius
    ctx.lineTo(x, y)
    rot += step
  }
  ctx.lineTo(cx, cy - outerRadius)
  ctx.closePath()
}

function drawTriangle(ctx, point, radius) {
  radius = radius / 2

  const cx = point.x,
    cy = point.y

  ctx.beginPath()
  ctx.moveTo(cx - (Math.sqrt(3) / 2) * radius, cy + radius / 2)
  ctx.lineTo(cx, cy - radius)
  ctx.lineTo(cx + (Math.sqrt(3) / 2) * radius, cy + radius / 2)
  ctx.closePath()
}

function circleContainsPoint(layer, point) {
  return point.distanceTo(layer._point) <= layer._radius / 2 + layer._clickTolerance()
}

function vector(p1, p2) {
  return { x: p2.x - p1.x, y: p2.y - p1.y }
}

function dot(u, v) {
  return u.x * v.x + u.y * v.y
}

function squareContainsPoint(layer, point) {
  const cx = layer._point.x,
    cy = layer._point.y,
    radius = layer._radius / 2

  const a = { x: cx - (Math.sqrt(2) / 2) * radius, y: cy + (Math.sqrt(2) / 2) * radius },
    b = { x: cx - (Math.sqrt(2) / 2) * radius, y: cy - (Math.sqrt(2) / 2) * radius },
    c = { x: cx + (Math.sqrt(2) / 2) * radius, y: cy - (Math.sqrt(2) / 2) * radius }

  const AB = vector(a, b),
    AM = vector(a, point),
    BC = vector(b, c),
    BM = vector(b, point)
  const dotABAM = dot(AB, AM),
    dotABAB = dot(AB, AB),
    dotBCBM = dot(BC, BM),
    dotBCBC = dot(BC, BC)

  return 0 <= dotABAM && dotABAM <= dotABAB && 0 <= dotBCBM && dotBCBM <= dotBCBC
}

function spikeContainsPoint(layer, point) {
  return point.distanceTo(layer._point) <= layer._radius / 2 + layer._clickTolerance()
}

function triangleContainsPoint(layer, point) {
  const cx = layer._point.x
  const cy = layer._point.y
  const radius = layer._radius / 2
  let insideTriangle = true

  const a = { x: cx - (Math.sqrt(3) / 2) * radius, y: cy + radius / 2 }
  const b = { x: cx, y: cy - radius }
  const c = { x: cx + (Math.sqrt(3) / 2) * radius, y: cy + radius / 2 }

  const ap_x = point.x - a.x
  const ap_y = point.y - a.y
  const p_ab = (b.x - a.x) * ap_y - (b.y - a.y) * ap_x > 0
  const p_ac = (c.x - a.x) * ap_y - (c.y - a.y) * ap_x > 0
  const p_bc = (c.x - b.x) * (point.y - b.y) - (c.y - b.y) * (point.x - b.x) > 0

  if (p_ac === p_ab) insideTriangle = false
  if (p_bc !== p_ab) insideTriangle = false

  return insideTriangle
}

function balloonContainsPoint(layer, point) {
  const r = layer._radius / 2

  const insideCircle = point.add([0, r * 2]).distanceTo(layer._point) <= r + layer._clickTolerance()

  const a = layer._point,
    b = a.subtract([0.58 * r, r]),
    c = a.subtract([-0.58 * r, r])

  let insideTriangle = true

  const ap_x = point.x - a.x
  const ap_y = point.y - a.y
  const p_ab = (b.x - a.x) * ap_y - (b.y - a.y) * ap_x > 0
  const p_ac = (c.x - a.x) * ap_y - (c.y - a.y) * ap_x > 0
  const p_bc = (c.x - b.x) * (point.y - b.y) - (c.y - b.y) * (point.x - b.x) > 0

  if (p_ac === p_ab) insideTriangle = false
  if (p_bc !== p_ab) insideTriangle = false

  return insideTriangle || insideCircle
}

function isShapeContainsPoint(layer, point) {
  const shapeType = layer.shapeType || DEFAULT_SHAPE

  if (shapeType === SHAPES.CIRCLE) {
    return circleContainsPoint(layer, point)
  } else if (shapeType === SHAPES.SQUARE) {
    return squareContainsPoint(layer, point)
  } else if (shapeType === SHAPES.SPIKE) {
    return spikeContainsPoint(layer, point)
  } else if (shapeType === SHAPES.TRIANGLE) {
    return triangleContainsPoint(layer, point)
  }
  // else if (shapeType === SHAPES.BALLOON) {
  // 	return balloonContainsPoint(layer, point)
  // }
}

L.Canvas.include({
  _updateShapePath: function (layer) {
    if (!this._drawing || layer._empty()) {
      return
    }

    let p = layer._point,
      ctx = this._ctx,
      r = layer._radius

    if (!this._drawnLayers) this._drawnLayers = {}

    this._drawnLayers[layer._leaflet_id] = layer

    const shapeType = layer.shapeType || DEFAULT_SHAPE

    if (shapeType === SHAPES.CIRCLE) {
      drawCircle(ctx, p, r)
    } else if (shapeType === SHAPES.SQUARE) {
      drawSquare(ctx, p, r)
    } else if (shapeType === SHAPES.SPIKE) {
      drawSpike(ctx, p, r, layer.numSpikes || 5)
    } else if (shapeType === SHAPES.TRIANGLE) {
      drawTriangle(ctx, p, r)
    }
    // else if (shapeType === SHAPES.BALLOON) {
    // 	drawBalloon(ctx, p, r)
    // }

    this._fillStroke(ctx, layer)

    if (layer.showHighlight) {
      drawHighLight(ctx, p, r, layer.options.weight || 0, layer.highlightColor)
    }
  },
  _updateHightLight: function (layer) {
    if (!this._drawing || layer._empty()) {
      return
    }

    let p = layer._point,
      ctx = this._ctx,
      r = layer._radius

    if (!this._drawnLayers) this._drawnLayers = {}

    this._drawnLayers[layer._leaflet_id] = layer

    const shapeType = layer.shapeType || DEFAULT_SHAPE

    if (shapeType === SHAPES.CIRCLE) {
      drawCircle(ctx, p, r)
    } else if (shapeType === SHAPES.SQUARE) {
      drawSquare(ctx, p, r)
    } else if (shapeType === SHAPES.SPIKE) {
      drawSpike(ctx, p, r, layer.numSpikes || 5)
    } else if (shapeType === SHAPES.TRIANGLE) {
      drawTriangle(ctx, p, r)
    }
    // else if (shapeType === SHAPES.BALLOON) {
    // 	drawBalloon(ctx, p, r)
    // }

    if (layer.showHighlight) {
      drawHighLight(ctx, p, r, layer.options.weight || 0, layer.highlightColor)
    }
  },
})

L.CustomMarkerShape = L.CircleMarker.extend({
  _updatePath: function () {
    this._renderer._updateShapePath(this)
  },
  _containsPoint: function (p) {
    return isShapeContainsPoint(this, p)
  },
})
L.HightLightPoint = L.CircleMarker.extend({
  _updatePath: function () {
    this._renderer._updateHightLight(this)
  },
  _containsPoint: function (p) {
    return isShapeContainsPoint(this, p)
  },
})
