<template>
  <b-modal size="lg" :title="datasource.name" centered :hide-footer="true" ref="datatableModal" :body-class="'custom-body'" @shown="shownHandler">
    <DatatableBody :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" :columns="columns" :updateDatatable="updateDatatable" @getData="getDataTable" :itemFilterLength="itemFilterLength" :dsType="datasourceType" :filtered="filteredData" :itemLength="itemLength" />
  </b-modal>
</template>

<script>
const _ = require('lodash');
import DatatableBody from './DatatableBody.vue';
import { STORAGE_MODE } from '@/constants/repository.js';

export default {
  props: ['datasource'],
  components: { DatatableBody },
  mounted() {
    this.rows = [];
  },
  data() {
    return {
      columns: [],
      firstOpen: true,
      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      isChangeDataSource: false,
      updateDatatable: 0,
      datasourceType: '',
    };
  },

  computed: {
    initProject() {
      return this.$store.state.ecoplot.initProject;
    },
  },
  watch: {
    datasource() {
      this.datasourceType = this.datasource && this.datasource.type ? this.datasource.type : null;
      this.isChangeDataSource = true;
    },
    initProject() {
      this.rows = [];
    },
  },

  methods: {
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false;
        if (this.datasourceType === 'timeseries') {
          for (let i = 0; i < row.length; i++) {
            let value = row[i] === null ? 'NA' : row[i].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              break;
            }
          }
        } else {
          for (const key in row) {
            let value = row[key] === null ? 'NA' : row[key].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              break;
            }
          }
        }

        return valid;
      });
      return filtered;
    },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    async getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (this.datasource.storage_mode === STORAGE_MODE.FILE) {
        let datasource = this.$db[this.datasource.id];
        let rowsOriginal = {};
        let rowsConvert = {};
        from = from === -1 ? 0 : from;
        to = to === 0 ? 20 : to;
        rowsOriginal = await this.selectDataTable(this.datasource.id, from, to);
        rowsConvert['dates'] = rowsOriginal['dates'];
        for (const key in datasource.columns) {
          rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')];
        }
        this.rows.length = from;
        this.rows = this.rows.concat(this.transpose(Object.values(rowsConvert)));
      }
      if (searchTerm === '') {
        from = from === -1 ? 0 : from;
        to = to === 0 ? 20 : to;
        this.itemFilterLength = this.itemLength;
        this.filteredData = this.filtered(this.rows.slice(+from, +to), searchTerm);
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm);
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from;
          to = to === 0 ? 20 : to;
          this.itemFilterLength = this.itemLength;
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '');
        } else {
          this.itemFilterLength = this.dataFilterStore.length;
          this.filteredData = this.dataFilterStore.slice(+from, +to);
        }
      }
    },
    open() {
      this.$refs.datatableModal.show();
    },
    close() {
      this.$refs.datatableModal.hide();
    },
    async shownHandler() {
      if (this.firstOpen || this.isChangeDataSource) {
        //init function run once time
        await this.init();
        this.firstOpen = false;
        this.isChangeDataSource = false;
      }
      this.updateDatatable++;
    },
    async init() {
      // DATATABLE FOR POINT DATASOURCE
      if (this.datasource.type === 'point') {
        this.columns = [
          { label: ['ID'], field: 'name' },
          { label: ['LONGITUDE'], field: 'lng', type: 'number' },
          { label: ['LATITUDE'], field: 'lat', type: 'number' },
        ];
        this.rows = await this.getAllPoints(this.datasource.id);
        this.itemLength = this.rows.length;
      }

      // DATATABLE FOR TIMESERIES DATASOURCE
      if (this.datasource.type === 'timeseries') {
        let datasource = this.$db[this.datasource.id];
        let items = datasource.items;
        let columns = [{ label: datasource.header, field: 'date' }];
        let rowsOriginal = {};
        let rowsConvert = {};
        if (this.datasource.storage_mode !== STORAGE_MODE.FILE) {
          rowsOriginal = await this.selectAll(this.datasource.id);
          rowsConvert['dates'] = rowsOriginal['dates'];
        }
        for (const key in datasource.columns) {
          if (this.datasource.storage_mode !== STORAGE_MODE.FILE) rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')];
          let [location, item] = key.split('*');
          columns.push({ label: datasource.header.length === 3 ? [location, item, datasource.columns[key].unit] : [location, datasource.columns[key].autoGroup, item, datasource.columns[key].unit], field: key, type: 'number' });
        }
        this.columns = columns;
        if (this.datasource.storage_mode !== STORAGE_MODE.FILE) this.rows = this.transpose(Object.values(rowsConvert));
        this.itemLength = datasource.dates.length;
        this.datasourceType = 'timeseries';
      }

      // DATATABLE FOR TYPHOON DATASOURCE
      if (this.datasource.type === 'typhoon') {
        this.columns = [
          { label: ['DATE'], field: 'date' },
          { label: ['LATITUDE'], field: 'lat', type: 'number' },
          { label: ['LONGITUDE'], field: 'lng', type: 'number' },
          { label: ['WIND'], field: 'wind', type: 'number' },
          { label: ['PRESSURE'], field: 'pressure', type: 'number' },
          { label: ['CLASS'], field: 'classes', type: 'number' },
          { label: ['STORM DIRECTION'], field: 'stormDirection' },
          { label: ['STORM MINOR RADIUS'], field: 'stormMinorRadius', type: 'number' },
          { label: ['STORM MAJOR RADIUS'], field: 'stormMajorRadius', type: 'number' },
          { label: ['GALE DIRECTION'], field: 'galeDirection' },
          { label: ['GALE MINOR RADIUS'], field: 'galeMinorRadius', type: 'number' },
          { label: ['GALE MAJOR RADIUS'], field: 'galeMajorRadius', type: 'number' },
        ];
        this.rows = await this.getAllTyphoons(this.datasource.id);
        this.itemLength = this.rows.length;
      }
    },
  },
};
</script>
