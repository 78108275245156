<template>
  <div @mousemove="setMapActive(mapIndex)" :class="`position-relative flex-grow-1 overflow-hidden app-leaflet-map-${mapIndex} ${isUselocaltionMapBasedTimeseriesLoader ? 'leaflet-map-based-timeseries' : ''}  ${isUselocaltionMapBasedTimeseriesLoader && isUselocaltionMapBasedTimeseriesLoader ? 'leaflet-map-based-timeseries-add-location' : ''}`">
    <!-- <VisibleSetting :map-index="mapIndex" v-show="!captureMode && mapIndex !== 0" /> -->
    <div class="leaflet-map" ref="leafletMap" :id="`leaflet-map-${mapIndex}`">
      <TileSetting v-model="tile" v-show="!captureMode" :mapIndex="mapIndex" />
      <!-- Layer Render -->
      <component v-if="layer.type != 'netcdf'" :is="`${layer.type}-layer`" :countLayerPointCluster="countLayerPointCluster" v-for="layer in layers" :key="layer.id" :map="map" :layer="layer" :map-index="mapIndex" ref="leafletLayer" @pointLayerClusterGroupSpiderfied="fPointLayerClusterGroupSpiderfied" @moveCountFromSpiderfied="fmoveCountFromSpiderfied" @emitBringToBackLayerStore="bringToBackLayerStore"></component>
      <component v-if="mapIndex == 0" :is="`${location.type}-location`" v-for="location in mapBasedLocationsTemp" :key="location.id" :map="map" :location="location" :map-index="mapIndex" ref="leafletLocation" :isUselocaltionMapBasedTimeseriesLoader="isUselocaltionMapBasedTimeseriesLoader"></component>
    </div>
    <!-- Tooltip Render -->
    <HoveringTooltip v-if="hovering && hoveringLayer && hoveringLayer.tooltip.visible" :map="map" :map-index="mapIndex" :moveCount="moveCount" :layer="hoveringLayer" :leafletLayer="hoveringLeafletLayer" />
    <HoveringTooltipProcessRate v-if="hoveringProcessRate && hoveringLayerProcessRate && hoveringLayerProcessRate.tooltip.visible" :map="map" :map-index="mapIndex" :moveCount="moveCount" :layer="hoveringLayerProcessRate" :leafletLayer="hoveringLeafletLayerProcessRate" />
    <PermanentTooltip :map="map" :moveCount="moveCount" :mapIndex="mapIndex" :countClusterGroup="countClusterGroup" />
    <!-- Popup Render -->
    <transition v-for="(click, index) in clicking" :key="index" name="fade">
      <component :is="`border-${popupComponent(index)}-popup`" check="true" v-if="popupComponent(index) && click && click.type !== 'netcdf' && clickingLayer[index] && clickingLayer[index].popup && clickingLayer[index].popup.visible && clickingLayer[index].popup.type === 'border' && clickingLayer[index].pin" :clicking="click" :map="map" :moveCount="moveCount" :layer="clickingLayer[index]" :leafletLayer="clickingLeafletLayer && clickingLeafletLayer[index]" :map-index="mapIndex"></component>
    </transition>
    <transition v-for="(click, index) in clicking" :key="index" name="slide">
      <component :is="`borderless-${popupComponent(index)}-popup`" v-if="popupComponent(index) && click && click.type !== 'netcdf' && clickingLayer[index] && clickingLayer[index].popup && clickingLayer[index].popup.visible && clickingLayer[index].popup.type === 'borderless'" :clicking="click" :map="map" :moveCount="moveCount" :layer="clickingLayer[index]" :leafletLayer="clickingLeafletLayer && clickingLeafletLayer[index]" :map-index="mapIndex"></component>
    </transition>
    <div v-if="clickingProcessRate && clickingProcessRate.length" v-for="(click, index) in clickingProcessRate" :key="index">
      <component :is="`process-rate-popup`" :clicking="click" :map="map" :moveCount="moveCount" :map-index="mapIndex" :layer="clickingLayerProcessRate[index]" :leafletLayer="processRateLeafletLayer && processRateLeafletLayer[index]"></component>
    </div>
    <ContextMenuMap v-show="showContextmenuMap" @closeContextMenu="closeContextMenu" :styleContextMenu="styleContextMenu" :class="`contextMenuMap`" />
  </div>
</template>

<script>
const _ = require('lodash')
import { v4 as uuidv4 } from 'uuid'
import L from 'leaflet'
var LEsri = require('esri-leaflet')
import 'leaflet-pixi-overlay'
import '/static/vendor/leaflet-extra-shapes/leaflet.extra-shapes.js'
import '/static/vendor/leaflet-custom-box/custom-map-box-selection.js'
import '/static/vendor/leaflet-smooth-wheel-zoom/smooth-wheel-zoom.js'
import 'leaflet-path-drag/dist/L.Path.Drag'
import 'leaflet-distortableimage/dist/vendor'
import 'leaflet-distortableimage/dist/leaflet.distortableimage'
import 'leaflet-minimap/dist/Control.MiniMap.min.css'
import GroupItemsMixin from '@/mixins/GroupItemsMixin'
import TileSetting from './controls/TileSetting.vue'
// import VisibleSetting from './controls/VisibleSetting.vue'
import PointLayer from './layers/PointLayer'
import ImageLayer from './layers/ImageLayer'
import TyphoonLayer from './layers/TyphoonLayer'
import MultiLayer from './layers/MultiLayer'
import pointLocation from './map-based-time-series-loader/pointLocation'
import BorderTyphoonPopup from './popups/BorderTyphoonPopup.vue'
import BorderlessTyphoonPopup from './popups/BorderlessTyphoonPopup.vue'
import BorderVectorPopup from './popups/BorderVectorPopup.vue'
import ProcessRatePopup from './popups/ProcessRatePopup.vue'
import BorderlessVectorPopup from './popups/BorderlessVectorPopup.vue'
import HoveringTooltip from './tooltips/HoveringTooltip.vue'
import HoveringTooltipProcessRate from './tooltips/HoveringTooltipProcessRate.vue'
import PermanentTooltip from './tooltips/PermanentTooltip.vue'
import MiniMap from 'leaflet-minimap'
import { TILE_LIGHT, TILE_DARK } from '@/constants/app.js'
import { DEFAULT_LOCATION } from '@/constants/location.js'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
import ContextMenuMap from './popups/ContextMenuMap.vue'
import i18n from '@/libs/i18n'

const MAP_CONFIG = {
  wheelPxPerZoomLevel: 1000,
  zoomControl: false,
  preferCanvas: true,
  customBoxZoom: true,
  minZoom: 2,
  maxZoom: 40,
  maxBounds: [
    [85, 10000],
    [-85, -10000],
  ],
  maxBoundsViscosity: 1,
  worldCopyJump: true,
  scrollWheelZoom: true,
  smoothWheelZoom: false,
  smoothSensitivity: 1,
}
const optionMinimap = {
  position: 'bottomleft',
  aimingRectOptions: true,
  toggleDisplay: true,
  strings: {
    hideText: i18n.t('hide_miniMap'),
    showText: i18n.t('show_miniMap'),
  },
}

L.Map.addInitHook(function () {
  this.getContainer()._leaflet_map = this
})

export default {
  props: ['mapIndex', 'mapBasedTimeseriesLoaderLocaltionIsActive'],
  mixins: [GroupItemsMixin, ThemeConfig],
  components: { TileSetting, PointLayer, ImageLayer, TyphoonLayer, MultiLayer, BorderTyphoonPopup, BorderlessTyphoonPopup, BorderVectorPopup, BorderlessVectorPopup, HoveringTooltip, PermanentTooltip, ContextMenuMap, pointLocation, ProcessRatePopup, HoveringTooltipProcessRate },
  data() {
    return {
      map: null,
      moveCount: 0, // Marked as map has moved. Layers, Popup, Tooltip position should be re-computed
      selectArea: false,
      showContextmenuMap: false,
      styleContextMenu: '',
      statusOverLoadDrawGrid: true,
      mapMove: false,
      countClusterGroup: 0,
      mapZoomEnd: 0,
      mapDragEnd: 0,
      countLayerPointCluster: 0,
    }
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    layers() {
      return this.$store.state.layer.layers
    },
    captureMode() {
      return this.$store.state.ecoplot.captureMode
    },
    mapBounds() {
      return this.$store.state.map.mapBounds
    },
    lockMaps() {
      return this.$store.state.map.lockMaps
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    minimapSetting() {
      return this.$store.state.settings.minimap
    },
    countMinimap() {
      return this.$store.state.settings.countMinimap
    },
    zoomSnap() {
      return this.$store.state.settings.zoomSnap
    },
    tile: {
      get() {
        return this.$store.state.map.mapTiles[this.mapIndex]
      },
      set(tile) {
        if (this.tile.url == tile.url) return
        this.$store.commit('map/SET_MAP_TILE', { tile, mapIndex: this.mapIndex })
      },
    },
    // #region Clicking info
    clicking() {
      return this.$store.state.map.clicking[this.mapIndex]
    },
    clickingLayer() {
      let array = []
      try {
        for (let i = 0; i < this.clicking.length; i++) {
          let layer = this.layers.find((l) => l.id === this.clicking[i].layerId)
          if (layer) {
            array.push(layer)
          }
        }
      } catch {}
      return array
    },
    clickingLeafletLayer() {
      let leafletLayer = null
      for (let i = 0; i < this.clicking.length; i++) {
        if (this.map && this.map.eachLayer) {
          this.map.eachLayer((l) => {
            if (l.id === this.clicking[i].layerId) {
              if (!leafletLayer) leafletLayer = []
              leafletLayer.push(l)
            }
          })
        }
      }
      return leafletLayer
    },
    hovering() {
      try {
        return this.$store.state.map.hovering[this.mapIndex]
      } catch {}
    },
    hoveringLayer() {
      try {
        return this.layers.find((l) => l.id === this.hovering.layerId)
      } catch {}
    },
    hoveringLeafletLayer() {
      let leafletLayer = null
      try {
        if (this.map) {
          this.map.eachLayer((l) => {
            if (l.id === this.hovering.layerId) leafletLayer = l
          })
        }
      } catch {}
      return leafletLayer
    },
    hoveringProcessRate() {
      try {
        return this.$store.state.map.hoveringProcessRate[this.mapIndex]
      } catch {}
    },
    hoveringLayerProcessRate() {
      try {
        return this.layers.find((l) => l.id === this.hoveringProcessRate.layerId)
      } catch {}
    },
    hoveringLeafletLayerProcessRate() {
      try {
        if (this.map) {
          if (this.map && this.map._layers) {
            for (let key in this.map._layers) {
              if (this.map._layers[key].id == this.hoveringProcessRate.layerId) {
                return this.map._layers[key]
                break
              }
            }
          }
        }
      } catch {}
      return
    },
    // #endregion Hovering info
    selectedLayerID() {
      return this.$store.state.map.layerSelected
    },
    selectedLayer() {
      return this.layers.find((o) => o.id === this.selectedLayerID)
    },
    mapActive() {
      return this.$store.state.map.mapActive
    },
    locale() {
      return i18n.locale
    },
    checkVisibleAllLayer() {
      let checkVisibleAllLayer = true
      try {
        for (let i = 0; i < this.layers.length; i++) {
          if (this.layers[i].visible && this.layers[i].visible.length && ((this.numberOfMap == 2 && (this.layers[i].visible[0] || this.layers[i].visible[1])) || (this.numberOfMap == 1 && this.layers[i].visible[0]))) {
            checkVisibleAllLayer = false
            break
          }
        }
      } catch {}
      return checkVisibleAllLayer
    },
    mapBasedTimeseriesLoaderIsActive() {
      return this.$store.state.mapBasedTimeseriesLoader.isActive
    },
    isUselocaltionMapBasedTimeseriesLoader() {
      return this.mapBasedTimeseriesLoaderIsActive && this.mapBasedTimeseriesLoaderLocaltionIsActive && this.mapIndex == 0
    },
    mapBasedLocations() {
      return this.$store.state.mapBasedTimeseriesLoader.locations
    },
    mapBasedLocationsTemp() {
      return this.$store.state.mapBasedTimeseriesLoader.locationsTemp
    },
    hoveringClusterGroup() {
      return this.$store.state.map.hoveringClusterGroup
    },
    clickingLayerProcessRate() {
      let array = []
      try {
        for (let i = 0; i < this.clickingProcessRate.length; i++) {
          let layer = this.layers.find((l) => l.id == this.clickingProcessRate[i].layerId)
          if (layer) {
            array.push(layer)
          }
        }
      } catch {}
      return array
    },
    clickingProcessRate() {
      try {
        return this.$store.state.map.clickingProcessRate[this.mapIndex]
      } catch {}
      return null
    },
    processRateLeafletLayer() {
      try {
        let leafletLayerFull = this.$refs.leafletLayer
        let leafletLayer = []
        if (this.clickingProcessRate && this.clickingProcessRate.length) {
          for (let i = 0; i < this.clickingProcessRate.length; i++) {
            if (leafletLayerFull && leafletLayerFull.length) {
              for (let j = 0; j < leafletLayerFull.length; j++) {
                if (leafletLayerFull[j].leafletLayer.id == this.clickingProcessRate[i].layerId) {
                  leafletLayer.push(leafletLayerFull[j].leafletLayer)
                  break
                }
              }
            }
          }
        }
        return leafletLayer
      } catch {}
    },
  },
  beforeDestroy() {
    try {
      if (this.tileLayer) {
        this.tileLayer.remove()
      }
      this.tileLayer = null
    } catch {}
    try {
      if (this.miniMap) {
        this.miniMap.remove()
      }
    } catch {}
    try {
      if (this.map) {
        this.map.remove()
      }
      this.map = null
    } catch {}
  },
  mounted() {
    document.addEventListener('contextmenu', function (event) {
      event.preventDefault()
    })
    MAP_CONFIG.zoomSnap = this.zoomSnap
    this.map = L.map(this.$refs.leafletMap, MAP_CONFIG).setView([38.997680445644974, 137.90039062500003], 5.5)

    // Tile setting
    this.tileLayer = L.tileLayer(this.tile.url, { ...this.tile }).addTo(this.map)
    // Minimap setting
    if (this.minimapSetting) {
      if (this.isDark) var osm2 = new L.TileLayer(TILE_DARK.url, { minZoom: 0, maxZoom: 13 })
      else var osm2 = new L.TileLayer(TILE_LIGHT.url, { minZoom: 0, maxZoom: 13 })
      this.miniMap = new L.Control.MiniMap(osm2, optionMinimap).addTo(this.map)
    }
    // Listen to map div resize => invalidate
    if (this.$refs.leafletMap) {
      new ResizeObserver(() => {
        try {
          this.map.invalidateSize({ animate: false })
        } catch {}
      }).observe(this.$refs.leafletMap)
    }

    // Click on map => delete popup
    this.map.on('click', (e) => {
      try {
        let clickingMap = []
        let clicking = _.cloneDeep(this.clicking)
        if (clicking && clicking.length > 0) {
          clickingMap = clicking.filter((cl) => !cl.pin)
        }
        if (clickingMap.length > 0) {
          const storeData = {
            data: clickingMap,
            mapIndex: this.mapIndex,
          }
          this.$store.commit('map/UPDATE_CLICKING', storeData)
        } else {
          const storeData = {
            data: null,
            mapIndex: this.mapIndex,
          }
          this.$store.commit('map/SET_CLICKING', storeData)
          this.$store.commit('map/SET_CLICKING_PROCESS_RATE', storeData)
        }
      } catch {
        const storeData = {
          data: null,
          mapIndex: this.mapIndex,
        }
        this.$store.commit('map/SET_CLICKING', storeData)
        this.$store.commit('map/SET_CLICKING_PROCESS_RATE', storeData)
      }
      if (!e.originalEvent._simulated) {
        // Xử lý click trên bản đồ
        // code map based timeseries loader
        let name = ''
        try {
          let locationsTemp = this.$store.state.mapBasedTimeseriesLoader.locationsTemp
          let arrName = []
          if (locationsTemp && locationsTemp.length) {
            for (let i = 0; i < locationsTemp.length; i++) {
              arrName.push(locationsTemp[i].name)
            }
          }
          name = this.createNewNameLocation(arrName, `Location`)
        } catch {}
        if (this.isUselocaltionMapBasedTimeseriesLoader) {
          let newLocation = _.cloneDeep(DEFAULT_LOCATION)
          newLocation.id = uuidv4()
          newLocation.name = name
          newLocation.type = 'point'
          newLocation.dataPoint = e.latlng
          this.$store.commit('mapBasedTimeseriesLoader/ADD_LOCALTION_TEMP', newLocation)
        }
      }
    })
    const self = this
    this.map.on('move', () => {
      //   this.mapMove = true;
      self.moveCount++
    })
    this.map.on('moveend', () => {
      if (this.$route && this.$route.name && this.$route.name == 'map') {
        let bounds
        try {
          let getbounds = this.map.getBounds()
          bounds = bounds = [
            [getbounds.getNorth(), getbounds.getWest()],
            [getbounds.getSouth(), getbounds.getEast()],
          ]
        } catch {}
        this.$store.commit('map/UPDATE_MAP_BOUNDS_AUTO_UPDATE', { mapIndex: this.mapIndex, data: bounds })
      }
    })
    this.map.on('dragend', () => {
      if (this.lockMaps && this.numberOfMap === 2) {
        let center = this.map.getCenter()
        let zoom = this.map.getZoom()
        let theMapBeside = null
        if (this.mapIndex === 0) {
          theMapBeside = (document.getElementById('leaflet-map-1') || {})._leaflet_map
        } else {
          theMapBeside = (document.getElementById('leaflet-map-0') || {})._leaflet_map
        }
        // Update other map
        if (center !== theMapBeside.getCenter() || zoom !== theMapBeside.getZoom()) {
          theMapBeside.setView(center, zoom, { animate: false })
        }
      }
      // this.mapDragEnd++;
    })
    this.map.on('zoomend', this.zoomHandler)
    this.map.on('boxzoomend', (e) => {
      this.groupSelectItems(e.boxZoomBounds)
    })

    L.DomEvent.on(this.map.getContainer(), 'mousewheel', (event) => {
      if (event.ctrlKey) {
        this.map.scrollWheelZoom.disable()
        this.map.smoothWheelZoom.enable()
      } else {
        this.map.scrollWheelZoom.enable()
        this.map.smoothWheelZoom.disable()
      }
    })

    // const option_group = self.$refs.leafletMap;
    this.map.on('mousedown', (event) => {
      try {
        if (event.originalEvent.which === 3) {
          setTimeout(() => {
            if (this.numberOfMap === 2) {
              for (let i = 0; i < this.$parent.$refs.mapItem.length; i++) {
                this.$parent.$refs.mapItem[i].showContextmenuMap = false
                this.$parent.$refs.mapItem[i].styleContextMenu = ''
              }
            }
            this.showContextmenuMap = true
            const panel = document.querySelector('.map-container').getBoundingClientRect()
            const contextMenu = document.querySelector(`.contextMenuMap`).getBoundingClientRect()
            let bottomHeight = panel.top + panel.height
            let rightWidth = panel.left + panel.width
            let top = bottomHeight - event.originalEvent.y > contextMenu.height + 10 ? event.originalEvent.y : bottomHeight - (contextMenu.height + 10)
            let left = rightWidth - event.originalEvent.x > contextMenu.width + 10 ? event.originalEvent.x + 10 : rightWidth - (contextMenu.width + 10)
            this.styleContextMenu = ` left:${left}px; top:${top}px;`
          }, 50)
        } else {
          this.showContextmenuMap = false
          this.styleContextMenu = ''
        }
      } catch {}
    })

    // this.mountPixi()
    this.openLinkLeafletWithBrowser()
  },
  watch: {
    checkVisibleAllLayer(checkVisibleAllLayer) {
      if (checkVisibleAllLayer) this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', false)
    },
    locale() {
      if (document.querySelector('.leaflet-control-minimap-toggle-display')) {
        let elementMiniMap = document.querySelector('.leaflet-control-minimap-toggle-display')
        if (elementMiniMap.classList.contains('minimized-bottomleft')) {
          elementMiniMap.title = i18n.t('show_miniMap')
        } else {
          elementMiniMap.title = i18n.t('hide_miniMap')
        }
      }
      this.miniMap.options.strings.hideText = i18n.t('hide_miniMap')
      this.miniMap.options.strings.showText = i18n.t('show_miniMap')
    },
    zoomSnap() {
      this.map.options.zoomSnap = this.zoomSnap
    },
    theme() {
      if (this.isDark) {
        this.$store.commit('map/SET_MAP_TILE', { tile: TILE_DARK, mapIndex: this.mapIndex })
      } else {
        this.$store.commit('map/SET_MAP_TILE', { tile: TILE_LIGHT, mapIndex: this.mapIndex })
      }
    },
    countMinimap() {
      setTimeout(() => {
        try {
          if (this.minimapSetting) {
            if (this.miniMap) {
              this.miniMap.remove()
            }
            var osm2
            if (this.tile && this.tile.category == 'esri-basemaplayer') {
              osm2 = LEsri.basemapLayer(this.tile.url, { minZoom: 0, maxZoom: 13 })
            } else {
              osm2 = new L.TileLayer(this.tile.url, { minZoom: 0, maxZoom: 13 })
            }
            this.miniMap = new L.Control.MiniMap(osm2, optionMinimap).addTo(this.map)
          } else {
            if (this.miniMap) {
              this.miniMap.remove()
            }
          }
        } catch (error) {}
      }, 500)
    },
    minimapSetting() {
      if (this.minimapSetting) {
        try {
          if (this.miniMap) {
            this.miniMap.remove()
          }
          var osm2
          if (this.tile && this.tile.category == 'esri-basemaplayer') {
            osm2 = LEsri.basemapLayer(this.tile.url, { minZoom: 0, maxZoom: 13 })
          } else {
            osm2 = new L.TileLayer(this.tile.url, { minZoom: 0, maxZoom: 13 })
          }
          this.miniMap = new L.Control.MiniMap(osm2, optionMinimap).addTo(this.map)
        } catch (error) {}
      } else {
        if (this.miniMap) {
          this.miniMap.remove()
        }
      }
    },
    mapBounds(mapBounds) {
      if (mapBounds && mapBounds[this.mapIndex]) {
        this.map.fitBounds(mapBounds[this.mapIndex])
      }
    },
    tile: {
      handler(newVal, oldVal) {
        if (newVal.url != oldVal.url) {
          let optionMinimapTemp = _.cloneDeep(optionMinimap)
          try {
            if (this.tileLayer) {
              this.tileLayer.remove()
            }
          } catch {}
          if (this.tile && this.tile.category == 'esri-basemaplayer') {
            this.tileLayer = LEsri.basemapLayer(this.tile.url).addTo(this.map)
          } else {
            this.tileLayer = L.tileLayer(this.tile.url, { ...this.tile }).addTo(this.map)
          }
          if (this.minimapSetting && this.miniMap) {
            try {
              if (this.miniMap) {
                this.miniMap.remove()
              }
              var osm2
              if (this.tile && this.tile.category == 'esri-basemaplayer') {
                osm2 = LEsri.basemapLayer(this.tile.url, { minZoom: 0, maxZoom: 13 })
              } else {
                osm2 = new L.TileLayer(this.tile.url, { minZoom: 0, maxZoom: 13 })
              }
              // var osm2 = new L.TileLayer(this.tile.url, { minZoom: 0, maxZoom: 13 });
              this.miniMap = new L.Control.MiniMap(osm2, optionMinimapTemp).addTo(this.map)
            } catch {}
          }
          this.openLinkLeafletWithBrowser()
        }
      },
    },
    layers: {
      deep: true,
      handler(layers) {
        this.bringToBackLayerStore()
        this.countLayerPointCluster++
        if (this.layers.length === 0) {
          this.$store.commit('image/DELETE_ALL_POPUP_IMAGES')
        }
      },
    },
    selectedLayer(layer) {
      this.statusOverLoadDrawGrid = true
      this.$store.commit('map/CLEAR_CLICKING')
    },

    mapBasedTimeseriesLoaderIsActive(isActive) {
      if (this.mapIndex == 0) {
        if (isActive) {
          try {
            let mapBasedLocations = []
            if (this.mapBasedLocations && this.mapBasedLocations.length) {
              mapBasedLocations = _.cloneDeep(this.mapBasedLocations)
            }
            let mapBasedLocationsTemp = []
            if (this.mapBasedLocationsTemp && this.mapBasedLocationsTemp.length) {
              mapBasedLocationsTemp = _.cloneDeep(this.mapBasedLocationsTemp)
            }
            // let mergeLocations;
            let mergeLocations = _.unionBy(mapBasedLocations, mapBasedLocationsTemp, 'id')
            this.$store.commit('mapBasedTimeseriesLoader/SET_LOCALTION_TEMP', mergeLocations)
          } catch {}
        } else {
          this.$store.commit('mapBasedTimeseriesLoader/SET_LOCALTION_TEMP', [])
        }
      }
    },
    mapBasedLocations: {
      deep: true,
      handler() {
        try {
          if (!this.mapBasedLocations || !this.mapBasedLocations.length) {
            this.$mapBasedTimeseriesLoader = {}
          } else {
            for (let i = 0; i < this.mapBasedLocations.length; i++) {
              if (!this.$mapBasedTimeseriesLoader[this.mapBasedLocations[i].id]) {
                this.$mapBasedTimeseriesLoader[this.mapBasedLocations[i].id] = {}
              }
            }
          }
        } catch {}
        if (this.mapIndex == 0 && this.mapBasedTimeseriesLoaderIsActive) {
          try {
            let mapBasedLocations = []
            if (this.mapBasedLocations && this.mapBasedLocations.length) {
              mapBasedLocations = _.cloneDeep(this.mapBasedLocations)
            }
            let mapBasedLocationsTemp = []
            if (this.mapBasedLocationsTemp && this.mapBasedLocationsTemp.length) {
              mapBasedLocationsTemp = _.cloneDeep(this.mapBasedLocationsTemp)
            }
            // let mergeLocations;
            let mergeLocations = _.unionBy(mapBasedLocations, mapBasedLocationsTemp, 'id')
            this.$store.commit('mapBasedTimeseriesLoader/SET_LOCALTION_TEMP', mergeLocations)
          } catch {}
        }
      },
    },
  },
  methods: {
    bringToBackLayerStore() {
      this.$nextTick(() => {
        setTimeout(() => {
          try {
            if (this.layers && this.layers.length) {
              let indexLeaflet
              // for (let i = 0; i < this.layers.length; i++) {
              //   let leafletLayer = this.$refs.leafletLayer.find((ref) => {
              //     indexLeaflet = i;
              //     return ref.leafletLayer && ref.leafletLayer.id === this.layers[i].id;
              //   });
              //   if (leafletLayer) {
              //     leafletLayer.leafletLayer.bringToBack();
              //     this.setZIndexImage(leafletLayer, indexLeaflet);
              //   }
              // }
              for (let i = this.layers.length - 1; i >= 0; i--) {
                let leafletLayer = this.$refs.leafletLayer.find((ref) => {
                  indexLeaflet = i
                  return ref.leafletLayer && ref.leafletLayer.id === this.layers[i].id
                })
                if (leafletLayer) {
                  leafletLayer.leafletLayer.bringToFront()
                  // this.setZIndexImage(leafletLayer, indexLeaflet);
                }
              }
            }
          } catch {}
        }, 0)
      })
    },
    fmoveCountFromSpiderfied() {
      this.moveCount++
    },
    fPointLayerClusterGroupSpiderfied() {
      this.countClusterGroup++
    },
    createNewNameLocation(existingNames, baseName = 'Location') {
      let count = 1
      let newName = `${baseName} ${count}`
      while (existingNames.includes(newName)) {
        newName = `${baseName} ${count}`
        count++
      }
      return newName
    },
    setZIndexImage(leafletLayer, i) {
      try {
        let flagImage = false
        if (leafletLayer) {
          if (leafletLayer.leafletLayer.type === 'image') {
            if (i == 0) {
              flagImage = true
              leafletLayer.leafletLayer._image.style.zIndex = 101
            } else if (flagImage) {
              leafletLayer.leafletLayer._image.style.zIndex = 101
            } else leafletLayer.leafletLayer._image.style.zIndex = 99
          } else {
            flagImage = false
          }
        }
      } catch (error) {}
    },
    closeContextMenu() {
      this.showContextmenuMap = false
      this.styleContextMenu = ''
    },
    setMapActive(mapIndex) {
      if (this.mapActive !== mapIndex) {
        this.$store.commit('map/SET_MAP_ACTIVE', mapIndex)
      }
    },
    popupComponent(index) {
      // Point, Multi layer have same popup => use this var help not have to write 3 popup component
      try {
        if (this.clickingLeafletLayer[index].type === 'typhoon') return 'typhoon'
        if (['point', 'multi'].includes(this.clickingLeafletLayer[index].type)) return 'vector'
      } catch {}
      return null
    },
    openLinkLeafletWithBrowser() {
      try {
        let listLink = document.querySelectorAll('.leaflet-control-attribution.leaflet-control a')
        if (listLink) {
          for (let i = 0; i < listLink.length; i++) {
            listLink[i].setAttribute('target', '_Blank')
          }
        }
      } catch {}
    },
    zoomHandler() {
      try {
        if (this.lockMaps && this.numberOfMap === 2) {
          let center = this.map.getCenter()
          let zoom = this.map.getZoom()
          let theMapBeside = null
          if (this.mapIndex === 0) {
            theMapBeside = (document.getElementById('leaflet-map-1') || {})._leaflet_map
          } else {
            theMapBeside = (document.getElementById('leaflet-map-0') || {})._leaflet_map
          }
          // Update other map
          if (center !== theMapBeside.getCenter() || zoom !== theMapBeside.getZoom()) {
            theMapBeside.setView(center, zoom, { animate: false })
          }
        }
      } catch (error) {
        console.error(error)
      }
      // this.mapZoomEnd++;
    },
    getPolygonBoundary(bounds) {
      let bottomLeft = bounds[0]
      let topRight = bounds[1]
      let topLeft = [topRight[0], bottomLeft[1]]
      let bottomRight = [bottomLeft[0], topRight[1]]

      return [topLeft, topRight, bottomRight, bottomLeft]
    },
  },
}
</script>

<style lang="scss" scoped>
.leaflet-map {
  position: relative;
  flex-grow: 1;
  height: calc(100vh - 94.5px - 42px - 1rem);
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: max-width 0.5s linear;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  max-width: 0;
}

.slide-leave,
.slide-enter-to {
  max-width: 1000px;
}
</style>

<style>
.my-tooltip {
  border-radius: 0;
  box-shadow: none;
}

.my-tooltip.leaflet-tooltip-left:before {
  border-left-color: var(--caret-color);
}

.my-tooltip.leaflet-tooltip-right:before {
  border-right-color: var(--caret-color);
}

.my-tooltip.leaflet-tooltip-top:before {
  border-top-color: var(--caret-color);
}

.my-tooltip.leaflet-tooltip-bottom:before {
  border-bottom-color: var(--caret-color);
}

.group-unselect-toast {
  padding: 10px;
}

.group-unselect-toast ul {
  margin: 0;
}

.leaflet-control {
  border: 0px !important;
}

.dark-layout .leaflet-control-minimap {
  /* border-radius: 0.428rem; */
  background: #161d31;
}

.leaflet-control-minimap {
  border-radius: 0.428rem;
  /* background: unset; */
}

.leaflet-control-minimap-toggle-display-bottomleft {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAqUlEQVRIie3TOwoCMRRG4U8RO1EL0VJLW1cg2IjgAsR9Cgp2Fm5FtLPQxkfhlGYSZ7CbAyEQfu65JLlUVPybekKmXab4BRvUApk17hjkFQnxxBFz7AKSIZroFhHAElvMsA9IcokJXlhkkmkRScojlpKkCEpJUgWlJL9S8/m6T5yyfRwKNyLFVhh9OT9ggn4mKETHZ4gekXWTM2ixO+yhFclccU5quaKiEG8pNSaDEcLeVgAAAABJRU5ErkJggg==');
}

.leaflet-bottom.leaflet-left {
  z-index: 419 !important;
}

.app-leaflet-map-0,
.app-leaflet-map-1 {
  z-index: 1;
}

.leaflet-map-based-timeseries-add-location .leaflet-map {
  cursor: crosshair;
}
</style>
