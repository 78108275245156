<template>
  <div class="panel-container" ref="panel">
    <b-card header-bg-variant="primary" header-text-variant="white" :style="{ maxHeight: isOpen ? '100vh' : '40px', width: isOpen ? '350px' : '40px' }">
      <div class="custom-scrollbar" id="custom-scrollbar-sidebar-right">
        <DatasourceManager />
        <LayerManager />
      </div>
    </b-card>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import DatasourceManager from './datasources/DatasourceManager.vue';
import LayerManager from './layers/LayerManager.vue';
import { ThemeConfig } from '@/mixins/ThemeMixin.js';

export default {
  props: ['walkPlayer', 'cancelWalk'],
  mixins: [ThemeConfig],
  components: { DatasourceManager, LayerManager, VuePerfectScrollbar },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    numberOfMap() {
      return this.$store.state.map.numberOfMap;
    },
  },
};
</script>

<style scoped>
.open-panel {
  margin-right: 10px;
  cursor: pointer;
}
.panel-container {
  user-select: none;
  top: calc(30px + 90.3px - 1rem + 10px);
  left: calc(260px + 1rem + 152px);
  z-index: 402;
  border-radius: 0.428rem;
  overflow: hidden;
}

.panel-container .card {
  margin: 0;
  background-color: #fff;
  backdrop-filter: blur(0px);
  transition: all 0.3s, backdrop-filter 0s;
  width: 350px;
  overflow: hidden;
}

.dark-layout .panel-container .card {
  background-color: rgba(22, 29, 49, 0.4);
  backdrop-filter: blur(0px);
}

.vertical-layout.vertical-menu-modern.menu-collapsed .panel-container {
  left: calc(80px + 1rem + 152px);
}

.panel-container:hover .card {
  background-color: #fff;
  backdrop-filter: blur(4px);
}

.dark-layout .panel-container:hover .card {
  background-color: rgba(22, 29, 49, 0.7);
  backdrop-filter: blur(4px);
}

.panel-container .card-header {
  position: relative;
  padding: 0;
  min-height: 40px;
}

.panel-container .card-body {
  padding: 0;
}

.panel-container .card-body .custom-scrollbar {
  max-height: calc(100vh - 30px - 90.3px - 2rem - 20px);
  height: 870px;
  padding: 0.5rem;
  overflow-y: auto;
  width: 350px;
}

.panel-container .panel-header {
  display: flex;
  align-items: center;
  line-height: 20px;
  width: 350px;
  min-width: 350px;
  padding: 10px 16px;
  transition: padding-left 0.3s;
}

.panel-container .action-container {
  display: flex;
  margin-left: auto;
}

.panel-container .action-container > div {
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.panel-container .action-container > div:last-child {
  margin-right: 0;
}
</style>

<style>
.panel-container .custom-toggle {
  border: 0;
  padding: 0;
  background-color: transparent !important;
}

.panel-container .custom-toggle::after {
  display: none;
}

.panel-container .dropdown-capture .dropdown-menu {
  margin-top: 20px;
  opacity: 0;
  transition: opacity 0.3s, margin-top 0.3s;
  display: block;
  pointer-events: none;
}
.panel-container .dropdown-capture .dropdown-menu.show {
  margin-top: 10px;
  opacity: 1;
  pointer-events: all;
}
.panel-container {
  z-index: 421;
}
.mb-0-5rem {
  margin-bottom: 0.5rem;
}
</style>
