<template>
  <div class="popup-container" :style="{ top: position.y + 'px', left: position.x + 'px', fontFamily: layer.popup.family }">
    <div class="popup-animation">
      <svg class="popup-svg" height="30" width="100%">
        <polyline points="4,26 174,26 200,1 999,1" :style="{ fill: 'none', strokeWidth: 2, stroke: layer.popup.lineColor }" />
        <circle cx="4" cy="26" r="4" :fill="layer.popup.lineColor" />
      </svg>
      <div class="popup-text">
        <div class="popup-header" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor }">
          {{ location.summary.date }}
          <feather-icon v-if="layer.popup.multiShow" size="16" icon="XIcon" class="cursor-pointer" @click="closePopup(clicking.layerId, clicking.clickId, clicking.clickUid, layer.type)" />
        </div>

        <div class="popup-content" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }">
          <div class="popup-info">
            <span>Position</span>
            <span>{{ location.latlng[0] }}, {{ location.latlng[1] }}</span>
          </div>
          <div class="popup-info">
            <span>Pressure</span>
            <span>{{ location.summary.pressure }}hPa</span>
          </div>
          <div class="popup-info">
            <span>Wind</span>
            <span>{{ Math.floor(location.summary.wind / 1.944) }}m/s ({{ location.summary.wind }}kt)</span>
          </div>
          <div class="popup-info" v-if="location.summary.storm">
            <span>Storm Radius</span>
            <span>{{ Math.floor(location.summary.storm * 1.852) }}km ({{ location.summary.storm }}nm)</span>
          </div>
          <div class="popup-info" v-if="location.summary.gale">
            <span>Gale Radius</span>
            <span>{{ Math.floor(location.summary.gale * 1.852) }}km ({{ location.summary.gale }}nm)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['map', 'moveCount', 'layer', 'leafletLayer', 'clicking', 'mapIndex'],
  computed: {
    location() {
      return this.leafletLayer.getLayers().find((l) => l.type === 'marker' && l.date === this.clicking.clickId);
    },
    position() {
      this.moveCount; // position cũng compute lại khi move map
      return this.map.latLngToContainerPoint(this.location.latlng);
    },
  },
  methods: {
    closePopup(layerId, clickId, clickUid, type) {
      const storeData = {
        data: { layerId, clickId, clickUid, type },
        mapIndex: this.mapIndex,
      };
      this.$store.commit('map/CLOSE_POPUP', storeData);
    },
  },
};
</script>

<style scoped>
.popup-container {
  position: absolute;
  z-index: 401;
  transform: translate(-4px, -56px);
  white-space: nowrap;
}

.popup-container .popup-animation {
  width: fit-content;
}

.popup-container .popup-svg {
  position: absolute;
  top: 30px;
  z-index: -1;
}

.popup-container .popup-text {
  margin-left: 200px;
  padding: 0 10px;
}

.popup-container .popup-header {
  font-weight: bold;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
}

.popup-container .popup-info {
  display: flex;
  justify-content: space-between;
}

.popup-container .popup-info span:first-child {
  min-width: 80px;
  margin-right: 10px;
  font-weight: bold;
}
</style>
