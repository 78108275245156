<template>
  <div>
    <div ref="popupContainer" class="popup-location-map-based" v-show="isUselocaltionMapBasedTimeseriesLoader">
      <div><input v-model="locationName" ref="inputContainer" class="ip-location-name" v-show="marker && location"
          placeholder="Enter location name" /></div>
      <div class="d-flex align-item-center justify-conent-center">
        <span v-if="validateName" :title="$t('Rename_location')" class="popup-location-map-based-check cursor-pointer">
          <feather-icon icon="CheckIcon" size="18" color="#28c76f" @click.stop="renameLocation" />
        </span>
        <span v-else :title="`Name already exists`" class="popup-location-map-based-check">
          <feather-icon icon="AlertOctagonIcon" size="17" color="#ff9f43" />
        </span>
        <span :title="$t('Delete_location')" @click.stop="deleteLocation" class="cursor-pointer btn-del-location">
          <feather-icon icon="Trash2Icon" size="17" color="red" />
        </span>


      </div>
    </div>

    <i class="fa-solid fa-location-dot leaflet-div-icon-i" v-show="marker" ref="iconContainer"
      :style="{ fontSize: locationActive && locationActive.id && location && location.id && locationActive.id == location.id ? `28px` : `24px`, color: `#397bff` }">
      <span class="location-name">{{ location && location.name ? location.name : '' }}</span>
    </i>
  </div>
</template>

<script>
export default {
  props: ['map', 'location', 'mapIndex', 'isUselocaltionMapBasedTimeseriesLoader'],
  data() {
    return {
      marker: null,
      locationName: '',
    };
  },
  beforeDestroy() {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
  },
  mounted() {
    // Tạo marker với icon là map pin
    const self = this;
    let iconContaier = this.$refs.iconContainer;
    const markerIcon = L.divIcon({
      className: 'leaflet-div-icon',
      html: iconContaier,
      iconSize: [24, 28], // Kích thước của icon
      iconAnchor: [12, 30], // Điểm neo của icon, thường là chính giữa dưới cùng của icon
      popupAnchor: [0, -38], // Điểm neo của popup, thường là bên trên của icon
      shadowSize: [20, 32],
    });
    let point = { lat: 41.31396399498711, lng: 141.24023437500003 };
    try {
      point = this.location.dataPoint;
    } catch { }
    var marker = L.marker(point, {
      icon: markerIcon,
      draggable: this.isUselocaltionMapBasedTimeseriesLoader != undefined ? this.isUselocaltionMapBasedTimeseriesLoader : false,
    });
    if (this.isUselocaltionMapBasedTimeseriesLoader) {
      this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', this.location);
    }
    try {
      self.locationName = self.location.name;
    } catch { }
    this.marker = marker;
    const popupElement = this.$refs.popupContainer;
    this.marker.addTo(this.map).bindPopup(popupElement);
    try {
      this.marker.openPopup();
    } catch { }

    this.marker.on('click', (event) => {
      if (self.marker && self.location && self.isUselocaltionMapBasedTimeseriesLoader) {
        self.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', self.location);
      }
      try {
        let isOpenPopup = this.marker.getPopup().isOpen();
        if (!isOpenPopup) {
          self.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', null);
        } else {
          self.locationName = self.location.name;
        }
      } catch { }
    });

    this.marker.on('dragend', (event) => {
      try {
        self.location.dataPoint = self.marker._latlng;
        self.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', null);
        this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_TEMP', {
          id: self.location.id,
          data: {
            dataPoint: self.marker._latlng,
          },
        });
      } catch { }
    });
  },
  computed: {
    locationActive() {
      return this.$store.state.mapBasedTimeseriesLoader.locationActive;
    },
    arrNameLocation() {
      let arrName = [];
      try {
        let locationsTemp = this.$store.state.mapBasedTimeseriesLoader.locationsTemp;
        if (locationsTemp && locationsTemp.length) {
          for (let i = 0; i < locationsTemp.length; i++) {
            arrName.push(locationsTemp[i].name);
          }
        }
      } catch { }
      return arrName;
    },
    validateName() {
      try {
        if (this.arrNameLocation && this.arrNameLocation.length && this.locationName && this.location.name != this.locationName) {
          return this.arrNameLocation.indexOf(this.locationName) > -1 ? false : true;
        }
      } catch { }
      return true;
    },
  },
  watch: {
    isUselocaltionMapBasedTimeseriesLoader: {
      handler(check) {
        try {
          this.marker.closePopup();
          this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', null);
        } catch { }
        try {
          if (this.marker) {
            if (check) {
              this.marker.dragging.enable();
            } else {
              this.marker.dragging.disable();
            }
          }
        } catch { }
      },
    },
    location() { },
    'location.dataPoint': {
      handler() {
        if (!this.marker) return;
        this.marker.setLatLng(this.location.dataPoint);
      },
    },
  },
  methods: {
    deleteLocation() {
      if (this.location && this.location.id) {
        if (this.$mapBasedTimeseriesLoader[this.location.id]) {
          delete this.$mapBasedTimeseriesLoader[this.location.id];
        }
        this.$store.commit('mapBasedTimeseriesLoader/REMOVE_LOCALTION_TEMP', this.location.id);
      }
    },
    renameLocation() {
      if (this.location && this.location.id) {
        this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_TEMP', {
          id: this.location.id,
          data: {
            name: this.locationName,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.leaflet-div-icon-i {
  position: relative;
}

#inputContainer {
  width: 100%;
}

.location-name {
  position: absolute;
  top: calc(100% + 3px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
}

.popup-location-map-based-check {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

span.location-name {
  display: inline-block;
  width: 100px;
  font-size: 14px;
  font-family: -webkit-body;
  font-weight: 100;
  text-align: center;
}

.btn-del-location {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.leaflet-div-icon {
  background: transparent;
  border: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.leaflet-popup {
  pointer-events: inherit !important;
}

.popup-location-map-based {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-location-map-based-remove {
  border: solid 0.1px #ccc;
  border-radius: 2px;
  color: red;
  display: inline-block;
  padding: 2px 10px;
  cursor: pointer;
}

// .input-popup-location {
//   border-radius: 4px;
//   &.disabled {
//     pointer-events: none;
//     opacity: 0.8;
//   }
// }
.leaflet-map-based-timeseries {
  .leaflet-popup-content-wrapper {
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4) !important;
    background: #fff !important;
    width: 100%;
  }

  .leaflet-popup-tip-container {
    display: inherit !important;
  }

  .leaflet-popup.leaflet-zoom-animated {
    margin-bottom: 15px !important;
  }

  .leaflet-popup-content {
    margin: 3px 8px !important;
    width: 190px !important;
  }
}

.ip-location-name {
  width: 150px;
  font-size: 13px;
  border: none;

  &:focus {
    outline: none;
  }
}
</style>
