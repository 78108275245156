<template>
  <b-modal id="datasource-modal" size="xl" :title="$t('add_datasource')" centered :hide-footer="true" ref="datasourceModal" :body-class="'custom-body body-datasource-option'">
    <template #modal-header>
      <div class="d-flex align-items-center justify-content-between flex-grow-1 modal-title">
        <h4 class="modal-title d-flex align-items-center justify-content-between">
          <label class="layer-name-config">{{ $t('add_datasource') }}</label>
        </h4>
      </div>
      <button type="button" class="close" :title="$t('close')" @click="close">&times;</button>
    </template>
    <b-tabs>
      <b-container class="bv-example-row custom-scrollbar" style="overflow: auto">
        <b-row style="overflow-x: hidden">
          <b-col style="padding: 0"><UploadFiles :initProject="initProject" /></b-col>
          <b-col :style="{ 'max-width': showChooseDatasources ? '100%' : '0%' }" style="padding: 0; transition: 0.5s"><ChooseDatasources /></b-col>
        </b-row>
      </b-container>
    </b-tabs>
  </b-modal>
</template>

<script>
import UploadFiles from './UploadFiles.vue';
import ChooseDatasources from './ChooseDatasources.vue';

export default {
  components: { UploadFiles, ChooseDatasources },
  data() {
    return {
      showChooseDatasources: false,
    };
  },
  computed: {
    initProject() {
      return this.$store.state.ecoplot.initProject;
    },
  },
  methods: {
    open() {
      this.$refs.datasourceModal.show();
    },
    close() {
      this.$refs.datasourceModal.hide();
    },
  },
};
</script>

<style>
.custom-body.modal-body {
  color: inherit !important;
}
.modal-body.body-datasource-option {
  padding-top: 0px;
}
</style>
