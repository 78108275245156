<template>
  <vue-select v-model="selected" :appendToBody="true" :calculatePosition="calculatePosition" :reduce="(data) => data.value" :options="options" class="select-size-sm v-select-layer-setting" :placeholder="$t('timeseries')" :multiple="multiple">
    <template #no-options>{{ $t('no_data_sources') }}</template>
  </vue-select>
</template>

<script>
export default {
  props: ['value', 'multiple', 'dataSelected'],
  components: {},
  data() {
    return {
      selected: this.value,
    }
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    options() {
      let optionMulti
      try {
        for (let i = 0; i < this.datasources.length; i++) {
          if (this.datasources[i].id == this.dataSelected && this.datasources[i].type == 'multi') {
            optionMulti = {
              value: this.datasources[i].id,
              label: this.datasources[i].name,
            }
            break
          }
        }
      } catch {}
      let dataOption = this.datasources.filter((d) => d.type === 'timeseries').map((d) => ({ value: d.id, label: d.name }))
      if (optionMulti) {
        dataOption.push(optionMulti)
      }
      return dataOption
    },
  },
  watch: {
    options() {
      if (this.options.findIndex((o) => o.value === this.selected) === -1) {
        this.selected = null
        this.$nextTick(() => {
          this.$emit('commit')
        })
      }
    },
    selected() {
      this.$emit('input', this.selected)
    },
    value() {
      this.selected = this.value
    },
  },
  methods: {
    calculatePosition(dropdownList, component, { width, top, left }) {
      dropdownList.classList.add('width-fit-content')
      dropdownList.style.top = top
      dropdownList.style.left = left
      dropdownList.style.width = width
    },
  },
}
</script>
<style lang="scss">
.v-select-layer-setting {
  input,
  span {
    font-size: 0.75rem !important;
  }
}
</style>
