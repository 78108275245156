<template>
  <div class="aws-s3-manager" :class="{ 'cursor-running': processRunning && S3Client }">
    <div class="d-none">
      <input type="file" ref="fileUploadInput" multiple @change="handleFileChange" />
    </div>
    <div class="d-none">
      {{ countActionUseDatasource }}
    </div>
    <div class="aws-s3-toplist">
      <vue-select v-model="selected" :appendToBody="false" :reduce="(data) => data.value" :options="buckets" class="select-size-sm select-list-bucket">
        <template #no-options>{{ $t('no_bucket') }}</template>
      </vue-select>
      <div class="aws-s3-setting" :title="$t('S3_config')" @click="showMoalConfigAwsS3">
        <feather-icon icon="SettingsIcon" size="18" />
      </div>
      <div class="aws-s3-loading-bucket" v-show="loadingBucket && S3Client">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t('loading') }}....
      </div>
    </div>
    <div class="path-tree d-flex align-items-center" v-if="selected != null && bucket">
      <div class="mr-50 path-tree-back-forward">
        <span class="cursor-pointer" :class="{ 'disable-action': back.length < 2 }" @click="funcBackForward('back')">
          <feather-icon icon="ArrowLeftIcon" size="16" />
        </span>
        <span class="cursor-pointer" :class="{ 'disable-action': forward.length < 1 }" @click="funcBackForward('forward')">
          <feather-icon icon="ArrowRightIcon" size="16" />
        </span>
      </div>
      <div class="path-tree-icon d-flex align-items-center path-tree-list">
        <span class="list-key">{{ key }}</span>
      </div>
    </div>
    <div class="aws-s3-button" v-show="selected != null && bucket">
      <b-button variant="success" :style="{ padding: locale == 'en' ? `0.486rem 0.99rem` : `0.486rem 0.49rem`, marginRight: '3px' }" class="btn-sm" :class="{ 'cursor-running': processRunning, 'disable-action': processRunning || !(key.slice(-1) == '/' || (bucket != '' && key == '')) }" @click="actionNewFolder"><feather-icon icon="FolderPlusIcon" size="16" class="mr-50" />{{ $t('new_folder') }}</b-button>
      <b-button variant="success" :style="{ padding: locale == 'en' ? `0.486rem 0.99rem` : `0.486rem 0.49rem`, marginRight: '3px' }" class="btn-sm" :class="{ 'cursor-running': processRunning, 'disable-action': processRunning || !(key.slice(-1) == '/' || (bucket != '' && key == '')) }" @click="actionUpload(key, true)"><feather-icon icon="UploadIcon" size="16" class="mr-50" />{{ $t('upload') }}</b-button>
      <b-button variant="danger" :style="{ padding: locale == 'en' ? `0.486rem 0.99rem` : `0.486rem 0.49rem`, marginRight: '3px' }" class="items-del btn-sm" :class="{ 'cursor-running': processRunning, 'disable-action': processRunning || !arrFoldersFilesActive.length }" @click="deleteMultilObject(arrFoldersFilesActive)">
        <span class="items-del-count card" v-if="arrFoldersFilesActive.length">{{ arrFoldersFilesActive.length }}</span>
        <feather-icon icon="TrashIcon" size="16" class="mr-50" />
        {{ $t('delete') }}
      </b-button>
    </div>
    <div class="aws-s3-files">
      <div class="view-container" :class="{ 'list-view': !dataFormat }">
        <div class="folder-manager">
          <div class="folder-manager-items folder selected" :class="{ 'card-no-list': dataFormat }" v-show="checkNewFolder">
            <div class="folder-manager-item">
              <div class="card-img-top d-flex file-logo-wrapper">
                <div class="d-flex align-items-center justify-content-center w-100">
                  <feather-icon icon="FolderIcon" class="mr-50" size="20" />
                </div>
              </div>
              <div class="folder-manager-item-info-new">
                <p class="card-text file-name mb-0"><input type="text" id="newfoldertree" ref="newfoldertree" v-model="inputValueNewFolder" placeholder="new folder" @blur="addNewFolderAWS" /></p>
              </div>
            </div>
          </div>
          <div class="folder-manager-items cursor-pointer" :class="{ selected: folder.active }" v-if="foldersSearch && foldersSearch.length > 0" v-for="(folder, index) in foldersSearch" :key="folder.Key" @dblclick.prevent="getFolderItems(folder.Key)">
            <div class="folder-manager-item folder">
              <div class="card-img-top file-logo-wrapper" @mouseleave="handleMouseLeave">
                <div class="dropdown float-right">
                  <feather-icon icon="MoreVerticalIcon" class="toggle-dropdown mt-n25" @click="clShowListAction(bucket, folder.Key)" />
                  <div class="dropdown-menu dropdown-menu-right file-dropdown" v-show="showListAction === `${bucket}-${folder.Key}`">
                    <!-- <div class="dropdown-menu dropdown-menu-right file-dropdown"> -->
                    <span class="dropdown-item" @click.stop="actionUpload(folder.Key, false)">
                      <feather-icon icon="UploadIcon" class="align-middle mr-50" />
                      <span class="align-middle">{{ $t('upload') }}</span>
                    </span>
                    <span class="dropdown-item" @click.stop="deleteMultilObject([{ Key: folder.Key }])">
                      <feather-icon icon="DeleteIcon" class="align-middle mr-50" />
                      <span class="align-middle">{{ $t('delete') }}</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center w-100">
                  <feather-icon icon="FolderIcon" class="mr-50" size="20" />
                </div>
              </div>
              <div class="folder-manager-item-info">
                <div class="folder-manager-item-info-name">{{ formatName(folder.Key) }}</div>
                <div class="folder-manager-item-info-date-size">
                  <div class="info-date">{{ timeFromNow(folder.LastModified) }}</div>
                  <div class="info-size">{{ formatSize(folder.Size) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file-manager-item file" :class="{ selected: file.active }" v-if="filesSearch && filesSearch.length > 0" v-for="(file, index) in filesSearch" :key="file.Key">
          <div class="file-manager-item-left">
            <label class="cursor-pointer m-0" :for="`files-${bucket}-${file.Key}`">
              <img :src="require(`/static/images/aws-s3/icons/${imgFile(file.Key)}`)" alt="aws s3" height="20" />
            </label>
          </div>
          <div class="file-manager-item-right">
            <div class="file-manager-item-right-top">
              <label class="file-manager-item-name cursor-pointer" :for="`files-${bucket}-${file.Key}`">{{ formatName(file.Key) }}</label>
              <div class="file-mangager-item-action">
                <div v-show="!file.run" :title="$t('use')" class="layer-component_right_top_action_icon action_use bg-light-primary rounded" :class="{ 'hidden-use': checkUseFile(file.Key) == false }" @click.stop="actionUseDatasource(file)">{{ $t('use') }}</div>
                <div v-show="file.run" :title="$t('use')" class="layer-component_right_top_action_icon action_use bg-light-primary rounded not-allowed" :class="{ 'hidden-use': checkUseFile(file.Key) == false }"><b-spinner size="12" class="spinner-bucket"></b-spinner></div>
                <div :title="$t('download')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.stop="actionDownload(file.Key)">
                  <feather-icon icon="DownloadIcon" size="14" />
                </div>
                <div :title="$t('delete')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.stop="deleteMultilObject([{ Key: file.Key }])">
                  <feather-icon icon="Trash2Icon" size="14" />
                </div>
                <div :title="$t('selected')" class="layer-component_right_top_action_icon rounded">
                  <input type="checkbox" class="ip-select cursor-pointer" v-model="file.active" :id="`files-${bucket}-${file.Key}`" @input="handleInputChange($event, file)" />
                  <label class="ip-select-label" :for="`files-${bucket}-${file.Key}`"></label>
                </div>
              </div>
            </div>
            <div class="file-manager-item-right-bottom">
              <div class="file-mangager-item-size">{{ formatSize(file.Size) }}</div>
              <div class="file-mangager-item-last-modifine">{{ formatDate(file.LastModified) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="aws-s3-files-loading" v-show="processRunning"></div>
    </div>
    <SettingModalComponent ref="settingModalComponent" :locale="locale" />
  </div>
</template>

<script>
import Vue from 'vue'
const _ = require('lodash')
import SettingModalComponent from '@/views/ecoplot-desktops/navbar/aws-s3/SettingModalComponent.vue'
import { formatBytes } from '@/utilities/DatasourceUtility.js'
import FileMixin from '@/mixins/FileMixin.js'
import moment from 'moment'
import 'moment/locale/ja'
import i18n from '@/libs/i18n'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
const TYPE_ADD_DATASOURCE = ['csv', 'geojson', 'jpg', 'png']
import { STORAGE_MODE } from '@/constants/repository.js'
import axios from 'axios'
import CryptoJS from 'crypto-js'
const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
import { ECOPLOT_API } from '@/constants/urls'
export default {
  components: { SettingModalComponent, ToastificationContent },
  mixins: [FileMixin],
  data() {
    return {
      buckets: [],
      selected: null,
      dataFormat: true,
      S3Client: null,
      filesSearch: [],
      foldersSearch: [],
      key: '',
      bucket: null,
      checkNewFolder: false,
      arrFoldersFilesActive: [],
      processRunning: false,
      showListAction: '',
      inputValueNewFolder: 'new folder',
      back: [],
      forward: [],
      loadingBucket: false,
      countActionUseDatasource: 0,
    }
  },
  async mounted() {
    if (!this.$root.appSettings) this.$root.appSettings = {}
    let awsAccessKeyId = localStorage.getItem('awsAccessKeyId')
    let awsSecretAccessKey = localStorage.getItem('awsSecretAccessKey')
    let isEncrypted = localStorage.getItem('isEncrypted')
    isEncrypted = !isEncrypted || isEncrypted == 'false' ? false : true
    try {
      this.$root.appSettings.awsAccessKeyIdTemp = awsAccessKeyId
      this.$root.appSettings.awsSecretAccessKeyTemp = awsSecretAccessKey
      this.$root.appSettings.isEncryptedTemp = isEncrypted
    } catch {
      this.$root.appSettings.awsAccessKeyIdTemp = ''
      this.$root.appSettings.awsSecretAccessKeyTemp = ''
      this.$root.appSettings.isEncryptedTemp = false
    }
    try {
      if (isEncrypted) {
        try {
          this.$root.appSettings.awsAccessKeyId = this.decryptAES(awsAccessKeyId)
        } catch {
          this.$root.appSettings.awsAccessKeyId = ''
        }
        try {
          this.$root.appSettings.awsSecretAccessKey = this.decryptAES(awsSecretAccessKey)
        } catch {
          this.$root.appSettings.awsSecretAccessKey = ''
        }
      } else {
        this.$root.appSettings.awsAccessKeyId = awsAccessKeyId
        this.$root.appSettings.awsSecretAccessKey = awsSecretAccessKey
      }
      this.$root.appSettings.isEncrypted = isEncrypted
    } catch {
      this.$root.appSettings.awsAccessKeyId = ''
      this.$root.appSettings.awsSecretAccessKey = ''
      this.$root.appSettings.isEncrypted = isEncrypted ? true : false
    }
    await this.loadDataBucket()
  },
  computed: {
    coutAccAwsS3Update() {
      return this.$store.state.ecoplot.coutAccAwsS3Update
    },
    locale() {
      return i18n.locale
    },
    user() {
      return this.$store.state.ecoplot.user
    },
  },
  watch: {
    locale(locale) {
      if (locale === 'ja') {
        moment.locale('ja')
      } else {
        moment.locale('en')
      }
    },
    async coutAccAwsS3Update() {
      await this.loadDataBucket()
    },
    async selected(index) {
      this.processRunning = false
      this.arrFoldersFilesActive = []
      this.filesSearch = []
      this.foldersSearch = []
      this.forward = []
      this.back = ['']
      this.key = ''
      if (index == null) {
        this.selected = null
        this.bucket = null
        return
      }
      this.bucket = this.buckets[index].bucket
      await this.handlerGetAllFiles(this.bucket)
    },
  },
  methods: {
    isCryptoJSAESString(encryptedBase64, password = 'ecoplotpro') {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedBase64, password)
        let bytesTemp = bytes.toString(CryptoJS.enc.Utf8)
        return bytesTemp !== null && bytesTemp.length > 0
      } catch (e) {
        return false
      }
    },
    decryptAES(encryptedBase64, password = 'ecoplotpro') {
      try {
        const decrypted = CryptoJS.AES.decrypt(encryptedBase64, password)
        if (decrypted) {
          try {
            const str = decrypted.toString(CryptoJS.enc.Utf8)
            if (str.length > 0) {
              return str
            } else {
              return
            }
          } catch (e) {
            return
          }
        }
      } catch {}
      return
    },
    async funcBackForward(action) {
      let key = null
      if (action === 'back' && this.back.length > 0) {
        let keyForward = this.back.pop()
        key = this.back[this.back.length - 1]
        if (!this.forward.length && this.forward[this.forward.length - 1] != keyForward) {
          this.forward.push(keyForward)
        }
      }
      if (action === 'forward' && this.forward.length > 0) {
        key = this.forward.pop()
        this.back.push(key)
      }
      if (key == null || !this.S3Client) return
      this.key = key
      await this.handlerGetAllFiles(this.bucket, key)
    },
    async getFolderItems(key) {
      this.key = key
      this.back.push(key)
      await this.handlerGetAllFiles(this.bucket, this.key)
    },
    async addNewFolderAWS() {
      try {
        let name = this.inputValueNewFolder
        if (!name || name == '') name = 'new folder'
        if (name && name != '') {
          var count = 0
          let nameTmp = name.split('(')[0]
          if (this.foldersSearch.length > 0) {
            for (let i = 0; i < this.foldersSearch.length; i++) {
              if (this.foldersSearch[i].name == name || this.foldersSearch[i].Key == `${name}/` || this.foldersSearch[i].Key.split('(')[0] == nameTmp) {
                count++
              }
            }
          }
          if (count) {
            name = `${nameTmp}(${count})/`
          } else {
            name = `${nameTmp}/`
          }
          let key = `${this.key}${name}`
          const data = {
            METHODS: 'createFolder',
            S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
            S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
            bucket: this.bucket,
            folder_name: key,
          }

          const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
          if (response && response.data && response.data.status == 200) {
            await this.handlerGetAllFiles(this.bucket, this.key)
          }
        }
      } catch {}
      this.checkNewFolder = false
    },
    actionNewFolder() {
      this.checkNewFolder = true
      this.inputValueNewFolder = 'new-folder'
      setTimeout(() => {
        this.$refs.newfoldertree.setSelectionRange(0, this.inputValueNewFolder.length)
        this.$refs.newfoldertree.focus()
      }, 100)
    },
    handleMouseLeave() {
      this.showListAction = ''
    },
    async handlerGetAllFiles(bucket, key = '') {
      if (!this.S3Client) {
        this.processRunning = false
        return
      }
      this.processRunning = true
      try {
        const data = {
          METHODS: 'getFiles',
          S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
          S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
          bucket,
          prefix: key,
        }
        const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
        if (response && response.data && response.data.status == 200 && response.data.files && response.data.folders) {
          this.filesSearch = response.data.files
          this.foldersSearch = response.data.folders
          this.arrFoldersFilesActive = []
        }
      } catch {}
      this.processRunning = false
    },
    clShowListAction(bucket, key) {
      this.showListAction = `${bucket}-${key}`
    },
    checkUseFile(key) {
      if (!key || key == '') return false
      let type = key.split('.')
      type = type[type.length - 1]
      if (type && TYPE_ADD_DATASOURCE.indexOf(type) > -1) return true
      return false
    },
    handleInputChange(event, data) {
      data.active = event.target.checked
      let keyActive = []
      if (this.filesSearch.length) {
        this.filesSearch.map((fl) => {
          if (fl.active == true) {
            keyActive.push({ Key: fl.Key })
          }
        })
      }
      this.arrFoldersFilesActive = keyActive
    },
    async loadDataBucket() {
      this.loadingBucket = true
      try {
        const data = {
          METHODS: 'listbucket',
          S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
          S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
        }

        const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
        if (response && response.data && response.data.status == 200 && response.data.listBuckets) {
          this.S3Client = {
            version: 1,
          }
          let buckets = []
          response.data.listBuckets.map((bucketName, index) => {
            buckets.push({
              value: index,
              label: bucketName,
              bucket: bucketName,
            })
          })
          this.buckets = buckets
        } else {
          this.S3Client = null
          this.buckets = []
          this.selected = null
        }
        Vue.prototype.$S3Client = this.S3Client
      } catch {
        this.S3Client = null
        this.buckets = []
        this.selected = null
        Vue.prototype.$S3Client = null
      }
      this.loadingBucket = false
    },
    showMoalConfigAwsS3() {
      this.$refs.settingModalComponent.open()
    },
    iconFolder(name) {
      try {
        name = name.split('/')[0]
        switch (name) {
          case 'important': {
            return 'StarIcon'
          }
          case 'documents': {
            return 'FileTextIcon'
          }
          case 'images': {
            return 'ImageIcon'
          }
          case 'videos': {
            return 'VideoIcon'
          }
          case 'audio': {
            return 'MusicIcon'
          }
          case 'archives': {
            return 'LayersIcon'
          }
          case 'LayersIcon': {
            return 'FileTextIcon'
          }
        }
      } catch {}
      return 'FolderIcon'
    },
    imgFile(name) {
      try {
        if (name.indexOf('documents') > -1 || name.indexOf('.xlsx') > -1) {
          return 'doc.png'
        }
        if (name.indexOf('images') > -1 || name.indexOf('.jpg') > -1 || name.indexOf('.png') > -1) {
          return 'jpg.png'
        }
        if (name.indexOf('.json') > -1) {
          return 'json.png'
        }
        if (name.indexOf('.txt') > -1) {
          return 'txt.png'
        }
      } catch {}
      return 'file.png'
    },
    timeFromNow(date) {
      try {
        return moment(date).fromNow()
      } catch {}
      return date
    },
    formatName(name) {
      try {
        let nameTmp = name.split('/')
        if (nameTmp[nameTmp.length - 1] == '') {
          return nameTmp[nameTmp.length - 2]
        } else {
          return nameTmp.pop()
        }
      } catch {}
      return name
    },
    formatDate(date) {
      if (this.locale === 'ja') {
        return moment(date).format('LL HH:mm:ss')
      }
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    formatType(name) {
      try {
        let typeTmp = name.split('.').pop()
        if (typeTmp.indexOf('/') < 0) {
          return `${typeTmp} File`
        } else {
          return `File folder`
        }
      } catch {}
      return `File`
    },
    formatSize(size) {
      return formatBytes(size)
    },
    async deleteMultilObject(arrObjectDelete) {
      if (!this.S3Client || this.selected == null || !arrObjectDelete.length || !this.user) {
        this.arrFoldersFilesActive = []
        this.processRunning = false
        return
      }

      this.$bvModal
        .msgBoxConfirm(this.$t('message_delete_file'), {
          title: this.$t('delete'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: this.$t('yes'),
          cancelTitle: this.$t('no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (!value || !arrObjectDelete || !arrObjectDelete.length) {
            this.processRunning = false
            return
          }
          try {
            this.processRunning = true
            const data = {
              METHODS: 'deleteMultilObject',
              S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
              S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
              bucket: this.bucket,
              object_key: arrObjectDelete,
            }
            const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
            if (response && response.data && response.data.status == 200) {
              await this.handlerGetAllFiles(this.bucket, this.key)
            }
          } catch (err) {}
          this.arrFoldersFilesActive = []
          this.processRunning = false
        })
        .catch((err) => {
          this.arrFoldersFilesActive = []
          this.processRunning = false
        })
    },
    actionUpload(key = this.key, check = true) {
      this.showListAction = ''
      if (!this.S3Client || this.selected == null || !this.user) {
        this.processRunning = false
        return
      }
      this.keyUpload = key
      if (!check) {
        this.key = key
      }
      this.$refs.fileUploadInput.checkAcitonUpload = check
      this.$refs.fileUploadInput.click()
    },
    async handleFileChange(event) {
      if (!this.user) {
        this.processRunning = false
        return
      }
      this.processRunning = true
      let filesUpload = event.target.files
      let bucket = this.bucket
      let key = this.keyUpload
      if (this.bucket && this.bucket != '' && filesUpload && filesUpload.length > 0) {
        let formData = new FormData()
        formData.append('METHODS', 'uploadfiles')
        formData.append('bucket', this.bucket)
        formData.append('key', key)
        formData.append('S3_ACCESS_KEY_ID', this.$root.appSettings.awsAccessKeyId)
        formData.append('S3_SECRET_ACCESS_KEY', this.$root.appSettings.awsSecretAccessKey)
        for (const file of filesUpload) {
          formData.append('data', file)
        }
        const response = await axios.post(`${ECOPLOT_API}/awss3/`, formData)
        if (response && response.data && response.data.status == 200) {
          if (this.$refs.fileUploadInput.checkAcitonUpload) {
            await this.handlerGetAllFiles(bucket, key)
          }
        }
      }
      this.processRunning = false
    },
    async actionDownload(key) {
      if (!this.user) {
        this.processRunning = false
        return
      }
      if (key) {
        const data = {
          METHODS: 'downloadFile',
          S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
          S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
          bucket: this.bucket,
          key,
        }
        const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
        if (response && response.data && response.data.status == 200) {
          const link = document.createElement('a')
          link.href = response.data.url
          link.download = this.formatName(key) // Tên tệp khi tải xuống
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    actionUseDatasource(fileAction) {
      if (!this.user) return
      this.countActionUseDatasource++
      return new Promise(async (resolve, reject) => {
        const key = fileAction.Key
        fileAction.run = true
        try {
          let checkImg = key.indexOf('.jpg') > -1 || key.indexOf('.png') > -1 ? true : false
          const data = {
            METHODS: 'loadFile',
            S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
            S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
            bucket: this.bucket,
            checkImg,
            key,
          }
          const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
          let file
          let keySplit = key.split('/')
          if (checkImg) {
            if (response && response.data && response.data.status == 200 && response.data.file_content) {
              const blob = this.dataURItoBlob(response.data.file_content)
              file = new File([blob], keySplit[keySplit.length - 1], { type: 'image/jpeg' })
            }
          } else {
            if (response && response.status == 200) {
              const blob = response.request.response
              file = new File([blob], keySplit[keySplit.length - 1], { type: 'text/plain' })
            }
          }

          if (file) {
            let datasource = await this.determineTypeAndReadData(file)
            const usedDatasource = {
              storage_mode: STORAGE_MODE.S3,
              live: false, // S3 datasource only
              bucket: this.bucket,
              keyBucket: key,
            }
            datasource = _.merge(datasource, usedDatasource)
            await this.addDatasource(datasource)
            this.$toast({ component: ToastificationContent, props: { title: this.$t('file_added_successfully'), icon: 'CheckIcon', variant: 'success' } })
            fileAction.run = false
          }
        } catch (err) {
          fileAction.run = false
          this.$toast({ component: ToastificationContent, props: { title: this.$t('add_corrupted_files'), text: err.message, icon: 'ArrowDownIcon', variant: 'danger' } })
        }
        fileAction.run = false
        this.countActionUseDatasource++
        resolve()
      })
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/jpeg' })
    },
  },
}
</script>

<style lang="scss" scoped>
.aws-s3-manager {
  padding: 5px 15px;
  height: calc(100vh - 30px - 90.3px - 1rem - 36px - 10px);
}
.aws-s3-toplist {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  .select-list-bucket {
    flex: 1;
  }
  .aws-s3-setting {
    margin-left: 6px;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 1.5px 5.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.aws-s3-button {
  margin-top: 10px;
}
.aws-s3-files {
  position: relative;
  margin-top: 10px;
  & > .view-container {
    max-height: calc(100vh - 90.3px - 2rem - 36px - 120px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888888;
    }
  }
  &-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: transparent;
    top: 0;
    left: 0;
    cursor: progress;
  }
}
.file-manager-item {
  cursor: default;
  margin-top: 4px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(57, 123, 255, 0.12);
  font-size: 13px;
  border-radius: 5px;
  border: solid 0.2px transparent;
  &-right {
    flex: 1;
    text-align: right;
    margin-left: 5px;
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .file-mangager-item-action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .layer-component_right_top_action_icon {
          min-height: 23.38px;
          &.action_use {
            font-size: 12px;
            padding: 3.2px 5px !important;
            &.hidden-use {
              pointer-events: none;
              opacity: 0;
              visibility: visible;
            }
          }
        }
      }
    }
    &-bottom {
      margin-top: 3px;
      display: flex;
      font-size: 12px !important;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-name {
    color: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
  }
  &.selected {
    border-color: #7367f0;
  }
}
.items-del {
  position: relative;
  &-count {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 9px;
    border-radius: 50%;
    display: inline-block;
    z-index: 1000000000;
    // background: blue;
    color: black;
    border: red;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(50%, -20%);
    border: solid 0.1px #ea5455;
  }
}
.cursor-running {
  cursor: progress !important;
}
.disable-action {
  pointer-events: none;
  opacity: 0.5;
}

.folder-manager {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
  &-items {
    padding: 0 2px;
    width: 50%;
    margin-top: 4px;
  }
  &-item {
    background-color: rgba(57, 123, 255, 0.12);
    font-size: 13px;
    border-radius: 5px;
    border: solid 0.2px rgba(57, 123, 255, 0.12);

    .card-img-top {
      position: relative;
      min-height: 5.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f8f8f8;

      .dropdown.float-right {
        position: absolute;
        top: 5px;
        right: 6px;
      }
    }
    &-info {
      padding: 4px 10px;
      &-date-size {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        .info-size {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
          text-align: end;
          max-width: 60px;
          margin-left: 3px;
        }
        .info-date {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex: 1;
        }
      }
      &-name {
        margin: 3px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 130px;
      }
      &-new {
        padding: 10px !important;
      }
    }
  }
}
#newfoldertree {
  width: 100%;
  border: #ccc;
  padding: 3px 5px;
  font-size: 14px;
  border: solid 0.1px #d0c8c8;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
}

.card-no-list {
  width: 50% !important;
  flex: inherit !important;
}
.path-tree-list {
  .list-key {
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 274px;
    cursor: default;
  }
}
.spinner-bucket {
  width: 13px;
  height: 13px;
}
</style>
<style lang="scss">
.file-dropdown {
  display: inherit;
  transform: inherit;
}
.aws-s3-loading-bucket {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}
.dark-layout {
  .aws-s3-setting {
    border: 1px solid #404656 !important;
  }
  .folder-manager-item .card-img-top {
    background-color: #161d31;
  }
}
</style>
