<template>
  <div class="process-rate-popup-container" v-show="visibleLayer && positionPin" :style="{ top: positionPin.y + 'px', left: positionPin.x + 'px', fontFamily: layer.popup.family, background: gradient, transform: `translate(-50% , calc(-100% - 10px))` }" ref="popupBorder">
    <div class="process-rate-popup-container-main">
      <div class="popup-detail d-flex" v-show="true || (popupImage && popupImage.length !== 0 && currentValue !== null) || (!popupImage && currentValue !== null) || (popupImage && popupImage.length === 0 && currentValue !== null) || (popupImage && popupImage.length === 0 && !flagFilter && currentValue !== null)" style="min-width: 0px; padding: 7px; overflow: hidden; transition: 0.2s">
        <div class="content-information">
          <div class="popup-header" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor }">
            <div class="process-rate-popup-container-header-name" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ namePopup }}</div>
            <div class="d-flex" style="gap: 2px">
              <div :title="$t('add_image')" @click.stop="toggleUploadImage" class="d-flex align-items-center">
                <feather-icon size="16" icon="ImageIcon" class="cursor-pointer" />
              </div>
              <div class="d-flex align-items-center">
                <feather-icon size="16" icon="XIcon" @click="closePopup(clicking.layerId, clicking.clickId, clicking.clickUid, layer.type)" class="cursor-pointer" />
              </div>
            </div>
          </div>
          <div class="popup-content mt-1" :style="{ width: `240px`, background: popupImage && popupImage.length ? '' : '#96969642', fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }">
            <div class="popup-column information">
              <div class="content-image">
                <div style="min-width: 0px; overflow: hidden; transition: 0.2s" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor, height: '194px' }">
                  <div style="top: 200px; position: absolute; z-index: 1000">
                    <div v-if="popupImage && popupImage.length !== 0 && flagFilter" style="width: 240px; padding: 0px 15px; justify-content: space-between" class="d-flex">
                      <div class="d-flex" v-if="popupImage" :style="{ fontSize: layer.popup.headerSize + 'px', color: 'gray' }">
                        <b-form-input style="background: transparent; height: 20px; border: 0px; font-size: 14px; padding: 0px; width: 15px; box-shadow: none !important" min="0" :max="popupImage.length" type="number" :value="currentSlide + 1" @click.stop @blur="selectPageImage($event.target.value)" :style="{ color: '#d8d8d8' }" />
                        <span :style="{ fontSize: layer.popup.headerSize + 'px', color: '#d8d8d8', textShadow: '1px 1px #7d7d7d' }"> / {{ popupImage.length }}</span>
                      </div>
                      <div v-if="popupImage" :style="{ fontSize: layer.popup.headerSize + 'px', color: '#d8d8d8', textShadow: '1px 1px #7d7d7d' }">{{ $t('total') }} : {{ totalImagePopup }}</div>
                    </div>
                  </div>
                  <div style="position: relative; width: 240px" v-if="(popupImage && popupImage.length !== 0) || (popupImage && popupImage.length === 0 && flagFilter)">
                    <b-carousel v-model="currentSlide" :interval="0" img-width="924" img-height="380" controls indicators>
                      <b-carousel-slide class="slide-image-metadata" v-for="(imgSrc, index) in popupImage" :key="index" data-interval="false">
                        <div class="icon-control-image upload-image" :title="$t('replace_files')" @click.stop="toggleReplaceImage(imgSrc.id)">
                          <feather-icon :style="{ color: layer.popup.contentColor + '!important' }" icon="RefreshCcwIcon" size="16" class="cursor-pointer text-light" />
                        </div>
                        <div class="btn-flat-danger icon-control-image" :title="$t('delete')" @click.stop="deleteImage(imgSrc.id)">
                          <feather-icon icon="Trash2Icon" size="16" class="cursor-pointer text-danger" />
                        </div>
                        <template v-slot:img>
                          <a :id="`sk-${imgSrc.id}`" :href="getImageSourceById(imgSrc.id)" style="display: none" :data-description="null" class="glightbox" data-desc-position="right" data-type="image" data-effect="fade" data-zoomable="true" data-draggable="true" data-dragToleranceY="0"></a>
                          <div class="cursor-pointer" @click="showLightBox(imgSrc.id)">
                            <div class="viewport" v-if="currentSlide === index">
                              <img class="d-block" :src="getImageSourceById(imgSrc.id)" alt="image" />
                            </div>
                          </div>
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="process-rate-popup-container-after" :style="{ borderColor: `transparent transparent ${backgroundColorToAfter} ${backgroundColorToAfter}` }"></div>
    <input type="file" ref="refReplaceImage" style="display: none" @change="replaceImageEvt" accept=".png, .jpeg, .jpg" />
    <input type="file" ref="refUploadImage" multiple style="display: none" @change="uploadImagesEvt" accept=".png, .jpeg, .jpg, .zip" />
  </div>
</template>

<script>
const _ = require('lodash')
import ImageMixin from '@/mixins/ImageMixin'
import GLightbox from 'glightbox'
import DynamicAnimate from '../../../common/DynamicAnimate.vue'
import 'glightbox/dist/css/glightbox.css'
import { BCarousel, BCarouselSlide, VBTooltip } from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
export default {
  props: ['clicking', 'layer', 'mapIndex', 'moveCount', 'leafletLayer', 'map'],
  mixins: [ImageMixin],
  components: { BCarousel, BCarouselSlide, BTable, DynamicAnimate },
  data() {
    return {
      lightbox: null,
      selectedFilter: {},
      flagFilter: false,
      currentSlide: 0,
      flagCurrentSlide: 0,
      idImageReplace: null,
    }
  },
  mounted() {
    this.createLightbox()
  },
  computed: {
    namePopup() {
      let location
      try {
        location = this.clicking.clickId
        return location != null ? `${location} - ${this.layer.name}` : `${this.layer.name}`
      } catch {}
    },
    locationLayer() {
      try {
        if (this.leafletLayer && this.clicking) {
          return this.leafletLayer.getLayers().find((l) => {
            return l.idxProcessRate == this.clicking.clickId
          })
        }
      } catch {}
    },
    positionPin() {
      let moveCount = this.moveCount
      try {
        if (this.clicking && this.clicking.latlng) {
          return this.map.latLngToContainerPoint(this.clicking.latlng)
        }
        if (this.locationLayer && this.map) {
          return this.map.latLngToContainerPoint(this.locationLayer.latlng)
        }
      } catch {}
      return {
        x: 0,
        y: 0,
      }
    },
    backgroundColorToAfter() {
      let color = `rgb(255, 255, 255)`
      try {
        color = this.layer.popup.backgroundColorTo
      } catch {}
      return color
    },
    gradient() {
      try {
        let color1 = this.layer.popup.backgroundColorFrom
        let color2 = this.layer.popup.backgroundColorTo
        return 'linear-gradient(to bottom, ' + color1 + ', ' + color2 + ')'
      } catch {}
    },
    visibleLayer() {
      try {
        return this.layer.visible[this.mapIndex]
      } catch {}
    },
    totalImagePopup() {
      return this.popupImages.length
    },
    popupImages() {
      try {
        return this.$store.state.image.processRate.filter((data) => data.layerId === this.clicking.layerId && data.locationItem === this.clicking.clickId)
      } catch {}
    },
    popupImage() {
      try {
        this.flagFilter = false
        const imageData = this.filterImageMetadata(this.popupImages, this.selectedFilter)
        if (this.popupImages && this.popupImages.length !== 0) this.flagFilter = true
        else this.flagFilter = false
        this.createLightbox()
        return imageData
      } catch {}
    },
  },
  watch: {
    popupImages: {
      deep: true,
      handler() {
        if (this.popupImages && this.popupImages.length && this.currentSlide > this.popupImages.length - 1) {
          this.currentSlide = this.popupImages.length >= 1 ? this.popupImages.length - 1 : 0
        }
      },
    },
  },
  methods: {
    toggleReplaceImage(id) {
      this.idImageReplace = id
      this.$refs.refReplaceImage.click()
    },
    toggleUploadImage() {
      this.$refs.refUploadImage.click()
    },
    async replaceImageEvt() {
      try {
        let id = this.idImageReplace
        const imageData = await this.uploadImages('', '', this.$refs.refReplaceImage.files, 'replace')
        if (imageData) {
          this.$imagesSrc[id] = imageData.src
          this.$store.commit('image/REPLACE_PROCESS_RATE_IMAGE', { id, fileName: imageData.fileName })
        }
      } catch {}
    },
    async uploadImagesEvt() {
      try {
        const imageData = await this.uploadImages(this.clicking, null, this.$refs.refUploadImage.files)
        if (imageData && imageData.length) {
          this.currentSlide = 0
          if (imageData && imageData.length) {
            for (let i = 0; i < imageData.length; i++) {
              imageData[i] = _.merge(imageData[i], {
                layerId: this.clicking.layerId,
                stepFixed: this.clicking.step,
                stepAdd: this.clicking.step,
                precent: this.clicking.step,
                weight: this.clicking.step,
                distance: this.clicking.step,
              })
            }
          }
          this.$store.commit('image/ADD_PROCESS_RATE_IMAGE', imageData)
        }
      } catch {}
      this.$refs.refUploadImage.files = null
    },
    closePopup(layerId, clickId, clickUid, type) {
      const storeData = {
        data: { layerId, clickId, clickUid, type },
        mapIndex: this.mapIndex,
      }
      this.$store.commit('map/CLOSE_POPUP_PROCESS_RATE', storeData)
    },
    showLightBox(id) {
      let aTag = document.querySelector(`#sk-${id}`)
      aTag.click()
    },
    deleteImage(id) {
      this.$store.commit('image/DELETE_PROCESS_RATE_IMAGE', { id })
    },
    async replaceImage(id) {
      try {
        const exts = ['png', 'jpeg', 'jpg']
        const imageData = await this.uploadImages(exts, '', '', 'replace')
        this.$imagesSrc[id] = `data:image/png;base64,${imageData.src}`
        this.$store.commit('image/REPLACE_PROCESS_RATE_IMAGE', { id, fileName: imageData.fileName })
      } catch {}
    },
    createLightbox() {
      setTimeout(() => {
        if (this.lightbox) {
          this.lightbox.destroy()
          this.lightbox = null
        }
        this.lightbox = GLightbox({
          dragToleranceY: 0,
        })
        this.lightbox.on('slide_changed', ({ current }) => {
          this.flagCurrentSlide = current.index
        })
        this.lightbox.on('close', () => {
          this.currentSlide = this.flagCurrentSlide
        })
      }, 0)
    },
    selectPageImage(indexPage) {
      if (indexPage > this.popupImage.length) {
        this.currentSlide = this.popupImage.length - 1
      } else if (indexPage < 0) {
        this.currentSlide = 0
      } else if (indexPage.length === 0) {
        this.currentSlide = 0
      } else this.currentSlide = indexPage - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.process-rate-popup-container {
  position: absolute;
  font-family: arial;
  border-radius: 0.428rem;

  width: fit-content;
  position: absolute;
  z-index: 401;
  white-space: nowrap;
  box-shadow: 0 3px 6px 1px rgb(0 0 0 / 15%);
  &-main {
    padding: 7px;
  }
}
.process-rate-popup-container .popup-header {
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.process-rate-popup-container .popup-content {
  margin-top: 5px;
  user-select: none;
  display: flex;
}

.carousel {
  position: unset;
}
.table-metadata.custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}
.table-metadata:hover.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888888 !important;
}
.table-metadata thead th {
  padding-right: 10px !important;
}

.carousel.slide:hover .icon-control-image {
  opacity: 1 !important;
}

.icon-control-image {
  transition: 0.2s;
  opacity: 0 !important;
  /* position: absolute;
  right: 0; */
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.process-rate-popup-container.box-metadata {
  /* min-width: 320px; */
  max-width: 730px;
  min-height: 40px;
}

.table-metadata .table th,
.table-metadata .table td {
  padding: 0px;
}
.table-metadata .table th {
  border-top: none;
}
.table-metadata .table th {
  background: transparent !important;
}

.process-rate-popup-container .popup-header {
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.process-rate-popup-container .popup-content {
  margin-top: 5px;
  user-select: none;
  display: flex;
}

.process-rate-popup-container .popup-info {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 6px;
}

.process-rate-popup-container.border .popup-info:last-child {
  margin-bottom: 0;
}

.process-rate-popup-container .popup-info span:first-child {
  font-weight: bold;
}

.process-rate-popup-container .popup-range {
  position: relative;
  border-radius: 3px;
  height: 6px;
  margin-top: 10px;
}

.process-rate-popup-container .popup-range:last-child {
  margin-bottom: 0 !important;
}

.process-rate-popup-container .popup-range .cursor {
  position: absolute;
  bottom: 0;
  transform: translate(-4px, 6px);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.process-rate-popup-container .timeseries-chart {
  margin-top: 5px;
  /* height: 121px; */
}

.process-rate-popup-container .boxplot-chart {
  margin-top: 5px;
  /* height: 122px; */
}

.process-rate-popup-container .histogram-chart {
  margin-top: 5px;
  /* height: 122px; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}
.viewport {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
  height: 190px;
  position: relative;
  overflow: hidden;
}

.process-rate-popup-container.box-metadata .carousel-item {
  height: 220px;
}
.process-rate-popup-container.box-metadata .table-metadata {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 315px;
  text-align: left;
}

.viewport img {
  height: 190px;
  width: 100%;
  z-index: -1;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  max-width: 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.process-rate-popup-container .upload-image:hover {
  background-color: rgb(255 255 255 / 12%);
}
.process-rate-popup-container-header-name {
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
}
.process-rate-popup-container {
  &-after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    box-sizing: border-box;
    border: 6px solid black;
    border-color: transparent transparent rgb(255, 255, 255) rgb(255, 255, 255);
    transform-origin: 0 0;
    transform: rotate(-45deg) translate(-50%, -50%);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.15);
  }
}
</style>
<style lang="scss">
.process-rate-popup-container .carousel-caption {
  position: absolute;
  right: 0 !important;
  top: 0 !important;
  z-index: 10;
  color: #ef0000;
  text-align: center;
  left: auto;
  bottom: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.process-rate-popup-container .carousel-indicators {
  display: none !important;
}
</style>
