<template>
  <div>
    <div style="height: 20px; width: 100%; text-align: right">
      <feather-icon @click="toggleChoodata()" size="16" :icon="this.$parent.$parent.$parent.$parent.showChooseDatasources ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'" class="cursor-pointer" />
    </div>
    <BFormFile size="lg" multiple accept=".csv, .geojson, .jpg, .png, .gif, .zip, .nc ,.shp" @input="uploadFiles" class="custom-upload" :placeholder="$t('drop_your_files')" />
  </div>
</template>

<script>
import Vue from 'vue'
import Encoding from 'encoding-japanese'
const _ = require('lodash')
const fs = require('fs')
import { v4 as uuidv4 } from 'uuid'
import * as zip from '@zip.js/zip.js/dist/zip.min.js'
const path = require('path')
import FileMixin from '@/mixins/FileMixin.js'
import MathMixin from '@/mixins/MathMixin.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { formatBytes, checkNameOfTheFileV2 } from '@/utilities/DatasourceUtility.js'
import { ACTION_LOG } from '@/constants/actionLog'
import { STORAGE_MODE } from '@/constants/repository.js'
const proj4 = require('proj4').default
const jschardet = require('jschardet')
const shapefile = require('shapefile')
import { EventBus } from '@/scripts/event-bus/event-bus.js'
export default {
  props: ['initProject'],
  components: { ToastificationContent, formatBytes },
  mixins: [FileMixin, MathMixin],
  data() {
    return {
      controller: null,
    }
  },
  mounted() {
    EventBus.$on('cancel-import', () => {
      try {
        if (this.controller) {
          this.controller.abort()
        }
      } catch {}
    })
  },
  methods: {
    toggleChoodata() {
      this.$parent.$parent.$parent.$parent.showChooseDatasources = !this.$parent.$parent.$parent.$parent.showChooseDatasources
    },
    // checkNameOfTheFileUploadFile(datasources, newFileName) {
    //   // Ex 'export.csv'
    //   const counter = datasources.filter((f) => f.name === newFileName).length;
    //   // If counter >= 2, an error has already been passed to the files because it means
    //   // 2 files have the same name
    //   if (counter >= 2) {
    //     throw 'Error duplicate name already present';
    //   }
    //   if (counter === 0) {
    //     return newFileName;
    //   }
    //   if (counter === 1) {
    //     const newName = `${newFileName.split('.')[0]}_${counter}.${newFileName.split('.')[1]}`;
    //     // Return export_1.csv;
    //     return this.checkNameOfTheFileUploadFile(datasources, newName);
    //     // We need to check if export_1.csv has not been already taken.
    //     // If so, the new name would be export_1_1.csv, not really pretty but it can be changed easily in this function
    //   }
    // },
    async delay(ms) {
      return new Promise((resolve) => setTimeout(() => resolve(), ms))
    },
    uploadFiles(files) {
      const self = this
      this.controller = new AbortController()
      return new Promise(async (resolve, reject) => {
        this.controller.signal.addEventListener('abort', () => {
          resolve()
          // throw 'Cancelled'
          return
        })

        // this.$parent.$parent.$parent.$parent.$parent.close();
        this.$parent.$parent.$parent.$parent.close()
        let start, end
        let overlayContent = []
        this.$store.commit('ecoplot/SET_MODAL', null)
        this.$store.commit('ecoplot/SET_MODAL', { show: true })
        this.$store.commit('ecoplot/SET_MODAL', { title: this.$t('import_data'), global: { title: 'import_data' } })

        for (let i = 0; i < files.length; i++) {
          if (this.controller && this.controller.signal.aborted) {
            this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 100 })
            this.$store.commit('ecoplot/SET_MODAL', { close: true })
            resolve()
            return
          }
          start = performance.now()
          overlayContent.push({ description: `${this.$t('preprocessing')} ${files[i].name}`, descriptionDF: 'preprocessing', fileNameDF: files[i].name, step: `${i + 1}/${files.length}`, size: files[i].size / 1048576 })
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })
          // let rdInt = (Math.floor(Math.random() * 10) + 0) * 1000
          // await this.delay(rdInt)
          try {
            // Read, validate then store data to indexedDB
            let result
            if (files[i].name.endsWith('.shp') || files[i].name.endsWith('.zip')) {
              let fileName = 'shapeFile.shp'
              try {
                fileName = files[i].name.replace('.shp', '.shp')
                fileName = files[i].name.replace('.zip', '.shp')
              } catch {}
              try {
                fileName = checkNameOfTheFileV2(this.datasources, fileName)
              } catch {}
              result = await this.determineTypeAndReadDataGeojonShapeFileAndAddData(files[i], { name: fileName })
            } else {
              result = await this.determineTypeAndReadData(files[i])
            }
            if (!files[i].name.endsWith('.shp') && !files[i].name.endsWith('.zip') && this.controller && this.controller.signal.aborted) {
              end = performance.now()
              const errorMess = ['Cancelled'].join('<br />')
              Object.assign(overlayContent[overlayContent.length - 1], { status: 'error', info: errorMess, time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
              this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 100 })
              this.$store.commit('ecoplot/SET_MODAL', { close: true })
              resolve()
              return
            }
            // Log runtime and error of file
            // const message = `${this.$t('add_datasource')}: '${files[i].name}' (${formatBytes(files[i].size)})`;
            let messageTitle = 'add_datasource'
            let messageLog = `: '${files[i].name}' (${formatBytes(files[i].size)})`
            let message = this.$t(messageTitle) + messageLog
            const id = ACTION_LOG[8].id
            if ((result.errors && result.errors.length) || (result.type === 'netcdf' && !result.items)) {
              if (files[i].name && files[i].name.endsWith('.csv')) {
                if (!files[i].name.endsWith('.shp') && !files[i].name.endsWith('.zip')) {
                  await this.addDatasource(result)
                }
                const errorMess = result.errors.join('<br />')
                end = performance.now()
                Object.assign(overlayContent[overlayContent.length - 1], { status: 'warning', info: errorMess, time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
                const duration = this.autoFormatDuration([Math.round(end - start) / 1000])[0]
                this.log.warn({ message, duration, id, messageTitle, messageLog })
              } else {
                const errorMess = result.type === 'netcdf' && !result.items ? 'Cannot open NetCDF File' : result.errors.join('<br />')
                end = performance.now()
                Object.assign(overlayContent[overlayContent.length - 1], { status: 'error', info: errorMess, time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
              }
            } else {
              // Add metadata to Store
              if (!files[i].name.endsWith('.shp') && !files[i].name.endsWith('.zip')) {
                await this.addDatasource(result)
              }
              if (result.warning && result.warning.length !== 0) {
                const warningMess = result.warning.join('<br />')
                end = performance.now()
                Object.assign(overlayContent[overlayContent.length - 1], { status: 'warning', info: warningMess, time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
                const duration = this.autoFormatDuration([Math.round(end - start) / 1000])[0]
                this.log.warn({ message, duration, id, messageTitle, messageLog })
              } else {
                end = performance.now()
                Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', info: `${(Math.round(end - start) / 1000).toFixed(1)}s`, time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
                const duration = this.autoFormatDuration([Math.round(end - start) / 1000])[0]
                this.log.info({ message, duration, id, messageTitle, messageLog })
              }
            }
          } catch (err) {
            console.error(err)
            end = performance.now()
            Object.assign(overlayContent[overlayContent.length - 1], { status: 'error', info: err, time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
          }
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: ((i + 1) / files.length) * 100 })
          if (this.initProject != this.$store.state.ecoplot.initProject) {
            this.$store.state.datasource.datasources.map(async (data) => {
              await this.deleteDatasource(data)
            })
            return
          }
          this.$store.commit('ecoplot/SET_MODAL', { close: true })
        }
      })
    },
    transformCoordinates(coordinates, source) {
      // Nếu đây là mảng chứa các tọa độ (latitude, longitude)
      if (coordinates && coordinates.length == 2 && (typeof coordinates[0] === 'number' || typeof coordinates[0] === 'string')) {
        return proj4(source).inverse(coordinates)
      } else if (Array.isArray(coordinates[0]) && (typeof coordinates[0][0] === 'number' || typeof coordinates[0][0] === 'string')) {
        return coordinates.map((coordinate) => proj4(source).inverse(coordinate))
      }
      // Nếu đây là một mảng con
      else if (Array.isArray(coordinates[0])) {
        return coordinates.map((subArray) => this.transformCoordinates(subArray, source))
      }
      // Nếu đầu vào không phải là mảng chứa các tọa độ hoặc mảng con
      else {
        console.log('Định dạng mảng không hợp lệ')
        return []
      }
    },
    async readFIleToBuffer(file) {
      return new Promise(async (resolve) => {
        let reader = new FileReader()
        reader.onload = async function (e) {
          try {
            let codes = new Uint8Array(e.target.result)
            resolve(codes)
          } catch {
            resolve(null)
          }
        }
        reader.readAsArrayBuffer(file)
      })
    },
    async determineTypeAndReadDataGeojonShapeFileAndAddData(file, datasourceConfig = { pathT: null, id: null, name: null }) {
      const self = this
      // write file original to temp
      let nameFileSave = datasourceConfig && datasourceConfig.name ? datasourceConfig.name : file.name
      let fileOriginWriteToTemp = file
      let fileOriginReadBuffer
      try {
        const pathOriginSave = await new Promise(async (resolve, reject) => {
          let reader = new FileReader()
          reader.onload = async function (e) {
            try {
              fileOriginReadBuffer = new Uint8Array(e.target.result)
              let extFileOrigin = path.extname(file.name)
              let newName = datasourceConfig && datasourceConfig.name ? datasourceConfig.name : file.name
              let baseName = path.basename(newName, path.extname(newName))
              nameFileSave = `${baseName}${extFileOrigin}`
              fileOriginWriteToTemp = new File([new Blob([fileOriginReadBuffer])], nameFileSave)

              resolve(null)
            } catch {}
            resolve(false)
          }
          reader.readAsArrayBuffer(file)
        })
      } catch {}
      let resultReturn = {
        event: 'succeed',
        errors: ['cannot_open_this_file'],
      }
      let items = {}
      let encoding = 'UTF-8'
      if (!fileOriginReadBuffer) return resultReturn
      if (file.name.endsWith('.shp')) {
        let features = []
        let fromProj = null
        let arrayPath = [file.path]
        let geojsonSingleShp
        let result

        try {
          let filePathDBF = `${file.path}`
          try {
            filePathDBF = filePathDBF.replace('.shp', '.dbf')
            if (!fs.existsSync(filePathDBF)) {
              filePathDBF = null
            } else {
              arrayPath.push(filePathDBF)
            }
          } catch {
            filePathDBF = null
          }
          let filePathSHX = `${file.path}`
          try {
            filePathSHX = filePathSHX.replace('.shp', '.shx')
            if (!fs.existsSync(filePathSHX)) {
              filePathSHX = null
            } else {
              arrayPath.push(filePathSHX)
            }
          } catch {
            filePathSHX = null
          }
          let filePathQMD = `${file.path}`
          try {
            filePathQMD = filePathQMD.replace('.shp', '.qmd')
            if (!fs.existsSync(filePathQMD)) {
              filePathQMD = null
            } else {
              arrayPath.push(filePathQMD)
            }
          } catch {
            filePathQMD = null
          }
          let filePathPRJ = `${file.path}`
          try {
            filePathPRJ = filePathPRJ.replace('.shp', '.prj')
            if (!fs.existsSync(filePathPRJ)) {
              filePathPRJ = null
            } else {
              fromProj = fs.readFileSync(filePathPRJ)
              fromProj = proj4(fromProj.toString())
              arrayPath.push(filePathPRJ)
            }
          } catch {
            fromProj = null
            filePathPRJ = null
          }
          let checkFIleCPG
          let filePathCPG1 = `${file.path}`
          try {
            filePathCPG1 = filePathCPG1.replace('.shp', '.cpg')
            if (fs.existsSync(filePathCPG1)) {
              let dataFileCOG = fs.readFileSync(filePathCPG1)
              dataFileCOG = dataFileCOG.toString()
              try {
                dataFileCOG = dataFileCOG.replace(/\s+/g, '')
              } catch {}
              encoding = dataFileCOG
              checkFIleCPG = true
              arrayPath.push(filePathCPG1)
            }
          } catch {
            checkFIleCPG = null
            filePathCPG1 = null
          }
          if (!checkFIleCPG) {
            let filePathCPG2 = `${file.path}`
            try {
              filePathCPG2 = filePathCPG2.replace('.shp', '.CPG')
              if (fs.existsSync(filePathCPG2)) {
                let dataFileCOG2 = fs.readFileSync(filePathCPG2)
                dataFileCOG2 = dataFileCOG2.toString()
                try {
                  dataFileCOG2 = dataFileCOG2.replace(/\s+/g, '')
                } catch {}
                encoding = dataFileCOG2
                checkFIleCPG = true
                arrayPath.push(filePathCPG2)
              }
            } catch {
              checkFIleCPG = null
              filePathCPG2 = null
            }
          }

          if (!checkFIleCPG) {
            if (filePathDBF) {
              let ObjectEcd = {}
              await shapefile
                .open(fileOriginReadBuffer)
                .then((source) =>
                  source.read().then(function log(result) {
                    if (result.done) return
                    // Xử lý thông tin đối tượng
                    const properties = result.value.properties
                    if (properties && Object.keys(properties).length) {
                      for (let keyProperties in properties) {
                        let valueTemp = properties[keyProperties]
                        const checkNumber = valueTemp ? self.isNumericString(valueTemp) : false
                        try {
                          if (valueTemp && !checkNumber) {
                            let propertiesString = valueTemp
                            propertiesString = propertiesString.replace(/[0-9\-_.,|#? ]/g, '')
                            let encodingjscharder = jschardet.detect(valueTemp)
                            if (encodingjscharder && encodingjscharder.encoding) {
                              ObjectEcd[encodingjscharder.encoding] = true
                            }
                          }
                        } catch {}
                      }
                    }

                    return source.read().then(log)
                  })
                )
                .catch((error) => console.error(error.stack))
              if (ObjectEcd && Object.keys(ObjectEcd).length) {
                for (let key in ObjectEcd) {
                  if (key && key.toUpperCase() == 'ASCII') {
                    encoding = 'Shift-JIS'
                    break
                  }
                }
              }
            }
          }
          let isEmptyUID = true
          let isEmptyID = true
          await shapefile
            .open(fileOriginReadBuffer, null, { encoding: encoding })
            .then((source) =>
              source.read().then(function log(result) {
                if (result.done) return
                // Xử lý thông tin đối tượng
                let properties = result.value.properties
                if (properties && Object.keys(properties).length) {
                  for (let keyProperties in properties) {
                    if (keyProperties == 'UID') {
                      isEmptyUID = false
                    }
                    if (keyProperties == 'ID') {
                      isEmptyID = false
                    }
                    let valueTemp = properties[keyProperties]
                    if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                      if (!items[keyProperties]) {
                        items[keyProperties] = {
                          min: Number(valueTemp),
                          max: Number(valueTemp),
                        }
                      } else {
                        if (Number(valueTemp) < Number(items[keyProperties].min)) {
                          items[keyProperties].min = Number(valueTemp)
                        }
                        if (Number(valueTemp) > Number(items[keyProperties].max)) {
                          items[keyProperties].max = Number(valueTemp)
                        }
                      }
                    }
                  }
                }
                const geometry = result.value.geometry

                // Tạo đối tượng GeoJSON từ thông tin đối tượng
                let feature = {
                  type: 'Feature',
                  properties: properties,
                  geometry: geometry,
                }
                if (fromProj) {
                  try {
                    let coordinatesConvertWS84 = geometry.coordinates
                    try {
                      if (coordinatesConvertWS84 && coordinatesConvertWS84.length) {
                        coordinatesConvertWS84 = self.transformCoordinates(geometry.coordinates, fromProj)
                      }
                    } catch {}
                    feature.geometry.coordinates = coordinatesConvertWS84
                  } catch {}
                }

                // Thêm đối tượng vào danh sách
                features.push(feature)
                return source.read().then(log)
              })
            )
            .catch((error) => console.error(error.stack))

          // Tạo đối tượng GeoJSON hoàn chỉnh
          let fileName = 'geojson'
          try {
            fileName = file.name.split('.')[0]
          } catch {}
          try {
            if (features && features.length && (isEmptyID || isEmptyUID)) {
              for (let k = 0; k < features.length; k++) {
                if (!features[k].properties) {
                  features[k].properties = {}
                }
                if (isEmptyID) {
                  features[k].properties.UID = features[k].properties.LocationID || features[k].properties.ID || `id_${k}`
                }
                if (isEmptyUID) {
                  features[k].properties.UID = `uid_${k}`
                }
              }
            }
          } catch {}
          geojsonSingleShp = {
            type: 'FeatureCollection',
            features: features,
            fileName: fileName,
            isEmptyUID: isEmptyUID,
            isEmptyID: isEmptyID,
          }
          result = {
            data: geojsonSingleShp,
            items,
            encoding: encoding,
            event: 'succeed',
            file: '',
            fileOrigin: fileOriginWriteToTemp || file,
            id: datasourceConfig && datasourceConfig.id ? datasourceConfig.id : uuidv4(),
            name: datasourceConfig && datasourceConfig.name ? datasourceConfig.name : file.name,
            nameOrigin: nameFileSave || file.name,
            path: '',
            size: file.size,
            storage_mode: STORAGE_MODE.RAM,
            type: 'multi',
            typeOrigin: 'shp',
          }
        } catch {}
        if (!features.length || !result) {
          resultReturn = {
            event: 'succeed',
            errors: ['cannot_open_this_file'],
          }
        } else {
          try {
            if (!Vue.prototype.$db[result.id]) Vue.prototype.$db[result.id] = {}
            if (self.controller && self.controller.signal.aborted) {
              resultReturn = {
                event: 'succeed',
                errors: ['Cancelled'],
              }
            } else {
              Vue.prototype.$db[result.id]['data'] = [{ data: geojsonSingleShp }]
              await this.addDatasource(result)
              resultReturn = {
                event: 'succeed',
              }
            }
          } catch {
            resultReturn = {
              event: 'succeed',
              errors: ['cannot_open_this_file'],
            }
          }
        }
        return resultReturn
      } else if (file.name.endsWith('.zip')) {
        let features = []
        let datasourceAdd
        let geojsonDataZip
        let isEmptyUID = true
        let isEmptyID = true
        try {
          let password = undefined
          const reader = new zip.ZipReader(new zip.BlobReader(new Blob([fileOriginReadBuffer])), { password })
          // get all entries from the zip
          const entries = await reader.getEntries()
          let geojsonShp = {}
          let fromProj = null
          let checkEncodingFromCPG
          if (entries && entries.length) {
            for (let i = 0; i < entries.length; i++) {
              let nameSplit = entries[i].filename.split('.')
              if (nameSplit[1] && nameSplit[1].toLowerCase() == 'cpg') {
                try {
                  let blob = await entries[i].getData(new zip.BlobWriter())
                  let file = new File([blob], entries[i].filename)
                  let buf = Buffer.from(await file.arrayBuffer())
                  let contentCPG = buf.toString()
                  try {
                    contentCPG = contentCPG.replace(/\s+/g, '')
                  } catch {}
                  encoding = contentCPG
                  checkEncodingFromCPG = true
                } catch {}
              }
              // write file zip
              try {
                let blob = await entries[i].getData(new zip.BlobWriter())
                let file = new File([blob], entries[i].filename)
                let buf = Buffer.from(await file.arrayBuffer())
                if (nameSplit[1] == 'prj') {
                  try {
                    fromProj = proj4(buf.toString())
                  } catch {
                    fromProj = null
                  }
                }
                if (!geojsonShp[nameSplit[0]]) geojsonShp[nameSplit[0]] = {}
                geojsonShp[nameSplit[0]][nameSplit[1]] = buf
              } catch {}
            }
          }
          if (Object.keys(geojsonShp).length) {
            for (const key in geojsonShp) {
              if (geojsonShp[key] && geojsonShp[key]['shp']) {
                features = []
                if (geojsonShp[key]['dbf']) {
                  // Đọc dữ liệu từ tệp Shapefile
                  let ObjectEcd = {}
                  if (!checkEncodingFromCPG) {
                    await shapefile
                      .open(geojsonShp[key]['shp'], geojsonShp[key]['dbf'], {})
                      .then((source) =>
                        source.read().then(function log(result) {
                          if (result.done) return

                          // Xử lý thông tin đối tượng
                          const properties = result.value.properties

                          if (properties && Object.keys(properties).length) {
                            for (let keyProperties in properties) {
                              let valueTemp = properties[keyProperties]
                              const checkNumber = valueTemp ? self.isNumericString(valueTemp) : false
                              try {
                                if (valueTemp && !checkNumber) {
                                  let propertiesString = valueTemp
                                  propertiesString = propertiesString.replace(/[0-9\-_.,|#? ]/g, '')
                                  let encodingjscharder = jschardet.detect(valueTemp)
                                  if (encodingjscharder && encodingjscharder.encoding) {
                                    ObjectEcd[encodingjscharder.encoding] = true
                                  }
                                }
                              } catch {}
                            }
                          }

                          return source.read().then(log)
                        })
                      )
                      .catch((error) => console.error(error.stack))
                    if (ObjectEcd && Object.keys(ObjectEcd).length) {
                      for (let key in ObjectEcd) {
                        if (key && key.toUpperCase() == 'ASCII') {
                          encoding = 'Shift-JIS'
                          break
                        }
                      }
                    }
                  }
                  await shapefile
                    .open(geojsonShp[key]['shp'], geojsonShp[key]['dbf'], { encoding: encoding })
                    .then((source) =>
                      source.read().then(function log(result) {
                        if (result.done) return

                        // Xử lý thông tin đối tượng
                        let properties = result.value.properties
                        if (properties && Object.keys(properties).length) {
                          for (let keyProperties in properties) {
                            if (keyProperties == 'UID') {
                              isEmptyUID = false
                            }
                            if (keyProperties == 'ID') {
                              isEmptyID = false
                            }
                            let valueTemp = properties[keyProperties]
                            if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                              if (!items[keyProperties]) {
                                items[keyProperties] = {
                                  min: Number(valueTemp),
                                  max: Number(valueTemp),
                                }
                              } else {
                                if (Number(valueTemp) < Number(items[keyProperties].min)) {
                                  items[keyProperties].min = Number(valueTemp)
                                }
                                if (Number(valueTemp) > Number(items[keyProperties].max)) {
                                  items[keyProperties].max = Number(valueTemp)
                                }
                              }
                            }
                          }
                        }
                        const geometry = result.value.geometry

                        // Tạo đối tượng GeoJSON từ thông tin đối tượng
                        let feature = {
                          type: 'Feature',
                          properties: properties,
                          geometry: geometry,
                        }
                        if (fromProj) {
                          try {
                            let coordinatesConvertWS84 = geometry.coordinates
                            try {
                              if (coordinatesConvertWS84 && coordinatesConvertWS84.length) {
                                coordinatesConvertWS84 = self.transformCoordinates(geometry.coordinates, fromProj)
                              }
                            } catch {}
                            feature.geometry.coordinates = coordinatesConvertWS84
                          } catch {}
                        }
                        // Thêm đối tượng vào danh sách
                        features.push(feature)
                        return source.read().then(log)
                      })
                    )
                    .catch((error) => console.error(error.stack))
                } else {
                  // Đọc dữ liệu từ tệp Shapefile
                  await shapefile
                    .open(geojsonShp[key]['shp'], null, { encoding: encoding })
                    .then((source) =>
                      source.read().then(function log(result) {
                        if (result.done) return
                        // Xử lý thông tin đối tượng
                        let properties = result.value.properties
                        if (properties && Object.keys(properties).length) {
                          for (let keyProperties in properties) {
                            if (keyProperties == 'UID') {
                              isEmptyUID = false
                            }
                            if (keyProperties == 'ID') {
                              isEmptyID = false
                            }
                            let valueTemp = properties[keyProperties]
                            if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                              if (!items[keyProperties]) {
                                items[keyProperties] = {
                                  min: Number(valueTemp),
                                  max: Number(valueTemp),
                                }
                              } else {
                                if (Number(valueTemp) < Number(items[keyProperties].min)) {
                                  items[keyProperties].min = Number(valueTemp)
                                }
                                if (Number(valueTemp) > Number(items[keyProperties].max)) {
                                  items[keyProperties].max = Number(valueTemp)
                                }
                              }
                            }
                          }
                        }
                        const geometry = result.value.geometry

                        // Tạo đối tượng GeoJSON từ thông tin đối tượng
                        let feature = {
                          type: 'Feature',
                          properties: properties,
                          geometry: geometry,
                        }
                        if (fromProj) {
                          try {
                            let coordinatesConvertWS84 = geometry.coordinates
                            try {
                              if (coordinatesConvertWS84 && coordinatesConvertWS84.length) {
                                coordinatesConvertWS84 = self.transformCoordinates(geometry.coordinates, fromProj)
                              }
                            } catch {}
                            feature.geometry.coordinates = coordinatesConvertWS84
                          } catch {}
                        }

                        // Thêm đối tượng vào danh sách
                        features.push(feature)
                        return source.read().then(log)
                      })
                    )
                    .catch((error) => console.error(error.stack))
                }
                let fileName = 'geojson'
                try {
                  fileName = file.name.split('.')[0]
                } catch {}
                try {
                  if (features && features.length && (isEmptyID || isEmptyUID)) {
                    for (let k = 0; k < features.length; k++) {
                      if (!features[k].properties) {
                        features[k].properties = {}
                      }
                      if (isEmptyID) {
                        features[k].properties.UID = features[k].properties.LocationID || features[k].properties.ID || `id_${k}`
                      }
                      if (isEmptyUID) {
                        features[k].properties.UID = `uid_${k}`
                      }
                    }
                  }
                } catch {}
                geojsonDataZip = {
                  type: 'FeatureCollection',
                  features: features,
                  fileName: fileName,
                  isEmptyID: isEmptyID,
                  isEmptyUID: isEmptyUID,
                }
                datasourceAdd = {
                  data: geojsonDataZip,
                  items,
                  encoding: null,
                  event: 'succeed',
                  file: '',
                  fileOrigin: fileOriginWriteToTemp || file,
                  id: datasourceConfig && datasourceConfig.id ? datasourceConfig.id : uuidv4(),
                  name: datasourceConfig && datasourceConfig.name ? datasourceConfig.name : file.name,
                  nameOrigin: nameFileSave || file.name,
                  path: '',
                  size: file.size,
                  storage_mode: STORAGE_MODE.RAM,
                  type: 'multi',
                  typeOrigin: 'shp',
                }
              }
            }
          }
        } catch {}
        if (!features.length || !datasourceAdd) {
          resultReturn = {
            event: 'succeed',
            errors: ['cannot_open_this_file'],
          }
        } else {
          try {
            if (!Vue.prototype.$db[datasourceAdd.id]) Vue.prototype.$db[datasourceAdd.id] = {}
            if (self.controller && self.controller.signal.aborted) {
              resultReturn = {
                event: 'succeed',
                errors: ['Cancelled'],
              }
            } else {
              Vue.prototype.$db[datasourceAdd.id]['data'] = [{ data: geojsonDataZip }]
              await this.addDatasource(datasourceAdd)
              resultReturn = {
                event: 'succeed',
              }
            }
          } catch {
            resultReturn = {
              event: 'succeed',
              errors: ['cannot_open_this_file'],
            }
          }
        }
        return resultReturn
      }
    },
    isNumericString(str) {
      try {
        const num = _.toNumber(str)
        return !_.isNaN(num) && _.isFinite(num)
      } catch {}
      return false
    },
  },
}
</script>

<style lang="scss">
/* Custom CSS for upload component */
.custom-upload {
  height: 350px !important;
}

.custom-upload input {
  height: 100%;
}

.custom-upload label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.25s;
  border-width: 2px;
  border-style: dashed;
}

.custom-upload label span {
  text-overflow: ellipsis;
  white-space: break-spaces;
  text-align: center;
}

.custom-upload label:hover {
  box-shadow: inset 0 0 1000px 0 rgba(255, 255, 255, 0.05);
}

.custom-upload label::after {
  display: none;
}

.tooltip {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
</style>
