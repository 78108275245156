<template>
  <vue-select v-model="selected" :reduce="data => data.value" :options="options" class="select-size-sm" :placeholder="$t('image')" />
</template>

<script>

export default {
  props: ['value'],
  components: {},
  data() {
    return {
      selected: this.value,
    }
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    options() {
      return this.datasources.filter(d => d.type === 'image').map(d => ({ value: d.id, label: d.name }))
    },
  },
  watch: {
    options() {
      if (this.options.findIndex(o => o.value === this.selected) === -1) {
        this.selected = null
        this.$nextTick(() => {
          this.$emit('commit')
        })
      }
    },
    selected() {
      this.$emit('input', this.selected)
    },
  },
}
</script>
