<template>
  <div>
    <b-modal size="lg" hide-footer centered ref="excelModal" :body-class="'custom-body tools-body'" modal-class="excel-modal">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between flex-grow-1 modal-title">2. {{ dataExcelModal && dataExcelModal.name ? `${dataExcelModal.name} - ` : ' ' }}{{ $t('location Upload data') }}</div>
        <button type="button" class="close" :title="$t('close')" @click="close">&times;</button>
      </template>

      <div class="excel-modal-container">
        <input size="lg" type="file" accept=".csv,.xlsx" ref="ipUploadFile" style="display: none" @change="handerUploadFile" />
        <b-button variant="flat-primary" :disabled="runReadFile" class="excel-select-container" @click.stop="uploadFiles">
          <div class="excel-pointer" v-ripple.400="'rgba(57, 123, 255, 0.15)'">
            <b-media no-body>
              <b-media-aside>
                <b-avatar rounded size="42" variant="light-primary">
                  <feather-icon v-if="!runReadFile" size="18" icon="UploadIcon" style="color: #397bff" />
                  <b-spinner v-else label="Spinning" size="16" icon="UploadIcon" style="color: #397bff"></b-spinner>
                </b-avatar>
              </b-media-aside>
              <b-media-body style="text-align: left">
                <h6 class="excel-title d-flex">
                  {{ $t('Upload data') }}
                </h6>
                <div class="excel-description small text-left">{{ $t('Support CSV or Excel format') }}</div>
              </b-media-body>
            </b-media>
          </div>
        </b-button>

        <div :filesVersion="filesVersion" class="mt-1 d-flex align-items-center justiy-content-center div-skip-header">
          <b-form-checkbox v-model="skipHeader">{{ $t('Header_MB') }}</b-form-checkbox>
          <b-form-input v-show="skipHeader" class="ip_text ml-2 bfip-num-skip-header" v-model="numSkipHeader" type="number" size="sm"></b-form-input>
        </div>
        <div class="" :style="`margin-right: -1rem; margin-left:-1rem;`">
          <div class="excel-sheet-colum d-flex align-items-center justify-content-center">
            <div class="excel-sheet-colum-item">
              <label for="">{{ $t('Sheet') }}</label>
              <vue-select ul-class-name="select-sheet" :title="sheetValue && sheetValue.label ? sheetValue.label : ''" v-model="sheetValue" :appendToBody="true" :reduce="(data) => data" :options="sheetItems" class="select-size-sm select-list-bucket">
                <template #no-options>{{ $t('no_sheet') }}</template>
                <template v-slot:option="option">
                  <span class="op-sheet-name" :title="option.label">{{ option.label }}</span>
                </template>
              </vue-select>
            </div>
            <div class="excel-sheet-colum-item">
              <label for="">{{ $t('Time Column') }}</label>
              <vue-select v-model="timeColumnValue" :appendToBody="true" :reduce="(data) => data" :options="columnItems" class="select-size-sm select-list-bucket">
                <template #no-options>{{ $t('no_column') }}</template>
              </vue-select>
            </div>
            <div class="excel-sheet-colum-item">
              <label for="">{{ $t('Value Column') }}</label>
              <vue-select multiple v-model="valueColumn" :appendToBody="true" :reduce="(data) => data.value" :options="columnItems" class="select-size-sm select-list-bucket">
                <template #no-options>{{ $t('no_column') }}</template>
              </vue-select>
            </div>
          </div>
        </div>
        <div class="table-container custom-scrollbar table-csv-excel" ref="tableContainer">
          <table>
            <thead class="table-header" v-if="datatableValue && datatableValue.length">
              <tr class="table-csv-excel-th-tr">
                <!-- :class="{ 'column-time': timeColumnValue && timeColumnValue.value != null && idy == timeColumnValue.value,
                  'column-value': isColumnValue(idy)}" -->
                <th class="table-csv-excel-th-tr" v-for="(column, idy) in datatableValue[0]" :key="idy">
                  <input class="header-input" type="text" :value="`${idy}`" :readonly="true" />
                </th>
              </tr>
            </thead>
            <tbody v-if="from !== 0 && to !== 0">
              <!-- <tr class="table-csv-excel-th-tr" v-for="(row, idx) in datatableValue" :key="idx" :class="{ 'column-skip-header': isColumnSkipHeader(idx) }">
                <td class="table-csv-excel-th-tr" v-for="(column, idy) in row" :key="idy" :class="{ 'column-time': timeColumnValue && timeColumnValue.value != null && idy == timeColumnValue.value, 'column-value': isColumnValue(idy) }">
                  <span>
                    {{ column }}
                  </span>
                </td>
              </tr> -->
              <tr class="table-csv-excel-th-tr" v-for="(row, idx) in filteredData" :key="idx" :class="{ 'column-skip-header': isColumnSkipHeader(idx) }">
                <td class="table-csv-excel-th-tr" v-for="(column, idy) in row" :key="idy" :class="{ 'column-time': timeColumnValue && timeColumnValue.value != null && idy == timeColumnValue.value, 'column-value': isColumnValue(idy) }">
                  <span>
                    {{ column }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between align-items-center b-paginatio-excel-modal">
          <div class="showing">{{ $t('pagination_info', { from, to, total: itemFilterLength }) }}{{ itemLength > itemFilterLength ? $t('pagination_info_extra', { value: itemLength }) : '' }}</div>
          <b-pagination v-model="pageIndex" :total-rows="itemFilterLength" :per-page="pageLength" class="mb-0"></b-pagination>
        </div>
        <div class="text-end text-right mt-1">
          <b-button variant="light" size="sm" class="mr-1" @click.stop="close"> {{ $t('cancel') }} </b-button>
          <b-button variant="success" size="sm" class="mr-1" @click.stop="save"> {{ $t('save') }} </b-button>
          <b-button variant="success" size="sm" class="mr-1" @click.stop="saveAndExit"> {{ $t('Save And Exit') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
const _ = require('lodash');
const dayjs = require('dayjs');
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import Encoding from 'encoding-japanese';
import { convertNumber } from '@/utilities/NumberUtility.js';
import Ripple from 'vue-ripple-directive';
import { v4 as uuidv4 } from 'uuid';
export default {
  props: ['dataExcelModal'],
  directives: { Ripple },
  data() {
    return {
      sheetValue: null,
      sheetItems: [],
      timeColumnValue: null,
      valueColumn: null,
      files: [],
      columnItems: [],
      skipHeader: false,
      numSkipHeader: 0,
      datatableValue: [],

      searchTerm: '',
      pageLength: 20,
      pageIndex: 1,
      oldSearchTerm: '',
      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      updateDatatable: 1,
      dataFilterStore: [],
      runReadFile: false,
    };
  },
  mounted() {
    try {
      this.files = Object.values(this.$store.state.mapBasedTimeseriesLoader.files);
    } catch {}
  },
  computed: {
    isChange() {
      return this.pageLength + this.pageIndex + this.updateDatatable + this.from + this.to;
    },
    from() {
      let from = (this.pageIndex - 1) * this.pageLength + 1;
      let maxFrom = Math.floor(this.itemFilterLength / this.pageLength) * this.pageLength + 1;
      if (!this.itemFilterLength) maxFrom = 0;
      return from > maxFrom ? maxFrom : from;
    },
    to() {
      let to = (this.pageIndex - 1) * this.pageLength + this.pageLength;
      let maxTo = this.itemFilterLength;
      return to > maxTo ? maxTo : to;
    },
    locale() {
      return this.$i18n.locale;
    },
    filesVersion() {
      return this.$store.state.mapBasedTimeseriesLoader.filesVersion;
    },
  },
  watch: {
    filesVersion: {
      deep: true,
      handler() {
        setTimeout(() => {
          try {
            this.files = Object.values(this.$store.state.mapBasedTimeseriesLoader.files);
          } catch {}
        }, 0);
      },
    },
    timeColumnValue: {
      deep: true,
      handler() {},
    },
    datatableValue: {
      deep: true,
      handler() {
        this.itemFilterLength = this.datatableValue.length;
      },
    },
    sheetValue: {
      deep: true,
      handler(oldVal, newVal) {
        try {
          if (this.sheetValue && this.sheetValue.id && this.files && this.files.length) {
            for (let i = 0; i < this.files.length; i++) {
              let columnItems = [];
              if (this.files[i].id == this.sheetValue.id) {
                for (let j = 0; j < this.files[i].columns.length; j++) {
                  columnItems.push({
                    id: `${this.files[i].columns[j]}`,
                    value: `${this.files[i].columns[j]}`,
                    uuid: `${uuidv4()}`,
                    label: `${this.files[i].columns[j]}`,
                  });
                }

                this.columnItems = columnItems;
                this.datatableValue = this.files[i].data;
                if (!this.timeColumnValue) {
                  this.timeColumnValue = this.columnItems[0];
                }
                if (!this.valueColumn || !this.valueColumn.length) {
                  this.valueColumn = [this.columnItems[1].value];
                }
                break;
              }
            }
          } else {
            this.datatableValue = [];
            this.timeColumnValue = null;
            this.valueColumn = [];
          }
        } catch {
          this.datatableValue = [];
          this.timeColumnValue = null;
          this.valueColumn = [];
        }
        this.getDataTable(this.searchTerm, this.from - 1, this.to, false);
      },
    },
    files: {
      deep: true,
      handler() {
        if (this.files && this.files.length) {
          let sheetItems = [];
          for (let i = 0; i < this.files.length; i++) {
            sheetItems.push({
              id: this.files[i].id,
              value: `${this.files[i].id}`,
              label: `${this.files[i].name}`,
            });
          }
          this.sheetItems = sheetItems;
        } else {
          this.sheetItems = [];
          this.sheetValue = null;
          this.timeColumnValue = null;
          this.valueColumn = null;
          this.columnItems = [];
        }
      },
    },
    columnItems() {},
    valueColumn() {},

    searchTerm(value) {
      // if (value !== '') this.getDataTable(this.searchTerm, this.from - 1, this.to, true);
      // else this.getDataTable(this.searchTerm, this.from - 1, this.to, false);
    },
    isChange() {
      this.getDataTable(this.searchTerm, this.from - 1, this.to, false);
    },
    locale() {
      this.changeTitleNavigation();
    },
  },
  methods: {
    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      try {
        if (searchTerm === '') {
          this.filteredData = this.datatableValue.slice(+from, +to);
        } else {
          if (isGetDataToSearch) this.dataFilterStore = _.cloneDeep(this.datatableValue);
          if (!this.dataFilterStore.length) {
            from = from === -1 ? 0 : from;
            to = to === 0 ? 20 : to;
            this.itemFilterLength = this.itemLength;
            this.filteredData = this.datatableValue.slice(+from, +to);
          } else {
            this.itemFilterLength = this.dataFilterStore.length;
            this.filteredData = this.dataFilterStore.slice(+from, +to);
          }
        }
      } catch {
        this.filteredData = _.cloneDeep(this.datatableValue);
      }
    },
    changeTitleNavigation() {
      try {
        let buttonTitle = document.querySelectorAll('ul > li.page-item');
        if (this.locale === 'en') {
          buttonTitle[0].title = 'First';
          buttonTitle[1].title = 'Prev';
          buttonTitle[buttonTitle.length - 2].title = 'Next';
          buttonTitle[buttonTitle.length - 1].title = 'Last';
        } else {
          buttonTitle[0].title = 'まず';
          buttonTitle[1].title = '前のページ';
          buttonTitle[buttonTitle.length - 2].title = '次の';
          buttonTitle[buttonTitle.length - 1].title = '最後';
        }
      } catch {}
    },
    normalizeRows(data) {
      const maxLength = Math.max(...data.map((row) => row.length));
      return data.map((row) => {
        const newRow = [];
        for (let i = 0; i < maxLength; i++) {
          newRow[i] = row[i] !== undefined ? `${row[i]}` : '';
        }
        return newRow;
      });
    },
    convertToWString(data, worksheet) {
      const formatsDate = ['M/D/YY', 'MM/DD/YY', 'M/D/YYYY', 'D-MMM-YY', 'D-MMM', 'MMM-YY', 'H:MM AM/PM', 'H:MM:SS AM/PM', 'H:MM', 'H:MM:SS', 'M/D/YYYY H:MM', 'MM:SS'];
      return data.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          const cellAddress = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex });
          const cellObject = worksheet[cellAddress];
          let valueText = cellObject && cellObject.w ? `${cellObject.w}` : cell !== undefined && cell !== null ? `${cell}` : '';
          if (moment(valueText, formatsDate, true).isValid()) {
            valueText = dayjs(valueText).format('YYYY-MM-DD HH:mm:ss');
          }
          return valueText;
        })
      );
    },
    async getEncodingFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          try {
            let codes = new Uint8Array(e.target.result);
            let encoding = Encoding.detect(codes); // Detech file encoding
            resolve(encoding);
          } catch {}
          resolve('UTF8');
        };
        reader.readAsArrayBuffer(file);
      });
    },

    async handerUploadFile(event) {
      const self = this;
      this.runReadFile = true;
      let encoding = 'UTF8';
      let file = this.$refs.ipUploadFile.files[0];
      try {
        encoding = await this.getEncodingFile(file);
      } catch {}
      if (file && file.name && file.name.endsWith('.csv')) {
        Papa.parse(file, {
          skipEmptyLines: true,
          encoding,
          skipEmptyLines: 'greedy',
          fastMode: true,
          delimitersToGuess: [',', ';'],
          complete: (result) => {
            try {
              let csv = result.data;
              let body = [];
              let header = [];
              let dataSort;
              // try {
              // csv.map((row) => {
              //   if (moment(row[0], ALL_FORMAT_DATE, true).isValid()) {
              //     row[0] = dayjs(row[0]).format('YYYY-MM-DD HH:mm:ss');
              //     body.push(row);
              //   } else {
              //     header.push(row);
              //   }
              // });
              // dataSort = _.concat(header, body);
              dataSort = csv;
              // } catch {
              //   dataSort = csv;
              // }
              let files = {
                id: uuidv4(),
                name: file.name,
                data: dataSort,
                columns: dataSort && dataSort.length ? _.range(0, dataSort[0].length).map((index) => `${index}`) : [],
                countHeader: header && header.length ? header.length : 0,
              };
              self.$store.commit('mapBasedTimeseriesLoader/ADD_FILES', files);
              self.$store.commit('mapBasedTimeseriesLoader/ADD_FILES_VERSION');
              // self.files.push(files);
            } catch {}
            self.runReadFile = false;
            self.$refs.ipUploadFile.value = '';
          },
        });
      } else if (file && file.name && file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            workbook.SheetNames.forEach((sheetName) => {
              const worksheet = workbook.Sheets[sheetName];
              let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });
              try {
                jsonData = self.convertToWString(jsonData, worksheet);
              } catch {}
              try {
                jsonData = self.normalizeRows(jsonData);
              } catch {}
              let files = {
                id: uuidv4(),
                name: `${sheetName}(${file.name})`,
                data: jsonData,
                columns: _.range(0, jsonData[0].length).map((index) => `${index}`),
                countHeader: 0,
              };
              self.$store.commit('mapBasedTimeseriesLoader/ADD_FILES', files);
              self.$store.commit('mapBasedTimeseriesLoader/ADD_FILES_VERSION');
            });
          } catch {}
          self.runReadFile = false;
          self.$refs.ipUploadFile.value = '';
        };
        reader.readAsArrayBuffer(file);
      }
    },
    uploadFiles() {
      this.$refs.ipUploadFile.click();
    },
    open() {
      try {
        this.files = Object.values(this.$store.state.mapBasedTimeseriesLoader.files);
      } catch {}
      try {
        let dataTemp = _.cloneDeep(this.$mapBasedTimeseriesLoader[this.dataExcelModal.id]);
        this.skipHeader = dataTemp.skipHeader;
        this.numSkipHeader = dataTemp.numSkipHeader ? Number(dataTemp.numSkipHeader) : 0;
        for (let i = 0; i < this.sheetItems.length; i++) {
          if (this.sheetItems[i].id == dataTemp.sheetValue.id) {
            this.sheetValue = this.sheetItems[i];
            break;
          }
        }
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].id == dataTemp.sheetValue.id) {
            if (this.files[i].columns.indexOf('1') > -1) {
              let columnItems = [];
              for (let j = 0; j < this.files[i].columns.length; j++) {
                columnItems.push({
                  id: `${this.files[i].columns[j]}`,
                  value: `${this.files[i].columns[j]}`,
                  uuid: `${uuidv4()}`,
                  label: `${this.files[i].columns[j]}`,
                });
              }

              this.columnItems = columnItems;
              this.timeColumnValue = dataTemp.timeColumnValue;
              this.valueColumn = dataTemp.valueColumn;
            }
          }
        }
      } catch {
        this.sheetItems = [];
        this.sheetValue = null;
        this.timeColumnValue = null;
        this.valueColumn = null;
        this.columnItems = [];
      }

      this.$refs.excelModal.show();
    },
    close() {
      this.$refs.excelModal.hide();
    },
    isColumnValue(idy) {
      try {
        if (this.valueColumn) {
          return this.valueColumn.indexOf(`${idy}`) >= 0;
        }
      } catch {}
      return false;
    },
    isColumnSkipHeader(idx) {
      try {
        if (!this.skipHeader) return false;
        let endPage = this.pageIndex * this.pageLength;
        if (endPage <= this.numSkipHeader) {
          return true;
        }
        if (this.numSkipHeader < this.from) {
          return false;
        } else {
          let idxTemp = Number(this.numSkipHeader) % Number(this.pageLength);
          if (idx + 1 <= idxTemp) return true;
        }
      } catch {}
      return false;
    },
    save() {
      try {
        let datatableValue = _.cloneDeep(this.datatableValue);
        let dataHeaderValue = [];
        let headerValue = {};
        if (this.skipHeader && this.numSkipHeader > 0) {
          try {
            dataHeaderValue = datatableValue.slice(0, Number(this.numSkipHeader));
          } catch {}
          datatableValue = datatableValue.slice(Number(this.numSkipHeader));
        }
        const dataTranspose = this.transpose(datatableValue);

        let time = [];
        try {
          if (this.timeColumnValue && this.timeColumnValue.value != null) {
            time = dataTranspose[Number(this.timeColumnValue.value)];
          }
        } catch {}

        let values = [];
        try {
          if (this.valueColumn && this.valueColumn.length) {
            for (let i = 0; i < this.valueColumn.length; i++) {
              let idxColumn = Number(this.valueColumn[i]);
              let dataTemp = dataTranspose[idxColumn];
              values.push({
                name: `ITEM${i + 1}`,
                data: dataTemp,
              });
            }
            try {
              let dataFill = ['GROUP', 'ITEM', 'UNIT'];
              for (let g = 0; g < dataHeaderValue.length; g++) {
                let dataTempHeahderValue = [];
                const idxTimeColumn = Number(this.timeColumnValue.value);
                let valueTimeHeader = String(dataHeaderValue[g][idxTimeColumn]).toUpperCase();
                if (dataFill.indexOf(valueTimeHeader) >= 0) {
                  for (let i = 0; i < this.valueColumn.length; i++) {
                    let idxColumn = Number(this.valueColumn[i]);
                    dataTempHeahderValue.push(dataHeaderValue[g][idxColumn]);
                  }
                  headerValue[valueTimeHeader] = dataTempHeahderValue;
                }
              }
            } catch {}
          }
          let dataCsvExcel = {};
          dataCsvExcel[this.dataExcelModal.id] = {
            time,
            values,
            skipHeader: this.skipHeader,
            numSkipHeader: this.numSkipHeader,
            sheetValue: this.sheetValue,
            timeColumnValue: this.timeColumnValue,
            valueColumn: this.valueColumn,
            headerValue,
          };
          this.$emit('dataCsvExcel', _.cloneDeep(dataCsvExcel), _.cloneDeep(this.dataExcelModal));
        } catch {}
      } catch {}
    },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    saveAndExit() {
      try {
        let datatableValue = _.cloneDeep(this.datatableValue);
        let dataHeaderValue = [];
        let headerValue = {};
        if (this.skipHeader && this.numSkipHeader > 0) {
          try {
            dataHeaderValue = datatableValue.slice(0, Number(this.numSkipHeader));
          } catch {}
          datatableValue = datatableValue.slice(Number(this.numSkipHeader));
        }
        const dataTranspose = this.transpose(datatableValue);

        let time = [];
        try {
          if (this.timeColumnValue && this.timeColumnValue.value != null) {
            time = dataTranspose[Number(this.timeColumnValue.value)];
          }
        } catch {}

        let values = [];
        try {
          if (this.valueColumn && this.valueColumn.length) {
            for (let i = 0; i < this.valueColumn.length; i++) {
              let dataTemp = dataTranspose[Number(this.valueColumn[i])];
              values.push({
                name: `ITEM${i + 1}`,
                data: dataTemp,
              });
            }
          }
          try {
            let dataFill = ['GROUP', 'ITEM', 'UNIT'];
            for (let g = 0; g < dataHeaderValue.length; g++) {
              let dataTempHeahderValue = [];
              const idxTimeColumn = Number(this.timeColumnValue.value);
              let valueTimeHeader = String(dataHeaderValue[g][idxTimeColumn]).toUpperCase();
              if (dataFill.indexOf(valueTimeHeader) >= 0) {
                for (let i = 0; i < this.valueColumn.length; i++) {
                  let idxColumn = Number(this.valueColumn[i]);
                  dataTempHeahderValue.push(dataHeaderValue[g][idxColumn]);
                }
                headerValue[valueTimeHeader] = dataTempHeahderValue;
              }
            }
          } catch {}
          let dataCsvExcel = {};
          dataCsvExcel[this.dataExcelModal.id] = {
            time,
            values,
            skipHeader: this.skipHeader,
            numSkipHeader: this.numSkipHeader,
            sheetValue: this.sheetValue,
            timeColumnValue: this.timeColumnValue,
            valueColumn: this.valueColumn,
            headerValue,
          };
          this.$emit('dataCsvExcel', _.cloneDeep(dataCsvExcel), _.cloneDeep(this.dataExcelModal));
        } catch {}
      } catch {}
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.excel-select-container {
  padding: 0;
  position: relative;
  width: 100%;
  max-width: 300px;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}

.excel-select-container:disabled .excel-pointer {
  cursor: not-allowed !important;
}

.dark-layout .excel-select-container {
  color: #d0d2d6;
}

.excel-select-container .excel-pointer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.excel-select-container .excel-title {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.excel-select-container .excel-description {
  color: #397bff;
}

.excel-modal-container {
  max-height: calc(100vh - 310px);
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

.excel-sheet-colum {
  &-item {
    flex: 1;
    // border: solid 1px;
    padding: 0.5rem 1rem;
  }
}

.bfip-num-skip-header {
  max-width: 60px;
  text-align: center;
}

.div-skip-header {
  min-height: 40px;
}

.table-container {
  height: calc(100vh - 580px);
  overflow: auto;
  margin-bottom: 1rem;
}

table {
  // min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  padding-right: 1px;
}

th {
  border: 1px solid #ebe9f1;
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer;
  position: sticky;
  background-color: #f8f8f8;
}

.table-header tr:nth-child(1) th {
  top: 0;
}

.table-header tr:nth-child(2) th {
  top: 34px;
}

.table-header tr:nth-child(3) th {
  top: 68px;
}

.table-header tr:nth-child(4) th {
  top: 102px;
}

th:first-child {
  width: 200px;
  position: sticky;
  left: 0px;
  background-color: #f8f8f8;
  z-index: 2;
}

td {
  width: 150px;
  border: 1px solid #ebe9f1;
  padding: 6px 10px;
  white-space: nowrap;
}

td:first-child {
  width: 200px;
  position: sticky;
  left: 0px;
  background-color: #ffffff;
  z-index: 1;
}

.column-skip-header {
  td:first-child {
    background-color: inherit !important;
  }

  th:first-child {
    background-color: #f8f8f8;
  }
}

/* td.is-nan {
  box-shadow: inset 0 0 5px 0 #5e587380;
} */

.showing {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th .header-input {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  width: 110px;
}

button.edit-column {
  position: absolute;
  top: -9px;
  right: 22px;
  padding: 3px;
}

button.delete-column {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
}

.highlight {
  border-left-color: #28c76f !important;
  border-right-color: #28c76f !important;
}

.highlight-header {
  text-align: left !important;
}

thead tr:first-child .highlight {
  border-top-color: #28c76f !important;
}

tbody tr:last-child .highlight {
  border-bottom-color: #28c76f !important;
}

/* DARK */
.dark-layout th {
  border-color: #3b4253;
  background-color: #343d55;
}

.dark-layout th:first-child {
  background-color: #343d55;
}

.dark-layout td {
  border-color: #3b4253;
}

.dark-layout td:first-child {
  background-color: #283046;
}

/* .dark-layout td.is-nan {
  box-shadow: inset 0 0 5px 0 #000000aa;
} */
.afterText {
  margin-right: -3px;
}

.hightLightText {
  background-color: #ccc;
  border-radius: 2px;
  margin-right: -3px;
  display: inline-block;
}

.custom-body.modal-body {
  color: inherit !important;
}

th,
th input {
  cursor: default !important;
}

.highlight-title {
  /* border-top: 0.5px solid red !important; */
  border-left: 1px solid rgb(13, 160, 218) !important;
  border-right: 1px solid rgb(13, 160, 218) !important;
}

.table-header tr {
  &:nth-child(1) .highlight-title {
    border-top: 1px solid rgb(13, 160, 218) !important;
  }
}

table tbody {
  tr {
    &:last-child .highlight-title {
      border-bottom: 1px solid rgb(13, 160, 218) !important;
    }
  }
}

.dark-layout {
  .hightLightText {
    color: black !important;
  }
}

.column-time {
  background: #ffecb3 !important;
  color: black;
}

.column-value {
  background: #2f93da4d;
}

.column-skip-header {
  background: red !important;

  .column-time {
    background: red !important;
  }

  .column-value {
    background: red !important;
  }
}
</style>
