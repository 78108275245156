<template>
  <div>
    <b-modal size="lg" hide-footer centered ref="createLayerModal" :body-class="'custom-body tools-body create-layer-modal-body'" modal-class="create-layer-modal">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between flex-grow-1 modal-title">3. {{ $t('Create Layer mb') }}</div>
        <button type="button" class="close" :title="$t('close')" @click="close">&times;</button>
      </template>
      <b-card-text>
        <section id="faq-tabs">
          <!-- <b-tabs pills> -->
          <!-- <b-tab @click="typeAdd(categoryObj.value)" v-for="(categoryObj, index) in optionSetting" :key="categoryObj.title" :active="!index"> -->
          <div v-for="(categoryObj, index) in optionSetting" :key="categoryObj.title" :active="!index">
            <!-- <template #title>
                <img :src="categoryObj.icon" :style="{ width: '18px', height: '18px', marginRight: '5px' }" />
                <span class="font-weight-bold">{{ $t(categoryObj.title) }}</span>
              </template> -->

            <div class="mb-1">
              <h3>{{ $t('template').toUpperCase() }}</h3>
              <div v-if="categoryObj.value === 'timeseries'" class="custom-scrollbar mt-1" style="height: 270px; overflow: auto">
                <!-- Circle  -->
                <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                  <PrefixLayer v-for="(option, index) in circleColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                </div>
                <!-- Triangle  -->
                <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                  <PrefixLayer v-for="(option, index) in triangleColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                </div>
                <!-- Square  -->
                <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                  <PrefixLayer v-for="(option, index) in squareColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                </div>
                <!-- Spike  -->
                <div class="d-flex" style="gap: 5px; margin-bottom: 5px; margin-right: 5px">
                  <PrefixLayer v-for="(option, index) in spikeColorConfig" :key="index" :layer-name="option.name" v-model="activeLayer" :title="$t(option.title)" @onUpdateLayer="fconUpdateLayer(option)" />
                </div>
              </div>
            </div>
          </div>
          <!-- </b-tab> -->
          <!-- </b-tabs> -->
        </section>
      </b-card-text>

      <div class="text-end text-right mt-1">
        <b-button variant="light" size="xl" class="mr-1" @click.stop="close"> {{ $t('Back') }} </b-button>
        <b-button variant="success" size="xl" class="mr-1" @click.stop="createLayer"> {{ $t('Finish') }} </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import MathMixin from '@/mixins/MathMixin.js'
import FileMixin from '@/mixins/FileMixin.js'
import { convertNumber } from '@/utilities/NumberUtility.js'
const _ = require('lodash')
import { v4 as uuidv4 } from 'uuid'
import SelectionArea from '@/scripts/viselect/viselect.js'
// import LocationIcon from '/static/images/layer-config/prepend/location.svg';
import { DEFAULT_LAYER, DEFAULT_GROUP } from '@/constants/default-layer.js'
import PrefixLayer from './PrefixLayer.vue'
import { GRADIENT_COLOR, FILL_COLOR, FLAT_COLOR, GOURAUD_COLOR, LINE_COLOR, CIRCLE, TRIANGLE, SQUARE, SPIKE, CIRCLE_SHAPE, TRIANGLE_SHAPE, SQUARE_SHAPE, SPIKE_SHAPE, TYPHOON, IMAGE } from '@/constants/template-layer'
import i18n from '@/libs/i18n'
export default {
  props: ['dataCreateLayerModal'],
  components: { PrefixLayer },
  mixins: [FileMixin, MathMixin],
  data() {
    return {
      selectedLocation: null,
      selectedMultiLocation: null,
      selectedIdMultiLocation: null,
      selectedIdLocation: null,
      selectedIdTimeseries: [],
      selectedIdMultiTimeseries: [],
      selectedIdnetCDF: [],
      selectedIdTyphoon: [],
      selectedIdImage: [],
      addToGroup: false,
      loadingCreate: false,
      typeLayer: 'timeseries',
      activeLayer: '',
      gradientColorConfig: GRADIENT_COLOR,
      fillColorConfig: FILL_COLOR,
      flatColorConfig: FLAT_COLOR,
      gouraudColorConfig: GOURAUD_COLOR,
      lineColorConfig: LINE_COLOR,
      circleColorConfig: CIRCLE,
      triangleColorConfig: TRIANGLE,
      squareColorConfig: SQUARE,
      spikeColorConfig: SPIKE,
      circleShapeColorConfig: CIRCLE,
      triangleShapeColorConfig: TRIANGLE,
      squareShapeColorConfig: SQUARE,
      spikeShapeColorConfig: SPIKE,
      typhoonColorConfig: TYPHOON,
      imageColorConfig: IMAGE,
      layerCommit: _.cloneDeep(DEFAULT_LAYER),
      idDatasourceStation: null,
      idDatasourceTimeSeries: null,
      configAddLayer: {},
    }
  },
  computed: {
    optionTimeseries() {
      return this.datasources.filter((d) => ['timeseries'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    optionSetting() {
      return [
        { title: 'point', icon: 'static/images/layer-config/radio/type-point.svg', value: 'timeseries' },
        // { title: 'shape', icon: 'static/images/layer-config/radio/type-multi.svg', value: 'multi' },
        // { title: 'NetCDF', icon: 'static/images/layer-config/radio/type-netcdf.svg', value: 'netcdf' },
        // { title: 'typhoon', icon: 'static/images/layer-config/radio/type-typhoon.svg', value: 'typhoon' },
        // { title: 'image', icon: 'static/images/layer-config/radio/type-image.svg', value: 'image' },
      ]
    },
    optionLocation() {
      return this.datasources.filter((d) => ['point'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
  },
  watch: {},
  methods: {
    open() {
      this.$refs.createLayerModal.show()
    },
    close() {
      this.$refs.createLayerModal.hide()
    },
    async createAndAddFilesTimeSeries() {
      try {
        const dataOriginal = _.cloneDeep(this.dataCreateLayerModal)
        if (!dataOriginal || !dataOriginal.rows || !dataOriginal.rows.length) {
          return
        }
        let id = uuidv4()
        let name = `TimeSeries.csv`
        let type = 'timeseries'
        let dates = dataOriginal.rows.map((r) => r[0])
        let data = {}
        let autoGroups = {}
        let datasourceData = dataOriginal.rows
        let datasourceColumns = {}
        let tmpGroup = null // temp variable
        const GROUP_IDX = dataOriginal.columns[0].label.findIndex((c) => c === 'GROUP')
        const ITEM_IDX = dataOriginal.columns[0].label.findIndex((c) => c === 'ITEM')
        const UNIT_IDX = dataOriginal.columns[0].label.findIndex((c) => c === 'UNIT')
        // Các cột ngoại trừ cột date
        let columns = []
        dataOriginal.columns.map((c) => {
          if (c.field && c.field !== 'date') {
            columns.push(c.field)
          }
        })
        columns.forEach((column, index) => {
          // TODO: real calculate 'nullCount'
          let tmpName = dataOriginal.columns[index + 1].label[0] + '*' + dataOriginal.columns[index + 1].label[ITEM_IDX]
          if (Object.keys(datasourceColumns).includes(tmpName)) tmpName = `${dataOriginal.columns[index + 1].label[0]}_1*${dataOriginal.columns[index + 1].label[ITEM_IDX]}`
          datasourceColumns[tmpName] = { nullCount: 0, autoGroup: dataOriginal.columns[index + 1].label[GROUP_IDX], unit: dataOriginal.columns[index + 1].label[UNIT_IDX] }
          // Build autoGroup
          tmpGroup = GROUP_IDX !== -1 ? this.getGroupName(dataOriginal.columns[index + 1].label[GROUP_IDX]) : []
          tmpGroup.map((grName) => {
            if (!grName) return
            if (!autoGroups[grName]) autoGroups[grName] = []
            autoGroups[grName].push(tmpName)
          })
        })
        // Build data

        //Build data
        const keysCol = [...Object.keys(datasourceColumns)]
        for (let i = 0; i < keysCol.length; i++) {
          for (let j = 0; j < datasourceData.length; j++) {
            if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = []
            let dataTemp = this.isNaValue(datasourceData[j][i + 1]) ? null : Number(datasourceData[j][i + 1])
            data[keysCol[i].replace('*', '-')].push(dataTemp)
          }
        }
        // Build Items
        let items = {}
        let itemArray = [...new Set(dataOriginal.columns.slice(1).map((column) => column.label[ITEM_IDX]))]
        itemArray.forEach((item) => {
          if (!item) return
          let obj = { min: Infinity, max: -Infinity, unit: '' }
          // Min & max
          try {
            for (const key in data) {
              if (key.endsWith(`-${item}`)) {
                for (let i = 0; i < data[key].length; i++) {
                  if (!this.isNaValue(data[key][i]) && data[key][i] < obj.min) obj.min = Number(data[key][i])
                  if (!this.isNaValue(data[key][i]) && data[key][i] > obj.max) obj.max = Number(data[key][i])
                }
              }
            }
            if (obj.min == Infinity) {
              obj.min = 0
            }
            if (obj.max == -Infinity) {
              obj.max = 0
            }
          } catch {}
          // Unit
          let index = dataOriginal.columns.findIndex((column) => column.label[ITEM_IDX] === item)
          obj.unit = dataOriginal.columns[index].label[UNIT_IDX]
          items[item] = obj
        })

        // Build Locations
        let locations = {}
        let locationArray = [...new Set(dataOriginal.columns.slice(1).map((column) => column.label[0]))]
        locationArray.forEach((location) => {
          if (!location) return
          let obj = {}
          locations[location] = obj
        })

        let datasourceObject = { id, name, type, storage_mode: 'RAM', columns: datasourceColumns, dates, locations, items, csvData: datasourceData, data, header: [...dataOriginal.columns[0].label], autoGroups }
        await this.storeData(datasourceObject)
        const file = await this.createObjectFile(datasourceObject)
        datasourceObject.file = file
        datasourceObject.size = file.size
        await this.addDatasource(datasourceObject)
        this.idDatasourceTimeSeries = datasourceObject.id
        this.updateDatatable++
      } catch {}
    },
    async createAndAddFilesLocations() {
      try {
        const dataOriginal = _.cloneDeep(this.dataCreateLayerModal)
        if (!dataOriginal || !dataOriginal.locations || !dataOriginal.locations.length) return
        let dataLocation = []
        let csvData = 'ID,LONGITUDE,LATITUDE\r\n'
        let fileName = `Station.csv`
        for (let i = 0; i < dataOriginal.locations.length; i++) {
          let name = ''
          let lat = ''
          let lon = ''
          try {
            name = dataOriginal.locations[i].name ? dataOriginal.locations[i].name : ''
          } catch {}
          try {
            lat = dataOriginal.locations[i].dataPoint && dataOriginal.locations[i].dataPoint.lat ? dataOriginal.locations[i].dataPoint.lat : 0
          } catch {}
          try {
            lon = dataOriginal.locations[i].dataPoint && dataOriginal.locations[i].dataPoint.lng ? dataOriginal.locations[i].dataPoint.lng : 0
          } catch {}
          dataLocation.push({
            name: name,
            lat: lat,
            lng: lon,
          })
          try {
            csvData += `${name},${lon},${lat}\r\n`
          } catch {}
        }
        let file
        try {
          let blob = new Blob([csvData], { type: 'application/json' })
          file = new File([blob], fileName)
        } catch {}
        let id = uuidv4()
        this.idDatasourceStation = id
        // Convert array of row object to 1 datasource object and add to store

        let type = 'point'
        let datasourceObject = {
          data: dataLocation,
          encoding: 'ASCII',
          errors: [],
          event: 'succeed',
          file: file,
          id,
          name: fileName,
          path: '',
          size: file && file.size ? file.size : 0,
          storage_mode: 'RAM',
          type,
        }

        await this.storeData(datasourceObject)
        await this.addDatasource(datasourceObject)

        this.updateDatatable++
      } catch {}
    },
    async createLayer() {
      try {
        await this.createAndAddFilesLocations()
        await this.createAndAddFilesTimeSeries()
        const dataOriginal = _.cloneDeep(this.dataCreateLayerModal)
        let IDXITEM = dataOriginal.columns[0].label.indexOf('ITEM')
        let item = IDXITEM > -1 ? dataOriginal.columns[1].label[IDXITEM] : ''
        if (this.idDatasourceStation && this.idDatasourceTimeSeries) {
          let layer = _.cloneDeep(DEFAULT_LAYER)
          layer = _.merge(layer, {
            name: `Map based timeseries loader`,
            id: uuidv4(),
            dataPoint: _.cloneDeep(this.idDatasourceStation),
            dataTimeseries: _.cloneDeep(this.idDatasourceTimeSeries),
            type: `point`,
            item: item,
          })
          layer = _.merge(layer, _.cloneDeep(this.configAddLayer))
          this.$store.commit('layer/ADD_GROUP', layer)
          this.$store.commit('layer/ADD_LAYER', layer)
        }
      } catch {}
      this.$emit('closeFromCreateLayer')
      this.exitMapBasedTimeseriesLoader()
      this.close()
    },
    exitMapBasedTimeseriesLoader() {
      this.$store.commit('mapBasedTimeseriesLoader/UPDATE_ACTIVE', false)
      this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', null)
      this.$store.commit('mapBasedTimeseriesLoader/SET_LOCALTION_TEMP', [])
    },
    getGroupName(str) {
      return str.toString().split('|')
    },
    fconUpdateLayer(config) {
      if (this.typeLayer === 'timeseries') this.configAddLayer = _.merge(this.configAddLayer, { fillColor: { mode: config.mode, colors: config.colors }, shape: { shapeType: config.shapeType } })
      if (this.typeLayer === 'netcdf') this.configAddLayer = _.merge(this.configAddLayer, { netcdf: { colorMap: config.colorMap, visualizeMode: config.visualizeMode } })
      if (this.typeLayer === 'multi') this.configAddLayer = _.merge(this.configAddLayer, { fillColor: { mode: config.mode, colors: config.colors }, shape: { shapeType: config.shapeType } })
    },
  },
}
</script>

<style lang="scss" scoped>
.create-layer-modal-body {
  max-width: 632px;
}
</style>
<style lang="scss">
.create-layer-modal {
  .modal-dialog {
    max-width: 632px !important;
  }
}
</style>
