import { render, staticRenderFns } from "./AwsS3Component.vue?vue&type=template&id=527d7632&scoped=true&"
import script from "./AwsS3Component.vue?vue&type=script&lang=js&"
export * from "./AwsS3Component.vue?vue&type=script&lang=js&"
import style0 from "./AwsS3Component.vue?vue&type=style&index=0&id=527d7632&lang=scss&scoped=true&"
import style1 from "./AwsS3Component.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527d7632",
  null
  
)

export default component.exports