<template>
  <div>
    <div v-if="Object.keys(layer).length" v-for="(layer, index) in callGroupLayer" :key="layer.id" v-show="!captureMode">
      <LegendShape v-if="layer.legend.shapeVisible && layer.fillColor.mode !== 'threshold' && layer.type !== 'netcdf'" :layer="layer" :index="index" />
      <MyLegend v-else :layer="layer" :index="index" />
    </div>
  </div>
</template>
<script>
const _ = require('lodash');
import Legend from './Legend.vue';
import LegendShape from './LegendShape.vue';
export default {
  props: ['groups'],
  components: { MyLegend: Legend, LegendShape },
  computed: {
    callGroupLayer() {
      let callGroupLayer = {};
      let groupData = _.cloneDeep(this.groups.groupData);
      if (groupData && groupData.length > 0) {
        groupData.map((groups) => {
          const group = _.cloneDeep(groups);
          if (!callGroupLayer[group.type]) callGroupLayer[group.type] = {};
          let check = group.legend.visible && (group.type === 'point' || group.type === 'multi' || group.type === 'netcdf') && group.item;
          if (check) {
            callGroupLayer[group.type] = _.merge(group, callGroupLayer[group.type]);
            if (!callGroupLayer[group.type].arrayIdGroup) callGroupLayer[group.type].arrayIdGroup = [];
            if (!callGroupLayer[group.type].arrayItemGroup) callGroupLayer[group.type].arrayItemGroup = [];
            if (!callGroupLayer[group.type].arrayDataNetcdf) callGroupLayer[group.type].arrayDataNetcdf = [];
            if (!callGroupLayer[group.type].arrayDataTimeseries) callGroupLayer[group.type].arrayDataTimeseries = [];
            callGroupLayer[groups.type].arrayIdGroup.push(groups.id);
            callGroupLayer[groups.type].arrayItemGroup.push(groups.item);
            callGroupLayer[groups.type].arrayDataNetcdf.push(groups.dataNetcdf);
            callGroupLayer[groups.type].arrayDataTimeseries.push(groups.dataTimeseries);
            callGroupLayer[groups.type].name = _.cloneDeep(this.groups.name);
            if (groups.visible[0]) callGroupLayer[groups.type].visible[0] = true;
          }
        });
      }
      return Object.values(callGroupLayer);
    },
    captureMode() {
      return this.$store.state.ecoplot.captureMode;
    },
  },
  watch:{
  },
};
</script>

<style lang="scss" scoped></style>
