<template>
  <div>
    <b-card class="mb-0 map-card" :class="{ 'show-sidebar': componentActive && componentActive != '' }">
      <div class="map-container">
        <div class="map-list" id="map-list" ref="mapList">
          <template v-for="n in numberOfMap">
            <div :key="`divider${n}`" class="map-divider" v-if="n > 1">
              <b-button @click="toggleLockMaps" :variant="lockMaps ? 'primary gradient-primary' : 'light-primary'" class="btn-icon rounded-circle">
                <b-avatar :variant="lockMaps ? 'primary gradient-primary' : 'light-primary'" class="primary-layout look-map" :title="$t('lock_split_screens')">
                  <feather-icon :icon="lockMaps ? 'LockIcon' : 'UnlockIcon'" />
                </b-avatar>
              </b-button>
            </div>
            <MapItem :key="`map${n}`" :mapIndex="n - 1" ref="mapItem" :mapBasedTimeseriesLoaderLocaltionIsActive="mapBasedTimeseriesLoaderLocaltionIsActive" />
          </template>
          <div class="map-list-menu" :class=" mapBasedTimeseriesLoaderIsActive ? 'disabled' : ''">
            <div class="map-list-menu-top">
              <div class="map-list-menu-svg map-list-menu-top-svg" :title="$t('data_panel')" :class="{ active: componentActive === 'control-panel' }" @click.stop="activeSidebarRight('control-panel')">
                <feather-icon icon="AirplayIcon" size="16" />
              </div>
              <div class="map-list-menu-svg map-list-menu-top-svg map-list-menu-chart" :title="$t('chart_panel')" :class="{ active: componentActive === 'select-layer' }" @click.stop="activeSidebarRight('select-layer')">
                <feather-icon icon="BarChartIcon" size="16" />
              </div>
              <div class="map-list-menu-svg map-list-menu-top-svg map-list-menu-image" :title="$t('image_panel')" :class="{ active: componentActive === 'images-component' }" @click.stop="activeSidebarRight('images-component')">
                <feather-icon icon="ImageIcon" size="16" />
              </div>
              <div class="map-list-menu-svg map-list-menu-top-svg" :title="$t('scene_panel')" :class="{ active: componentActive === 'scene-component' }" @click.stop="activeSidebarRight('scene-component')">
                <feather-icon icon="InstagramIcon" size="16" />
              </div>
              <div class="map-list-menu-svg map-list-menu-top-svg" :title="$t('aws_s3_manager')" :class="{ active: componentActive === 'aws-s3-component' }" @click.stop="activeSidebarRight('aws-s3-component')">
                <span>S3</span>
              </div>
              <div class="map-list-menu-svg map-list-menu-top-svg" :title="$t('ai_component')" :class="{ active: componentActive === 'ai-component' }" @click.stop="activeSidebarRight('ai-component')">
                <span>AI</span>
              </div>
            </div>
            <div class="map-list-menu-bottom">
              <div class="map-list-menu-svg map-list-menu-bottom-svg" :title="numberOfMap === 1 ? $t('split') : $t('merge_map')" @click.stop="toggleSplit">
                <feather-icon size="16" :icon="numberOfMap === 1 ? 'ColumnsIcon' : 'SquareIcon'" />
              </div>
              <div class="map-list-menu-svg map-list-menu-bottom-svg" :title="$t('auto_event')" @click.stop="autoEvent">
                <feather-icon icon="FlagIcon" size="16" />
              </div>
              <div class="map-list-menu-svg map-list-menu-bottom-svg map-list-menu-download-capture" :title="$t('download')">
                <b-dropdown :toggle-tag="'div'" :toggle-class="'custom-toggle'" class="dropdown-capture" dropleft>
                  <template #button-content>
                    <div :title="$t('capture')" class="d-flex">
                      <feather-icon size="16" icon="DownloadIcon" />
                    </div>
                  </template>
                  <PngExporter />
                  <JPEGExporter />
                  <GifExporter hidden :walk-player="walkPlayer" :cancel-walk="cancelWalk" />
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="map-based-timeseries-list-menu" v-show="mapBasedTimeseriesLoaderIsActive" :class="!mapBasedTimeseriesLoaderIsActive ? 'disabled' : ''">
            <div class="map-list-menu-top">
              <div class="map-list-menu-svg map-based-timeseries-list-menu-top-svg" :title="$t('add_location')" :class="{ active: mapBasedTimeseriesLoaderLocaltionIsActive }" @click.stop="mapBasedTimeseriesLoaderLocaltionIsActive = !mapBasedTimeseriesLoaderLocaltionIsActive">
                <feather-icon icon="MapPinIcon" size="16" />
              </div>
              <div class="map-list-menu-svg map-based-timeseries-list-menu-top-svg map-list-menu-save" :class="{ disabled: !mapBasedLocationsTemp || !mapBasedLocationsTemp.length }" :title="$t('import_data')" @click.stop="saveMapBasedTimeseriesLoader">
                <feather-icon icon="ArrowRightIcon" color="#28c76f" size="16" />
              </div>
              <div class="map-list-menu-svg map-based-timeseries-list-menu-top-svg map-list-menu-image" :title="$t('Exit')" @click.stop="exitMapBasedTimeseriesLoader">
                <feather-icon icon="XIcon" color="red" size="16" />
              </div>
            </div>
          </div>
          <div class="map-list-group-layer" :style="{ bottom: numberOfMap == 1 ? '1rem' : '2rem', flexDirection: !checkColumGroup ? 'row' : 'column' }">
            <div class="map-list-group-layer_layer" :title="$t('layer') + `${layerNameSelect ? ':' + layerNameSelect : ''}`" :style="{ order: !checkColumGroup ? 0 : 1 }">
              <VueSelectAuto :dropdown="'dropup'" :value="layerList" :name="'label'" :keyitem="'value'" :placeholder="$t('select_layer')" @change="changeLayerSelected" :active="layerSelected" />
            </div>
            <div class="map-list-group-layer_group" :style="{ order: !checkColumGroup ? 1 : 0 }">
              <GroupItems />
            </div>
          </div>
        </div>
        <div class="map-container-right custom-scrollbar" :class="mapBasedTimeseriesLoaderIsActive ? 'disabled' : ''">
          <SidebarRightComponent :value="componentActive" />
        </div>
      </div>
      <div v-for="(layer, index) in layers.filter((l) => ['point', 'multi', 'netcdf'].includes(l.type))" :key="layer.id" v-show="!captureMode && !checkShowGroup(layer.id)">
        <LegendShape v-if="layer.legend.shapeVisible && layer.fillColor.mode !== 'threshold' && layer.type !== 'netcdf'" :layer="layer" :index="index" />
        <MyLegend v-else :layer="layer" :index="index" />
      </div>
      <div v-for="(groups, index) in groupData" v-if="groupLegend">
        <LegendGroup :groups="groups" />
      </div>

      <Calendar v-show="!captureMode" :start="start" />
    </b-card>
    <Timeplayer class="map-time-player" :class="mapBasedTimeseriesLoaderIsActive ? 'disabled' : ''" ref="timeplayer" v-model="date" :speedValue="speed" @changeStatusTimePlayer="funcChangeStatusTimePlayer" @speedInput="speed = $event" :modeList="['single']" @startInput="start = $event" :liveMode="liveMode" :refreshTime="refreshTime" />
    <canvas id="cell-select-highlight" width="256" height="256" style="position: fixed; top: -300px; left: -300px"></canvas>
    <OptionAlertSystems ref="autoEventModal" />
    <filesImportModal ref="filesImport" />
  </div>
</template>

<script>
const _ = require('lodash')
const axios = require('axios')
import Vue from 'vue'
import PausePlayerMixin from '@/mixins/PausePlayerMixin'
import CompressMixin from '@/mixins/CompressMixin.js'
import TimerMixin from '@/mixins/TimerMixin'
import Timeplayer from '../../common/Timeplayer.vue'
import ControlPanel from './control-panel/ControlPanel.vue'
import SidebarRightComponent from './sidebar/SidebarRightComponent.vue'
import MapItem from './MapItem.vue'
import Calendar from './controls/Calendar.vue'
import Legend from './controls/Legend.vue'
import LegendShape from './controls/LegendShape.vue'
import LegendGroup from './controls/LegendGroup.vue'
import GroupItems from '@/views/ecoplot-desktops/common/GroupItems.vue'
import VueSelectAuto from '@/views/ecoplot-desktops/common/VueSelectAuto.vue'
import OptionAlertSystems from './control-panel/layers/OptionAlertSystems.vue'
import { TILE_LIGHT, TILE_DARK } from '@/constants/app.js'
import PngExporter from './control-panel/actions/PngExporter.vue'
import JPEGExporter from './control-panel/actions/JPEGExporter.vue'
import GifExporter from './control-panel/actions/GifExporter.vue'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
import filesImportModal from './map-based-time-series-loader/filesImportModal'
import { ECOPLOT_API } from '@/constants/urls'

export default {
  name: 'map_component',
  components: { MapItem, ControlPanel, Calendar, MyLegend: Legend, LegendShape, LegendGroup, Timeplayer, SidebarRightComponent, OptionAlertSystems, PngExporter, JPEGExporter, GifExporter, GroupItems, VueSelectAuto, filesImportModal },
  mixins: [PausePlayerMixin, CompressMixin, TimerMixin, ThemeConfig],
  async mounted() {
    let shareId = this.$route.query.shareId
    if (shareId) {
      try {
        await this.fetchMeta(shareId)
        this.$nextTick(async () => {
          await this.fetchData(this.project.id)
          this.$store.commit('ecoplot/UPDATE_IS_PROJECT_SHARE', true)
        })
      } catch (error) {
        this.$store.commit('ecoplot/UPDATE_IS_PROJECT_SHARE', false)
        console.log(error)
      }
    }
    /** Draw rect outline to use later */
    try {
      let cellHighlight = document.getElementById('cell-select-highlight').getContext('2d')
      cellHighlight.lineWidth = 20
      cellHighlight.strokeStyle = '#ffffff'
      cellHighlight.strokeRect(0, 0, 256, 256)
      cellHighlight.rect(10, 10, 236, 236)
      cellHighlight.fillStyle = '#167df052'
      cellHighlight.fill()
    } catch {}

    //CHECK GLOBAL STORAGE
    if (!Vue.prototype.$latlngOnMap) Vue.prototype.$latlngOnMap = {}
    if (!Vue.prototype.$imagesSrc) Vue.prototype.$imagesSrc = {}
    if (!Vue.prototype.$imagesExtract) Vue.prototype.$imagesExtract = {}
    new ResizeObserver(() => {
      const boundEle = this.$refs.mapList.getBoundingClientRect()
      if (boundEle.width <= 850) {
        this.checkColumGroup = true
      } else {
        this.checkColumGroup = false
      }
    }).observe(this.$refs.mapList)
  },
  data() {
    return {
      sidebarOpen: false,
      start: null,
      layerNameSelect: '',
      checkColumGroup: true,
      statusTimePlayer: 'pausing',
      mapBasedTimeseriesLoaderLocaltionIsActive: true,
    }
  },
  computed: {
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    lockMaps() {
      return this.$store.state.map.lockMaps
    },
    captureMode() {
      return this.$store.state.ecoplot.captureMode
    },
    isActivateProduct() {
      return this.$store.state.ecoplot.isActivateProduct
    },
    date: {
      get() {
        return this.$store.state.map.date
      },
      set(date) {
        this.$store.commit('map/UPDATE_DATE', date)
      },
    },
    speed: {
      get() {
        return this.$store.state.map.speed
      },
      set(speed) {
        this.$store.commit('map/UPDATE_SPEED', speed)
      },
    },
    layers() {
      return this.$store.state.layer.layers
    },
    groupData() {
      let groups = this.$store.state.layer.groups
      if (groups.length) return groups.filter((group) => group.groupData && group.groupData.length > 0)
      return []
    },
    liveMode() {
      try {
        let check = false
        let dataLive = []
        this.$store.state.datasource.datasources.map((data) => {
          if (data.live) dataLive.push(data.id)
        })
        if (dataLive.length && this.layers && this.layers.length) {
          for (let i = 0; i < this.layers.length; i++) {
            if (dataLive.indexOf(this.layers[i].dataImage) > -1 || dataLive.indexOf(this.layers[i].dataMulti) > -1 || dataLive.indexOf(this.layers[i].dataNetcdf) > -1 || dataLive.indexOf(this.layers[i].dataPoint) > -1 || dataLive.indexOf(this.layers[i].dataTimeseries) > -1 || dataLive.indexOf(this.layers[i].dataTyphoon) > -1) {
              check = true
              return check
            }
          }
        }
      } catch {}
      return false
    },
    groupLegend() {
      return this.$store.state.settings.groupLegend
    },
    arrIdLayerShowGroup() {
      let result = []
      try {
        if (!this.groupLegend || !this.groupData.length) return []
        for (let i = 0; i < this.groupData.length; i++) {
          if (!this.groupData[i].groupData || !this.groupData[i].groupData.length) continue
          for (let j = 0; j < this.groupData[i].groupData.length; j++) {
            result.push(this.groupData[i].groupData[j].id)
          }
        }
      } catch {}
      return result
    },
    layerList() {
      return this.layers.filter((layer) => ['point', 'multi'].includes(layer.type)).map((layer) => ({ value: layer.id, label: layer.name, visible: layer.visible[0] }))
    },
    layerSelected: {
      get() {
        return this.$store.state.map.layerSelected
      },
      set(value) {
        this.$store.commit('map/SET_LAYER_SELECTED', value)
      },
    },
    mapBasedTimeseriesLoaderIsActive() {
      return this.$store.state.mapBasedTimeseriesLoader.isActive
    },
    mapBasedLocations() {
      return this.$store.state.mapBasedTimeseriesLoader.locations
    },
    mapBasedLocationsTemp() {
      return this.$store.state.mapBasedTimeseriesLoader.locationsTemp
    },
    componentActive: {
      get() {
        return this.$store.state.ecoplot.sidePanelSelected
      },
      set(componentActive) {
        this.$store.commit('ecoplot/SET_SIDE_PANEL_SELECTED', componentActive)
      },
    },
  },
  watch: {
    liveMode() {
      if (this.liveMode == true) this.startTimer()
      else this.stopTimer()
    },
    refreshTime() {
      // Update 'refreshTime' to Map Store for other Component watch
      this.$store.state.map.refreshTime = this.refreshTime
    },
    isActivateProduct(isShow) {
      if (!isShow) {
        this.componentActive = null
      }
    },
    layers(layers) {
      try {
        if (!layers || !layers.length) {
          this.$layerStatus = {}
        } else {
          for (let i = 0; i < layers.length; i++) {
            if (!this.$layerStatus[layers[i].id]) {
              this.$layerStatus[layers[i].id] = {}
            }
          }
        }
      } catch {}
    },
    mapBasedTimeseriesLoaderIsActive(isActive) {
      if (this.numberOfMap == 2 && isActive) {
        this.$store.commit('map/SET_NUMBER_MAP', 1)
      }
    },
  },
  methods: {
    walkPlayer(callback) {
      this.$refs.timeplayer.walk(callback)
    },
    cancelWalk(callback) {
      this.$refs.timeplayer.cancelWalk(callback)
    },
    toggleLockMaps() {
      this.$store.commit('map/TOGGLE_LOCK_MAPS')
      let map1 = document.getElementById('leaflet-map-0')._leaflet_map
      map1.fire('move')
    },
    checkShowGroup(idLayer) {
      return this.arrIdLayerShowGroup.indexOf(idLayer) > -1
    },
    // code quandev18
    activeSidebarRight(component) {
      if (this.componentActive == component) {
        this.componentActive = null
      } else {
        this.componentActive = component
      }
    },
    autoEvent() {
      this.$refs.autoEventModal.open()
    },
    toggleSplit() {
      if (this.numberOfMap === 1) {
        this.$store.commit('map/SET_NUMBER_MAP', 2)
        // after split map, set the second mapBound to same as the first one
        this.$nextTick(() => {
          try {
            let map1 = document.getElementById('leaflet-map-0')._leaflet_map
            let center1 = map1.getCenter()
            let zoom1 = map1.getZoom()
            let map2 = document.getElementById('leaflet-map-1')._leaflet_map
            map2.setView(center1, zoom1, { animate: false })
            if (this.isDark) {
              this.$store.commit('map/SET_MAP_TILE', { tile: TILE_DARK, mapIndex: 1 })
            } else {
              this.$store.commit('map/SET_MAP_TILE', { tile: TILE_LIGHT, mapIndex: 1 })
            }
          } catch {}
        })
      } else {
        this.$store.commit('map/SET_NUMBER_MAP', 1)
      }
      this.$store.commit('map/UPDATE_CLICKING', {
        data: null,
        mapIndex: 1,
      })
    },
    downloadImageMap(buf) {
      let url = 'data:image/png;base64,' + buf.toString('base64')
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none;'
      a.href = url
      a.download = 'Ecoplotpro_Map.png'
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },
    changeLayerSelected(layer) {
      this.layerNameSelect = layer.label
      this.layerSelected = layer.value
    },
    funcChangeStatusTimePlayer(status) {
      if (status == 'playing' || status == 'pausing') {
        this.statusTimePlayer = status
      }
    },
    async fetchMeta(id) {
      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      let response = await axios.get(`${ECOPLOT_API}/projects/${id}/?share=true`, { headers: { Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/CHANGE_PROJECT', { project: response.data })
    },
    async fetchData(id) {
      let response = await axios.get(`${ECOPLOT_API}/file/${id}/download/`, { responseType: 'blob' })
      this.extractProject(new File([response.data], 'filename'))
    },
    exitMapBasedTimeseriesLoader() {
      this.mapBasedTimeseriesLoaderLocaltionIsActive = true
      this.$store.commit('mapBasedTimeseriesLoader/UPDATE_ACTIVE', false)
      this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION_ACTIVE_TEMP', null)
      this.$store.commit('mapBasedTimeseriesLoader/SET_LOCALTION_TEMP', [])
    },
    saveMapBasedTimeseriesLoader() {
      this.$refs.filesImport.open()
      let markerTemp = _.cloneDeep(this.mapBasedLocationsTemp)
      this.$store.commit('mapBasedTimeseriesLoader/SET_LOCALTION', markerTemp)
    },
  },
}
</script>

<style scoped lang="scss">
.map-container .look-map {
  position: absolute;
  top: 50%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: unset;
}
.map-card {
  overflow: hidden;
}

.map-container {
  display: flex;
  position: relative;
  overflow: hidden;
}

.map-container .map-list {
  display: flex;
  flex-grow: 1;
}

/* .map-container .sidebar-container {
  overflow: hidden;
  transition: width 0.5s;
  z-index: 400;
} */

.map-container .close-sidebar {
  position: absolute;
  top: 50%;
  z-index: 400;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateY(-50%);
  transition: right 0.5s;
  border-right: none !important;
}

.map-card .card-body {
  padding: 1rem;
}

.map-container .map-divider {
  width: 4px;
  background-color: transparent;
  position: relative;
  z-index: 401;
}

.map-container .map-divider button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.map-time-player {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
  }
}
</style>
<style lang="scss">
.map-card {
  margin-right: calc(-350px - 14px);
  transition: margin-right 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  .map-container {
    &-right {
      width: 350px;
      border-radius: 0.428rem;
      background: #fff;
      height: calc(100vh - 30px - 90.3px - 1rem - 22px);
      overflow: hidden;
      margin-left: 14px;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
  &.card {
    box-shadow: inherit !important;
    background: transparent !important;
  }
  & > .card-body {
    padding: 0 !important;
  }
  &.show-sidebar {
    margin-right: 0;
    .map-container {
      &-right {
        margin-left: 5px;
      }
    }
  }
}

.sidebar-right {
  position: absolute;
  top: 114px;
  left: 22px;
  z-index: 100000000000;
}
.map-list {
  position: relative;
  background: #fff;
  border-radius: 0.428rem;
  overflow: hidden;
  &-menu {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 402;
    display: flex;
    flex-direction: column;
    &-svg {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background 0.2s ease;
      svg.feather-bar-chart {
        stroke-width: 3;
      }
    }
    &-top {
      &-svg {
        background: #397bff;
        color: #fff;
        margin: 8px 0;
        border-radius: 4px;
        &.active {
          background: rgb(29 78 216);
        }
        &:hover {
          background: rgb(37 99 235);
        }
      }
    }
    &-bottom {
      border-radius: 4px;
      &-svg {
        color: #82868b;
        background: #fff;
        &:first-child {
          border-radius: 3px 3px 0 0;
        }
        &:last-child {
          border-radius: 0 0 3px 3px;
        }
        &.active {
          background: #eaeaea;
        }
        &:hover {
          background: #eaeaea;
        }
      }
    }
    &-download-capture {
      .btn.dropdown-toggle {
        color: inherit !important;
        background: transparent !important;
        padding: inherit !important;
        border: none !important;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          display: none !important;
        }
        &:focus,
        &.active,
        &:hover {
          background: inherit !important;
          border: none !important;
          color: inherit !important;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &-group-layer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 410;
    display: flex;
    align-items: center;
    .group-items-container {
      position: static;
    }
    &_layer {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 9px 0px;
      border-radius: 0.8rem;
      transition: box-shadow 0.2s ease;
      &:hover {
        box-shadow: 0 8px 25px -8px rgba(100, 100, 111, 0.5);
      }
      .feather-instagram {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        z-index: 2;
      }
      .btn-select-feather {
        .feather-chevron-right.rotate {
          transform: rotate(-90deg);
        }
      }
    }
    &_group {
      margin-left: 10px;
    }
  }
  .dropdown-select {
    button {
      border-radius: 1.5rem;
      font-size: 13px;
      background: #397bff !important;
      color: #fff !important ;
      padding: 8px 5px 8px 32px;
      .btn-select-text {
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .dropdown-menu {
      width: 300px;
      font-size: 13px;
      border: none;
      .dropdown-item-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 240px;
        display: inline-block;
      }
    }
  }
}
.map-based-timeseries-list-menu {
  position: absolute;
  top: 4px;
  right: 50px;
  z-index: 402;
  display: flex;
  flex-direction: column;
  &-top {
    &-svg {
      background: #fff;
      color: #000;
      margin: 8px 0;
      border-radius: 4px;
      &.active {
        color: #fff;
        background: rgb(29 78 216);
      }
      &:hover {
        color: #fff;
        background: rgb(37 99 235);
      }
    }
  }
}

.dark-layout {
  .map-list {
    background: #1b243e;
    &-menu {
      &-svg {
        box-shadow: none;
      }
      &-bottom {
        &-svg {
          background-color: #283046;
          &.active {
            background: #161d31;
          }
          &:hover {
            background: #161d31;
          }
        }
      }
    }
  }
  .map-container {
    &-right {
      background: #1b243e !important;
      .card {
        background: #1b243e !important;
      }
    }
    .sidebar-container .card {
      background: #1b243e !important;
    }
  }
  .map-based-timeseries-list-menu {
    &-top {
      &-svg {
        background-color: #283046;
        color: #fff;
        &.active {
          color: #fff;
          background: rgb(37 99 235);
        }
        &:hover {
          color: #fff;
          background: rgb(37 99 235);
        }
      }
    }
  }
}
.map-list-menu-save {
  &.disabled {
    opacity: 0, 6;
    pointer-events: none;
  }
}
</style>
