<template>
  <div class="shapeselect-container">
    <b-form-group>
      <b-dropdown variant="link" block toggle-class="text-decoration-none p-0" no-caret>
        <template #button-content>
          <b-form-input type="text" placeholder="type" :value="$t(selectedIcon.name)" size="sm" />
        </template> 
        <b-dropdown-item v-for="icon in icons" @click="selectedIcon = icon" :key="icon.name">
          <b-avatar :variant="selectedIcon.name === icon.name ? 'primary' : 'light-primary'" size="35">
            <feather-icon size="21" :icon="icon.icon" />
          </b-avatar>
        </b-dropdown-item>
      </b-dropdown>
    </b-form-group>
    <b-form-group v-if="selectedIcon.name === 'Spike'">
      <b-form-input v-model="noSpikes" type="number" min="3" placeholder="Spikes" size="sm" />
    </b-form-group>
  </div>
</template>
<script>
import * as SHAPES from '@/constants/shapes'

export default {
  props: ['shapeType', 'numSpikes'],
  data() {
    return {
      noSpikes: this.numSpikes || 5,
      selectedIcon: {},
      icons: [
        { name: SHAPES.CIRCLE, icon: 'CircleIcon' },
        { name: SHAPES.TRIANGLE, icon: 'TriangleIcon' },
        { name: SHAPES.SQUARE, icon: 'SquareIcon' },
        { name: SHAPES.SPIKE, icon: 'StarIcon' },
      ],
    }
  },
  mounted() {
    this.selectedIcon = this.icons.find(o => o.name === this.shapeType) || this.icons[0]
  },
  watch: {
    noSpikes() {
      this.handleChange()
    },
    selectedIcon() {
      this.handleChange()
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', { shapeType: this.selectedIcon.name, numSpikes: this.noSpikes })
    },
  },
}
</script>

<style>
.shapeselect-container .dropdown-menu {
  width: 192px;
  padding: 10px;
}
.shapeselect-container .dropdown-menu li { 
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.shapeselect-container .dropdown-menu li a:hover,
.shapeselect-container .dropdown-menu li a:active {
  background: none !important;
}
.shapeselect-container .dropdown-menu li:not(:last-child) {
  margin-right: 10px;
}
.shapeselect-container .dropdown-menu .dropdown-item {
  padding: 0px;
  width: 35px;
  height: 35px;
}
.shapeselect-container .dropdown-menu .dropdown-item img {
  width: 100%;
  height: 100%;
}
.shapeselect-container .dropdown input {
  transition: border-color 0.15s ease-in-out;
}
.shapeselect-container .dropdown.show input {
  border-color: #397bff;
}
</style>