<template>
  <div>
    <div id="draggableDatasource" class="draggableDatasource" :style="{ height: `${heightDraggable}px`, maxHeight: `${maxHeight}px` }">
      <div v-if="datasources.length" class="scrollable" :style="{ maxHeight: `${maxHeight}px` }">
        <Draggable handle=".handle" :animation="150" class="visiable" v-model="datasources" :style="{ transform: `translateY(${translateY}px)`, maxHeight: `${maxHeight}px` }">
          <DatasourceItem class="virtual-list-item" v-if="index >= startIdx && index < startIdx + keeps" v-for="(datasource, index) in datasources" :key="`${datasource.id}-${index}`" :datasource="datasource" />
        </Draggable>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
import Draggable from 'vuedraggable';
import DatasourceItem from './DatasourceItem.vue';
export default {
  name: 'virtual-drag-list-datasource-item',
  components: { Draggable, DatasourceItem },
  computed: {
    datasources: {
      get() {
        return this.$store.state.datasource.datasources;
      },
      set(datasources) {
        this.$store.commit('datasource/SET_DATASOURCES', datasources);
      },
    },
    routeName() {
      return this.$route.name;
    },
    heightDraggable() {
      let height = 30;
      try {
        const virtualListItem = document.querySelector('.draggableDatasource .virtual-list-item').getBoundingClientRect();
        if (virtualListItem && virtualListItem.height > 0) height = virtualListItem.height;
      } catch {}
      return this.datasources.length * height;
    },
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      const draggableDatasource = document.querySelector('.draggableDatasource');
      var height = 30;

      draggableDatasource.addEventListener('scroll', () => {
        try {
          const virtualListItem = document.querySelector('.draggableDatasource .virtual-list-item').getBoundingClientRect();
          height = virtualListItem.height;
        } catch {}
        const sTop = draggableDatasource.scrollTop;
        const topNum = Math.floor(sTop / height);
        const morePx = sTop % height;
        if (topNum + self.keeps >= self.datasources.length) return;
        self.startIdx = Math.max(topNum, 0);
        self.translateY = draggableDatasource.scrollTop - morePx;
      });
    });
  },
  data() {
    return {
      translateY: 0,
      startIdx: 0,
      keeps: 20,
      maxHeight: 270,
    };
  },
  watch: {
    routeName(route) {
      if (!this.datasources.length) return;
      if (route === 'map') {
        setTimeout(() => {
          try {
            let draggableDatasource = document.querySelector('.draggableDatasource');
            draggableDatasource.scrollTop = this.translateY;
          } catch {}
        }, 500);
      }
    },
    datasources(datasources) {
      if (!datasources.length) {
        this.startIdx = 0;
        this.translateY = 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
body {
  font-family: sans-serif;
}

.draggableDatasource {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 2.5px;
  }
}

.scrollable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.visiable {
  position: relative;
}

.visiable > div {
  text-align: center;
}
</style>
