<template>
  <div class="calendar-container" v-show="calendarShow" ref="calendar">
    <b-card header-bg-variant="primary" header-text-variant="white" :style="{ height: isOpen ? '150px' : '40px' }">
      <template #header>
        <div @mousedown="statusClick = true"  @mouseup="statusClick = false" class="calendar-header" :style="{ fontSize: isOpen ? '20px' : '14px' }">
          <div :title="$t('click_to_toggle_calendar')" class="calendar-unit cursor-pointer" @click="isOpen = !isOpen">{{ isOpen ? passedUnit || $t('unit_calender') : date[0] || '0000-00-00 00:00:00' }}</div>
        </div>
      </template>
      <div class="calendar-body">{{ passed || '0' }}</div>
      <div class="calendar-footer bg-primary text-white">{{ date[0] || '0000-00-00 00:00:00' }}</div>
    </b-card>
  </div>
</template>

<script>
const dayjs = require('dayjs');
import interact from 'interactjs';

export default {
  props: ['start'],
  data() {
    return {
      isOpen: true,
      passedUnit: null,
      statusClick: false,
    };
  },
  mounted() {
    var self = this;
    let calendar = interact(self.$refs.calendar);
    calendar.draggable({
      inertia: false,
      allowFrom: '.calendar-header',
      ignoreFrom: '.calendar-unit',
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: document.querySelector('.map-container .map-list'),
          endOnly: true,
        }),
      ],
      listeners: {
        move(event) {
          if (self.statusClick) {
            let x = (parseFloat(event.target.dataset.x) || 0) + event.dx;
            let y = (parseFloat(event.target.dataset.y) || 0) + event.dy;
            event.target.style.transform = `translate(${x}px, ${y}px)`;
            Object.assign(event.target.dataset, { x, y });
          }
        },
        end() {
          self.statusClick = false;
        },
      },
    });

    new ResizeObserver(() => {
      try {
        let element = document.querySelector('.calendar-container').style.transform;
        let widthMap = document.querySelector('.map-container .map-list').offsetWidth - 150;
        let heightMap = document.querySelector('.map-container .map-list').offsetHeight - 150;

        if (element && element !== '') {
          let arrayXY = element.split(',');
          let valueX = Number(arrayXY[0].replace(/[^0-9]/g, ''));
          let valueY = Number(arrayXY[1].replace(/[^0-9]/g, ''));

          if (valueX > widthMap) {
            valueX = widthMap;
            document.querySelector('.calendar-container').style.transform = `translate(${valueX}px, ${valueY}px)`;
            document.querySelector('.calendar-container').setAttribute('data-x', valueX);
          }
          if (valueY > heightMap) {
            valueY = heightMap;
            document.querySelector('.calendar-container').style.transform = `translate(${valueX}px, ${valueY}px)`;
            document.querySelector('.calendar-container').setAttribute('data-y', valueY);
          }
        }
        calendar.reflow({ name: 'drag', axis: 'xy' });
      } catch (error) {}
    }).observe(document.querySelector('.map-container .map-list'));
  },

  computed: {
    calendarShow() {
      return this.$store.state.settings.calendarShow;
    },
    calendarUnit() {
      return this.$store.state.settings.calendarUnit;
    },
    date() {
      return this.$store.state.map.date;
    },
    passed() {
      // const diffUnit = { yearly: 'years', monthly: 'months', daily: 'days', hourly: 'hours' };
      // const diffUnitName = { yearly: this.$i18n.t('years'), monthly: this.$i18n.t('months'), daily: this.$i18n.t('days'), hourly: this.$i18n.t('hours') };

      this.passedUnit = null;
      if (!this.date[0] || !this.start) return null;

      // let start = dayjs(this.start);
      // let end = dayjs(this.date[0]);
      // if (this.calendarUnit === 'auto') {
      //   for (const key in diffUnit) {
      //     let diff = end.diff(start, diffUnit['daily']);
      //     if (diff > 0 || key === 'hourly') {
      //       this.passedUnit = diffUnitName['daily'];
      //       return diff;
      //     }
      //   }
      // }

      // this.passedUnit = diffUnitName[this.calendarUnit];
      // return end.diff(start, diffUnit[this.calendarUnit]);

      let date = dayjs(this.date[0]).date();
      this.passedUnit = this.$i18n.t('date');
      return date;
    },
  },
};
</script>

<style scoped>
.calendar-container {
  position: fixed;
  top: calc(30px + 90.3px - 1rem + 10px);
  left: calc(260px + 1rem + 10px);
  z-index: 401;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  user-select: none;
  border-radius: 0.428rem;
  overflow: hidden;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .calendar-container {
  left: calc(80px + 1rem + 10px);
}

.vertical-layout.vertical-overlay-menu .calendar-container {
  left: calc(1rem + 10px);
}

.calendar-container .card {
  margin: 0;
  background-color: #fff;
  backdrop-filter: blur(0px);
  transition: all 0.25s, backdrop-filter 0s;
  width: 150px;
  overflow: hidden;
}

.dark-layout .calendar-container .card {
  background-color: rgba(22, 29, 49, 0.4);
  backdrop-filter: blur(0px);
}

.calendar-container:hover .card {
  background-color: #fff;
  backdrop-filter: blur(4px);
}

.dark-layout .calendar-container:hover .card {
  background-color: rgba(22, 29, 49, 0.7);
  backdrop-filter: blur(4px);
}

.calendar-container .card-header {
  padding: 0;
}

.calendar-container .calendar-header {
  padding: 10px 0;
  width: 100%;
  line-height: 20px;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}

.calendar-container .card-body {
  padding: 0;
}

.calendar-container .calendar-body {
  font-size: 50px;
  line-height: 90px;
}
.calendar-container {
  z-index: 420;
}
</style>
