<template>
  <div class="sidebar-container">
    <b-card>
      <!-- Layer select -->
      <!-- <div class="d-flex align-items-center" style="margin-bottom: 1rem">
        <b-form-group :class="`mb-0 flex-grow-1 dropdown-container vue-select-layer-${chartType}`" title="Select active layer">
          <vue-select v-model="layerSelected" direction="up" position="top" :reduce="(data) => data.value" :options="layerList" :appendToBody="true" class="select-dropdown select-size-sm" :placeholder="$t('select_layer')">
            <template #open-indicator>{{ null }}</template>
          </vue-select>
        </b-form-group>

        <feather-icon :icon="isOpen ? 'ChevronsUpIcon' : 'ChevronsDownIcon'" class="arrow-list-sidebar" size="16" @click="isOpen = !isOpen" />
      </div> -->

      <!-- Content -->
      <div class="option-select-sidebar" :countValue="countValue">
        <div class="d-flex justify-content-between">
          <!-- Select mode toolbar -->
          <b-form-group>
            <RadioGroup :name="`sidebar-chart-type`" :moveCount="moveCountChartType" v-if="chartType === 'chart'" :label="$t('sidebar_chart_type')" :options="typeList" v-model="typeSelected" />
          </b-form-group>

          <div v-if="chartType === 'chart'" class="settings-chart-btn" :title="$t('option_chart')">
            <span class="toolbar-title text-primary text-left-end">{{ $t('option_chart') }}</span>
            <div class="w-100 flex-1 icon-sidebart-settings" ref="iconSidebartSettings" @click.stop="showSideBarChart = true" :title="$t('option_chart')">
              <feather-icon icon="SettingsIcon" size="20" />
              <div class="settings-sidebar-chart-right-popup" :class="{ show: showSideBarChart }">
                <div class="settings-sidebar-chart-right-popup-content">
                  <b-row class="mb-1">
                    <b-col cols="12">
                      <div class="label-form-group">
                        <fill-icon class="color-icon" style="width: 16px; height: 16px; margin-right: 10px; color: #397bff; transform: rotate(-270deg)" /><span>{{ $t('chart_color') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <ColorPicker v-model="chartColor" :placeholder="$t('chart_color')" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <div class="label-form-group">
                        <fill-icon class="color-icon" style="width: 16px; height: 16px; margin-right: 10px; color: #397bff; transform: rotate(-270deg)" /><span>{{ $t('background_color') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <ColorPicker v-model="backgroundColor" :placeholder="$t('background_color')" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <div class="label-form-group">
                        <color-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_color') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <ColorPicker v-model="chartTextColor" :placeholder="$t('text_color')" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <div class="label-form-group">
                        <family-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /> <span>{{ $t('font_family') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <vue-select :options="chartFontFamilyChoices" v-model="chartFontFamily" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <div class="label-form-group">
                        <size-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_size') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <b-form-input v-model="chartTextSize" :placeholder="$t('text_size')" type="number" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <div class="text-right text-end">
                        <b-button variant="flat-secondary" @click="resetToDefault">{{ $t('reset_to_default') }}</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
          <!-- Image-list toolbar -->
          <div class="d-flex box-selece-image-sidebar" :class="{ 'pt-15': chartType === 'chart' }" v-if="typeSelected === 'image' && layer">
            <div>
              <!-- Count images -->
              <b-badge class="count-number-image-sidebar" variant="light-primary">{{ imageData.length }} /{{ totalImagePopup }} </b-badge>
              <div :style="{ justifyContent: 'flex-end' }" class="d-flex">
                <!-- Clear filter -->
                <b-badge v-if="dataFilterShow.length !== 0" class="clearfilter-button" variant="light-danger" @click="clearFilter" :title="$t('clear_filter')">
                  <svg :style="{ width: '16px', height: '16px', fill: '#ea5455' }" class="tool-icon text-danger" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g><path d="m11 21.4c-.4 0-.7-.1-1.1-.3-.6-.4-.9-1-.9-1.7v-6l-5.4-5.4c-.4-.4-.6-.9-.6-1.4v-1.6c0-1.1.9-2 2-2h2.5c.6 0 1 .4 1 1s-.4 1-1 1h-2.5v1.6l5.4 5.4c.4.4.6.9.6 1.4v6l2-1v-5c0-.5.2-1 .6-1.4l1.2-1.2c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-1.2 1.2v5c0 .8-.4 1.4-1.1 1.8l-2 1c-.3.1-.6.2-.9.2zm7-11.4c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.7-1.7v-1.6h-6c-.6 0-1-.4-1-1s.4-1 1-1h6c1.1 0 2 .9 2 2v1.6c0 .5-.2 1-.6 1.4l-1.7 1.7c-.2.2-.4.3-.7.3z" /></g>
                    <g><path d="m19 16c-.3 0-.5-.1-.7-.3l-14-14c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l14 14c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" /></g>
                  </svg>
                </b-badge>
                <!-- Delete all images -->
                <b-badge class="clearfilter-button" variant="light-danger" @click="openRemoveAllImageConfirm" :title="$t('delete')">
                  <feather-icon class="remove-all-image cursor-pointer text-danger" icon="Trash2Icon" size="16" />
                </b-badge>
                <ImageDeleteAllConfirmComponent ref="ImageDeleteAllConfirm" :layer="layer" />
              </div>
            </div>
            <div>
              <!-- Upload images -->
              <b-button class="upload-image-sidebar" @click="toggleImage" variant="outline-primary" :title="$t('upload_image_sidebar')">
                <feather-icon icon="ImageIcon" />
              </b-button>
            </div>
          </div>
        </div>

        <!-- Timeseries mode 2nd toolbar -->
        <div v-if="typeSelected === 'timeseries'" class="d-flex">
          <b-form-group>
            <RadioGroup :name="`sidebar-chart-scale`" :moveCount="moveCountChartType" :label="$t('sidebar_chart_scale')" :options="axisRangeChoices" v-model="yAxisRange" />
          </b-form-group>
          <b-form-group class="ml-1">
            <RadioGroup :name="`sidebar-lock-draw-sidebar`" :label="$t('lock_draw_sidebar')" :toggle="true" :options="optionBlock" v-model="blockZoomAll" />
          </b-form-group>
        </div>

        <!-- Image mode filter toolbar -->
        <div class="d-flex box-filter-image-sidebar" v-if="(typeSelected === 'image' && layer && dataFilterShow.length) || (typeSelected === 'image' && layer && dataFilterShow.length)">
          <div v-for="(filter, index) in dataFilterShow" :key="index">
            <FilterMetadata @getDatafilter="getDataFilter" :value="selectedFilter" :options="filter" :name="filter.text" />
          </div>
        </div>

        <!-- Loading -->
        <div class="loading-import-image" v-if="showLoadingImage">
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </div>

        <!-- Virtual list mode components -->
        <VirtualList class="chart-container custom-scrollbar timeseries-container" ref="timeseriesList" v-if="typeSelected === 'timeseries'" :data-key="'id'" :data-sources="timeseriesData" :data-component="timeseriesComponent" :keeps="15" :extra-props="{ layerSelected, hovering, date, clicking, globalRange, blockZoomAll, itemsLayer: layer && layer.item ? layer.item : '' }" />
        <VirtualList class="chart-container custom-scrollbar boxplot-container" ref="boxplotList" v-if="typeSelected === 'boxplot'" :data-key="'id'" :data-sources="boxplotData" :data-component="boxplotComponent" :keeps="15" :extra-props="{ layerSelected, hovering, date, clicking, globalRange, blockZoomAll }" />
        <VirtualList class="chart-container custom-scrollbar image-container" ref="imageList" v-if="typeSelected === 'image'" :data-key="'id'" :data-sources="imageData" :data-component="imageComponent" :keeps="30" @scroll="onImageListScroll" :extra-props="{ layerSelected, hovering, clicking, lightBoxSideBar }" />
      </div>
    </b-card>
    <input type="file" ref="imageUploadSidebar" multiple style="display: none" @change="uploadImageEvt" accept=".zip" />
  </div>
</template>

<script>
import MathMixin from '@/mixins/MathMixin.js'
const _ = require('lodash')
import { METADATA_POPUP } from '@/constants/metadata.js'
import { ACTION_LOG } from '@/constants/actionLog'
import VirtualList from 'vue-virtual-scroll-list'
import { splitByLastIndex } from '@/utilities/StringUtility.js'
import RadioGroup from '../../../common/RadioGroup.vue'
import TimeseriesComponent from './TimeseriesComponent.vue'
import BoxplotComponent from './BoxplotComponent.vue'
import ImageComponent from './ImageComponent.vue'
import FilterMetadata from './FilterMetadataComponent.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import ImageMixin from '@/mixins/ImageMixin'
import ImageDeleteAllConfirmComponent from './ImageDeleteAllConfirmComponent.vue'
import ColorPicker from '@/views/ecoplot-desktops/common/ColorPicker.vue'

// icon
import FamilyIcon from '/static/images/layer-config/prepend/font-family.svg'
import ColorIcon from '/static/images/layer-config/prepend/font-color.svg'
import SizeIcon from '/static/images/layer-config/prepend/font-size.svg'
import FillIcon from '/static/images/layer-config/prepend/fill2.svg'
export default {
  components: { RadioGroup, VirtualList, FilterMetadata, ImageDeleteAllConfirmComponent, ColorPicker, FamilyIcon, FillIcon, ColorIcon, SizeIcon },
  mixins: [ToastificationContent, ImageMixin, MathMixin],
  props: {
    chartType: {
      type: String,
      default: 'chart',
    },
    countValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: false,
      lastRender: '', // Last render string, compare to check need to render charts again
      typeList: [
        // { value: 'image', icon: require('/static/images/timeseries/image.svg').default },
        { value: 'timeseries', icon: require('/static/images/timeseries/lines.svg').default },
        { value: 'boxplot', icon: require('/static/images/boxplot/box.svg').default },
      ],
      typeSelected: 'timeseries',
      optionBlock: [{ text: 'off', value: true, icon: require('/static/images/timeseries/block.svg').default }],
      blockZoomAll: false,
      timeseriesComponent: TimeseriesComponent,
      boxplotComponent: BoxplotComponent,
      imageComponent: ImageComponent,
      timeseriesData: [],
      boxplotData: [],
      imageData: [],
      filterNatural: [],
      filterFuntion: [],
      filterPlance: [],
      filterProcess: [],
      filterState: [],
      filterMeasurement: [],
      flagImage: false,
      lightbox: null,
      showLoadingImage: false,
      listFilter: null,
      lightBoxSideBar: null,
      imageListOffset: 0,
      selectedFilter: {},
      valueScroll: { image: 0, timeseries: 0, boxplot: 0 },
      moveCountChartType: 0,
      showSideBarChart: false,

      chartFontFamilyChoices: ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace'],
    }
  },
  mounted() {
    const self = this
    if (this.chartType !== 'chart') {
      this.typeSelected = 'image'
    }
    this.$nextTick(() => {
      if (this.chartType == 'chart') {
        const element = this.$refs.iconSidebartSettings
        document.addEventListener('mousedown', (event) => {
          setTimeout(() => {
            try {
              if (!element.contains(event.target)) {
                this.showSideBarChart = false
              }
            } catch {}
          }, 300)
        })
      }
    })
  },
  computed: {
    chartTextSize: {
      get() {
        return this.$store.state.settings.chartTextSize
      },
      set(chartTextSize) {
        this.$store.commit(`settings/UPDATE_SETTINGS`, { chartTextSize })
      },
    },
    chartTextColor: {
      get() {
        return this.$store.state.settings.chartTextColor
      },
      set(chartTextColor) {
        this.$store.commit(`settings/UPDATE_SETTINGS`, { chartTextColor })
      },
    },
    chartFontFamily: {
      get() {
        return this.$store.state.settings.chartFontFamily
      },
      set(chartFontFamily) {
        this.$store.commit(`settings/UPDATE_SETTINGS`, { chartFontFamily })
      },
    },
    optionSetting() {
      return [{ title: this.$i18n.t('chart'), icon: 'BarChart2Icon', value: 'chart' }]
    },
    dataFilter() {
      return this.$store.state.settings.dataFilter
    },
    dataFilterShow() {
      // if (this.isOpen) this.selectedFilter = {};
      if (this.dataFilter && this.dataFilter[0].children.length !== 0) {
        let arrayObjFilter = this.dataFilter[0].children.filter((obj) => obj.visible === 'show')
        return arrayObjFilter
      } else {
        return []
      }
    },
    mapActive() {
      return this.$store.state.map.mapActive
    },
    totalImagePopup() {
      return this.popupImages.length
    },
    listChangeListPopup() {
      return this.$store.state.image.popupImages
    },
    hovering() {
      this.mapActive
      return this.$store.state.map.hovering[this.mapActive]
    },
    popupImages() {
      if (!this.layer) return []
      return this.$store.state.image.popupImages.filter((data) => data.layerId === this.layer.id)
    },
    clicking() {
      this.mapActive
      return this.$store.state.map.clicking[this.mapActive]
    },
    lineColor() {
      return this.$store.state.settings.lineColor
    },
    backgroundColor: {
      get() {
        return this.$store.state.settings.backgroundColor
      },
      set(backgroundColor) {
        this.$store.commit('settings/UPDATE_SETTINGS', { backgroundColor: backgroundColor })
      },
    },
    chartColor: {
      get() {
        return this.$store.state.settings.chartColor
      },
      set(chartColor) {
        this.$store.commit('settings/UPDATE_SETTINGS', { chartColor: chartColor })
      },
    },
    date() {
      return this.$store.state.map.date
    },
    layers() {
      return this.$store.state.layer.layers
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    layerList() {
      return this.layers.filter((layer) => ['point', 'multi', 'netcdf'].includes(layer.type)).map((layer) => ({ value: layer.id, label: layer.name }))
    },
    axisRangeChoices() {
      return [
        { text: this.$i18n.t('original'), value: 'same' },
        { text: this.$i18n.t('normalize'), value: 'normalize' },
      ]
    },
    yAxisRange: {
      get() {
        return this.$store.state.settings.yAxisRange
      },
      set(value) {
        this.$store.commit('settings/UPDATE_SETTINGS', { yAxisRange: value })
      },
    },
    layerSelected: {
      get() {
        return this.$store.state.map.layerSelected
      },
      set(value) {
        this.$store.commit('map/SET_LAYER_SELECTED', value)
      },
    },
    layer() {
      if (!this.layerSelected) return null
      let layer = this.layers.find((l) => l.id === this.layerSelected)
      return layer
    },
    datasource() {
      if (!this.layer) return
      return this.datasources.find((d) => d.id === this.layer.dataTimeseries)
    },
    timeseries() {
      if (!this.layer) return
      return this.$db[this.layer.dataTimeseries]
    },
    liveMode() {
      return this.datasource ? this.datasource.live : false
    },
    // refreshTime() {
    //   return this.$store.state.map.refreshTime;
    // },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    shouldRender() {
      // this.isOpen; // Watch when user toggle open
      if (this.typeSelected !== 'image') {
        if (!this.layer || !this.layer.dataTimeseries || !this.layer.item) return 'clear' // No layer, clear command
        return this.layer.dataTimeseries + '_' + this.layer.item + '_' + this.typeSelected + '_' + this.yAxisRange + '_' + this.layer.scale.value
      } else {
        if (!this.layer) return 'clear' // No layer, clear command
        return this.layer.dataTimeseries + '_' + this.layer.item + '_' + this.typeSelected + '_' + this.yAxisRange + '_' + this.layer.scale.value
      }
    },
    idMultiSelected() {
      let id = 'ID'
      try {
        id = this.layer.idMultiSelected ? this.layer.idMultiSelected : 'ID'
      } catch {}
      return id
    },
  },
  asyncComputed: {
    globalRange: {
      default: [null, null],
      async get() {
        let item = this.layer ? this.layer.item : null
        if (!this.timeseries || !item) return [null, null]
        if (this.layer && this.layer.scale && this.layer.scale.value) {
          item = `${item}_${this.layer.scale.value}`
        }
        if (this.timeseries.items && this.timeseries.items[item]) {
          return [this.timeseries.items[item].min, this.timeseries.items[item].max]
        }
        try {
          if (!this.timeseries.minMaxItems) {
            this.timeseries.minMaxItems = _.cloneDeep(this.timeseries.items)
          }
        } catch {}
        try {
          if (this.timeseries.minMaxItems[item] && this.timeseries.minMaxItems[item].min != null && this.timeseries.minMaxItems[item].max != null) {
            return [this.timeseries.minMaxItems[item].min, this.timeseries.minMaxItems[item].max]
          }
        } catch {}
        let { min, max } = await this.getNewMinMaxRange()
        return [min, max]
      },
    },
  },
  watch: {
    countValue: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.moveCountChartType++
        }, 600)
      },
    },
    chartTextSize() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
    chartTextColor() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
    chartFontFamily() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
    chartColor() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
    backgroundColor() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
    layerSelected(layer) {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      else if (this.typeSelected === 'image') this.getImageData()

      for (let item of this.layerList) {
        if (item.value == layer) {
          let messageTitle = 'selected_layer'
          let messageLog = `: '${item.label}'`
          let message = this.$t(messageTitle) + messageLog
          this.log.info({ message, id: ACTION_LOG[20].id, messageTitle, messageLog })
          // this.log.info({ message: `Selected layer: '${item.label}'`, id: ACTION_LOG[20].id });
          break
        }
      }
    },
    typeSelected() {
      if (document.querySelector('.chart-container.image-container')) this.valueScroll.image = document.querySelector('.chart-container.image-container').scrollTop
      if (document.querySelector('.chart-container.timeseries-container')) this.valueScroll.timeseries = document.querySelector('.chart-container.timeseries-container').scrollTop
      if (document.querySelector('.chart-container.boxplot-container')) this.valueScroll.boxplot = document.querySelector('.chart-container.boxplot-container').scrollTop
      setTimeout(() => {
        if (this.typeSelected === 'image') document.querySelector('.chart-container.image-container').scrollTop = this.valueScroll.image
        if (this.typeSelected === 'timeseries') document.querySelector('.chart-container.timeseries-container').scrollTop = this.valueScroll.timeseries
        if (this.typeSelected === 'boxplot') document.querySelector('.chart-container.boxplot-container').scrollTop = this.valueScroll.boxplot
      }, 0)
    },
    listChangeListPopup: {
      deep: true,
      handler() {
        // if (!this.isOpen) return;
        this.getImageData()
        this.listFilter = this.getlistCountFilter(this.popupImages)
      },
    },
    dataFilter() {
      // if (!this.isOpen) return;
      this.listFilter = this.getlistCountFilter(this.popupImages)
    },
    selectedFilter() {
      this.getImageData()
    },
    blockZoomAll(value) {
      this.optionBlock[0].text = value == true ? 'on' : 'off'
    },
    lineColor() {
      document.documentElement.style.setProperty('--line-color', this.lineColor)
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
    layerList(list) {
      if (!list.some((layer) => layer.value === this.layerSelected)) {
        this.layerSelected = null
      }
    },
    clicking(clicking) {
      if (!clicking || clicking.layerId !== this.layerSelected || clicking.source !== 'map') return

      let locationIndex = this.timeseriesData.findIndex((d) => d.customName === clicking.clickId && d.customUid === clicking.clickUid)
      if (locationIndex === -1) return
      try {
        this.$refs.timeseriesList.scrollToIndex(locationIndex)
        this.$refs.boxplotList.scrollToIndex(locationIndex)
      } catch (error) {
        // Silent error because not important when scroll
      }
    },
    /** Auto refresh when map in live-mode */
    // refreshTime() {
    //   if (this.typeSelected === 'timeseries') this.getTimeseriesData();
    //   else if (this.typeSelected === 'boxplot') this.getBoxplotData();
    // },
    countLiveMode() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
    },
    /** Watch "shouldRender" and decide to render component or not */
    shouldRender: {
      deep: true,
      handler(value) {
        // if (!this.isOpen) return; // No render when SideBar is closed.

        // Clear command: clear all renderers
        if (value == 'clear') {
          this.timeseriesData = []
          this.boxplotData = []
          this.imageData = []
          this.lastRender = value
          return
        }

        // Render: check should render by new value difference with lastRender
        if (value != this.lastRender) {
          if (this.typeSelected === 'timeseries') this.getTimeseriesData()
          else if (this.typeSelected === 'boxplot') this.getBoxplotData()
          else if (this.typeSelected === 'image') {
            this.getImageData()
            this.refreshVirtualList()
            this.listFilter = this.getlistCountFilter(this.popupImages)
          }
          this.lastRender = value
        }
      },
    },
    idMultiSelected() {
      if (this.typeSelected === 'timeseries') this.getTimeseriesData()
      else if (this.typeSelected === 'boxplot') this.getBoxplotData()
      // else if (this.typeSelected === 'image') this.getImageData();
    },
  },
  methods: {
    resetToDefault() {
      this.chartFontFamily = 'Noto Sans JP'
      this.chartTextColor = '#d0d2d6'
      this.chartTextSize = 14
      this.backgroundColor = '#ffffff'
      this.chartColor = '#397bff'

      this.$toast({ component: ToastificationContent, props: { title: this.$t('settings_reset_successfully'), icon: 'RotateCcwIcon', variant: 'success' } })
    },
    clSettingsChart() {
      this.$refs.modalSidebarChartSettings.show()
    },
    // #region Data
    /** Timeseries get data */
    async getTimeseriesData() {
      if (!this.datasource) return // Not select timeseries datasource yet
      let arrLocaltion = []
      let data = []
      const item = this.layer.item
      const locationUids = await this.getLocationUids()
      const locationsItems = locationUids.map((l) => splitByLastIndex(l, ' - ')[0] + '-' + item)
      const records = await this.selectAllByLocationsItems(this.datasource.id, locationsItems, this.layer.scale.value)
      let x = _.clone(this.timeseries.dates) || []
      // Live data
      let liveRecords = []
      if (this.liveMode) {
        liveRecords = await this.selectAllByLocationsItems(this.datasource.id, locationsItems, 'live')
        if (liveRecords.length != 0) {
          const dates = liveRecords['dates']
          if (dates) x = x.concat(dates)
        }
      }

      for (const locationUid of locationUids) {
        const [location, uid] = splitByLastIndex(locationUid, ' - ')
        const locationItem = location + '-' + item
        let y = _.clone(records[locationItem]) || []
        // Live data
        if (this.liveMode) {
          const liveY = liveRecords[locationItem]
          if (liveY) y = y.concat(liveY)
        }
        if (!location || arrLocaltion.indexOf(locationItem) > -1) continue
        data.push({ id: location, x, y, name: '', customName: location, customUid: uid === 'undefined' ? undefined : uid, type: 'scatter', connectgaps: false, mode: 'lines', fill: 'tozeroy', hovertemplate: `(%{x}: %{y}, ${item})<extra></extra>`, customData: { lineColor: this.lineColor, backgroundColor: this.backgroundColor, chartColor: this.chartColor, yAxisRange: this.yAxisRange } })
        arrLocaltion.push(locationItem)
      }
      this.timeseriesData = data
    },
    /** Boxplot get data */
    async getBoxplotData() {
      if (!this.datasource) return // Not select timeseries datasource yet

      let data = []
      const locationUids = await this.getLocationUids()
      const item = this.layer.item
      const locationsItems = locationUids.map((l) => splitByLastIndex(l, ' - ')[0] + '-' + item)
      const records = await this.selectAllByLocationsItems(this.datasource.id, locationsItems, this.layer.scale.value)
      let arrLocaltion = []
      for (const locationUid of locationUids) {
        const [location, uid] = locationUid.split(' - ')
        const locationItem = location + '-' + item
        if (!location || arrLocaltion.indexOf(locationItem) > -1) continue
        const x = records[locationItem]
        data.push({
          id: location,
          x: x,
          y: item,
          name: item,
          customName: location,
          customUid: uid === 'undefined' ? undefined : uid,
          orientation: 'h',
          type: 'box',
          customData: { lineColor: this.lineColor, backgroundColor: this.backgroundColor, chartColor: this.chartColor, yAxisRange: this.yAxisRange },
        })
        arrLocaltion.push(locationItem)
      }
      this.boxplotData = data
    },
    /** Get all location's UUIDs */
    async getLocationUids() {
      let locationUids = []
      if (this.layer.type === 'point' && this.layer.dataPoint) {
        locationUids = (await this.getAllPoints(this.layer.dataPoint)).map((record) => `${record.name} - ${undefined}`)
      } else if (this.layer.type === 'multi' && this.layer.dataMulti) {
        locationUids = (await this.getGeojson(this.layer.dataMulti)).features.map((d) => `${d.properties[this.idMultiSelected]} - ${d.properties.UID}`)
      }
      locationUids = [...new Set(locationUids)]
      return locationUids
    },
    /** Get new min/max range base on data */
    async getNewMinMaxRange() {
      //GET NEW MIN-MAX IF SCALE VALUE OF MAP ! null
      let minValueItem = Infinity
      let maxValueItem = -Infinity
      try {
        if (this.layer.scale.value) {
          let locations = Object.keys(this.timeseries.columns)
            .filter((pair) => pair.endsWith(`*${this.layer.item}`))
            .map((pair) => pair.split('*')[0])
          const locationsItems = _.map(locations, (l) => l + '-' + this.layer.item)
          let allRecord = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationsItems, this.layer.scale.value)
          for (let key in allRecord) {
            if (key != 'dates') {
              let allRecordValueTempItemsFilterNA = allRecord[key].filter((bt) => !this.isNaValue(bt))
              minValueItem = Math.min(minValueItem, _.min([].concat(...allRecordValueTempItemsFilterNA)))
              maxValueItem = Math.max(maxValueItem, _.max([].concat(...allRecordValueTempItemsFilterNA)))
            }
          }

          // minValueItem = Math.min(minValueItem, _.min([].concat(...allRecordValue)));
          // maxValueItem = Math.max(maxValueItem, _.max([].concat(...allRecordValue)));
          let item = `${this.layer.item}_${this.layer.scale.value}`
          let unit = ''
          try {
            unit = this.timeseries.items[this.layer.item].unit
          } catch {}
          try {
            this.timeseries.minMaxItems[item] = { min: minValueItem, max: maxValueItem, unit: unit }
          } catch {}
        }
      } catch {}
      return { min: minValueItem, max: maxValueItem }
    },
    // #endregion

    // #region Images
    /** Images get data */
    getImageData() {
      this.imageData = this.filterImageMetadata(this.popupImages, this.selectedFilter, 'sidebar')
      this.refreshVirtualList()
    },
    getDataFilter() {
      let obj = _.cloneDeep(this.selectedFilter)
      this.selectedFilter = {}
      this.selectedFilter = obj
    },
    /** Remove all images */
    openRemoveAllImageConfirm() {
      this.$refs.ImageDeleteAllConfirm.open()
    },
    /** Clear all filters */
    clearFilter() {
      let checkFilter = this.checkStatusFilter()
      if (checkFilter) this.selectedFilter = {}
    },
    checkStatusFilter() {
      for (const property in this.selectedFilter) {
        let arrayChilren = this.selectedFilter[property]
        if (arrayChilren && arrayChilren.length !== 0) {
          this.showFilter = true
          return true
        }
      }
      return false
    },
    /** Upload images */
    toggleImage() {
      this.$refs.imageUploadSidebar.click()
    },
    async uploadImageEvt() {
      let imageData
      try {
        imageData = await this.uploadImages({ layerId: this.layer.id }, METADATA_POPUP, this.$refs.imageUploadSidebar.files)
      } catch {}
      this.showLoadingImage = false
      if (imageData) {
        this.$store.commit('image/ADD_POPUP_IMAGE', imageData)
      }
    },
    /** Save current image-list scroll position */
    onImageListScroll() {
      this.imageListOffset = this.$refs.imageList.getOffset()
    },
    /** Scroll to previous position */
    refreshVirtualList() {
      try {
        setTimeout(() => {
          // wait for virtual-list render
          if (!this.$refs.imageList) return
          this.$refs.imageList.scrollToOffset(this.imageListOffset)
        }, 0)
      } catch (error) {
        console.log(error)
      }
    },
    // #endregion
  },
}
</script>

<style lang="scss">
/* Global select color */
:root {
  --line-color: #ff9f43;
}

/* Virtual list */
.sidebar-container .image-container div[role='group'] {
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  display: flex;
}
.sidebar-container .image-container div[role='listitem'] {
  position: relative;
  flex: 0 0 50%;
  height: 122px; /** ratio 4:3 162:122 **/
}

/* glightbox */
.glightbox-container .box-key-metadata {
  font-weight: 600;
  padding-top: 10px;
  color: #000 !important;
  user-select: text;
}
.glightbox-container .key-metadata {
  padding-left: 16px !important;
}

.glightbox-container .box-value-metadata {
  user-select: text;
}

.glightbox-container .box-value-metadata span {
  color: #000000 !important;
  font-weight: bold;
}

/* Vue Select overwrite */
.sidebar-container .vs__dropdown-toggle {
  border-color: transparent !important;
}
.sidebar-container .vs__actions {
  padding: 0 !important;
}
.sidebar-container .vs__actions .vs__clear {
  margin: 0;
  display: flex;
}
.sidebar-container .vs__dropdown-toggle {
  background-color: transparent !important;
}

/* Focus to active animation */
.focus-to-active {
  animation: shadow-pulse 1s 3 ease-in-out;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0 0 10px var(--line-color);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}
.settings-sidebar-chart-right-popup.show {
  .vs__dropdown-toggle {
    border-color: #d8d6de !important;
  }
}
.dark-layout {
  .settings-sidebar-chart-right-popup.show {
    .vs__dropdown-toggle {
      border-color: #404656 !important;
    }
  }
}
</style>

<style scoped lang="scss">
.sidebar-container .remove-all-image {
  align-self: flex-end;
  width: 16px;
  height: 16px;
}
.sidebar-container .option-select-sidebar {
  padding: 5px 10px 10px;
}
.sidebar-container .arrow-list-sidebar {
  cursor: pointer;
  margin: 0 10px;
  min-width: 16px;
}
.sidebar-container .box-selece-image-sidebar {
  text-align: right;
}
.sidebar-container .box-selece-image-sidebar.pt-15 {
  padding-top: 15px;
}
.sidebar-container .count-number-image-sidebar {
  margin-top: 5px;
  margin-right: 5px;
}
.sidebar-container .upload-image-sidebar {
  margin-top: 5px;
  align-self: center;
  padding: 10px;
  width: 42px;
  height: 42px;
  border: 1px solid rgba(115, 103, 240, 0.24) !important;
}
.sidebar-container .box-filter-image-sidebar {
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  padding: 4px;
  display: flex;
  position: relative;
  margin-bottom: 5px;
}
.all-image-lightbox {
  display: none;
}
.loading-import-image {
  width: 330px;
  display: flex;
  position: absolute;
  z-index: 1000;
  /* height: calc(100vh - 90.3px - 2rem - 10px - 35px - 10px - 76px - 44px - 1rem - 10px - 70px); */
  align-items: center;
  justify-content: center;
  opacity: 0.85;
  backdrop-filter: blur(2px);
}
.sidebar-container {
  height: 100%;
  /* position: absolute;
  top: calc(1rem + 10px);
  right: calc(1rem + 10px);
  z-index: 401;
  width: 350px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.428rem;
  overflow: hidden; */
}

.sidebar-container .card {
  background-color: #fff;
  backdrop-filter: blur(0px);
  margin-bottom: 0;
  overflow: hidden;
  transition: all 0.25s, backdrop-filter 0s, max-height 0.3s;
  height: 100%;
}
.sidebar-container:hover .card {
  background-color: #fff;
  backdrop-filter: blur(4px);
}
.dark-layout .sidebar-container .card {
  background-color: rgba(22, 29, 49, 1);
  backdrop-filter: unset;
}
.dark-layout .sidebar-container:hover .card {
  background-color: rgba(22, 29, 49, 0.7);
  backdrop-filter: blur(4px);
}
.dark-layout .sidebar-container.opening .card {
  background-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}
.sidebar-container .card-body {
  padding: 0;
}

.sidebar-container .chart-container {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sidebar-container .chart-container.image-container {
  margin-top: 15px;
  height: calc(100vh - 30px - 90.3px - 1rem - 22px - 64px);
}
.sidebar-container .chart-container.timeseries-container {
  height: calc(100vh - 30px - 90.3px - 1rem - 22px - 150px);
}
.sidebar-container .chart-container.boxplot-container {
  height: calc(100vh - 30px - 90.3px - 1rem - 22px - 82px);
}

.clearfilter-button {
  padding: 1px 3px;
  display: block;
  margin-top: 3px;
  margin-right: 5px;
  cursor: pointer;
  background-color: transparent !important;
}
.clearfilter-button:hover {
  background-color: rgba(234, 84, 85, 0.12) !important;
}
.dropdown-container {
  position: relative;
}

.select-dropdown .dropdown-menu {
  position: absolute;
  top: auto;
  bottom: 100%;
}
.settings-chart-btn {
  display: flex;
  flex-direction: column;
  text-align: end;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 1rem;
}
.icon-sidebart-settings {
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  width: 45px !important;
  height: 40px;
  margin-left: auto;
  cursor: pointer;
}
.text-left-end {
  text-align: end;
  margin-left: auto;
}
.settings-sidebar-chart-right-popup {
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  background: red;
  z-index: 999999999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: top linear 0.1s;
  backdrop-filter: blur(2px);
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  background-color: rgba(57, 123, 255, 0.12) !important;
  padding: 10px;
  &-content {
    padding: 10px 10px 10px !important;
    background-color: #fff;
    border-radius: 7px;
    width: 320px;
    text-align: start;
  }
  &.show {
    top: calc(100% + 5px);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}
.dark-layout {
  .settings-sidebar-chart-right-popup {
    &-content {
      background-color: #283046;
    }
  }
}
.label-form-group {
  padding-bottom: calc(0.438rem + 1px);
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #808080;
}
</style>
