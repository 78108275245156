<template>
  <div class="timeseries-chart-container">
    <div class="timeseries-chart-item row" style="padding: 0px 15px; margin-bottom: 5px">
      <div class="box-image-sidebar" style="position: relative">
        <div style="right: 5px; position: absolute; opacity: 0" class="btn-flat-danger icon-control-image" :title="$t('delete')" @click="deleteImage(source.id)">
          <feather-icon icon="Trash2Icon" size="16" class="cursor-pointer text-danger" />
        </div>
        <div style="right: 25px; position: absolute; opacity: 0" class="btn-flat-danger icon-control-image" :title="$t('replace_files')" @click="toggleReplaceImage(source.id)">
          <feather-icon :style="{ color: $parent.$parent.$parent.layer.popup.contentColorBorderless + '!important' }" icon="RefreshCcwIcon" size="16" class="cursor-pointer text-light" />
        </div>
        <div style="right: 45px; position: absolute; opacity: 0" class="btn-flat-danger icon-control-image" :title="$t('replace_files')" @click="showLightBox(source.id)">
          <feather-icon :style="{ color: $parent.$parent.$parent.layer.popup.contentColorBorderless + '!important' }" icon="MaximizeIcon" size="16" class="cursor-pointer text-light" />
        </div>
        <div class="image-metadata-sidebar" @click="selectMetadataPopup(source.metadata, index, source.id)" :style="{ backgroundImage: `url(${getImageSourceById(source.id)})` }"></div>
      </div>
    </div>
    <input type="file" ref="replaceImage" style="display: none" @change="replaceImage" accept=".png, .jpeg, .jpg" />
  </div>
</template>

<script>
const _ = require('lodash')
import 'glightbox/dist/css/glightbox.css'
import ImageMixin from '@/mixins/ImageMixin'

export default {
  props: ['index', 'source'],
  mixins: [ImageMixin],
  data() {
    return {
      idImageReplace: null,
    }
  },
  computed: {
    chartFontFamily() {
      return this.$store.state.settings.chartFontFamily
    },
    chartTextColor() {
      return this.$store.state.settings.chartTextColor
    },
    chartTextSize() {
      return this.$store.state.settings.chartTextSize
    },
  },
  methods: {
    selectMetadataPopup(metadata, index, id) {
      this.$parent.$parent.$parent.showMetadata = _.cloneDeep(metadata)
      this.$parent.$parent.$parent.indexImageMetadata = index
      this.$parent.$parent.$parent.imageIdSelected = id
    },
    toggleReplaceImage(id) {
      this.idImageReplace = id
      this.$refs.replaceImage.click(id)
    },
    async replaceImage() {
      let id = this.idImageReplace
      const imageData = await this.uploadImages('', '', this.$refs.replaceImage.files, 'replace')
      this.$imagesSrc[id] = imageData.src
      this.$store.commit('image/REPLACE_POPUP_IMAGE', { id, fileName: imageData.fileName })
    },
    deleteImage(id) {
      this.$store.commit('image/DELETE_POPUP_IMAGE', { id })
    },
    showLightBox(id) {
      let aTag = document.querySelector(`#sk-${id}`)
      aTag.click()
    },
  },
}
</script>

<style scoped>
.image-metadata-sidebar {
  background-repeat: no-repeat;
  border: 1px solid;
  border-color: transparent;
  height: 100px;
  background-size: cover;
  background-position: center;
  width: 320px;
  height: 150px;
  cursor: pointer;
}
.box-image-sidebar:hover .icon-control-image {
  opacity: 1 !important;
}

.timeseries-chart-container {
  height: 100%;
  overflow: hidden;
}
.timeseries-chart-name {
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.timeseries-chart-item {
  height: auto;
}
</style>
