<template>
  <b-modal :title="$t('delete_layer')" centered ok-only ok-variant="danger" :ok-title="$t('delete')" ref="deleteLayer" :body-class="'custom-body'" @ok="deleteLayer">
    {{ $t('message_delete_layer', { name: layer.name }) }}
  </b-modal>
</template>

<script>
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  props: ['layer'],
  methods: {
    open() {
      this.$refs.deleteLayer.show();
    },
    deleteLayer() {
      if (this.layer.type === 'netcdf') delete this.$netcdfStatus[this.layer.id];
      this.$store.commit('layer/DELETE_LAYER', this.layer.id);
      let messageTitle = 'delete_layer';
      let messageLog = `: '${this.layer.name}'`;
      let message = this.$t(messageTitle) + messageLog;
      this.log.info({ message, id: ACTION_LOG[19].id, messageTitle, messageLog });
      // this.log.info({ message: `${this.$t('delete_layer')}: '${this.layer.name}'`, id: ACTION_LOG[19].id });
    },
  },
};
</script>
