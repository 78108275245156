export const ALL_FORMAT_DATE = [
  'MM/DD/YYYY',
  'M/DD/YYYY',
  'MM/D/YYYY',
  'M/D/YYYY',
  'DD/MM/YYYY',
  'D/MM/YYYY',
  'DD/M/YYYY',
  'D/M/YYYY',
  'MM-DD-YYYY',
  'M-DD-YYYY',
  'MM-D-YYYY',
  'M-D-YYYY',
  'DD-MM-YYYY',
  'D-MM-YYYY',
  'DD-M-YYYY',
  'D-M-YYYY',
  'MM DD YYYY',
  'M DD YYYY',
  'MM D YYYY',
  'M D YYYY',
  'DD MM YYYY',
  'D MM YYYY',
  'DD M YYYY',
  'D M YYYY',
  'YYYY/MM/DD',
  'YYYY/M/DD',
  'YYYY/MM/D',
  'YYYY/M/D',
  'YYYY/DD/MM',
  'YYYY/D/MM',
  'YYYY/DD/M',
  'YYYY/D/M',
  'YYYY-MM-DD',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY-M-D',
  'YYYY-DD-MM',
  'YYYY-D-MM',
  'YYYY-DD-M',
  'YYYY-D-M',
  'YYYY MM DD',
  'YYYY M DD',
  'YYYY MM D',
  'YYYY M D',
  'YYYY DD MM',
  'YYYY D MM',
  'YYYY DD M',
  'YYYY D M',
  'MM/DD/YY',
  'M/DD/YY',
  'MM/D/YY',
  'M/D/YY',
  'DD/MM/YY',
  'D/MM/YY',
  'DD/M/YY',
  'D/M/YY',
  'MM-DD-YY',
  'M-DD-YY',
  'MM-D-YY',
  'M-D-YY',
  'DD-MM-YY',
  'D-MM-YY',
  'DD-M-YY',
  'D-M-YY',
  'MM DD YY',
  'M DD YY',
  'MM D YY',
  'M D YY',
  'DD MM YY',
  'D MM YY',
  'DD M YY',
  'D M YY',
  'YY/MM/DD',
  'YY/M/DD',
  'YY/MM/D',
  'YY/M/D',
  'YY/DD/MM',
  'YY/D/MM',
  'YY/DD/M',
  'YY/D/M',
  'YY-MM-DD',
  'YY-M-DD',
  'YY-MM-D',
  'YY-M-D',
  'YY-DD-MM',
  'YY-D-MM',
  'YY-DD-M',
  'YY-D-M',
  'YY MM DD',
  'YY M DD',
  'YY MM D',
  'YY M D',
  'YY DD MM',
  'YY D MM',
  'YY DD M',
  'YY D M',
  'MM/DD/YYYY HH:mm:ss',
  'M/DD/YYYY HH:mm:ss',
  'MM/D/YYYY HH:mm:ss',
  'M/D/YYYY HH:mm:ss',
  'DD/MM/YYYY HH:mm:ss',
  'D/MM/YYYY HH:mm:ss',
  'DD/M/YYYY HH:mm:ss',
  'D/M/YYYY HH:mm:ss',
  'MM-DD-YYYY HH:mm:ss',
  'M-DD-YYYY HH:mm:ss',
  'MM-D-YYYY HH:mm:ss',
  'M-D-YYYY HH:mm:ss',
  'DD-MM-YYYY HH:mm:ss',
  'D-MM-YYYY HH:mm:ss',
  'DD-M-YYYY HH:mm:ss',
  'D-M-YYYY HH:mm:ss',
  'MM DD YYYY HH:mm:ss',
  'M DD YYYY HH:mm:ss',
  ' MM D YYYY HH:mm:ss',
  'M D YYYY HH:mm:ss',
  'DD MM YYYY HH:mm:ss',
  'D MM YYYY HH:mm:ss',
  'DD M YYYY HH:mm:ss',
  'D M YYYY HH:mm:ss',
  'YYYY/MM/DD HH:mm:ss',
  'YYYY/M/DD HH:mm:ss',
  'YYYY/MM/D HH:mm:ss',
  'YYYY/M/D HH:mm:ss',
  'YYYY/DD/MM HH:mm:ss',
  'YYYY/D/MM HH:mm:ss',
  'YYYY/DD/M HH:mm:ss',
  'YYYY/D/M HH:mm:ss',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-M-DD HH:mm:ss',
  'YYYY-MM-D HH:mm:ss',
  'YYYY-M-D HH:mm:ss',
  'YYYY-DD-MM HH:mm:ss',
  'YYYY-D-MM HH:mm:ss',
  'YYYY-DD-M HH:mm:ss',
  'YYYY-D-M HH:mm:ss',
  'YYYY MM DD HH:mm:ss',
  'YYYY M DD HH:mm:ss',
  'YYYY MM D HH:mm:ss',
  'YYYY M D HH:mm:ss',
  'YYYY DD MM HH:mm:ss',
  'YYYY D MM HH:mm:ss',
  'YYYY DD M HH:mm:ss',
  'YYYY D M HH:mm:ss',
  'MM/DD/YY HH:mm:ss',
  'M/DD/YY HH:mm:ss',
  'MM/D/YY HH:mm:ss',
  'M/D/YY HH:mm:ss',
  'DD/MM/YY HH:mm:ss',
  'D/MM/YY HH:mm:ss',
  'DD/M/YY HH:mm:ss',
  'D/M/YY HH:mm:ss',
  'MM-DD-YY HH:mm:ss',
  'M-DD-YY HH:mm:ss',
  'MM-D-YY HH:mm:ss',
  'M-D-YY HH:mm:ss',
  'DD-MM-YY HH:mm:ss',
  'D-MM-YY HH:mm:ss',
  'DD-M-YY HH:mm:ss',
  'D-M-YY HH:mm:ss',
  'MM DD YY HH:mm:ss',
  'M DD YY HH:mm:ss',
  'MM D YY HH:mm:ss',
  'M D YY HH:mm:ss',
  'DD MM YY HH:mm:ss',
  'D MM YY HH:mm:ss',
  'DD M YY HH:mm:ss',
  'D M YY HH:mm:ss',
  'YY/MM/DD HH:mm:ss',
  'YY/M/DD HH:mm:ss',
  'YY/MM/D HH:mm:ss',
  'YY/M/D HH:mm:ss',
  'YY/DD/MM HH:mm:ss',
  'YY/D/MM HH:mm:ss',
  'YY/DD/M HH:mm:ss',
  'YY/D/M HH:mm:ss',
  'YY-MM-DD HH:mm:ss',
  'YY-M-DD HH:mm:ss',
  'YY-MM-D HH:mm:ss',
  'YY-M-D HH:mm:ss',
  'YY-DD-MM HH:mm:ss',
  'YY-D-MM HH:mm:ss',
  'YY-DD-M HH:mm:ss',
  'YY-D-M HH:mm:ss',
  'YY MM DD HH:mm:ss',
  'YY M DD HH:mm:ss',
  'YY MM D HH:mm:ss',
  'YY M D HH:mm:ss',
  'YY DD MM HH:mm:ss',
  'YY D MM HH:mm:ss',
  'YY DD M HH:mm:ss',
  'YY D M HH:mm:ss',
];
