<template></template>

<script>
import { v4 as uuidv4 } from 'uuid'
const _ = require('lodash')
import L from 'leaflet'
import chroma from 'chroma-js'
import * as SHAPES from '@/constants/shapes'
import 'leaflet.markercluster'
import 'leaflet.featuregroup.subgroup'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'animate.css/animate.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import { highlightMixin } from '@/mixins/GroupItemsMixin.js'
import MathMixin from '@/mixins/MathMixin'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['map', 'layer', 'mapIndex', 'countLayerPointCluster'],
  mixins: [MathMixin, highlightMixin],
  mounted() {
    this.$nextTick(async () => {
      await this.create()
      await this.update()
      // if (this.version == 1) {
      //   this.inspect();
      // }
    })
    if (this.mapIndex == 0) {
      try {
        if (this.layerSelectedOpenProject && this.layerSelectedOpenProject[`${this.layer.id}`] != undefined) {
          this.layerSelectedOpenProject[`${this.layer.id}`] = true
        }
      } catch {}
      let isCheckDrawAllLayer = true
      try {
        if (this.layerSelectedOpenProject) {
          for (let key in this.layerSelectedOpenProject) {
            if (key != 'layerSelected' && !this.layerSelectedOpenProject[key]) {
              isCheckDrawAllLayer = false
              break
            }
          }
        }
      } catch {}
      let idLayerSelected
      if (isCheckDrawAllLayer) {
        if (this.layerSelectedOpenProject && this.layerSelectedOpenProject['layerSelected']) {
          idLayerSelected = _.cloneDeep(this.layerSelectedOpenProject['layerSelected'])
          this.layerSelectedOpenProject = {}
        } else {
          if (this.layer && ['point', 'multi', 'netcdf'].includes(this.layer.type)) {
            idLayerSelected = _.cloneDeep(this.layer.id)
          }
        }
      }
      setTimeout(() => {
        try {
          if (idLayerSelected) {
            this.$store.commit('map/SET_LAYER_SELECTED', idLayerSelected)
          }
        } catch {}
      }, 500)
    }
  },
  destroyed() {
    this.delete()
    this.leafletLayer = null
  },
  data() {
    return {
      renderDate: '',
      operator: ['>', '>=', '<', '<=', '==', '!='],
      toastLayer: [],
      eventHasShow: [],
      checkTimeout: null,
      getStatusSuccess: 0,
      oldVersion: null,
      typeClusterMarker: null,
      dataFigureCluster: {},
      firstGroupCluster: {},
    }
  },
  computed: {
    lineColor() {
      return this.$store.state.settings.lineColor
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    datasource() {
      return this.$store.state.datasource.datasources.find((d) => d.id == this.layer.dataTimeseries)
    },
    version() {
      return this.layer.version
    },
    visible() {
      return this.layer.visible[this.mapIndex]
    },
    inspectCount() {
      return this.layer.inspectCount
    },
    date() {
      return this.$store.state.map.date
    },
    conditionRule() {
      return this.$store.state.map.conditionRule
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    layers() {
      if (this.numberOfMap == 1) {
        return this.$store.state.layer.layers.filter((l) => l.visible[0] && l.type != 'image')
      } else {
        return this.$store.state.layer.layers.filter((l) => (l.visible[0] || l.visible[1]) && l.type != 'image')
      }
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    layersStore() {
      return this.$store.state.layer.layers
    },
    idxLayer() {
      try {
        if (this.layersStore && this.layer) {
          for (let i = 0; i < this.layersStore.length; i++) {
            if (this.layersStore[i].id == this.layer.id) {
              return 1000 + this.layersStore.length - i
            }
          }
        }
      } catch {}
      return 1000
    },
    layerSelectedOpenProject: {
      get() {
        return this.$store.state.ecoplot.layerSelectedOpenProject
      },
      set(layerSelectedOpenProject) {
        this.$store.commit('ecoplot/UPDATE_LAYER_SELECTED_OPEN_PROJECT', layerSelectedOpenProject)
      },
    },
    allRecordTimeseries: {
      async get() {
        let allRecord
        try {
          allRecord = await this.selectAll(this.layer.dataTimeseries, this.layer.scale.value)
        } catch {}
        return allRecord
      },
    },
    latLonItemBuild: {
      async get() {
        let arrayBuild = {}
        try {
          let allRecordTimeseries = _.cloneDeep(await this.allRecordTimeseries)
          let metadataColumn = this.$db[this.layer.dataTimeseries].columns
          let locations = Object.keys(metadataColumn)
            .filter((pair) => pair.endsWith(`*${this.layer.item}`))
            .map((pair) => pair.split('*')[0])
          const allPoint = await this.getAllPoints(this.layer.dataPoint)
          let objPoint = {}
          if (allPoint && allPoint.length) {
            for (let i = 0; i < allPoint.length; i++) {
              if (allPoint[i].name) {
                objPoint[allPoint[i].name] = allPoint[i]
              }
            }
          }
          if (locations && locations.length) {
            for (let i = 0; i < locations.length; i++) {
              let latLocation = `${locations[i]}-LATITUDE`
              let lonLocation = `${locations[i]}-LONGITUDE`
              if (allRecordTimeseries && objPoint[locations[i]] && allRecordTimeseries[latLocation] && allRecordTimeseries[latLocation].length && allRecordTimeseries[lonLocation] && allRecordTimeseries[lonLocation].length) {
                let latStart = objPoint[locations[i]].lat
                let lonStart = objPoint[locations[i]].lng
                for (let j = 0; j < allRecordTimeseries[latLocation].length; j++) {
                  if (allRecordTimeseries[latLocation][j] && !this.isNaValue(allRecordTimeseries[latLocation][j]) && allRecordTimeseries[lonLocation][j] && !this.isNaValue(allRecordTimeseries[lonLocation][j])) {
                    latStart = allRecordTimeseries[latLocation][j]
                    lonStart = allRecordTimeseries[lonLocation][j]
                  } else {
                    allRecordTimeseries[latLocation][j] = latStart
                    allRecordTimeseries[lonLocation][j] = lonStart
                  }
                }
                arrayBuild[latLocation] = allRecordTimeseries[latLocation]
                arrayBuild[lonLocation] = allRecordTimeseries[lonLocation]
              }
            }
          }
        } catch {}
        if (this.layerSelected && this.layerSelected == this.layer.id) {
          this.$store.commit('map/SET_LAT_LON_BUILD_UPDATE_LAYER_ACTIVE', arrayBuild)
        }
        return arrayBuild
      },
    },
    layerSelected() {
      return this.$store.state.map.layerSelected
    },
  },
  watch: {
    conditionRule() {
      this.eventHasShow = []
    },
    async version(version) {
      if (this.checkTimeout) clearTimeout(this.checkTimeout)
      this.checkTimeout = setTimeout(async () => {
        this.delete()
        await this.create()
        await this.update()
        // if (version == 2 && this.inspectCount == 1) {
        //   this.inspect(); // create layer
        // }
      }, 100)
    },
    async countLiveMode() {
      this.delete()
      await this.create()
      await this.update()
    },
    async visible() {
      this.delete()
      await this.create()
      await this.update()
    },
    async date(value) {
      const metadata = this.$db[this.layer.dataTimeseries]
      const date = this.date[0]

      // Check is time outside of layer time-range, then it should not be draw
      const isOutside = metadata ? !(metadata.dates[0] <= date && date <= metadata.dates[metadata.dates.length - 1]) : true
      if (!this.visible && !isOutside) this.$store.commit('ecoplot/SET_STATUS_TIMEPLAYER')
      await this.update()
    },
    inspectCount() {
      this.inspect()
    },
    toastLayer(toastLayer) {
      if (toastLayer.length > 0) {
        let i = 0
        let interValFunc = setInterval(() => {
          try {
            this.$toast({ component: ToastificationContent, props: { title: ``, icon: 'BellIcon', text: toastLayer[i].text, variant: toastLayer[i].style, timeout: toastLayer[i].timeout } })
            i++
          } catch {}
          if (i >= toastLayer.length) {
            clearInterval(interValFunc)
          }
        }, 500)
      }
    },
    getStatusSuccess() {
      let status = true
      const date = _.cloneDeep(this.date[0])
      try {
        if (this.layers && this.layers.length) {
          if (this.numberOfMap == 1) {
            for (let i = 0; i < this.layers.length; i++) {
              if (!this.$layerStatus[this.layers[i].id] || !this.$layerStatus[this.layers[i].id][date] || (this.$layerStatus[this.layers[i].id] && !this.$layerStatus[this.layers[i].id][date][0])) {
                status = false
                break
              }
            }
          } else if (this.numberOfMap == 2) {
            for (let i = 0; i < this.layers.length; i++) {
              if (!this.$layerStatus[this.layers[i].id] || !this.$layerStatus[this.layers[i].id][date] || (this.$layerStatus[this.layers[i].id] && (!this.$layerStatus[this.layers[i].id][this.date[0]][0] || !this.$layerStatus[this.layers[i].id][this.date[0]][1]))) {
                status = false
                break
              }
            }
          }
        }
      } catch {}
      if (status) {
        this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', false)
        this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', false)
      }
    },
    idxLayer: {
      deep: true,
      async handler() {
        try {
          if (this.layer.markerCluster.using) {
            await this.update()
          }
        } catch {}
      },
    },
    countLayerPointCluster: {
      deep: true,
      async handler() {
        try {
          if (this.layer.markerCluster.using) {
            this.$nextTick(() => {
              setTimeout(async () => {
                await this.update()
              }, 500)
            })
          }
        } catch {}
      },
    },
    // mapZoomEnd: {
    //   deep: true,
    //   async handler() {
    //     try {
    //       if (this.layer.markerCluster.using) {
    //         this.$nextTick(() => {
    //           setTimeout(async () => {
    //             await this.update();
    //           }, 500);
    //         });
    //       }
    //     } catch {}
    //   },
    // },
    // mapDragEnd: {
    //   deep: true,
    //   async handler() {
    //     try {
    //       if (this.layer.markerCluster.using) {
    //         this.$nextTick(() => {
    //           setTimeout(async () => {
    //             await this.update();
    //           }, 200);
    //         });
    //       }
    //     } catch {}
    //   },
    // },
  },
  methods: {
    changeColorAlpha(color, opacity) {
      try {
        if (color.length > 7) color = color.substring(0, color.length - 2)
        const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
        let opacityHex = _opacity.toString(16).toUpperCase()
        if (opacityHex.length == 1) opacityHex = '0' + opacityHex
        return color + opacityHex
      } catch {}
      return '#01BEFE'
    },
    rgbToHex(r, g, b, opacity) {
      try {
        // Chuyển đổi giá trị RGB từ số thập phân sang số nguyên
        r = Math.round(r)
        g = Math.round(g)
        b = Math.round(b)
        // Chuyển đổi từng giá trị RGB từ số nguyên sang mã HEX
        const toHex = (n) => {
          const hex = n.toString(16)
          return hex.length === 1 ? '0' + hex : hex
        }
        // Chuyển đổi giá trị độ mờ (opacity) thành mã HEX
        const alpha = Math.round(opacity * 255)
        const alphaHex = toHex(alpha)
        // Kết hợp các giá trị HEX thành một chuỗi mã màu HEX
        return `#${toHex(r)}${toHex(g)}${toHex(b)}${alphaHex}`
      } catch {}
    },
    async delay(ms) {
      return new Promise((resolve) => setTimeout(() => resolve(), ms))
    },
    logCircleRadiusInPixels(radiusInPixels, latLng) {
      try {
        if (latLng && this.map && radiusInPixels != null) {
          const point = this.map.latLngToLayerPoint(latLng)
          const radiusPoint = L.point(point.x + radiusInPixels, point.y)
          const newLatLng = this.map.layerPointToLatLng(radiusPoint)
          const newPoint = this.map.latLngToLayerPoint(newLatLng)
          const actualRadiusInPixels = newPoint.x - point.x
          return actualRadiusInPixels
        }
      } catch {}
      return
    },
    customClusterGroup(count, idFirst, style) {
      let data = this.dataFigureCluster
      let shape = this.typeClusterMarker ? this.typeClusterMarker : 'Circle'
      let color
      let fontSize = 15
      try {
        if (style) {
          if (style.color) {
            color = style.color
          }
          if (style.fontSize >= 15) {
            fontSize = style.fontSize
          }
        }
      } catch {}
      if (!color) {
        color = this.changeColorAlpha(data.color, Number(data.fillOpacity))
      }
      // let scale = 1;
      // try {
      //   scale = fontSize / 14;
      //   scale = scale >= 1 ? 1 : scale;
      //   elementCountCluter.style.transform = `translate(-50%, -50%) scale(${scale})`;
      // } catch {}
      switch (shape) {
        case 'Square': {
          return `<i class="fa-solid fa-square custom-cluster-icon" id="cluster${this.mapIndex}-${idFirst}"  style="color:${color};font-size:${fontSize}px; -webkit-text-stroke-color: ${data.outlineColor};-webkit-text-stroke-width: ${data.outlineWeight}px; ">
            <i class="fa-solid fa-angles-down custom-cluster-icon-count"  style="color:${color};font-size:${fontSize * 0.3}px " ></i>
            </i>`
          break
        }
        case 'Circle': {
          return `<i class="fa-solid fa-circle custom-cluster-icon" id="cluster${this.mapIndex}-${idFirst}"  style="color:${color};font-size:${fontSize}px; -webkit-text-stroke-color: ${data.outlineColor};-webkit-text-stroke-width: ${data.outlineWeight}px; ">
            <i class="fa-solid fa-angles-down custom-cluster-icon-count"  style="color:${color};font-size:${fontSize * 0.3}px" ></i>
            </i>`
          break
        }
        case 'Spike': {
          return `<i class="fa-solid fa-star custom-cluster-icon"  id="cluster${this.mapIndex}-${idFirst}"  style="color:${color};font-size:${fontSize}px; -webkit-text-stroke-color: ${data.outlineColor};-webkit-text-stroke-width: ${data.outlineWeight}px; ">
            <i class="fa-solid fa-angles-down custom-cluster-icon-count"  style="color:${color};font-size:${fontSize * 0.3}px" ></i>
            </i>`
          break
        }
        case 'Triangle': {
          return `<i class="fa-solid fa-play custom-cluster-icon"  id="cluster${this.mapIndex}-${idFirst}"  style="color:${color};font-size:${fontSize}px; -webkit-text-stroke-color: ${data.outlineColor};-webkit-text-stroke-width: ${data.outlineWeight}px; ">
            <i class="fa-solid fa-angles-down custom-cluster-icon-count"  style="color:${color};font-size:${fontSize * 0.3}px" ></i>
            </i>`
          break
        }
      }
    },
    calDataCluster(circleMarkerFirst, value, minValueItem, maxValueItem) {
      const self = this
      try {
        let colorCluster
        let fillColor = null
        let radiusPixel = 0
        try {
          const metadata = self.$db[self.layer.dataTimeseries]
          if (value) {
            const valueMin = self.layer.scale.value ? minValueItem : metadata.items[self.layer.item].min
            const valueMax = self.layer.scale.value ? maxValueItem : metadata.items[self.layer.item].max
            const radiusMin = +self.layer.radius.min
            const radiusMax = +self.layer.radius.max
            const radius = ((value - valueMin) / (valueMax - valueMin)) * (radiusMax - radiusMin) + radiusMin
            if (self.layer.fillColor.mode === 'solid') {
              fillColor = self.layer.fillColor.color
            } else if (self.layer.fillColor.mode === 'linear') {
              fillColor = chroma.scale(self.layer.fillColor.colors).domain([valueMin, valueMax])(value)
            } else if (self.layer.fillColor.mode === 'threshold') {
              fillColor = self.layer.fillColor.color
              let values = self.layer.fillColor.thresholds[self.layer.fillColor.individualThreshold ? circleMarkerFirst.feature.properties.ID : 'General']
              for (let index = 0; index < self.layer.fillColor.levels.length; index++) {
                if (values[index] !== '' && value >= +values[index]) fillColor = self.layer.fillColor.levels[index].color
              }
            }
            radiusPixel = self.logCircleRadiusInPixels(radius, circleMarkerFirst._latlng)
          }
          const opacityCluster = self.layer.fillOpacity ? Number(self.layer.fillOpacity) : 0
          if (fillColor) {
            if (fillColor._rgb) {
              colorCluster = `rgba(${fillColor._rgb[0]}, ${fillColor._rgb[1]}, ${fillColor._rgb[2]}, ${opacityCluster})`
            }
          }
        } catch {}
        return { colorCluster, radiusPixel }
      } catch {}
      return
    },
    async create() {
      if (!this.visible) return

      if (!this.layer.dataPoint) return
      let leafletLayer = null
      let markersCluster = null
      const self = this
      try {
        if (this.layer && this.layer.fillColor) {
          if (this.layer.fillColor.mode == 'solid') {
            this.dataFigureCluster = {
              color: this.layer.fillColor.color ? this.layer.fillColor.color : '#01BEFE',
              fillOpacity: this.layer.fillOpacity,
              outlineWeight: this.layer.weight ? this.layer.weight : 0,
              outlineColor: this.layer.color ? this.layer.color : '#fff',
            }
          } else {
            this.dataFigureCluster = {
              color: this.layer.fillColor.colors && this.layer.fillColor.colors.length && this.layer.fillColor.colors[0] ? this.layer.fillColor.colors[0] : '#01BEFE',
              fillOpacity: this.layer.fillOpacity,
              outlineWeight: this.layer.weight ? this.layer.weight : 0,
              outlineColor: this.layer.color ? this.layer.color : '#fff',
            }
          }
        }
        this.typeClusterMarker = this.layer.shape.shapeType
      } catch {}
      let markerClusterOption = {
        showCoverageOnHover: this.layer.markerCluster.showCoverageOnHover,
        // zoomToBoundsOnClick: this.layer.markerCluster.zoomToBoundsOnClick,
        zoomToBoundsOnClick: false,
        maxClusterRadius: this.layer.markerCluster.maxClusterRadius,
        animate: true,
        animateAddingMarkers: false,
        spiderfyDistanceMultiplier: 2,
        spiderfyOnMaxZoom: !this.layer.markerCluster.zoomToBoundsOnClick,
        spiderLegPolylineOptions: { weight: 1, color: '#397bff', opacity: 0.5 },
      }
      if (!this.layer.markerCluster.zoomToBoundsOnClick) {
        // markerClusterOption.disableClusteringAtZoom = 3;
      }
      let minValueItem = Infinity
      let maxValueItem = -Infinity
      let allRecord
      let metadata
      if (this.dataFigureCluster && this.dataFigureCluster.color && this.typeClusterMarker && this.layer.markerCluster.using) {
        try {
          metadata = self.$db[self.layer.dataTimeseries]
          let locations = Object.keys(metadata.columns)
            .filter((pair) => pair.endsWith(`*${self.layer.item}`))
            .map((pair) => pair.split('*')[0])
          const locationsItems = _.map(locations, (l) => l + '-' + self.layer.item)
          allRecord = await self.selectAllByLocationsItems(self.layer.dataTimeseries, locationsItems, self.layer.scale.value)
          let allRecordValue = _.cloneDeep(allRecord)
          try {
            if (allRecordValue.dates) {
              delete allRecordValue.dates
            }
          } catch {}
          allRecordValue = Object.values(allRecordValue)
          if (self.layer.scale.value) {
            minValueItem = Math.min(minValueItem, _.min([].concat(...allRecordValue)))
            maxValueItem = Math.max(maxValueItem, _.max([].concat(...allRecordValue)))
          }
        } catch {}

        markerClusterOption.iconCreateFunction = function (cluster) {
          let colorCluster
          let radiusPixel = 0
          let idFirstUpdate
          let countCluster = 0
          try {
            countCluster = cluster.getChildCount()
            if (countCluster) {
              let allclusterChild = Object.values(cluster.getAllChildMarkers())
              const circleMarkerFirst = allclusterChild[0]
              idFirstUpdate = circleMarkerFirst.clusterId ? circleMarkerFirst.clusterId : uuidv4()
              self.firstGroupCluster[idFirstUpdate] = true
              cluster.clusterId = idFirstUpdate
              try {
                const itemClusterFirst = circleMarkerFirst.feature.properties.ID + '-' + self.layer.item
                const record = allRecord[itemClusterFirst]
                const allDate = allRecord.dates
                let indexDate = allDate.indexOf(self.date[0])
                const value = record[indexDate]
                let calDataCluster = self.calDataCluster(circleMarkerFirst, value, minValueItem, maxValueItem)
                if (calDataCluster) {
                  if (calDataCluster.radiusPixel != null) {
                    radiusPixel = calDataCluster.radiusPixel
                  }
                  if (calDataCluster.colorCluster) {
                    colorCluster = calDataCluster.colorCluster
                  }
                }
              } catch {}
            }
          } catch {}

          let htmlIcon = self.customClusterGroup(countCluster, idFirstUpdate, { fontSize: radiusPixel, color: colorCluster })
          return L.divIcon({ html: htmlIcon, className: `htmlicon-cluster${self.mapIndex}-${idFirstUpdate}` })
        }
      }

      // if (!this.map.getPane(this.layer.id)) this.map.createPane(this.layer.id);
      const points = await this.getAllPoints(this.layer.dataPoint)
      if (this.layer.markerCluster.using) {
        markersCluster = L.markerClusterGroup(markerClusterOption)
        leafletLayer = L.featureGroup.subGroup(markersCluster)
      } else leafletLayer = L.featureGroup()

      leafletLayer.id = this.layer.id
      leafletLayer.type = 'point'

      if (markersCluster) {
        markersCluster.on('clusterclick', function (e) {
          try {
            let markersInCluster = e.layer
            let checkGroup = false
            try {
              checkGroup = markersInCluster._childClusters && markersInCluster._childClusters.length ? true : false
            } catch {}

            if (markersInCluster) {
              if (!self.layer.markerCluster.zoomToBoundsOnClick) {
                markersInCluster.spiderfy()
              } else {
                if (!checkGroup) {
                  markersInCluster.spiderfy()
                } else {
                  markersInCluster.zoomToBounds()
                }
              }
            }
          } catch {}

          // const cluster = e.layer;
          //   const markersInCluster = cluster.getAllChildMarkers();
          //   let data = [];
          //   try {
          //     if (markersInCluster && markersInCluster.length) {
          //       for (let i = 0; i < markersInCluster.length; i++) {
          //         data.push({
          //           feature: markersInCluster[i].feature,
          //           latlng: markersInCluster[i].latlng,
          //           leafletId: markersInCluster[i]._leaflet_id,
          //         });
          //       }
          //     }
          //   } catch {}
          //   let storeData = {
          //     id: cluster._leaflet_id,
          //     layerId: self.layer.id,
          //     latlng: cluster._latlng,
          //     mapIndex: self.mapIndex,
          //     data: data,
          //     multiShow: self.layer.popup.multiShow,
          //     pin: true,
          //   };
          //   try {
          //     if (!self.layer.popup.multiShow) {
          //       const clusterGroupSingle = _.cloneDeep(Object.values(self.$store.state.map[`clickingCLusterGroupMap${self.mapIndex + 1}`]));
          //       if (clusterGroupSingle && clusterGroupSingle.length && clusterGroupSingle[0].position && !clusterGroupSingle[0].pin && clusterGroupSingle[0].layerId == self.layer.id) {
          //         storeData.pin = clusterGroupSingle[0].pin;
          //         storeData.position = clusterGroupSingle[0].position;
          //       }
          //     }
          //   } catch {}
          //   self.$store.commit(`map/ADD_CLICKING_CLUSTER_GROUP_MAP_${self.mapIndex + 1}`, storeData);
        })
        markersCluster.on('clustermouseover', function (e) {
          try {
            const cluster = e.layer
            let allclusterChild = Object.values(cluster.getAllChildMarkers())
            const circleMarkerFirst = allclusterChild[0]
            // let data = [];
            // try {
            //   if (markersInCluster && markersInCluster.length) {
            //     for (let i = 0; i < markersInCluster.length; i++) {
            //       data.push({
            //         feature: markersInCluster[i].feature,
            //         latlng: markersInCluster[i].latlng,
            //         leafletId: markersInCluster[i]._leaflet_id,
            //       });
            //     }
            //   }
            // } catch {}

            // const storeData = {
            //   layerId: self.layer.id,
            //   position: cluster._latlng,
            //   mapIndex: self.mapIndex,
            //   data: data,
            // };
            // self.$store.commit('map/SET_HOVERING_CLUSTER_GROUP', storeData);
            const storeData = {
              data: { layerId: self.layer.id, hoverId: circleMarkerFirst.feature.properties.ID, hoverUid: circleMarkerFirst.feature.properties.UID, latLonSpider: cluster.getLatLng(), source: 'map' },
              mapIndex: self.mapIndex,
            }
            self.$store.commit('map/SET_HOVERING', storeData)
          } catch {}
        })
        markersCluster.on('clustermouseout', function (e) {
          try {
            // self.$store.commit('map/SET_HOVERING_CLUSTER_GROUP', null);
            const storeData = {
              data: null,
              mapIndex: self.mapIndex,
            }
            self.$store.commit('map/SET_HOVERING', storeData)
          } catch {}
        })
        markersCluster.on('spiderfied', function (e) {
          // const cluster = e.cluster;
          e.cluster.isSpiderfied = true
          const markersInCluster = e.markers
          try {
            e.cluster.setZIndexOffset(self.idxLayer)
          } catch {}

          try {
            let clicking = self.$store.state.map.clicking[self.mapIndex]
            let arrayUpdate = []
            if (markersInCluster && markersInCluster.length && clicking && clicking.length) {
              for (let i = 0; i < markersInCluster.length; i++) {
                const circleMarkerClusterClick = markersInCluster[i]
                let item = `${self.layer.id}-${circleMarkerClusterClick.feature.properties.ID}-${circleMarkerClusterClick.feature.properties.UID}-${circleMarkerClusterClick.latlng}-map`
                arrayUpdate.push(item)
              }
            }
            if (arrayUpdate && arrayUpdate.length) {
              self.$store.commit(`map/UPDATE_CLICKING_CLUSTER`, {
                mapIndex: self.mapIndex,
                data: arrayUpdate,
                isOpen: true,
              })
            }
          } catch {}
          self.$emit('moveCountFromSpiderfied')
        })
        markersCluster.on('unspiderfied', function (e) {
          e.cluster.isSpiderfied = false
          // const cluster = e.cluster;
          const markersInCluster = e.markers
          try {
            e.cluster.setZIndexOffset(self.idxLayer)
          } catch {}
          try {
            let clicking = self.$store.state.map.clicking[self.mapIndex]
            let arrayUpdate = []
            if (markersInCluster && markersInCluster.length && clicking && clicking.length) {
              for (let i = 0; i < markersInCluster.length; i++) {
                const circleMarkerClusterClick = markersInCluster[i]
                let item = `${self.layer.id}-${circleMarkerClusterClick.feature.properties.ID}-${circleMarkerClusterClick.feature.properties.UID}-${circleMarkerClusterClick.latlng}-map`
                arrayUpdate.push(item)
              }
            }
            if (arrayUpdate && arrayUpdate.length) {
              self.$store.commit(`map/UPDATE_CLICKING_CLUSTER`, {
                mapIndex: self.mapIndex,
                data: arrayUpdate,
                isOpen: false,
              })
            }
          } catch {}
          self.$emit('moveCountFromSpiderfied')
        })
        markersCluster.on('animationend', function (e) {
          try {
            let allCluster = markersCluster._featureGroup._layers
            if (allCluster && Object.keys(allCluster).length) {
              let firstGroupCluster = {}
              try {
                const allDate = allRecord.dates
                for (let key in allCluster) {
                  try {
                    let countCluster = allCluster[key]._childCount
                    let colorCluster
                    let radiusPixel = 0
                    if (countCluster) {
                      let allclusterChild = allCluster[key].getAllChildMarkers()
                      try {
                        allCluster[key].setZIndexOffset(self.idxLayer)
                      } catch {}
                      const circleMarkerFirst = allclusterChild[0]
                      let idFirstUpdate = circleMarkerFirst.clusterId ? circleMarkerFirst.clusterId : uuidv4()
                      firstGroupCluster[idFirstUpdate] = true
                      allCluster[key].clusterId = idFirstUpdate
                      try {
                        const itemClusterFirst = circleMarkerFirst.feature.properties.ID + '-' + self.layer.item
                        const record = allRecord[itemClusterFirst]
                        let indexDate = allDate.indexOf(self.date[0])
                        const value = record[indexDate]
                        let calDataCluster = self.calDataCluster(circleMarkerFirst, value, minValueItem, maxValueItem)
                        if (calDataCluster) {
                          if (calDataCluster.radiusPixel != null) {
                            radiusPixel = calDataCluster.radiusPixel
                          }
                          if (calDataCluster.colorCluster) {
                            colorCluster = calDataCluster.colorCluster
                          }
                        }
                      } catch {}
                      let htmlIcon = self.customClusterGroup(countCluster, idFirstUpdate, { fontSize: radiusPixel, color: colorCluster })
                      let iconTemp = L.divIcon({ html: htmlIcon, className: `htmlicon-cluster${self.mapIndex}-${idFirstUpdate}` })
                      allCluster[key].setIcon(iconTemp)
                    } else {
                      try {
                        let idFirstUpdate = allCluster[key].clusterId ? allCluster[key].clusterId : uuidv4()
                        firstGroupCluster[idFirstUpdate] = true
                      } catch {}
                    }
                  } catch {}
                }
              } catch {}
              self.firstGroupCluster = firstGroupCluster
            }
          } catch {}
          self.$emit('moveCountFromSpiderfied')
        })
      }

      for (const point of points) {
        const latlng = [point.lat, point.lng]
        let circleMarker = new L.CustomMarkerShape(latlng)
        // let circleMarker = new L.CustomMarkerShape(latlng, {
        //   pane: this.layer.id,
        // });

        const isMatchLayer = this.selectedGroupItems && this.selectedGroupItems.layerID === this.layer.id
        circleMarker.showHighlight = isMatchLayer && this.selectedGroupItems.items.includes(point.name)
        circleMarker.feature = { properties: { ID: point.name } }
        circleMarker.shapeType = this.layer.shape.shapeType || SHAPES.CIRCLE
        circleMarker.numSpikes = this.layer.shape.numSpikes
        circleMarker.highlightColor = this.lineColor
        circleMarker.latlng = latlng
        circleMarker.clusterId = uuidv4()
        try {
          circleMarker.latLonOrigin = [point.lat, point.lng]
        } catch {}

        this.$latlngOnMap[circleMarker.feature.properties.ID] = latlng

        circleMarker.on('mouseover', () => {
          const storeData = {
            data: { layerId: this.layer.id, hoverId: circleMarker.feature.properties.ID, hoverUid: circleMarker.feature.properties.UID, latLonSpider: circleMarker.getLatLng(), source: 'map' },
            mapIndex: this.mapIndex,
          }
          this.$store.commit('map/SET_HOVERING', storeData)
        })

        circleMarker.on('mouseout', () => {
          const storeData = {
            data: null,
            mapIndex: this.mapIndex,
          }
          this.$store.commit('map/SET_HOVERING', storeData)
        })
        circleMarker.on('move', () => {
          // if (self.layer.markerCluster.using) {
          //   let storeData = {
          //     data: { layerId: self.layer.id, clickId: circleMarker.feature.properties.ID, clickUid: circleMarker.feature.properties.UID, latLonSpider: circleMarker.getLatLng(), source: 'map', multiShow: self.layer.popup.multiShow, latlng: circleMarker.latlng, pin: true },
          //     mapIndex: self.mapIndex,
          //   };
          //   try {
          //     if (!self.layer.popup.multiShow) {
          //       let clickingSingle = _.cloneDeep(self.$store.state.map.clicking[self.mapIndex]);
          //       if (clickingSingle && clickingSingle.length == 1 && clickingSingle[0].position && !clickingSingle[0].pin && clickingSingle[0].layerId == self.layer.id) {
          //         storeData.data.pin = clickingSingle[0].pin;
          //         storeData.data.position = clickingSingle[0].position;
          //         storeData.data.latLonSpider = clickingSingle[0].latLonSpider ? clickingSingle[0].latLonSpider : null;
          //       }
          //     }
          //   } catch {}
          //   self.$store.commit('map/UPDATE_CLICKING_FILTER', storeData);
          // }
        })

        circleMarker.on('click', (ev) => {
          let inGroupCluster = false
          try {
            inGroupCluster = markersCluster.getVisibleParent(circleMarker)
          } catch {}
          L.DomEvent.stopPropagation(ev)
          if (ev.originalEvent && ev.originalEvent.ctrlKey) {
            this.toggleHighlight(this.layer, ev)
          } else {
            let storeData = {
              data: { layerId: this.layer.id, clusterOpen: inGroupCluster ? true : false, clickId: circleMarker.feature.properties.ID, clickUid: circleMarker.feature.properties.UID, latLonSpider: circleMarker.getLatLng(), source: 'map', multiShow: this.layer.popup.multiShow, latlng: circleMarker.latlng, pin: true },
              mapIndex: this.mapIndex,
            }
            try {
              if (!this.layer.popup.multiShow) {
                let clickingSingle = _.cloneDeep(this.$store.state.map.clicking[this.mapIndex])
                if (clickingSingle && clickingSingle.length == 1 && clickingSingle[0].position && !clickingSingle[0].pin && clickingSingle[0].layerId == this.layer.id) {
                  storeData.data = _.merge(storeData.data, {
                    position: clickingSingle[0].position,
                    pin: clickingSingle[0].pin,
                    latLonSpider: clickingSingle[0].latLonSpider ? clickingSingle[0].latLonSpider : null,
                  })
                }
              }
            } catch {}
            this.$store.commit('map/SET_CLICKING', _.cloneDeep(storeData))
          }
        })
        circleMarker.addTo(leafletLayer)
      }

      leafletLayer.addTo(this.map)
      if (this.layer.markerCluster.using) markersCluster.addTo(this.map)
      this.$emit('emitBringToBackLayerStore')
      this.leafletLayer = leafletLayer
    },
    async update() {
      this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', true)
      const date = _.cloneDeep(this.date[0])
      if (!this.$layerStatus[this.layer.id]) this.$layerStatus[this.layer.id] = {}
      if (!this.$layerStatus[this.layer.id][date]) this.$layerStatus[this.layer.id][date] = [false, false]
      if (!this.leafletLayer) {
        this.$layerStatus[this.layer.id][date][this.mapIndex] = true
        this.getStatusSuccess++
        return
      }
      const metadata = this.$db[this.layer.dataTimeseries]

      // Check is time outside of layer time-range, then it should not be draw
      const isOutside = metadata ? !(metadata.dates[0] <= date && date <= metadata.dates[metadata.dates.length - 1]) : true
      // if (!this.visible && !isOutside) this.$store.commit('ecoplot/SET_STATUS_TIMEPLAYER');
      if (!this.visible) {
        this.$layerStatus[this.layer.id][date][this.mapIndex] = true
        this.getStatusSuccess++
        return
      }
      this.$layerStatus[this.layer.id][date][this.mapIndex] = false

      // Get the last record having date smaller than current date
      let record = null
      if (!isOutside && this.layer.dataTimeseries && this.layer.item) {
        record = await this.selectDateByItem(this.layer.dataTimeseries, this.layer.item, date, this.layer.scale.value)
        this.renderDate = date
      }
      //GET NEW MIN-MAX IF SCALE VALUE OF MAP ! null
      let minValueItem = Infinity
      let maxValueItem = -Infinity
      if (this.layer.scale.value) {
        let locations = Object.keys(metadata.columns)
          .filter((pair) => pair.endsWith(`*${this.layer.item}`))
          .map((pair) => pair.split('*')[0])
        const locationsItems = _.map(locations, (l) => l + '-' + this.layer.item)
        let allRecord = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationsItems, this.layer.scale.value)
        let allRecordValue = allRecord
        if (allRecordValue.dates) {
          delete allRecordValue.dates
        }
        allRecordValue = Object.values(allRecordValue)
        minValueItem = Math.min(minValueItem, _.min([].concat(...allRecordValue)))
        maxValueItem = Math.max(maxValueItem, _.max([].concat(...allRecordValue)))
      }
      // Update each leaflet layers
      const layers = this.leafletLayer.getLayers()
      let arrRuleShowPopup = []
      try {
        if (this.conditionRule && this.conditionRule.length > 0) {
          this.conditionRule.map((data, index) => {
            if (data.layer == this.layer.id) {
              arrRuleShowPopup.push(index)
            }
          })
        }
      } catch {}
      this.toastLayer = []
      this.NAValRecord = []
      let unit = ''
      try {
        unit = this.$db[this.layer.dataTimeseries].items[this.layer.item].unit
      } catch {}

      const latLonItemBuild = await this.latLonItemBuild
      let idxDate = -1
      try {
        if (metadata && metadata.dates && metadata.dates.length) {
          idxDate = metadata.dates.indexOf(date)
        }
      } catch {}
      try {
        if (idxDate < 0) {
          if (!isOutside) {
            if (metadata.dates && metadata.dates.length && date) {
              const lastIdxDate = metadata.dates.findIndex((n) => n >= date)
              if (lastIdxDate >= 1 && lastIdxDate <= metadata.dates.length) {
                idxDate = lastIdxDate - 1
              }
            }
          }
        }
      } catch {}
      let arrayPopupUpdate = {}
      for (const circleMarker of layers) {
        const locationItem = circleMarker.feature.properties.ID + '-' + this.layer.item
        let latlonUpdate
        let latLonOld = circleMarker.latlng
        if (!isOutside) {
          if (!latLonOld) {
            try {
              let latlonMarker = circleMarker.getLatLng()
              latLonOld = [latlonMarker.lat, latlonMarker.lng]
            } catch {}
          }
          try {
            const locationItemLON = circleMarker.feature.properties.ID + '-' + 'LONGITUDE'
            const locationItemLAT = circleMarker.feature.properties.ID + '-' + 'LATITUDE'
            if (latLonItemBuild && latLonItemBuild[locationItemLON] != null && latLonItemBuild[locationItemLAT] != null && idxDate >= 0 && idxDate < latLonItemBuild[locationItemLAT].length) {
              latlonUpdate = [latLonItemBuild[locationItemLAT][idxDate], latLonItemBuild[locationItemLON][idxDate]]
            }
          } catch {}
          if (latlonUpdate && `${latlonUpdate}` != `${latLonOld}`) {
            let latlonOld
            let latLonSpiderOld
            try {
              latlonOld = circleMarker.latlng
              latLonSpiderOld = circleMarker.getLatLng()
            } catch {}
            try {
              circleMarker.setLatLng(latlonUpdate)
              circleMarker.latlng = latlonUpdate
              circleMarker._latlng = {
                lat: latlonUpdate[0],
                lng: latlonUpdate[1],
              }
              // nếu update được vị trí thì phải update cả latlon trong bordervectorpopup;
              try {
                if (latlonOld) {
                  let latLonSpiderNew
                  try {
                    latLonSpiderNew = circleMarker.getLatLng()
                  } catch {}
                  arrayPopupUpdate[`${circleMarker.feature.properties.ID}-${circleMarker.feature.properties.UID}-${latlonOld}`] = {
                    clickId: circleMarker.feature.properties.ID,
                    clickUid: circleMarker.feature.properties.UID,
                    latlonOld: latlonOld,
                    latlonNew: latlonUpdate,
                    latLonSpiderOld: latLonSpiderOld,
                    latLonSpiderNew: latLonSpiderNew,
                  }
                }
              } catch {}
            } catch {}
          }
        } else {
          if (circleMarker.latLonOrigin && `${circleMarker.latlng}` != `${circleMarker.latLonOrigin}`) {
            let latlonOld = _.cloneDeep(circleMarker.latlng)
            let latlonUpdate = _.cloneDeep(circleMarker.latLonOrigin)
            let latLonSpiderOld
            try {
              latLonSpiderOld = _.cloneDeep(circleMarker.getLatLng())
            } catch {}
            circleMarker.setLatLng(latlonUpdate)
            circleMarker.latlng = latlonUpdate
            circleMarker._latlng = {
              lat: latlonUpdate[0],
              lng: latlonUpdate[1],
            }
            let latLonSpiderNew
            try {
              latLonSpiderNew = _.cloneDeep(circleMarker.getLatLng())
            } catch {}
            arrayPopupUpdate[`${circleMarker.feature.properties.ID}-${circleMarker.feature.properties.UID}-${circleMarker.latLonOrigin}`] = {
              clickId: circleMarker.feature.properties.ID,
              clickUid: circleMarker.feature.properties.UID,
              latlonOld: latlonOld,
              latlonNew: latlonUpdate,
              latLonSpiderOld: latLonSpiderOld,
              latLonSpiderNew: latLonSpiderNew,
            }
          }
        }

        const value = record ? record[locationItem] : null

        if (value == null) {
          this.drawValueNA(circleMarker)
          this.NAValRecord.push(locationItem)
        } else {
          const valueMin = this.layer.scale.value ? minValueItem : metadata.items[this.layer.item].min
          const valueMax = this.layer.scale.value ? maxValueItem : metadata.items[this.layer.item].max
          const radiusMin = +this.layer.radius.min
          const radiusMax = +this.layer.radius.max
          const radius = ((value - valueMin) / (valueMax - valueMin)) * (radiusMax - radiusMin) + radiusMin
          const weight = +this.layer.weight
          const color = this.layer.color
          const fillOpacity = +this.layer.fillOpacity
          let fillColor = null
          if (this.layer.fillColor.mode === 'solid') {
            fillColor = this.layer.fillColor.color
          } else if (this.layer.fillColor.mode === 'linear') {
            fillColor = chroma.scale(this.layer.fillColor.colors).domain([valueMin, valueMax])(value)
          } else if (this.layer.fillColor.mode === 'threshold') {
            fillColor = this.layer.fillColor.color
            let values = this.layer.fillColor.thresholds[this.layer.fillColor.individualThreshold ? circleMarker.feature.properties.ID : 'General']
            for (let index = 0; index < this.layer.fillColor.levels.length; index++) {
              if (values[index] !== '' && value >= +values[index]) fillColor = this.layer.fillColor.levels[index].color
            }
          }
          circleMarker.setRadius(radius)
          try {
            if (this.firstGroupCluster && circleMarker.clusterId && this.firstGroupCluster[circleMarker.clusterId]) {
              try {
                // let zindexCluster = 1000;
                // let elementIconCluster;
                // let zIndexNow;

                // let styleCss=elementIconCluster.getAttribute('style')+`;z-index: ${zindexCluster} !important`
                // elementIconCluster.style = elementIconCluster.style + `;${zindexCluster} !important`;
                // elementIconCluster.setAttribute('style',styleCss)
                let elementIconCluster = document.querySelector(`.htmlicon-cluster${this.mapIndex}-${circleMarker.clusterId}`)
                if (elementIconCluster) {
                  elementIconCluster.style.zIndex = this.idxLayer
                }
              } catch {}
              let radiusPixel = this.logCircleRadiusInPixels(radius, circleMarker._latlng)
              let colorCluster = null
              let opacityCluster = Number(fillOpacity)
              if (fillColor) {
                if (fillColor._rgb) {
                  colorCluster = `rgba(${fillColor._rgb[0]}, ${fillColor._rgb[1]}, ${fillColor._rgb[2]}, ${opacityCluster})`
                }
              }
              let elementId = document.getElementById(`cluster${this.mapIndex}-${circleMarker.clusterId}`)
              if (elementId) {
                if (radiusPixel != null) {
                  radiusPixel = radiusPixel >= 15 ? radiusPixel : 15
                  elementId.style.fontSize = `${radiusPixel}px`
                  try {
                    let elementCountCluter = elementId.querySelector('i')
                    elementCountCluter.style.fontSize = `${radiusPixel * 0.3}px`
                  } catch {}
                }

                if (colorCluster) {
                  elementId.style.color = colorCluster
                }
              }
            }
          } catch {}
          circleMarker.setStyle({ stroke: true, fill: true, weight, color, fillColor, fillOpacity, zIndex: 1000 })
          this.addAutoPopup(arrRuleShowPopup, value, circleMarker._latlng, this.layer, locationItem, date, unit)
        }
      }
      if (arrayPopupUpdate && Object.keys(arrayPopupUpdate).length) {
        this.$store.commit('map/UPDATE_POUPUP_FROM_UPDATE_LOCATION_POINT', arrayPopupUpdate)
      }
      this.$layerStatus[this.layer.id][date][this.mapIndex] = true
      this.getStatusSuccess++
    },
    drawValueNA(circleMarker) {
      if (!this.layer.navalue.visible) {
        circleMarker.setStyle({ stroke: false, fill: false })
      } else {
        const radius = +this.layer.navalue.radius
        const weight = +this.layer.navalue.weight
        const color = this.layer.navalue.color
        const fillColor = this.layer.navalue.fillColor
        const fillOpacity = +this.layer.navalue.fillOpacity
        circleMarker.setRadius(radius)
        circleMarker.setStyle({ stroke: true, fill: true, weight, color, fillColor, fillOpacity })
      }
    },
    delete() {
      if (!this.leafletLayer) return

      this.leafletLayer.eachLayer((circleMarker) => {
        circleMarker.off()
        circleMarker.remove()
      })
      this.leafletLayer.off()
      this.leafletLayer.remove()
    },
    async inspect() {
      try {
        if (!this.leafletLayer) return
        const layers = this.leafletLayer._layers
        const bound = this.leafletLayer.getBounds()
        let zoomNow = 20
        let zoomMax = 20
        let bounds = []
        if (this.selectedGroupItems && this.selectedGroupItems.items) {
          for (let i in layers) {
            const locationItem = `${layers[i].feature.properties.ID}-${this.layer.item}`
            if (this.selectedGroupItems.items.indexOf(layers[i].feature.properties.ID) > -1 && this.NAValRecord.indexOf(locationItem) < 0) {
              bounds.push(layers[i].latlng)
            }
          }
        } else {
          for (let i in layers) {
            const locationItem = `${layers[i].feature.properties.ID}-${this.layer.item}`
            if (this.NAValRecord.indexOf(locationItem) < 0) {
              bounds.push(layers[i].latlng)
            }
          }
        }
        if (this.map) {
          if (bounds.length) {
            if (bounds.length == 1) {
              this.map.setView(bounds[0], zoomMax)
            } else {
              try {
                let zoomFullAll = this.map.getBoundsZoom(bounds)
                zoomNow = zoomFullAll >= zoomMax ? zoomMax : zoomFullAll
              } catch {}
              this.map.fitBounds(bounds, {
                animate: true,
                duration: 0.4,
                maxZoom: zoomNow,
              })
            }
          } else if (bound) {
            try {
              let zoomFullAll = this.map.getBoundsZoom(bound)
              zoomNow = zoomFullAll >= zoomMax ? zoomMax : zoomFullAll
            } catch {}

            this.map.fitBounds(bound, {
              animate: true,
              duration: 0.4,
              maxZoom: zoomNow,
            })
          }
        }
      } catch {}
    },
    addAutoPopup(arrRuleShowPopup, value, LatLng, layer, locationItem, date, unit) {
      try {
        let htmlPopup = ''
        let popupMarker = []
        if (arrRuleShowPopup.length > 0) {
          for (let k = 0; k < arrRuleShowPopup.length; k++) {
            let rule = this.conditionRule[arrRuleShowPopup[k]]
            let operatorRule = rule.condition
            let valueRule = Number(rule.value)
            let message = this.tAll(rule.message, { LOCATION: `${locationItem}`, ITEM: `${layer.item}`, TIME: `${date}`, LAYER: `${layer.name}`, CONDITION: `${operatorRule}`, VALUE: value, UNIT: `${unit}` })
            if ((operatorRule === `${this.operator[0]}` && value > valueRule) || (operatorRule === `${this.operator[1]}` && value >= valueRule) || (operatorRule === `${this.operator[2]}` && value < valueRule) || (operatorRule === `${this.operator[3]}` && value <= valueRule) || (operatorRule === `${this.operator[4]}` && value == valueRule) || (operatorRule === `${this.operator[5]}` && value != valueRule)) {
              let duration = Number(rule.duration)
              if (!rule.multiShowEvent) {
                let checkShow = this.eventHasShow.filter((e) => e.locationItem === locationItem && e.layerID === layer.id)
                if (checkShow && checkShow.length === 0) this.eventHasShow.push({ locationItem: locationItem, layerID: layer.id })
                else continue
              }
              if (rule.visible == 'popup') {
                htmlPopup = `
                        <div class='popup-auto-event svgText animated fadeOutUp event-color-text-${rule.style}' style='-webkit-animation-duration: ${duration / 1000}s;animation-duration: ${duration / 1000}s;'>
                          <div>${message}</div>
                        </div>
                        `
                popupMarker[`${k}_${rule.layer}`] = L.popup({ autoPan: false }).setLatLng(LatLng).setContent(htmlPopup).addTo(this.map)
                setTimeout(() => {
                  popupMarker[`${k}_${rule.layer}`].removeFrom(this.map)
                }, duration)
              } else {
                this.toastLayer.push({ style: rule.style, text: message, timeout: duration })
              }
            }
          }
        }
      } catch {}
    },
    tAll(string, param = {}) {
      try {
        let stringTemp = string
        let keyParam = Object.keys(param)
        if (keyParam.length > 0) {
          for (let i = 0; i < keyParam.length; i++) {
            string = string.replaceAll(`[${keyParam[i]}]`, `${param[keyParam[i]]}`)
          }
        }
        return string
      } catch {
        return stringTemp
      }
    },
  },
}
</script>

<style lang="scss">
.marker-cluster {
  color: #000;
}

.leaflet-popup.leaflet-zoom-animated {
  margin-bottom: 0;
  pointer-events: none;

  .leaflet-popup-content {
    box-shadow: none;
    background: transparent;
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    background: transparent;
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.popup-auto-event {
  // max-width: 200px;
  font-weight: bold;
  font-size: 14px;
  pointer-events: none;
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff;
  animation-name: fadeOutUp;
}

.svgText {
  fill: white;
  stroke: black;
  stroke-width: 15px;
  stroke-linejoin: round;
  paint-order: stroke;
}
.leaflet-marker-icon {
  // position: relative;
}
.custom-cluster-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &-count {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    font-size: 10px;
    font-weight: bold;
    display: inline-flex;
    transform: translate(-50%, -50%);
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #1fea42 !important;
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: 0;
    text-shadow: none !important;
  }

  &.fa-play {
    font-size: 30px;
    transform: translate(-50%, -50%) rotate(-90deg);

    .fa-angles-down {
      left: calc(50% - 3px);
      &::before {
        top: calc(50% + 0px);
        left: calc(50% -3px);
        transform: rotate(90deg);
      }
    }
  }
}
</style>
