const fs = require('fs')
const path = require('path')
import Jimp from 'jimp/es'
const cachedJpegDecoder = Jimp.decoders['image/jpeg']
Jimp.decoders['image/jpeg'] = (data) => {
  const userOpts = { maxMemoryUsageInMB: 1024 }
  return cachedJpegDecoder(data, userOpts)
}
const _ = require('lodash')
import papa from 'papaparse'
import GLightbox from 'glightbox'
import { v4 as uuidv4 } from 'uuid'
import * as zip from '@zip.js/zip.js/dist/zip.min.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { splitByLastIndex } from '@/utilities/StringUtility.js'
export default {
  mixins: [ToastificationContent],
  computed: {
    dataFilter() {
      return this.$store.state.settings.dataFilter
    },
  },
  methods: {
    resizeImage(base64Str, maxWidth = 165, maxHeight = 115) {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = maxWidth
          const MAX_HEIGHT = maxHeight
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
        }
      })
    },
    uploadImages(clickingData, metadataTemplate, files, METHOD = 'upload') {
      const self = this
      return new Promise(async (resolve, reject) => {
        try {
          if (METHOD === 'upload') {
            let imageResult = []
            this.showLoadingImage = true
            for (let i = 0; i < files.length; i++) {
              if (files[i].name.endsWith('zip')) {
                let buf = await new Promise((resolveRead, reject) => {
                  let reader = new FileReader()
                  reader.onload = async function (e) {
                    let codes = new Uint8Array(e.target.result)
                    resolveRead(codes)
                  }
                  reader.readAsArrayBuffer(files[i])
                })
                const reader = new zip.ZipReader(new zip.BlobReader(new Blob([buf])))
                const entries = await reader.getEntries()
                let imageFiles = entries.filter((e) => !e.filename.endsWith('csv'))
                for (let i = 0; i < imageFiles.length; i++) {
                  // Read Image from zip file
                  let blobImg = await imageFiles[i].getData(new zip.BlobWriter())
                  let bufImg = Buffer.from(await blobImg.arrayBuffer())
                  let base64 = bufImg.toString('base64')
                  let dataBase64 = `data:image/png;base64,${base64}`
                  let checkImage = true
                  try {
                    checkImage = await self.checkImageCorruptedWithBase64FromImageMixin(dataBase64, null, bufImg)
                  } catch {}
                  if (checkImage) {
                    let fileName = imageFiles[i].filename.replaceAll('\\', '')
                    let imageOnlyShow = false
                    let latlngSidebarImport = null
                    let locationItemImport = ''
                    try {
                      if (!clickingData.clickId && !clickingData.latlng) {
                        locationItemImport = fileName.split('_')[1]
                        const locationUids = await this.getLocationUids()
                        const locationsItems = locationUids.map((l) => splitByLastIndex(l, ' - ')[0])
                        let findLocation = locationsItems.find((loc) => loc === locationItemImport)
                        if (!findLocation) imageOnlyShow = true
                        latlngSidebarImport = findLocation ? this.$latlngOnMap[findLocation] : ''
                      }
                    } catch {}
                    // Read Metadata csv from zip file
                    let metadataFile
                    let metadata
                    try {
                      metadataFile = entries.find((e) => e.filename.startsWith(imageFiles[i].filename.replace(/\.[^/.]+$/, '')) && e.filename.endsWith('csv'))
                      metadata = _.cloneDeep(metadataTemplate)
                      if (metadataFile) {
                        let blob = await metadataFile.getData(new zip.BlobWriter())
                        let file = new File([blob], metadataFile.filename.slice(metadataFile.filename.lastIndexOf('/') + 1))
                        metadata = await this.readCSVMetadata(file)
                      }
                    } catch {}
                    // Create Image data
                    const imageMetadata = { id: uuidv4(), layerId: clickingData.layerId, locationItem: clickingData.clickId ? clickingData.clickId : locationItemImport, latlngPos: !clickingData.latlng ? latlngSidebarImport : clickingData.latlng, fileName, metadata: metadata, imageOnlyShow }
                    this.$imagesSrc[imageMetadata.id] = `data:image/png;base64,${base64}`
                    imageResult.push(imageMetadata)
                  }
                }
              }
              // Handle image file
              else {
                var fileName = files[i].name
                let arrayLine = _.cloneDeep(metadataTemplate)

                let base = await new Promise((resolveRead, reject) => {
                  let reader = new FileReader()
                  reader.onload = async function (e) {
                    resolveRead(e.target.result)
                  }
                  reader.readAsArrayBuffer(files[i])
                })
                let bufImg = Buffer.from(base)
                let base64 = bufImg.toString('base64')
                let dataBase64 = `data:image/png;base64,${base64}`
                let checkImage = true
                try {
                  checkImage = await self.checkImageCorruptedWithBase64FromImageMixin(dataBase64, null, bufImg)
                } catch {}
                if (checkImage && dataBase64) {
                  const imageMetadata = { id: uuidv4(), layerId: clickingData.layerId, locationItem: clickingData.clickId, latlngPos: clickingData.latlng, fileName, metadata: arrayLine, imageOnlyShow: false }
                  this.$imagesSrc[imageMetadata.id] = dataBase64
                  imageResult.push(imageMetadata)
                }
              }
            }
            resolve(imageResult)
          } else if (METHOD === 'replace') {
            const fileName = files[0].name

            let baseReplace = await new Promise((resolveRead, reject) => {
              let reader = new FileReader()
              reader.onload = async function (e) {
                resolveRead(e.target.result)
              }
              reader.readAsArrayBuffer(files[0])
            })
            let bufImg = Buffer.from(baseReplace)
            let base64 = bufImg.toString('base64')
            let dataBase64 = `data:image/png;base64,${base64}`
            let checkImage = true
            try {
              checkImage = await self.checkImageCorruptedWithBase64FromImageMixin(dataBase64, null, bufImg)
            } catch {}
            let imageResult
            if (checkImage) {
              imageResult = {
                fileName,
                src: dataBase64,
              }
            }
            resolve(imageResult)
          }
        } catch (error) {
          resolve()
          console.error('Error occur when read image file' + error)
          this.$toast({ component: ToastificationContent, props: { title: 'Error occur when read image file', text: error.message, icon: 'ArrowDownIcon', variant: 'danger' } })
        }
      })
    },
    async checkImageCorruptedWithBase64FromImageMixin(dataBase64, filePath, dataBuf) {
      return await new Promise(async (resolve, reject) => {
        let checkCorruptWithJimp = true
        if (filePath) {
          try {
            await Jimp.read(filePath)
            checkCorruptWithJimp = true
          } catch {
            checkCorruptWithJimp = false
          }
        } else {
          if (dataBuf) {
            try {
              await Jimp.read(dataBuf)
              checkCorruptWithJimp = true
            } catch (error) {
              checkCorruptWithJimp = false
            }
          }
        }
        if (!checkCorruptWithJimp) {
          resolve(false)
          return
        } else {
          let img = new Image()
          img.src = dataBase64
          img.onload = () => {
            // try {
            //   const canvas = document.createElement('canvas');
            //   const ctx = canvas.getContext('2d');
            //   canvas.width = img.width;
            //   canvas.height = img.height;
            //   ctx.drawImage(img, 0, 0);
            //   const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
            //   let transparentPixels = 0;
            //   const totalPixels = img.width * img.height;
            //   for (let i = 3; i < imageData.length; i += 4) {
            //     if (imageData[i] === 0) {
            //       transparentPixels++;
            //     }
            //     let transparentRatio = transparentPixels / totalPixels;
            //     if (transparentRatio > 0.99) {
            //       resolve(false);
            //       return;
            //     }
            //   }
            //   resolve(true);
            // } catch {
            //   resolve(false);
            // }
            resolve(true)
          }
          img.onerror = (e) => {
            resolve(false)
          }
        }
      })
    },
    readCSVMetadata(file) {
      return new Promise(async (resolve, reject) => {
        let filehandle
        let fileBuffer
        if (typeof file === 'string') {
          try {
            filehandle = await fs.promises.open(file, 'r')
            fileBuffer = await filehandle.readFile()
            fileBuffer = fileBuffer.toString()
          } finally {
            if (filehandle) await filehandle.close()
          }
        } else {
          fileBuffer = file
        }
        papa.parse(fileBuffer, {
          worker: true,
          complete: function (result) {
            resolve(result.data)
          },
        })
      })
    },

    getImageSourceById(id) {
      return this.$imagesSrc[id]
    },

    filterImageMetadata(popupImages, selectedFilter, source = 'border') {
      let arrayPopup = _.cloneDeep(popupImages)
      let imageData = []
      let lightBoxEle = []
      let arrayFilter = []
      if (Object.keys(selectedFilter).length > 0) {
        for (const property in selectedFilter) {
          let arrayChilren = selectedFilter[property]
          if (arrayChilren && arrayChilren.length !== 0) arrayFilter = arrayFilter.concat(arrayChilren)
        }
      }
      if (arrayFilter.length === 0) {
        imageData = arrayPopup
        if (source === 'sidebar') {
          for (let i = 0; i < arrayPopup.length; i++) {
            if (source === 'sidebar') lightBoxEle.push(this.createDataImageLightbox(arrayPopup[i].id, arrayPopup[i].metadata))
          }
        }
      } else {
        for (let i = 0; i < arrayPopup.length; i++) {
          let imageCheck = true
          for (const property in selectedFilter) {
            let arrayChilren = selectedFilter[property]
            if (arrayChilren && arrayChilren.length !== 0) {
              let imageFilter = this.filterImageByItem(arrayChilren, arrayPopup[i], property)
              if (!imageFilter) {
                imageCheck = false
                break
              }
            }
          }

          if (imageCheck) {
            imageData.push(arrayPopup[i])
            if (source === 'sidebar') lightBoxEle.push(this.createDataImageLightbox(arrayPopup[i].id, arrayPopup[i].metadata))
          }
        }
      }
      if (source === 'sidebar') this.lightBoxSideBar = GLightbox({ elements: lightBoxEle })
      return imageData
    },

    filterImageByItem(arrayFilter, arrayPopup, filterVal) {
      for (let j = 0; j < arrayFilter.length; j++) {
        for (let k = 0; k < arrayPopup.metadata.length; k++) {
          if (arrayPopup.metadata[k][1] === filterVal && arrayPopup.metadata[k][2].replaceAll('、', ',').split(',').includes(arrayFilter[j])) return true
        }
      }
      return false
    },

    getlistCountFilter(popupImages) {
      if (this.dataFilter[0].children.length === 0) return null
      let arrayObjFilter = this.dataFilter[0].children.filter((obj) => obj.visible === 'show')
      let listObjFilter = {}
      for (let i = 0; i < arrayObjFilter.length; i++) {
        for (let j = 0; j < arrayObjFilter[i].children.length; j++) {
          listObjFilter[arrayObjFilter[i].children[j].text] = this.countValueFilter(arrayObjFilter[i].children[j].text, popupImages, arrayObjFilter[i].text)
          if (arrayObjFilter[i].children[j].children.length !== 0) {
            for (let k = 0; k < arrayObjFilter[i].children[j].children.length; k++) {
              listObjFilter[arrayObjFilter[i].children[j].children[k].text] = this.countValueFilter(arrayObjFilter[i].children[j].children[k].text, popupImages, arrayObjFilter[i].text)
            }
          }
        }
      }
      return listObjFilter
    },
    countValueFilter(value, popupImages, key) {
      let count = 0
      for (let i = 0; i < popupImages.length; i++) {
        for (let j = 0; j < popupImages[i].metadata.length; j++) {
          if (popupImages[i].metadata[j][1] === key && popupImages[i].metadata[j][2].replaceAll('、', ',').split(',').includes(value)) count++
        }
      }
      return count
    },

    createDataImageLightbox(id, metadata) {
      return {
        href: this.getImageSourceById(id),
        description: this.showDesMetadata(metadata),
        descPosition: 'right',
        type: 'image',
        effect: 'fade',
        zoomable: true,
        draggable: true,
        dragToleranceY: 0,
      }
    },
    showDesMetadata(metadata) {
      let stringMetadata = ''
      for (let i = 0; i < metadata.length; i++) {
        if (i === 0) continue
        if (metadata[i][0] !== '') {
          stringMetadata = stringMetadata + `</ul>`
          stringMetadata = stringMetadata + `<div class="box-key-metadata">${metadata[i][0]}:</div>`
          stringMetadata = stringMetadata + `<ul class="key-metadata">`
        }
        for (let j = 0; j < metadata[i].length; j++) {
          if (j !== 0 && j !== metadata[i].length - 1) {
            stringMetadata = stringMetadata + `<li class="box-value-metadata"><span>${metadata[i][j]}</span> :`
          }
          if (j === metadata[i].length - 1) {
            stringMetadata = stringMetadata + ` ${metadata[i][j]}</li>`
          }
        }
      }
      return stringMetadata
    },
  },
}
