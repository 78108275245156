<template>
  <div class="legend-shape-item" :style="wrapStyles">
    <img :id="spikeID" :width="size" :height="size" :style="spikesStyles" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: ['layer', 'radius', 'border', 'background', 'opacity'],
  mounted() {
    this.drawSpikes()
  },
  data() {
    return {
      spikeID: uuidv4(),
    }
  },
  computed: {
    size() {
      return Number(this.radius) + Number(this.border.width)
    },
    wrapStyles() {
      return {}
    },
    spikesStyles() {
      return {
        marginRight: 'auto',
        marginLeft: 'auto',
      }
    },
  },
  watch: {
    layer() {
      this.drawSpikes()
    },
  },
  methods: {
    drawSpikes() {
      this.$nextTick(() => {
        const canvas = document.createElement('CANVAS')
        canvas.width = this.size
        canvas.height = this.size
        const ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        const point = { x: this.size / 2, y: this.size / 2 }
        const cx = point.x
        const cy = point.y

        ctx.beginPath()
        ctx.moveTo(cx - (Math.sqrt(2) / 2) * this.radius / 2, cy + (Math.sqrt(2) / 2) * this.radius / 2)
        ctx.lineTo(cx - (Math.sqrt(2) / 2) * this.radius / 2, cy - (Math.sqrt(2) / 2) * this.radius / 2)
        ctx.lineTo(cx + (Math.sqrt(2) / 2) * this.radius / 2, cy - (Math.sqrt(2) / 2) * this.radius / 2)
        ctx.lineTo(cx + (Math.sqrt(2) / 2) * this.radius / 2, cy + (Math.sqrt(2) / 2) * this.radius / 2)
        ctx.closePath()

        ctx.fillStyle = this.hexToRGB(this.background, this.opacity)
        ctx.strokeStyle = this.border.color
        ctx.lineWidth = this.border.width
        ctx.fill()
        ctx.stroke()
        let src = canvas.toDataURL()
        if(document.getElementById(this.spikeID)) document.getElementById(this.spikeID).src = src;
        canvas.remove();
      })
    },
    hexToRGB(hex, alpha) {
      let r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
          return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
          return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    },
  },
}
</script>
