<template>
  <b-modal :title="$t('delete_image')" centered ok-only ok-variant="danger" :ok-title="$t('delete')" ref="deleteImage" :body-class="'custom-body'" @ok="removeAllImage">
    {{ $t('message_delete_image') }}
  </b-modal>
</template>

<script>
export default {
  props: ['layer'],
  methods: {
    open() {
      this.$refs.deleteImage.show();
    },

    removeAllImage() {
      this.$parent.clearFilter();
      this.$store.commit('image/DELETE_ALL_POPUP_IMAGES_BY_LAYER', { layerId: this.layer.id });
    },
  },
};
</script>
