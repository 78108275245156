<template>
  <div class="popup-select-metadata">
    <b-button v-if="mode === 'border'" style="width: 100%; min-height: 18px; cursor: pointer !important; background-color: transparent !important; line-height: normal" variant="flat-primary" class="datasource-select-container">
      <div class="d-flex">
        <div style="text-align: left; padding-top: 2px; white-space: normal" :title="formartText(selected.toString())" :style="{ fontSize: mode === 'border' ? $parent.layer.popup.contentSize + 'px' : $parent.layer.popup.contentColorBorderless + 'px', color: mode === 'border' ? $parent.layer.popup.contentColor : $parent.layer.popup.contentColorBorderless, width: 100 + '%', overflow: 'hidden' }">{{ formartText(selected.toString()) }}</div>
        <feather-icon style="align-self: center" size="16" icon="ChevronDownIcon" :style="{ fontSize: mode === 'border' ? $parent.layer.popup.contentSize + 'px' : $parent.layer.popup.contentColorBorderless + 'px', color: mode === 'border' ? $parent.layer.popup.contentColor : $parent.layer.popup.contentColorBorderless }" />
      </div>
      <div @click="setFlagListFilter" style="padding: 0px; width: 300px; z-index: 11; left: -170px; max-height: 100px !important; overflow-y: auto" class="datasource-select bg-light custom-scrollbar">
        <table class="table">
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b-form-group>
                  <b-form-checkbox-group id="checkbox-group-2" v-model="selected" name="flavour-2" class="demo-inline-spacing" style="padding-left: 10px">
                    <div v-for="(select, index) in options" :key="index">
                      <b-form-checkbox :value="select.text"> {{ select.text }}</b-form-checkbox>
                      <div v-if="select.children.length !== 0" style="margin-left: 10px">
                        <b-form-checkbox v-for="(selectChildren, indexChildren) in select.children" :key="indexChildren" :value="selectChildren.value"> {{ selectChildren.value }} </b-form-checkbox>
                      </div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-button>
    <b-button v-if="mode === 'borderless'" style="width: 154px; min-height: 18px; cursor: pointer !important; background-color: transparent !important" variant="flat-primary" class="datasource-select-container">
      <div class="d-flex">
        <div style="text-align: left; padding-top: 2px; white-space: normal" :title="formartText(selected.toString())" :style="{ fontSize: mode === 'border' ? $parent.layer.popup.contentSize + 'px' : $parent.layer.popup.contentColorBorderless + 'px', color: mode === 'border' ? $parent.layer.popup.contentColor : $parent.layer.popup.contentColorBorderless, width: 100 + '%', overflow: 'hidden' }">
          {{ formartText(selected.toString()) }}
        </div>
        <feather-icon style="align-self: center" size="16" icon="ChevronDownIcon" :style="{ fontSize: mode === 'border' ? $parent.layer.popup.contentSize + 'px' : $parent.layer.popup.contentColorBorderless + 'px', color: mode === 'border' ? $parent.layer.popup.contentColor : $parent.layer.popup.contentColorBorderless }" />
      </div>
      <div @click="setFlagListFilter" style="padding: 0px; width: 310px; z-index: 11; left: -170px; max-height: 100px !important; overflow-y: auto" class="datasource-select bg-light custom-scrollbar">
        <table class="table">
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b-form-group>
                  <b-form-checkbox-group id="checkbox-group-2" v-model="selected" name="flavour-2" class="demo-inline-spacing" style="padding-left: 10px">
                    <div v-for="(select, index) in options" :key="index">
                      <b-form-checkbox :value="select.text"> {{ select.text }}</b-form-checkbox>
                      <div v-if="select.children.length !== 0" style="margin-left: 10px">
                        <b-form-checkbox v-for="(selectChildren, indexChildren) in select.children" :key="indexChildren" :value="selectChildren.value"> {{ selectChildren.value }} </b-form-checkbox>
                      </div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-button>
  </div>
</template>

<script>
const _ = require('lodash');
import Ripple from 'vue-ripple-directive';
import VirtualList from 'vue-virtual-scroll-list';
import { BFormCheckboxGroup } from 'bootstrap-vue';

export default {
  props: ['options', 'value', 'mode'],
  directives: { Ripple },
  components: { VirtualList, BFormCheckboxGroup },
  data() {
    return {
      flagSetListFilter: false,
    };
  },
  computed: {
    selected: {
      get() {
        this.flagSetListFilter = false;
        return this.value;
      },
      set(value) {
        if (this.flagSetListFilter) this.$emit('selectValue', value);
      },
    },
  },
  watch: {},
  methods: {
    setFlagListFilter() {
      this.flagSetListFilter = true;
    },
    formartText(string) {
      string = string.replaceAll(',', ' 、');
      return string;
    },
  },
};
</script>
<style>
.popup-select-metadata .custom-control-label {
  cursor: pointer;
}
</style>
<style scoped>
.custom-control-inline {
  display: block;
}
.demo-inline-spacing > * {
  margin-right: 1rem;
  margin-top: 1rem;
}
.datasource-select-container {
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}
.datasource-select-container:disabled .datasource-pointer {
  cursor: not-allowed !important;
}

.dark-layout .datasource-select-container {
  color: #d0d2d6;
}

.datasource-select-container .datasource-pointer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-title {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.datasource-select-container .datasource-description {
  color: #397bff;
}

.datasource-select-container .datasource-select {
  position: absolute;
  left: 0;
  top: 100%;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 2;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 634px;
  transition: transform 0.4s, opacity 0.4s;
  margin-top: 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}

.datasource-select-container:focus .datasource-select {
  transform: scale(1);
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select {
  transform: scale(1);
  opacity: 1;
}

.datasource-select-container:focus .datasource-select:hover {
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select:hover {
  opacity: 1;
}

.datasource-select-container table {
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}

table {
  background-color: #fff;
}

.datasource-select-container th {
  padding: 10px;
  border: none;
  text-align: left;
  text-transform: uppercase;
}

.datasource-select-container td {
  padding: 0;
  border: none;
  text-align: left;
  max-width: 200px;
  vertical-align: top;
  border-right: 2px solid #f3f2f7 !important;
}

.dark-layout .datasource-select-container td {
  border-right: 2px solid #343d55 !important;
}

.datasource-select-container td:last-child {
  border-right: none !important;
}

.datasource-select-container .datasource-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .datasource-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .datasource-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .datasource-option.active {
  background-color: #323f6e;
}

.datasource-select-container .datasource-option .datasource-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .datasource-option .datasource-content .datasource-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datasource-select-container .item-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .item-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .item-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .item-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .item-option.active {
  background-color: #323f6e;
}

.datasource-select-container .item-option .item-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .item-option .item-content .item-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datasource-select-container .count-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datasource-select-container .custom-scrollbar {
  min-height: 52px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
