<template>
  <div class="datasource-container" id="datasource-container-id">
    <!-- Datasource Toolbar -->
    <div class="datasource-toolbar">
      <div class="d-flex align-items-center">
        <feather-icon icon="DatabaseIcon" style="margin-right: 5px" size="16" />
        <span style="padding: 0 5px">{{ $t('datasources') }}</span>
        <!-- <span :title="$t('add_datasource')" class="d-flex align-items-center">
          <feather-icon icon="PlusIcon" size="18" style="margin-left: 5px; cursor: pointer" @click="openAddModal" />
        </span> -->
      </div>
    </div>
    <b-button variant="primary" :title="$t('import_data_sources')" class="btn-sm text-center w-100 mb-0-5rem" @click="openAddModal">{{ $t('import_data_sources') }}</b-button>
    <VirtualDragListDatasourceItem />
    <!-- Modal -->
    <DatasourceModal ref="addDatasourceModal" />
    <div class="options-datasource-menu" ref="refMenuDatasourceOption" id="menu-datasource-option" style="top: 0" v-show="datasourceOptionActiveIsShow && datasourceOptionActive && datasourceOptionActive.id">
      <ul v-if="datasourceOptionActiveIsShow && datasourceOptionActive && datasourceOptionActive.id">
        <li :title="liveMode ? 'LIVE' : datasourceOptionActive.size">
          <button class="d-flex align-items-center" @click.stop="toggleLiveMode(datasourceOptionActive)">
            <span class="menu-icon d-flex align-items-center">
              <b-badge :title="liveMode ? 'LIVE' : datasourceOptionActive.size" style="font-weight: 400; font-size: 80%" :variant="liveMode ? 'light-danger' : 'light-secondary'">
                <feather-icon v-if="datasourceOptionActive.storage_mode === 'IDB'" icon="DatabaseIcon" />
                <feather-icon v-if="datasourceOptionActive.storage_mode === 'RAM'" icon="FilmIcon" />
                <feather-icon v-if="datasourceOptionActive.storage_mode === 'FILE'" icon="HardDriveIcon" />
                <feather-icon v-if="datasourceOptionActive.storage_mode === 'DYNAMODB' || datasourceOptionActive.storage_mode === 'S3'" :icon="liveMode ? 'RadioIcon' : 'CloudIcon'" />
              </b-badge>
            </span>
            <span>{{ liveMode ? 'LIVE' : datasourceOptionActive.size }}</span>
          </button>
        </li>
        <li v-if="['point', 'timeseries', 'typhoon'].includes(datasourceOptionActive.type)" :title="$t('view')">
          <button class="d-flex align-items-center" @click.stop="openDatatableModal">
            <span class="menu-icon d-flex align-items-center">
              <b-badge style="font-weight: 400; font-size: 80%" variant="light-secondary">
                <feather-icon icon="DatabaseIcon" style="cursor: pointer" />
              </b-badge>
            </span>
            <span>{{ $t('view') }}</span>
          </button>
        </li>
        <li v-if="['netcdf'].includes(datasourceOptionActive.type)" :title="$t('view')">
          <button class="d-flex align-items-center" @click.stop="openInformationModal">
            <span class="menu-icon d-flex align-items-center">
              <b-badge style="font-weight: 400; font-size: 80%" variant="light-secondary">
                <feather-icon icon="InfoIcon" style="cursor: pointer" />
              </b-badge>
            </span>
            <span>{{ $t('view') }}</span>
          </button>
        </li>
        <li :title="$t('download')">
          <button class="d-flex align-items-center" @click.stop="downloadDatasource(datasourceOptionActive)">
            <span class="menu-icon d-flex align-items-center">
              <b-badge style="font-weight: 400; font-size: 80%" variant="light-secondary">
                <feather-icon icon="DownloadIcon" style="cursor: pointer" />
              </b-badge>
            </span>
            <span>{{ $t('download') }}</span>
          </button>
        </li>
        <li :title="$t('delete')">
          <button class="d-flex align-items-center" @click.stop="openDatasourceDeleteConfirm">
            <span class="menu-icon d-flex align-items-center">
              <b-badge style="font-weight: 400; font-size: 80%" variant="light-secondary">
                <feather-icon icon="Trash2Icon" class="text-danger" style="cursor: pointer" />
              </b-badge>
            </span>
            <span>{{ $t('delete') }}</span>
          </button>
        </li>
      </ul>
    </div>
    <DatatableModal ref="datatableModal" v-if="datasourceOptionActive && datasourceOptionActive.id" :datasource="datasourceOptionActive" />
    <DatasourceItemDeleteConfirm v-if="datasourceOptionActive && datasourceOptionActive.id" ref="datasourceItemDeleteConfirm" :datasource="datasourceOptionActive" />
  </div>
</template>

<script>
import Vue from 'vue'
const _ = require('lodash')
import axios from 'axios'
const fs = require('fs')
import FileMixin from '@/mixins/FileMixin.js'
import DatasourceModal from './modal/DatasourceModal.vue'
// import ToolsModal from './modal/ToolsModal.vue';
import { STORAGE_MODE } from '@/constants/repository.js'
import DatasourceItemDeleteConfirm from '@/views/ecoplot-desktops/tabs/map/control-panel/datasources/tools/DatasourceItemDeleteConfirm.vue'
import DatatableModal from '@/views/ecoplot-desktops/tabs/map/control-panel/datasources/tools/DatatableModal.vue'
import DatasourceItem from './DatasourceItem.vue'
import Ripple from 'vue-ripple-directive'
import Draggable from 'vuedraggable'
import VirtualList from 'vue-virtual-scroll-list'
import { ECOPLOT_API } from '@/constants/urls'
import { formatBytes } from '@/utilities/DatasourceUtility.js'
import VirtualDragListDatasourceItem from './VirtualDragListDatasourceItem.vue'

export default {
  directives: { Ripple },
  components: { DatasourceItemDeleteConfirm, DatatableModal, DatasourceModal, DatasourceItem, Draggable, VirtualList, VirtualDragListDatasourceItem },
  mixins: [FileMixin],
  data() {
    return {
      drag: false,
      background: {
        default: 'bg-light-primary',
        primary: 'bg-light-primary',
        success: 'bg-light-success',
        warning: 'bg-light-warning',
        danger: 'bg-light-danger',
      },
      funcInterVal: null,
    }
  },
  mounted() {
    const self = this
    this.$nextTick(() => {
      const element = this.$refs.refMenuDatasourceOption
      document.addEventListener('mousedown', (event) => {
        setTimeout(() => {
          try {
            let elementPopperDatasourceOption
            if (self.datasourceOptionActive && self.datasourceOptionActive.id) {
              elementPopperDatasourceOption = document.getElementById(`${self.datasourceOptionActive.id}-option-datasource`)
            }
            if (elementPopperDatasourceOption && self.datasourceOptionActiveIsShow) {
              if (!element.contains(event.target) && !elementPopperDatasourceOption.contains(event.target)) {
                self.$store.commit('map/UPDATE_DATASOURCE_OPTION_ACTIVE_SHOW', false)
              }
            }
          } catch {}
        }, 300)
      })
      try {
        document.getElementById('draggableDatasource').addEventListener('scroll', function () {
          setTimeout(() => {
            if (self.datasourceOptionActiveIsShow) {
              self.$store.commit('map/UPDATE_DATASOURCE_OPTION_ACTIVE_SHOW', false)
            }
          }, 300)
        })
      } catch {}
      try {
        document.getElementById('custom-scrollbar-sidebar-right').addEventListener('scroll', function () {
          setTimeout(() => {
            if (self.datasourceOptionActiveIsShow) {
              self.$store.commit('map/UPDATE_DATASOURCE_OPTION_ACTIVE_SHOW', false)
            }
          }, 300)
        })
      } catch {}
    })
  },
  computed: {
    isAwsSource() {
      return this.datasourceOptionActive.storage_mode == STORAGE_MODE.DYNAMODB || this.datasourceOptionActive.storage_mode == STORAGE_MODE.S3
    },
    liveMode() {
      if (this.datasourceOptionActive) {
        return this.datasourceOptionActive.live
      }
      return false
    },
    timeRefreshLiveMode() {
      return this.$store.state.settings.liveModeRefresh
    },
    datasources: {
      get() {
        return this.$store.state.datasource.datasources
      },
      set(value) {
        this.setDatasources(value)
      },
    },
    modal() {
      return this.$store.state.ecoplot.modal
    },
    percent() {
      return this.modal ? this.modal.percent || 0 : 0
    },
    isLoadData() {
      return this.$store.state.ecoplot.modal != null && this.$store.state.ecoplot.modal.close == null
    },
    datasourceOptionActive() {
      try {
        return this.$store.state.map.datasourceOptionActive.datasource
      } catch {}
    },
    datasourceOptionActiveIsShow() {
      try {
        return this.$store.state.map.datasourceOptionActive.show
      } catch {}
    },
  },
  watch: {
    datasourceOptionActive: {
      deep: true,
      handler() {
        this.updatePositionMenuOptionsDatasrouce()
      },
    },
    // liveMode: {
    //   handler(newVal, oldVal) {
    //     if (newVal != oldVal) {
    //       this.updateDataLiveMode();
    //     }
    //   },
    // },
    // timeRefreshLiveMode() {
    //   this.updateDataLiveMode();
    // },
  },
  methods: {
    updateDataLiveMode() {
      if (this.liveMode) {
        if (this.funcInterVal) {
          clearInterval(this.funcInterVal)
          this.funcInterVal = null
        }
        this.funcInterVal = setInterval(async () => {
          await this.loadDatasourceFromAwsS3(this.$S3Client, this.datasourceOptionActive)
        }, Number(this.timeRefreshLiveMode))
      } else {
        if (this.funcInterVal) {
          clearInterval(this.funcInterVal)
          this.funcInterVal = null
        }
      }
    },
    loadDatasourceFromAwsS3(S3Client, datasource) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!this.liveMode || !this.$S3Client || !datasource || !datasource.bucket || !datasource.keyBucket) {
            resolve()
            return
          }
          Vue.prototype.$startTimerRedraw = performance.now()
          let key = datasource.keyBucket
          let checkImg = key.indexOf('.jpg') > -1 || key.indexOf('.png') > -1 ? true : false
          const data = {
            METHODS: 'loadFile',
            S3_ACCESS_KEY_ID: this.$root.appSettings.awsAccessKeyId,
            S3_SECRET_ACCESS_KEY: this.$root.appSettings.awsSecretAccessKey,
            bucket: datasource.bucket,
            checkImg,
            key,
          }
          const response = await axios.post(`${ECOPLOT_API}/awss3/`, data)
          let file
          let keySplit = key.split('/')
          if (checkImg) {
            if (response && response.data && response.data.status == 200 && response.data.file_content) {
              const blob = this.dataURItoBlob(response.data.file_content)
              file = new File([blob], keySplit[keySplit.length - 1], { type: 'image/jpeg' })
            }
          } else {
            if (response && response.status == 200) {
              const blob = response.request.response
              file = new File([blob], keySplit[keySplit.length - 1], { type: 'text/plain' })
            }
          }

          if (!this.liveMode || !this.$S3Client || !file) {
            resolve()
            return
          }
          let indexDatasourceLive = -1
          const datasourceStore = this.$store.state.datasource.datasources
          if (!datasourceStore || !datasourceStore.length) {
            resolve()
            return
          }
          for (let i = 0; i < datasourceStore.length; i++) {
            if (datasourceStore[i].id == datasource.id) {
              indexDatasourceLive = i
              break
            }
          }
          if (indexDatasourceLive < 0 || datasourceStore[indexDatasourceLive].sizeOrigin == file.size) {
            resolve()
            return
          } else {
            if (!this.$db[datasource.id]) this.$db[datasource.id] = {}
            let datasourceRead = await this.determineTypeAndReadData(file, { id: datasource.id })
            await this.updateDatasourceLiveMode(datasourceRead)
            datasourceRead.live = true
            datasourceRead.size = formatBytes(file.size)
            datasourceRead.sizeOrigin = file.size
            datasourceRead.bucket = datasource.bucket
            datasourceRead.keyBucket = datasource.keyBucket
            datasourceRead.storage_mode = STORAGE_MODE.S3
            this.$store.commit('datasource/UPDATE_DATASOURCE', datasourceRead)
            this.$store.commit('settings/SET_COUNT_LIVEMODE')
          }
        } catch {}
        resolve(true)
      })
    },
    popperDatasourceOptionHide() {
      setTimeout(() => {
        this.$store.commit('map/UPDATE_DATASOURCE_OPTION_ACTIVE_SHOW', false)
      }, 200)
    },
    openDatasourceDeleteConfirm() {
      this.$refs.datasourceItemDeleteConfirm.open()
      this.popperDatasourceOptionHide()
    },
    async downloadDatasource(datasource) {
      try {
        this.popperDatasourceOptionHide()
        let filename = 'data'
        let file
        try {
          file = datasource.file || datasource.fileOrigin
          filename = file.name
        } catch {}
        if (!file) return
        if (file) {
          let downloadLink = document.createElement('a')
          let url = URL.createObjectURL(file)
          downloadLink.href = url
          downloadLink.download = file.name
          document.body.appendChild(downloadLink)
          downloadLink.click()
          window.URL.revokeObjectURL(url)
        }
      } catch {}
    },
    openInformationModal() {
      this.popperDatasourceOptionHide()
      this.$refs.inforModal.openModal()
    },
    openDatatableModal() {
      this.popperDatasourceOptionHide()
      this.$refs.datatableModal.open()
    },
    toggleLiveMode() {
      try {
        if (this.isAwsSource) {
          this.datasourceOptionActive.live = !this.datasourceOptionActive.live
          this.$store.commit('datasource/UPDATE_DATASOURCE', this.datasourceOptionActive)
        }
      } catch {}
    },
    updatePositionMenuOptionsDatasrouce() {
      try {
        let elementOption = document.getElementById(`${this.datasourceOptionActive.id}-option-datasource`)
        let elementOptionBox = elementOption.getBoundingClientRect()
        let customScrollbarSidebarRight = document.getElementById('custom-scrollbar-sidebar-right')
        let customScrollbarSidebarRightBox = customScrollbarSidebarRight.getBoundingClientRect()
        let positionY = elementOptionBox.y - customScrollbarSidebarRightBox.y + elementOptionBox.height + 10
        let menudataSourceOption = document.getElementById(`menu-datasource-option`)
        menudataSourceOption.style.top = `${positionY}px`
      } catch {}
    },
    openAddModal() {
      this.$refs.addDatasourceModal.open()
    },
    // openToolsModal() {
    //   this.$refs.datasourceToolsModal.open();
    // },
    showModalLoadding() {
      if (this.$store.state.ecoplot.modal) {
        if (this.$store.state.ecoplot.modal.global) {
          let globals = this.$store.state.ecoplot.modal.global
          if (Object.keys(globals).length > 0) {
            let i18nGlobals = {}
            for (let key in globals) {
              i18nGlobals = { ...i18nGlobals, ...{ [key]: this.$t(globals[key]) } }
            }
            this.$store.commit('ecoplot/SET_MODAL', i18nGlobals)
          }
        }
        if (this.$store.state.ecoplot.modal.content && this.$store.state.ecoplot.modal.content.length > 0) {
          let contents = this.$store.state.ecoplot.modal.content
          let i18nContentArray = []
          for (let i = 0; i < contents.length > 0; i++) {
            if (contents[i].global && Object.keys(contents[i].global).length > 0) {
              let globals = contents[i].global
              let i18nGlobals = {}
              for (let key in globals) {
                let globalAddString = globals[key].addString != null ? ' ' + globals[key].addString : ''
                i18nGlobals = { ...i18nGlobals, ...{ [key]: this.$t(globals[key].i18n) + globalAddString } }
              }
              let i18nContent = { ...contents[i], ...i18nGlobals }
              i18nContentArray.push(i18nContent)
            }
          }
          if (i18nContentArray.length > 0) {
            this.$store.commit('ecoplot/SET_MODAL', { content: i18nContentArray })
          }
        }
        this.$store.commit('ecoplot/SET_MODAL', { show: true })
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/jpeg' })
    },
  },
}
</script>

<style scoped lang="scss">
.datasource-container {
  margin-bottom: 1.5rem;
  padding: 0 8px;
}
.datasource-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 1.285rem;
}
.datasource-list {
  max-height: 270px;
  overflow-y: auto;
}

.sortable-ghost {
  border: 1px dashed #397bff;
  background-color: rgba(57, 123, 255, 0.12) !important;
  opacity: 0.69;
  z-index: 10;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.virtual-list-datasource {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 2.5px;
  }
}

.options-datasource {
  position: relative;
}
.options-datasource-menu {
  position: fixed;
  top: 0;
  right: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 180px;
  min-width: 140px;
  border-radius: 2px;
  transition: none;
  background: #fff;
  z-index: 10;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: calc(100%);
    right: 7px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
}

.options-datasource-menu {
  position: fixed;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 180px;
  min-width: 140px;
  border-radius: 2px;
  transition: none;
  background: #fff;
  color: #4f4a5f;
  font-size: 13px;
  z-index: 500;
  ul {
    padding: 0;
    padding-left: 0px !important;
    margin: 0.1rem 0;
    li {
      list-style: none;
      cursor: pointer;
      line-height: 1.8rem;
      button {
        text-align: left;
        border: none;
        width: 100%;
        background: inherit;
        color: inherit;
        &.ena {
          opacity: 1;
          &:hover {
            background: rgba(130, 134, 139, 0.12);
            color: black;
          }
        }
        &.dis {
          opacity: 0.6;
          cursor: not-allowed;
        }
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
  .menu-icon {
    display: inline-flex;
    width: 24px;
    height: 20px !important;
    align-self: center;
    margin-right: 8px;
  }
}
.options-datasource-menu {
  ul {
    li {
      button {
        &:hover {
          background: rgba(57, 123, 255, 0.12);
        }
      }
    }
  }
  .menu-icon {
    display: inline-flex;
    width: 24px;
  }
}
.dark-layout {
  .options-datasource-menu {
    color: #b4b7bd;
    background-color: #161d31;
    ul {
      li {
        button {
          &:hover {
            background: rgb(31 37 47 / 12%);
            color: #fff;
          }
        }
      }
    }
    .menu-icon {
      display: inline-flex;
      width: 24px;
    }
    &::after {
      border-bottom: 8px solid #161d31;
    }
  }
}
</style>
