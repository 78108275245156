<template>
  <div class="legend-container" ref="legend" :style="{ visibility: layer.visible[0] && layer.legend.visible ? 'visible' : 'hidden', top: top + 'px' }">
    <b-card>
      <template #header v-if="layer.legend.displayLayerName || layer.legend.displayItem || (layer.legend.displayUnit && item && item.unit) || (layer.legend.displayUnit && layer.type === 'netcdf')">
        <div class="legend-header" v-if="layer.legend.displayLayerName" :title="layer.name">{{ layer.name }}</div>
        <div class="legend-header" v-if="item && layer.type !== 'netcdf'">
          <span class="text-primary" v-if="layer.legend.displayItem">{{ nameLegend }}</span>
          <small class="text-secondary" v-if="layer.legend.displayUnit && item.unit">{{ item.unit ? ` (${item.unit})` : '' }}</small>
        </div>
        <!--NETCDF ITEM-->
        <div class="legend-header" v-if="layer.type === 'netcdf' && minMaxNetCDFValue.length !== 0">
          <span class="text-primary" v-if="layer.legend.displayItem">{{ nameLegend }}</span>
          <small class="d-block text-secondary" v-if="layer.legend.displayUnit">{{ minMaxNetCDFValue[2] ? ` (${minMaxNetCDFValue[2]})` : '' }}</small>
        </div>
      </template>
      <div class="legend-body">
        <!-- LEGEND FOR POINT, MULTI LAYER  -->
        <div v-if="layer.fillColor.mode === 'solid' && layer.type !== 'netcdf'" :style="{ display: 'flex' }">
          <div class="color-bar solid" :style="{ background: layer.fillColor.color, '--bar-width': barWidth }"></div>
          <div class="scale solid" v-if="item && valuesLinear">
            <div :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[5] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[4] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[3] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[2] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[1] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[0] }}</div>
          </div>
        </div>
        <div v-if="layer.fillColor.mode === 'linear' && layer.type !== 'netcdf'" :style="{ display: 'flex' }">
          <div class="color-bar linear" :style="{ '--bar-width': barWidth, background: `linear-gradient(to top, ${layer.fillColor.colors[0]}, ${layer.fillColor.colors[1]}, ${layer.fillColor.colors[2]}, ${layer.fillColor.colors[3]}, ${layer.fillColor.colors[4]}, ${layer.fillColor.colors[5]})` }"></div>
          <div class="scale linear" v-if="item && valuesLinear">
            <div :style="{ '--tick-color': layer.fillColor.colors[5] }">{{ valuesLinear[5] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.colors[4] }">{{ valuesLinear[4] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.colors[3] }">{{ valuesLinear[3] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.colors[2] }">{{ valuesLinear[2] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.colors[1] }">{{ valuesLinear[1] }}</div>
            <div :style="{ '--tick-color': layer.fillColor.colors[0] }">{{ valuesLinear[0] }}</div>
          </div>
        </div>
        <div v-if="layer.fillColor.mode === 'threshold' && layer.type !== 'netcdf'" :style="{ display: 'flex' }">
          <div class="color-bar threshold" :style="{ '--bar-width': barWidth, background: thresholdGradient }"></div>
          <div class="scale threshold position-relative" v-if="thresholdColors && thresholdColors.length > 1">
            <div v-if="showThresholdMax && item" class="threshold-max-value threshold-fixed-value" :style="{ '--tick-color': thresholdColors[thresholdColors.length - 1].color }">{{ valuesSolid[1] }}</div>
            <div v-if="showThresholdMin && item" class="threshold-min-value threshold-fixed-value" :style="{ '--tick-color': thresholdColors[0].color }">{{ valuesSolid[0] }}</div>
            <div :style="{ '--tick-color': threshold.color, marginBottom: `${marginBottom(index)}px` }" v-for="(threshold, index) in thresholdColors.slice(1)" :key="index">{{ valuesThreshold[index] || '' }}</div>
          </div>
          <div class="scale solid" v-else>
            <div v-if="item && valuesLinear" :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[5] }}</div>
            <div v-if="item && valuesLinear" :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[4] }}</div>
            <div v-if="item && valuesLinear" :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[3] }}</div>
            <div v-if="item && valuesLinear" :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[2] }}</div>
            <div v-if="item && valuesLinear" :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[1] }}</div>
            <div v-if="item && valuesLinear" :style="{ '--tick-color': layer.fillColor.color }">{{ valuesLinear[0] }}</div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
const _ = require('lodash')
import interact from 'interactjs'
import MathMixin from '@/mixins/MathMixin'
import { NETCDF_PALETTE } from '@/constants/colors'
import { scaleLog10, scaleLog10Plus, scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js'
export default {
  props: ['layer', 'index'],
  mixins: [MathMixin],
  mounted() {
    // set CSS top
    this.top += this.index * 210
    this.$nextTick(() => {
      this.draggable = interact(this.$refs.legend).draggable({
        inertia: false,
        allowFrom: '.card',
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: document.querySelector('.map-container .map-list'),
            endOnly: true,
          }),
        ],
        listeners: {
          move(event) {
            let x = (parseFloat(event.target.dataset.x) || 0) + event.dx
            let y = (parseFloat(event.target.dataset.y) || 0) + event.dy
            event.target.style.transform = `translate(${x}px, ${y}px)`
            Object.assign(event.target.dataset, { x, y })
          },
        },
      })
      if (document.querySelector('.map-container .map-list')) {
        new ResizeObserver(() => {
          this.draggable.reflow({ name: 'drag', axis: 'xy' })
        }).observe(document.querySelector('.map-container .map-list'))
      }

      // init first
      let x = this.layer.legend.position.x
      let y = this.layer.legend.position.y
      this.$refs.legend.style.transform = `translate(${x}px, ${y}px)`
      Object.assign(this.$refs.legend.dataset, { x, y })

      // then listen to event
      this.draggable.on('dragend', this.updatePosition)

      // and reflow if outside
      this.draggable.reflow({ name: 'drag', axis: 'xy' })
    })
  },
  destroyed() {
    if (this.draggable) {
      this.draggable.off('dragend', this.updatePosition)
    }
  },
  data() {
    return {
      colorMaps: NETCDF_PALETTE,
      top: 30 + 90.3 + 14 + 10 + 40,
      minMaxNetCDFValue: [],
    }
  },
  watch: {
    minMaxNetCDFValueTemp() {
      this.minMaxNetCDFValue = _.cloneDeep(this.minMaxNetCDFValueTemp)
    },
  },
  computed: {
    date() {
      return this.$store.state.map.date
    },
    minMaxNetCDFValueTemp() {
      this.date
      let minMaxNetCDFValue = []
      try {
        if (this.layer.arrayDataNetcdf) {
          if (this.layer.arrayDataNetcdf.length > 0) {
            for (let i = 0; i < this.layer.arrayDataNetcdf.length; i++) {
              if (!this.layer.arrayDataNetcdf[i]) continue
              let netCDFDataTemp = this.datasources.find((d) => d.id === this.layer.arrayDataNetcdf[i])
              const dataTimeseries = this.$db[netCDFDataTemp.id].timeseries[this.layer.arrayItemGroup[i]]
              if (!dataTimeseries) continue
              let minMaxNetCDFValueTemp = [dataTimeseries.min_value, dataTimeseries.max_value, dataTimeseries.unit_data]
              if (!minMaxNetCDFValue.length) {
                minMaxNetCDFValue = minMaxNetCDFValueTemp
              } else {
                if (minMaxNetCDFValue[0] == undefined) {
                  minMaxNetCDFValue[0] = minMaxNetCDFValueTemp[0]
                } else {
                  if (minMaxNetCDFValueTemp[0] !== undefined && minMaxNetCDFValue[0] > minMaxNetCDFValueTemp[0]) {
                    minMaxNetCDFValue[0] = minMaxNetCDFValueTemp[0]
                  }
                }
                if (minMaxNetCDFValue[1] == undefined) {
                  minMaxNetCDFValue[1] = minMaxNetCDFValueTemp[1]
                } else {
                  if (minMaxNetCDFValueTemp[1] !== undefined && minMaxNetCDFValue[1] < minMaxNetCDFValueTemp[1]) {
                    minMaxNetCDFValue[1] = minMaxNetCDFValueTemp[1]
                  }
                }
              }
            }
          }
        } else {
          if (!this.layer.dataNetcdf) return minMaxNetCDFValue
          let netCDFData = this.datasources.find((d) => d.id === this.layer.dataNetcdf)
          const dataTimeseries = this.$db[netCDFData.id].timeseries[this.layer.item]
          if (!dataTimeseries) return minMaxNetCDFValue
          minMaxNetCDFValue = [dataTimeseries.min_value, dataTimeseries.max_value, dataTimeseries.unit_data]
        }
      } catch (error) {
        console.log(error)
      }
      return minMaxNetCDFValue
    },
    barWidth() {
      const DEFAULT_BAR_WIDTH = 16
      const width = this.layer.legend.barWidth || DEFAULT_BAR_WIDTH
      return `${width}px`
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    timeseries() {
      if (!this.layer.dataTimeseries) return null
      let timeseries = this.datasources.find((d) => d.id === this.layer.dataTimeseries)
      if (!timeseries) return null
      return { ...timeseries, ...this.$db[timeseries.id] }
    },
    item() {
      let item = {
        min: 0,
        max: 0,
      }
      try {
        if (this.layer.arrayIdGroup && this.layer.arrayIdGroup.length) {
          for (let i = 0; i < this.layer.arrayIdGroup.length; i++) {
            if (!this.layer.arrayItemGroup[i] || !this.layer.arrayDataTimeseries[i]) continue
            let timeseries = this.datasources.find((d) => d.id === this.layer.arrayDataTimeseries[i])
            if (!timeseries) {
              continue
            } else {
              timeseries = _.cloneDeep({ ...timeseries, ...this.$db[timeseries.id] })
            }
            let itemTemp = timeseries.items[this.layer.arrayItemGroup[i]]
            if (!item) {
              item = itemTemp
            } else {
              item.min = item.min > itemTemp.min ? itemTemp.min : item.min
              item.max = item.max < itemTemp.max ? itemTemp.max : item.max
            }
          }
        } else {
          if (!this.timeseries || !this.layer.item) return null
          let timeseries = _.cloneDeep(this.timeseries)
          item = timeseries.items[this.layer.item]
        }
      } catch {}
      return item
    },
    thresholdColors() {
      let defaultColor = { color: this.layer.fillColor.color, value: null }
      let levelValues = this.layer.fillColor.thresholds.General
      let levelColors = this.layer.fillColor.levels.reduce((result, element, index) => {
        if (levelValues[index] !== '') {
          result.push({ color: element.color, value: levelValues[index] })
        }
        return result
      }, [])
      return [defaultColor, ...levelColors]
    },
    thresholdGradient() {
      let gradient = `linear-gradient(to top`
      let length = this.thresholdColors.length
      this.thresholdColors.forEach((obj, index) => {
        gradient += `, ${obj.color} ${Math.round((index / length) * 140)}px, ${obj.color} ${Math.round(((index + 1) / length) * 140)}px`
      })
      gradient += `)`
      return gradient
    },
    valuesSolid() {
      try {
        if (this.item) {
          return this.autoFormatNumbers([this.item.min, this.item.max], { addComma: true })
        }
      } catch {}
    },
    valuesLinear() {
      if (this.item) {
        let arrData = [this.item.min + 0.0 * (this.item.max - this.item.min), this.item.min + 0.2 * (this.item.max - this.item.min), this.item.min + 0.4 * (this.item.max - this.item.min), this.item.min + 0.6 * (this.item.max - this.item.min), this.item.min + 0.8 * (this.item.max - this.item.min), this.item.min + 1.0 * (this.item.max - this.item.min)]
        if (this.layer && this.layer.scale.value) arrData = this.generateScale(arrData, this.layer.scale.value)
        return this.autoFormatNumbers(arrData, { addComma: true })
      }
    },
    valuesNetCDF() {
      return this.autoFormatNumbers([this.minMaxNetCDFValue[0] + 0.0 * (this.minMaxNetCDFValue[1] - this.minMaxNetCDFValue[0]), this.minMaxNetCDFValue[0] + 0.2 * (this.minMaxNetCDFValue[1] - this.minMaxNetCDFValue[0]), this.minMaxNetCDFValue[0] + 0.4 * (this.minMaxNetCDFValue[1] - this.minMaxNetCDFValue[0]), this.minMaxNetCDFValue[0] + 0.6 * (this.minMaxNetCDFValue[1] - this.minMaxNetCDFValue[0]), this.minMaxNetCDFValue[0] + 0.8 * (this.minMaxNetCDFValue[1] - this.minMaxNetCDFValue[0]), this.minMaxNetCDFValue[0] + 1.0 * (this.minMaxNetCDFValue[1] - this.minMaxNetCDFValue[0])], { addComma: true })
    },
    valuesThreshold() {
      if (this.item) {
        const values = [this.item.min, ...this.thresholdColors.slice(1).map((t) => t.value), this.item.max]
        return this.autoFormatNumbers(values, { addComma: true }).slice(1, values.length - 1)
      }
      return []
    },
    showThresholdMax() {
      return true
    },
    showThresholdMin() {
      return true
    },
    nameLegend() {
      let result = ``
      if (this.layer.arrayItemGroup && this.layer.arrayItemGroup.length) {
        let count = 0
        for (let i = 0; i < this.layer.arrayItemGroup.length; i++) {
          if (this.layer.arrayItemGroup[i]) {
            if (count == 0) {
              result = `${this.layer.arrayItemGroup[i]}`
              count++
            } else {
              result += `-${this.layer.arrayItemGroup[i]}`
            }
          }
        }
      } else {
        result = this.layer.item
      }
      return result
    },
  },
  methods: {
    marginBottom(index) {
      let length = this.thresholdColors.length
      if (index === 0) {
        return Math.round(((index + 1) / length) * 140) - 5
      }
      return Math.round(((index + 1) / length) * 140) - Math.round((index / length) * 140) - 12
    },
    updatePosition(event) {
      if (!this.layer.legend.visible) return
      let x = parseFloat(event.target.dataset.x) || 0
      let y = parseFloat(event.target.dataset.y) || 0
      this.$store.commit('layer/UPDATE_LEGEND_POSITION', { id: this.layer.id, legend: { position: { x, y } } })
    },
    generateScale(data, scale) {
      if (scale === 'log10') {
        data = scaleLog10(data)
      } else if (scale === 'log10+') {
        data = scaleLog10Plus(data)
      } else if (scale === 'normalize') {
        data = scaleNormalize(data)
      } else if (scale === 'standardize') {
        data = scaleStandardize(data)
      }
      return data
    },
  },
}
</script>

<style scoped>
.legend-container {
  position: fixed;
  right: calc(1rem + 1rem + 10px);
  z-index: 401;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%);
  text-align: center;
  user-select: none;
  border-radius: 0.428rem;
  overflow: hidden;
}

.legend-container .card {
  margin: 0;
  background-color: #fff;
  backdrop-filter: blur(0px);
  transition: all 0.25s, backdrop-filter 0s;
}

.dark-layout .legend-container .card {
  background-color: rgba(22, 29, 49, 0.4);
  backdrop-filter: blur(0px);
}

.legend-container:hover .card {
  background-color: #fff;
  backdrop-filter: blur(4px);
}

.dark-layout .legend-container:hover .card {
  background-color: rgba(22, 29, 49, 0.7);
  backdrop-filter: blur(4px);
}

.legend-container .card-header {
  padding: 2px 10px;
  background-color: #f8f8f8;
  display: block;
}

.dark-layout .legend-container .card-header {
  padding: 2px 10px;
  background-color: #161d31;
  display: block;
}

.legend-container .legend-header {
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.legend-container .legend-header span {
  font-size: 12px;
}
.legend-container .legend-header small {
  font-size: 10px;
}

.legend-container .card-body {
  padding: 0;
}

.legend-container .legend-body {
  padding: 10px;
}

.legend-container .color-bar {
  position: relative;
  height: 136px;
  width: var(--bar-width);
  flex-shrink: 0;
}

.legend-container .scale.solid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1;
  margin: -5px 0 -5px;
  overflow: hidden;
  white-space: nowrap;
}
.legend-container .scale.solid > div {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.legend-container .scale.solid > div::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}

.legend-container .scale.linear {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1;
  margin: -5px 0 -5px;
  overflow: hidden;
  white-space: nowrap;
}
.legend-container .scale.linear > div {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.legend-container .scale.linear > div::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}

.legend-container .scale.threshold {
  display: flex;
  flex-direction: column-reverse;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
}
.legend-container .scale.threshold > div {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.legend-container .scale.threshold > div:not(.threshold-fixed-value)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}
.threshold-max-value {
  position: absolute !important;
  top: 0;
}
.threshold-min-value {
  position: absolute !important;
  bottom: 0;
}
.legend-container .scale.threshold > div.threshold-fixed-value::before {
  content: '';
  position: absolute !important;
  left: 0;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}
.legend-container .scale.threshold > div.threshold-fixed-value.threshold-max-value::before {
  top: 0;
}
.legend-container .scale.threshold > div.threshold-fixed-value.threshold-min-value::before {
  top: 10px;
}
</style>
