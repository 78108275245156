const _ = require('lodash')
import CryptoJS from 'crypto-js'
import Vue from 'vue'
const fs = require('fs')
const path = require('path')
import * as zip from '@zip.js/zip.js/dist/zip.min.js'
import i18n from '../libs/i18n'
import papa from 'papaparse'
import { STORAGE_MODE } from '@/constants/repository.js'
import FileMixin from '@/mixins/FileMixin.js'
import MathMixin from '@/mixins/MathMixin.js'
import { ACTION_LOG } from '@/constants/actionLog'
import { v4 as uuidv4 } from 'uuid'
const proj4 = require('proj4').default
const jschardet = require('jschardet')
const shapefile = require('shapefile')

export default {
  mixins: [FileMixin, MathMixin],
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    layers() {
      return this.$store.state.layer.layers
    },
    groupsLayer() {
      return this.$store.state.layer.groups
    },
    date() {
      return this.$store.state.map.date
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    scenes() {
      return this.$store.state.map.scenes
    },
    mapTiles() {
      return this.$store.state.map.mapTiles
    },
    popupImages() {
      return this.$store.state.image.popupImages
    },
    popupImagesProcessRate() {
      return this.$store.state.image.processRate
    },
    clicking() {
      return this.$store.state.map.clicking
    },
    timeseries() {
      return this.$store.state.tabs.timeseries
    },
    scatter() {
      return this.$store.state.tabs.scatter
    },
    scatter_d3() {
      return this.$store.state.tabs.scatter_d3
    },
    scatter_three() {
      return this.$store.state.tabs.scatter_three
    },
    boxplot() {
      return this.$store.state.tabs.boxplot
    },
    histogram() {
      return this.$store.state.tabs.histogram
    },
    decomposition() {
      return this.$store.state.tabs.decomposition
    },
    seasonal_analytics() {
      return this.$store.state.tabs.seasonal_analytics
    },
    correlation() {
      return this.$store.state.tabs.correlation
    },
    machine_learning() {
      return this.$store.state.tabs.machine_learning
    },
    layerSelected() {
      return this.$store.state.map.layerSelected
    },
    groupItemsList() {
      return this.$store.state.groupItems.groupItemsList
    },
    selectedGroupItems() {
      return this.$store.state.groupItems.selectedGroupItems
    },
    currentRoute() {
      return this.$router.currentRoute.path
    },
    theme() {
      return this.$store.state.appConfig.layout.skin
    },
    locale() {
      return i18n.locale
    },
    dataFilter() {
      return this.$store.state.settings.dataFilter
    },
    lines() {
      return this.$store.state.tabs.lines
    },
    project() {
      return this.$store.state.ecoplot.project
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    sidePanelSelected() {
      return this.$store.state.ecoplot.sidePanelSelected
    },
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    lockMaps() {
      return this.$store.state.map.lockMaps
    },
    mapBoundsAutoUpdate() {
      return this.$store.state.map.mapBoundsAutoUpdate
    },
  },
  methods: {
    async compressProject({ name, description, password, imageUpload, thumbnail, version, appId }) {
      try {
        let start, end
        let overlayContent = []
        const pahtLib = require('path')
        this.$store.commit('ecoplot/SET_MODAL', null)
        this.$store.commit('ecoplot/SET_MODAL', { show: true })
        this.$store.commit('ecoplot/SET_MODAL', { title: 'Save Project' })

        // use a BlobWriter to store with a ZipWriter the zip into a Blob object
        const blobWriter = new zip.BlobWriter('application/zip')
        const writer = new zip.ZipWriter(blobWriter, { password })
        start = performance.now()
        let startReadDataFiles = []
        let promisesReadFileData = []
        let liveData = {}
        for (let i = 0; i < this.datasources.length; i++) {
          startReadDataFiles[i] = performance.now()
          let content = null
          const promise = new Promise(async (resolve) => {
            try {
              let nameFileWrite = this.datasources[i].nameOrigin || this.datasources[i].name
              if (this.datasources[i].file) {
                content = this.datasources[i].file
              } else if (this.datasources[i].fileOrigin) {
                content = this.datasources[i].fileOrigin
              } else {
                if (this.datasources[i].path) {
                  if (this.datasources[i].typeOrigin && this.datasources[i].typeOrigin == 'shp') {
                    try {
                      let blob = new Blob([JSON.stringify(this.datasources[i].contentFileJson)])
                      content = new File([blob], nameFileWrite)
                    } catch {}
                  } else {
                    let filehandle
                    try {
                      filehandle = await fs.promises.open(this.datasources[i].path, 'r')
                      let buffer = await filehandle.readFile()
                      let blob = new Blob([buffer])
                      content = new File([blob], nameFileWrite)
                    } catch {
                    } finally {
                      if (filehandle) await filehandle.close()
                    }
                  }
                }
              }
              overlayContent.push({ description: `${this.$t('compressing')} ${this.datasources[i].name}`, step: `${i + 1}/${this.datasources.length}` })
              this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })
              const dataToAdd = new zip.BlobReader(content)

              if (this.datasources[i].type === 'timeseries') {
                const staticsData = new zip.TextReader(JSON.stringify([this.$db[this.datasources[i].id].items, this.datasources[i].id]))
                writer.add(`${path.basename(this.datasources[i].name, path.extname(this.datasources[i].name))}_static_data.txt`, staticsData)
              }
              if (this.datasources[i].type === 'multi') {
                const staticsData = new zip.TextReader(JSON.stringify(['', this.datasources[i].id]))
                writer.add(`${path.basename(nameFileWrite, path.extname(nameFileWrite))}_static_data.txt`, staticsData)
              }
              writer.add(nameFileWrite, dataToAdd).then(() => {
                end = performance.now()
                Object.assign(overlayContent[i], { status: 'success', time: `${(Math.round(end - startReadDataFiles[i]) / 1000).toFixed(1)}s` })
                this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: ((i + 1) / this.datasources.length) * 40 })
                resolve()
              })
              // livedata
              try {
                let extName = pahtLib.extname(nameFileWrite)
                // live data no file zip
                if (extName != '.zip' && extName != '.rar' && this.datasources[i].storage_mode == 'S3') {
                  liveData[nameFileWrite] = {
                    bucket: this.datasources[i].bucket,
                    size: this.datasources[i].size,
                    sizeOrigin: this.datasources[i].sizeOrigin,
                    storage_mode: this.datasources[i].storage_mode,
                    keyBucket: this.datasources[i].keyBucket,
                    live: this.datasources[i].live,
                  }
                }
              } catch {}
            } catch {
              resolve()
            }
          })

          promisesReadFileData.push(promise)
        }
        await Promise.all(promisesReadFileData)
        start = performance.now()
        overlayContent.push({ description: `${this.$t('compressing_project_metadata')}` })
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })

        if (!thumbnail) thumbnail = null

        await writer.add('information.json', new zip.TextReader(JSON.stringify({ name, description, version, appId, thumbnail })))

        end = performance.now()
        Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 70 })

        start = performance.now()
        overlayContent.push({ description: `${this.$t('compressing_project_configuration')}` })
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })
        let mapBoundsSave
        if (this.mapBoundsAutoUpdate && this.mapBoundsAutoUpdate.length && this.mapBoundsAutoUpdate[0] != null) {
          mapBoundsSave = this.mapBoundsAutoUpdate
        } else {
          try {
            mapBoundsSave = [...document.getElementsByClassName('leaflet-map')].map((el) => {
              let bounds = el._leaflet_map.getBounds()
              return [
                [bounds.getNorth(), bounds.getWest()],
                [bounds.getSouth(), bounds.getEast()],
              ]
            })
          } catch {}
        }
        let rootParam = {}
        try {
          rootParam.awsAccessKeyId = this.$root.appSettings.awsAccessKeyIdTemp
          rootParam.awsSecretAccessKey = this.$root.appSettings.awsSecretAccessKeyTemp
          rootParam.isEncrypted = this.$root.appSettings.isEncryptedTemp
        } catch {}

        let config = {
          appConfig: {
            isVerticalMenuCollapsed: this.isVerticalMenuCollapsed,
            sidePanelSelected: this.sidePanelSelected,
            lockMaps: this.lockMaps,
            root: rootParam,
          },

          liveData: liveData,

          layers: this.layers,
          date: this.date,
          numberOfMap: this.numberOfMap,
          scenes: this.scenes,
          mapBounds: mapBoundsSave,
          mapTiles: this.mapTiles,
          popupImages: this.popupImages,
          popupImagesProcessRate: this.popupImagesProcessRate,
          clicking: this.clicking,
          timeseries: this.timeseries,
          lines: this.lines,

          scatter: this.scatter,
          scatter_d3: this.scatter_d3,
          scatter_three: this.scatter_three,
          boxplot: this.boxplot,
          histogram: this.histogram,
          decomposition: this.decomposition,
          seasonal_analytics: this.seasonal_analytics,
          correlation: this.correlation,
          machine_learning: this.machine_learning,
          layerSelected: this.layerSelected,
          groupItemsList: this.groupItemsList,
          selectedGroupItems: this.selectedGroupItems,
          currentRoute: this.$router.currentRoute.path,
          theme: this.theme,
          locale: this.locale,
          groupsLayer: this.groupsLayer, //add group layer v1.1.41.1
          dataFilter: this.dataFilter,
        }
        let datasourceMap = {}
        this.datasources.forEach((datasource) => {
          datasourceMap[datasource.id] = datasource.name
        })
        let result = await this.writeConfig(config, datasourceMap)
        let newResult = {
          ...result,
          layers: result.layers.map((layer) => ({ ...layer, key: uuidv4() })),
        }
        await writer.add('configuration.json', new zip.TextReader(JSON.stringify(newResult)))

        end = performance.now()
        Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 90 })

        // SAVE IMAGE TO FOLDER thumbnails
        if (imageUpload) {
          start = performance.now()
          overlayContent.push({ description: `${this.$t('compressing_project_thumbnail')}` })
          await writer.add(`thumbnails\\imageUpload.png`, new zip.BlobReader(new Blob([new Uint8Array(imageUpload)])))
          end = performance.now()
          Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 95 })
        }

        // SAVE IMAGE TO FOLDER images
        if (config && ((config.popupImages && config.popupImages.length !== 0) || (config.popupImagesProcessRate && config.popupImagesProcessRate.length !== 0))) {
          start = performance.now()
          overlayContent.push({ description: `${this.$t('compressing_image_popup')}` })
          try {
            if (config.popupImages.length) {
              for (let i = 0; i < config.popupImages.length; i++) {
                try {
                  let fileNameImage = ''
                  if (config.popupImages[i].fileName) fileNameImage = config.popupImages[i].fileName
                  let idImage = config.popupImages[i].id
                  let base64Image = this.$imagesSrc[idImage].replace('data:image/png;base64,', '')
                  const myBufferImage = Buffer.from(base64Image, 'base64')

                  let content = papa.unparse([...config.popupImages[i].metadata]) + '\r\n'
                  await writer.add(`images\\` + idImage + '-' + fileNameImage, new zip.BlobReader(new Blob([new Uint8Array(myBufferImage)])))
                  await writer.add(`images\\` + idImage + '-' + fileNameImage.replace(/\.[^/.]+$/, '') + '.csv', new zip.TextReader(content))
                } catch {}
              }
            }
            if (config.popupImagesProcessRate && config.popupImagesProcessRate.length !== 0) {
              for (let i = 0; i < config.popupImagesProcessRate.length; i++) {
                try {
                  let fileNameImage = ''
                  if (config.popupImagesProcessRate[i].fileName) fileNameImage = config.popupImagesProcessRate[i].fileName
                  let idImage = config.popupImagesProcessRate[i].id
                  let base64Image = this.$imagesSrc[idImage].replace('data:image/png;base64,', '')
                  const myBufferImage = Buffer.from(base64Image, 'base64')
                  await writer.add(`images\\` + idImage + '-' + fileNameImage, new zip.BlobReader(new Blob([new Uint8Array(myBufferImage)])))
                } catch {}
              }
            }
          } catch {}
          end = performance.now()
          Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 96 })
        }

        start = performance.now()
        overlayContent.push({ description: this.$t('creating_zip_file') })
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })

        // close the ZipReader
        await writer.close()

        // get the zip file as a Blob
        const blob = blobWriter.getData()
        let ab = await blob.arrayBuffer()
        let buf = Buffer.from(ab)

        end = performance.now()
        Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 100 })
        this.$store.commit('ecoplot/SET_MODAL', { close: true })
        return buf
      } catch {}
    },
    async extractProject(buf, path, password, isCloud = true, isEmbed = false) {
      let startIsCloud = performance.now()
      let start, end
      let coutReadSuccess = 0
      let overlayContent = []
      let objectModal = {}
      start = performance.now()
      const pathLib = require('path')
      Vue.prototype.$latlngOnMap = {}
      Vue.prototype.$imagesSrc = {}
      Vue.prototype.$netcdfStatus = {}
      Vue.prototype.$layerStatus = {}
      Vue.prototype.$mapBasedTimeseriesLoader = {}
      if (!this.$root.appSettings) this.$root.appSettings = {}
      try {
        this.$store.commit('ecoplot/INIT_PROJECT')
        this.$store.commit('ecoplot/SET_MODAL', null)
        this.$store.commit('ecoplot/SET_MODAL', { show: true })
        this.$store.commit('ecoplot/SET_MODAL', { title: i18n.t('open_project'), global: { title: 'open_project' } })
        this.$store.commit('ecoplot/UPDATE_IS_PROJECT_SHARE', false)
        this.$store.commit('mapBasedTimeseriesLoader/RESET_MAP_BASED_TIMESERIES_LOADER')
        // this.$store.dispatch('datasource/CLEAR_DATASOURCE')
        this.clearDatasource()
        this.$store.commit('layer/CLEAR_LAYER')
        this.$store.commit('tabs/RESET_STATE')
        let datasourceMap = {}
        let liveDataTemp = {}
        start = performance.now()
        overlayContent.push({ description: i18n.t('extracting_save_file'), global: { description: { i18n: 'extracting_save_file' } } })
        objectModal['extracting_save_file'] = overlayContent.length - 1
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })
        // create a BlobReader to read with a ZipReader the zip from a Blob object
        const reader = new zip.ZipReader(new zip.BlobReader(new Blob([buf])), { password })
        // get all entries from the zip
        const entries = await reader.getEntries()
        end = performance.now()
        Object.assign(overlayContent[objectModal['extracting_save_file']], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
        coutReadSuccess++
        let files = entries.filter((e) => e.filename !== 'configuration.json' && e.filename !== 'information.json' && !e.directory && !e.filename.startsWith('NetCDF') && !e.filename.endsWith('_static_data.txt') && !e.filename.startsWith('thumbnails') && !e.filename.startsWith('popup-image') && !e.filename.startsWith('images'))
        this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: (coutReadSuccess / (files.length + 3)) * 100 })
        const staticsDataFiles = entries.filter((e) => e.filename.endsWith('_static_data.txt'))
        let popupImageDir = entries.filter((e) => (e.filename.startsWith('images') || e.filename.startsWith('popup-image')) && !e.filename.endsWith('csv'))
        this.$imagesExtract = {}
        for (let k = 0; k < popupImageDir.length; k++) {
          let blob = await popupImageDir[k].getData(new zip.BlobWriter())
          let buf = Buffer.from(await blob.arrayBuffer())
          let fileName = popupImageDir[k].filename.replace('popup-image', 'images')
          var base64 = btoa(new Uint8Array(buf).reduce((data, byte) => data + String.fromCharCode(byte), ''))
          let srcImage = `data:image/png;base64,${base64}`
          this.$imagesExtract[fileName] = srcImage
        }
        let thumbnailDir = entries.filter((e) => e.filename.startsWith('thumbnails'))
        //GET THUMBNAIL v1.1.41.1
        if (!isCloud) {
          let promiseAllThumbNail = []
          for (let i = 0; i < thumbnailDir.length; i++) {
            const promiseReadThumbNail = new Promise(async (resolve) => {
              try {
                let blob = await thumbnailDir[i].getData(new zip.BlobWriter())
                let buf = Buffer.from(await blob.arrayBuffer())
                this.$store.commit('ecoplot/SET_PROJECT_THUMBNAILS', buf)
              } catch {}
              resolve(true)
            })
            promiseAllThumbNail.push(promiseReadThumbNail)
          }
          await Promise.all(promiseAllThumbNail)
        }

        //GET DATA FROM CSV FILE
        const promiseReadFile = await new Promise(async (resolve) => {
          try {
            for (let i = 0; i < files.length; i++) {
              let startReadFile = performance.now()
              overlayContent.push({ description: `${i18n.t('preprocessing')} ${files[i].filename}`, step: `${i + 1}/${files.length}`, size: files[i].uncompressedSize / 1048576, global: { description: { i18n: 'preprocessing', addString: `${files[i].filename}` } } })
              objectModal[`${files[i].filename}-${i}`] = overlayContent.length - 1
              this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })

              let blob = await files[i].getData(new zip.BlobWriter())
              let file = new File([blob], files[i].filename.slice(files[i].filename.lastIndexOf('/') + 1))
              //STATIC DATA FOR TIMESERIES v1.1.41.1
              let staticDataFile
              if (files[i].filename) {
                try {
                  let fileNameDataStatic = `${pathLib.basename(files[i].filename, pathLib.extname(files[i].filename))}_static_data.txt`
                  staticDataFile = staticsDataFiles.find((item) => item.filename === `${fileNameDataStatic}`)
                } catch {}
              }
              let staticData = null
              if (staticDataFile && !files[i].filename.endsWith('.geojson')) staticData = await staticDataFile.getData(new zip.TextWriter())
              staticData = JSON.parse(staticData)
              let itemDataInput = Array.isArray(staticData) ? staticData[0] : staticData
              let datasourceId = Array.isArray(staticData) ? staticData[1] : null
              try {
                // Read, validate then store data to indexedDB
                // let result = await this.determineTypeAndReadData(file, { id: datasourceId, size: files[i].uncompressedSize, items: itemDataInput });
                let result
                let fileName = file.name
                if (file.name.endsWith('.shp') || file.name.endsWith('.zip')) {
                  try {
                    fileName = fileName.replace('.shp', '.shp')
                    fileName = fileName.replace('.zip', '.shp')
                  } catch {}
                  result = await this.determineTypeAndReadDataGeojonShapeFileAndAddDataCompress(file, { id: datasourceId, name: fileName })
                } else {
                  result = await this.determineTypeAndReadData(file, { id: datasourceId, size: file.size, items: itemDataInput })
                }
                datasourceMap[fileName] = result.id
                liveDataTemp[file.name] = {
                  id: result.id,
                }
                if ((result.errors && result.errors.length) || (result.type === 'netcdf' && !result.items)) {
                  if (file.name && file.name.endsWith('.csv')) {
                    if (!file.name.endsWith('.shp') && !file.name.endsWith('.zip')) {
                      await this.addDatasource(result)
                    }
                    const errorMess = result.errors.join('<br />')
                    end = performance.now()
                    Object.assign(overlayContent[objectModal[`${files[i].filename}-${i}`]], { status: 'warning', info: errorMess, time: `${(Math.round(end - startReadFile) / 1000).toFixed(1)}s` })
                  } else {
                    const errorMess = result.type === 'netcdf' && !result.items ? 'Cannot open NetCDF File' : result.errors.join('<br />')
                    end = performance.now()
                    Object.assign(overlayContent[objectModal[`${files[i].filename}-${i}`]], { status: 'error', info: errorMess, time: `${(Math.round(end - startReadFile) / 1000).toFixed(1)}s` })
                  }
                } else {
                  // Add metadata to Store
                  if (!file.name.endsWith('.shp') && !file.name.endsWith('.zip')) {
                    await this.addDatasource(result)
                  }
                  if (result.warning && result.warning.length !== 0) {
                    const warningMess = result.warning.join('<br />')
                    end = performance.now()
                    Object.assign(overlayContent[objectModal[`${files[i].filename}-${i}`]], { status: 'warning', info: warningMess, time: `${(Math.round(end - startReadFile) / 1000).toFixed(1)}s` })
                  } else {
                    end = performance.now()
                    Object.assign(overlayContent[objectModal[`${files[i].filename}-${i}`]], { status: 'success', info: `${(Math.round(end - startReadFile) / 1000).toFixed(1)}s`, time: `${(Math.round(end - startReadFile) / 1000).toFixed(1)}s` })
                  }
                }
              } catch (err) {
                end = performance.now()
                Object.assign(overlayContent[objectModal[`${files[i].filename}-${i}`]], { status: 'error', info: err, time: `${(Math.round(end - startReadFile) / 1000).toFixed(1)}s` })
              }
              coutReadSuccess++
              this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: (coutReadSuccess / (files.length + 3)) * 100 })
            }
          } catch {}
          resolve(true)
        })
        // Load project Information: name & description
        let startInfo = performance.now()
        overlayContent.push({ description: i18n.t('loading_project_metadata'), global: { description: { i18n: 'loading_project_metadata' } } })
        objectModal['loading_project_metadata'] = overlayContent.length - 1
        try {
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })

          let inforName = 'information.json'
          let inforEntry = entries.find((entry) => entry.filename === inforName)
          if (inforEntry && !isCloud) {
            let inforText = await inforEntry.getData(new zip.TextWriter())
            this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...JSON.parse(inforText), source: 'local', path } })
          }
          end = performance.now()
          Object.assign(overlayContent[objectModal['loading_project_metadata']], { status: 'success', time: `${(Math.round(end - startInfo) / 1000).toFixed(1)}s` })
          coutReadSuccess++
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: (coutReadSuccess / (files.length + 3)) * 100 })
        } catch {
          end = performance.now()
          Object.assign(overlayContent[objectModal['loading_project_metadata']], { status: 'error', time: `${(Math.round(end - startInfo) / 1000).toFixed(1)}s` })
          coutReadSuccess++
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: (coutReadSuccess / (files.length + 3)) * 100 })
        }

        // Load project Configuration
        let startConfig = performance.now()
        overlayContent.push({ description: i18n.t('loading_project_configuration'), global: { description: { i18n: 'loading_project_configuration' } } })
        objectModal['loading_project_configuration'] = overlayContent.length - 1
        try {
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent })
          let configName = 'configuration.json'
          let configEntry = entries.find((entry) => entry.filename === configName)
          let configBlob = await configEntry.getData(new zip.BlobWriter())
          let configFile = new File([configBlob], configName)
          let { appConfig, layers, date, numberOfMap, scenes, mapBounds, mapTiles, popupImages, popupImagesProcessRate, clicking, timeseries, timeseries_uplot, lines, lines_uplot, scatter, scatter_uplot, scatter_d3, scatter_three, boxplot, boxplot_uplot, histogram, histogram_uplot, decomposition, seasonal_analytics, correlation, machine_learning, layerSelected, groupItemsList, selectedGroupItems, currentRoute, theme, locale, groupsLayer, dataFilter, liveData } = await this.readConfig(configFile, datasourceMap)
          // Init config
          let layerSelectedOpenProject = {
            layerSelected: layerSelected,
          }
          layers.forEach((layer) => {
            if (!layer.id) layer.id = uuidv4()
            if (!groupsLayer) this.$store.commit('layer/ADD_GROUP', layer)
            this.$store.commit('layer/ADD_LAYER', layer)
            try {
              if (['point', 'multi'].indexOf(`${layer.type}`) >= 0) {
                layerSelectedOpenProject[`${layer.id}`] = false
              }
            } catch {}
          })
          this.$store.commit('ecoplot/UPDATE_LAYER_SELECTED_OPEN_PROJECT', layerSelectedOpenProject)
          if (appConfig) {
            try {
              this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', appConfig.isVerticalMenuCollapsed)
            } catch {}
            try {
              this.$store.commit('ecoplot/SET_SIDE_PANEL_SELECTED', appConfig.sidePanelSelected)
            } catch {}
            try {
              this.$store.commit('map/SCENE_SET_LOCK_MAPS', appConfig.lockMaps)
            } catch {}
            try {
              if (appConfig.root) {
                this.$root.appSettings.awsAccessKeyIdTemp = appConfig.root.awsAccessKeyId
                this.$root.appSettings.awsSecretAccessKeyTemp = appConfig.root.awsSecretAccessKey
                this.$root.appSettings.isEncryptedTemp = appConfig.root.isEncrypted
                await this.setDataRootFromAppSettingsCompress(appConfig.root.isEncrypted, appConfig.root.awsAccessKeyId, appConfig.root.awsSecretAccessKey)
                this.$store.commit('ecoplot/UPDATE_COUNT_ACC_ASWS3_UPDATE')
              }
            } catch {}
          }
          try {
            if (liveData && Object.keys(liveData).length) {
              if (liveDataTemp) {
                for (let nameDt in liveDataTemp) {
                  if (liveData[nameDt]) {
                    let dataUpdate = _.merge(liveData[nameDt], {
                      id: liveDataTemp[nameDt].id,
                      live: liveData[nameDt].live ? true : false,
                      storage_mode: STORAGE_MODE.S3,
                    })
                    this.$store.commit('datasource/UPDATE_DATASOURCE_STATUS_LIVE', dataUpdate)
                  }
                }
              }
            }
          } catch {}
          if (groupsLayer) this.$store.commit('layer/SET_GROUPS', groupsLayer)
          this.$store.commit('map/SET_LAYER_SELECTED', layerSelected)
          this.$store.commit('groupItems/SET_GROUP_ITEMS_LIST', groupItemsList)
          this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', selectedGroupItems)
          this.$store.commit('map/SET_SELECTED_GROUP', selectedGroupItems)
          this.$nextTick(() => {
            this.$store.commit('map/UPDATE_DATE', date)
          })
          this.$store.commit('map/SET_NUMBER_MAP', numberOfMap)
          this.$store.commit('map/SET_SCENE', scenes)
          this.$nextTick(() => {
            this.$store.commit('map/SET_MAP_BOUND', mapBounds)
            this.$store.commit('map/SET_MAP_TILES', mapTiles)
          })
          popupImages.forEach((img) => {
            if (!img.latlngPos) img.latlngPos = this.$latlngOnMap[img.locationItem]
          })
          this.$store.commit('image/ADD_POPUP_IMAGE', popupImages)
          if (!isEmbed) {
            if (popupImagesProcessRate && popupImagesProcessRate.length) {
              this.$store.commit('image/ADD_PROCESS_RATE_IMAGE', popupImagesProcessRate)
            }
            let tabsClone = _.cloneDeep({ timeseries, lines, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning })
            this.$store.commit('tabs/RESTORE_TABS', { timeseries, lines, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning })
            this.$nextTick(() => {
              this.$store.commit('tabs/RESTORE_TABS_DATE', tabsClone)
            })
            this.$store.commit('appConfig/UPDATE_SKIN', theme)
            i18n.locale = locale
            this.$store.commit('settings/SET_FILTER_FILTER', { dataFilter })
            setTimeout(() => {
              let clickingTmp = clicking ? clicking : [null, null]
              try {
                if (clicking && typeof clicking == 'object') {
                  if (!Array.isArray(clicking)) {
                    clickingTmp = [[clicking], null]
                  } else if (clicking.length && !Array.isArray(clicking[0])) {
                    if (!clicking[0]) {
                      clickingTmp = [null, null]
                    } else {
                      clickingTmp = [clicking, null]
                    }
                  }
                }
              } catch {
                clickingTmp = [null, null]
              }
              this.$store.commit('map/SET_CLICKING_OPENFILE', clickingTmp)
              this.$store.commit('map/SET_PROCESS_RATE_OPEN_FILE')
              end = performance.now()
              Object.assign(overlayContent[overlayContent.length - 1], { status: 'success', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
              this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 100 })
            }, 0)
            this.$router.push({ path: currentRoute }).catch(() => {})
            end = performance.now()
            Object.assign(overlayContent[objectModal['loading_project_configuration']], { status: 'success', time: `${(Math.round(end - startConfig) / 1000).toFixed(1)}s` })
            coutReadSuccess++
            this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: (coutReadSuccess / (files.length + 3)) * 100 })

            // close the ZipReader
            this.$store.commit('ecoplot/SET_MODAL', { close: true })
            if (isCloud) {
              let nameProject = this.project.name || this.tempProject.name
              end = performance.now()
              let duration = this.autoFormatDuration([Math.round(end - startIsCloud) / 1000])[0]
              let messageTitle = 'open_project'
              let messageLog = `: '${nameProject}'`
              let message = this.$t(messageTitle) + messageLog
              let id = ACTION_LOG[3].id
              this.log.info({ message, duration, id, messageTitle, messageLog })
            }
          } else {
            this.$store.commit('map/SET_CLICKING_OPENFILE', [null, null])
          }
        } catch {
          end = performance.now()
          Object.assign(overlayContent[objectModal['loading_project_configuration']], { status: 'error', time: `${(Math.round(end - startConfig) / 1000).toFixed(1)}s` })
          coutReadSuccess++
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: (coutReadSuccess / (files.length + 3)) * 100 })
        }
        await reader.close()
      } catch {
        if (!isEmbed) {
          end = performance.now()
          Object.assign(overlayContent[objectModal['extracting_save_file']], { status: 'error', time: `${(Math.round(end - start) / 1000).toFixed(1)}s` })
          this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 100 })
        }
      }
      this.$store.commit('ecoplot/SET_MODAL', { content: overlayContent, percent: 100 })
    },
    transformCoordinatesCompress(coordinates, source) {
      // Nếu đây là mảng chứa các tọa độ (latitude, longitude)
      if (coordinates && coordinates.length == 2 && (typeof coordinates[0] === 'number' || typeof coordinates[0] === 'string')) {
        return proj4(source).inverse(coordinates)
      } else if (Array.isArray(coordinates[0]) && (typeof coordinates[0][0] === 'number' || typeof coordinates[0][0] === 'string')) {
        return coordinates.map((coordinate) => proj4(source).inverse(coordinate))
      }
      // Nếu đây là một mảng con
      else if (Array.isArray(coordinates[0])) {
        return coordinates.map((subArray) => this.transformCoordinatesCompress(subArray, source))
      }
      // Nếu đầu vào không phải là mảng chứa các tọa độ hoặc mảng con
      else {
        console.log('Định dạng mảng không hợp lệ')
        return []
      }
    },
    async readFIleToBufferCompress(file) {
      return new Promise(async (resolve) => {
        let reader = new FileReader()
        reader.onload = async function (e) {
          try {
            let codes = new Uint8Array(e.target.result)
            resolve(codes)
          } catch {
            resolve(null)
          }
        }
        reader.readAsArrayBuffer(file)
      })
    },
    async determineTypeAndReadDataGeojonShapeFileAndAddDataCompress(file, datasourceConfig = { pathT: null, id: null, name: null }) {
      const self = this
      // write file original to temp
      let pathOriginSave
      let fileOriginReadBuffer
      try {
        fileOriginReadBuffer = await this.readFIleToBufferCompress(file)
      } catch {}
      let resultReturn = {
        event: 'succeed',
        errors: ['cannot_open_this_file'],
      }
      let items = {}
      let encoding = 'UTF-8'
      if (!fileOriginReadBuffer) {
        return resultReturn
      }
      if (file.name.endsWith('.shp')) {
        let features = []
        let fromProj = null
        let result
        let geojsonSingleShp
        try {
          let filePathDBF = `${pathOriginSave}`
          try {
            filePathDBF = filePathDBF.replace('.shp', '.dbf')
            if (!fs.existsSync(filePathDBF)) {
              filePathDBF = null
            }
          } catch {
            filePathDBF = null
          }
          let filePathPRJ = `${pathOriginSave}`
          try {
            filePathPRJ = filePathPRJ.replace('.shp', '.prj')
            if (!fs.existsSync(filePathPRJ)) {
              filePathPRJ = null
            } else {
              fromProj = fs.readFileSync(filePathPRJ)
              fromProj = proj4(fromProj.toString())
            }
          } catch {
            fromProj = null
            filePathPRJ = null
          }
          let checkFIleCPG
          let filePathCPG1 = `${pathOriginSave}`
          try {
            filePathCPG1 = filePathCPG1.replace('.shp', '.cpg')
            if (fs.existsSync(filePathCPG1)) {
              let dataFileCOG = fs.readFileSync(filePathCPG1)
              dataFileCOG = dataFileCOG.toString()
              try {
                dataFileCOG = dataFileCOG.replace(/\s+/g, '')
              } catch {}
              encoding = dataFileCOG
              checkFIleCPG = true
            }
          } catch {
            checkFIleCPG = null
            filePathCPG1 = null
          }
          if (!checkFIleCPG) {
            let filePathCPG2 = `${pathOriginSave}`
            try {
              filePathCPG2 = filePathCPG2.replace('.shp', '.CPG')
              if (fs.existsSync(filePathCPG2)) {
                let dataFileCOG2 = fs.readFileSync(filePathCPG2)
                dataFileCOG2 = dataFileCOG2.toString()
                try {
                  dataFileCOG2 = dataFileCOG2.replace(/\s+/g, '')
                } catch {}
                encoding = dataFileCOG2
                checkFIleCPG = true
              }
            } catch {
              checkFIleCPG = null
              filePathCPG2 = null
            }
          }

          if (!checkFIleCPG) {
            if (filePathDBF) {
              let ObjectEcd = {}
              await shapefile
                .open(fileOriginReadBuffer)
                .then((source) =>
                  source.read().then(function log(result) {
                    if (result.done) return
                    // Xử lý thông tin đối tượng
                    const properties = result.value.properties
                    if (properties && Object.keys(properties).length) {
                      for (let keyProperties in properties) {
                        let valueTemp = properties[keyProperties]
                        const checkNumber = valueTemp ? self.isNumericString(valueTemp) : false
                        try {
                          if (valueTemp && !checkNumber) {
                            let propertiesString = valueTemp
                            propertiesString = propertiesString.replace(/[0-9\-_.,|#? ]/g, '')
                            let encodingjscharder = jschardet.detect(valueTemp)
                            if (encodingjscharder && encodingjscharder.encoding) {
                              ObjectEcd[encodingjscharder.encoding] = true
                            }
                          }
                        } catch {}
                      }
                    }

                    return source.read().then(log)
                  })
                )
                .catch((error) => console.error(error.stack))
              if (ObjectEcd && Object.keys(ObjectEcd).length) {
                for (let key in ObjectEcd) {
                  if (key && key.toUpperCase() == 'ASCII') {
                    encoding = 'Shift-JIS'
                    break
                  }
                }
              }
            }
          }
          let isEmptyUID = true
          let isEmptyID = true
          await shapefile
            .open(fileOriginReadBuffer, null, { encoding: encoding })
            .then((source) =>
              source.read().then(function log(result) {
                if (result.done) return
                // Xử lý thông tin đối tượng
                let properties = result.value.properties
                if (properties && Object.keys(properties).length) {
                  for (let keyProperties in properties) {
                    if (keyProperties == 'UID') {
                      isEmptyUID = false
                    }
                    if (keyProperties == 'ID') {
                      isEmptyID = false
                    }
                    let valueTemp = properties[keyProperties]
                    if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                      if (!items[keyProperties]) {
                        items[keyProperties] = {
                          min: Number(valueTemp),
                          max: Number(valueTemp),
                        }
                      } else {
                        if (Number(valueTemp) < Number(items[keyProperties].min)) {
                          items[keyProperties].min = Number(valueTemp)
                        }
                        if (Number(valueTemp) > Number(items[keyProperties].max)) {
                          items[keyProperties].max = Number(valueTemp)
                        }
                      }
                    }
                  }
                }
                const geometry = result.value.geometry

                // Tạo đối tượng GeoJSON từ thông tin đối tượng
                let feature = {
                  type: 'Feature',
                  properties: properties,
                  geometry: geometry,
                }
                if (fromProj) {
                  try {
                    let coordinatesConvertWS84 = geometry.coordinates
                    try {
                      if (coordinatesConvertWS84 && coordinatesConvertWS84.length) {
                        coordinatesConvertWS84 = self.transformCoordinatesCompress(geometry.coordinates, fromProj)
                      }
                    } catch {}
                    feature.geometry.coordinates = coordinatesConvertWS84
                  } catch {}
                }

                // Thêm đối tượng vào danh sách
                features.push(feature)
                return source.read().then(log)
              })
            )
            .catch((error) => console.error(error.stack))

          // Tạo đối tượng GeoJSON hoàn chỉnh
          let fileName = 'geojson'
          try {
            fileName = file.name.split('.')[0]
          } catch {}
          try {
            if (features && features.length && (isEmptyID || isEmptyUID)) {
              for (let k = 0; k < features.length; k++) {
                if (!features[k].properties) {
                  features[k].properties = {}
                }
                if (isEmptyID) {
                  features[k].properties.UID = features[k].properties.LocationID || features[k].properties.ID || `id_${k}`
                }
                if (isEmptyUID) {
                  features[k].properties.UID = `uid_${k}`
                }
              }
            }
          } catch {}
          geojsonSingleShp = {
            type: 'FeatureCollection',
            features: features,
            fileName: fileName,
            isEmptyID: isEmptyID,
            isEmptyUID: isEmptyUID,
          }
          result = {
            data: geojsonSingleShp,
            items,
            encoding: encoding,
            event: 'succeed',
            file: '',
            fileOrigin: file,
            id: datasourceConfig && datasourceConfig.id ? datasourceConfig.id : uuidv4(),
            name: datasourceConfig && datasourceConfig.name ? datasourceConfig.name : file.name,
            nameOrigin: datasourceConfig && datasourceConfig.name ? datasourceConfig.name : file.name,
            path: null,
            size: file.size,
            storage_mode: STORAGE_MODE.RAM,
            type: 'multi',
            typeOrigin: 'shp',
          }
        } catch (error) {
          console.log('err', error)
        }
        if (!features.length || !result) {
          resultReturn = {
            event: 'succeed',
            errors: ['cannot_open_this_file'],
            id: result && result.id ? result.id : null,
          }
        } else {
          try {
            if (!Vue.prototype.$db[result.id]) Vue.prototype.$db[result.id] = {}
            Vue.prototype.$db[result.id]['data'] = [{ data: geojsonSingleShp }]
            await this.addDatasource(result)
            resultReturn = {
              event: 'succeed',
              id: result && result.id ? result.id : null,
            }
          } catch {
            resultReturn = {
              event: 'succeed',
              errors: ['cannot_open_this_file'],
              id: result && result.id ? result.id : null,
            }
          }
        }
        return resultReturn
      } else if (file.name.endsWith('.zip')) {
        let features = []
        let datasourceAdd
        let geojsonDataZip
        let isEmptyUID = true
        let isEmptyID = true
        try {
          let password = undefined
          const reader = new zip.ZipReader(new zip.BlobReader(new Blob([fileOriginReadBuffer])), { password })
          // get all entries from the zip
          const entries = await reader.getEntries()
          let geojsonShp = {}
          let fromProj = null
          let checkEncodingFromCPG
          if (entries && entries.length) {
            for (let i = 0; i < entries.length; i++) {
              let nameSplit = entries[i].filename.split('.')
              if (nameSplit[1] && nameSplit[1].toLowerCase() == 'cpg') {
                try {
                  let blob = await entries[i].getData(new zip.BlobWriter())
                  let file = new File([blob], entries[i].filename)
                  let buf = Buffer.from(await file.arrayBuffer())
                  let contentCPG = buf.toString()
                  try {
                    contentCPG = contentCPG.replace(/\s+/g, '')
                  } catch {}
                  encoding = contentCPG
                  checkEncodingFromCPG = true
                } catch {}
              }
              // write file zip
              try {
                let blob = await entries[i].getData(new zip.BlobWriter())
                let file = new File([blob], entries[i].filename)
                let buf = Buffer.from(await file.arrayBuffer())
                if (nameSplit[1] == 'prj') {
                  try {
                    fromProj = proj4(buf.toString())
                  } catch {
                    fromProj = null
                  }
                }
                if (!geojsonShp[nameSplit[0]]) geojsonShp[nameSplit[0]] = {}
                geojsonShp[nameSplit[0]][nameSplit[1]] = buf
              } catch {}
            }
          }
          if (Object.keys(geojsonShp).length) {
            for (const key in geojsonShp) {
              if (geojsonShp[key] && geojsonShp[key]['shp']) {
                features = []
                if (geojsonShp[key]['dbf']) {
                  // Đọc dữ liệu từ tệp Shapefile
                  let ObjectEcd = {}
                  if (!checkEncodingFromCPG) {
                    await shapefile
                      .open(geojsonShp[key]['shp'], geojsonShp[key]['dbf'], {})
                      .then((source) =>
                        source.read().then(function log(result) {
                          if (result.done) return

                          // Xử lý thông tin đối tượng
                          const properties = result.value.properties

                          if (properties && Object.keys(properties).length) {
                            for (let keyProperties in properties) {
                              let valueTemp = properties[keyProperties]
                              const checkNumber = valueTemp ? self.isNumericString(valueTemp) : false
                              try {
                                if (valueTemp && !checkNumber) {
                                  let propertiesString = valueTemp
                                  propertiesString = propertiesString.replace(/[0-9\-_.,|#? ]/g, '')
                                  let encodingjscharder = jschardet.detect(valueTemp)
                                  if (encodingjscharder && encodingjscharder.encoding) {
                                    ObjectEcd[encodingjscharder.encoding] = true
                                  }
                                }
                              } catch {}
                            }
                          }

                          return source.read().then(log)
                        })
                      )
                      .catch((error) => console.error(error.stack))
                    if (ObjectEcd && Object.keys(ObjectEcd).length) {
                      for (let key in ObjectEcd) {
                        if (key && key.toUpperCase() == 'ASCII') {
                          encoding = 'Shift-JIS'
                          break
                        }
                      }
                    }
                  }
                  await shapefile
                    .open(geojsonShp[key]['shp'], geojsonShp[key]['dbf'], { encoding: encoding })
                    .then((source) =>
                      source.read().then(function log(result) {
                        if (result.done) return

                        // Xử lý thông tin đối tượng
                        let properties = result.value.properties
                        if (properties && Object.keys(properties).length) {
                          for (let keyProperties in properties) {
                            if (keyProperties == 'UID') {
                              isEmptyUID = false
                            }
                            if (keyProperties == 'ID') {
                              isEmptyID = false
                            }
                            let valueTemp = properties[keyProperties]
                            if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                              if (!items[keyProperties]) {
                                items[keyProperties] = {
                                  min: Number(valueTemp),
                                  max: Number(valueTemp),
                                }
                              } else {
                                if (Number(valueTemp) < Number(items[keyProperties].min)) {
                                  items[keyProperties].min = Number(valueTemp)
                                }
                                if (Number(valueTemp) > Number(items[keyProperties].max)) {
                                  items[keyProperties].max = Number(valueTemp)
                                }
                              }
                            }
                          }
                        }
                        const geometry = result.value.geometry

                        // Tạo đối tượng GeoJSON từ thông tin đối tượng
                        let feature = {
                          type: 'Feature',
                          properties: properties,
                          geometry: geometry,
                        }
                        if (fromProj) {
                          try {
                            let coordinatesConvertWS84 = geometry.coordinates
                            try {
                              if (coordinatesConvertWS84 && coordinatesConvertWS84.length) {
                                coordinatesConvertWS84 = self.transformCoordinatesCompress(geometry.coordinates, fromProj)
                              }
                            } catch {}
                            feature.geometry.coordinates = coordinatesConvertWS84
                          } catch {}
                        }
                        // Thêm đối tượng vào danh sách
                        features.push(feature)
                        return source.read().then(log)
                      })
                    )
                    .catch((error) => console.error(error.stack))
                } else {
                  // Đọc dữ liệu từ tệp Shapefile
                  await shapefile
                    .open(geojsonShp[key]['shp'], null, { encoding: encoding })
                    .then((source) =>
                      source.read().then(function log(result) {
                        if (result.done) return
                        // Xử lý thông tin đối tượng
                        let properties = result.value.properties
                        if (properties && Object.keys(properties).length) {
                          for (let keyProperties in properties) {
                            if (keyProperties == 'UID') {
                              isEmptyUID = false
                            }
                            if (keyProperties == 'ID') {
                              isEmptyID = false
                            }
                            let valueTemp = properties[keyProperties]
                            if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                              if (!items[keyProperties]) {
                                items[keyProperties] = {
                                  min: Number(valueTemp),
                                  max: Number(valueTemp),
                                }
                              } else {
                                if (Number(valueTemp) < Number(items[keyProperties].min)) {
                                  items[keyProperties].min = Number(valueTemp)
                                }
                                if (Number(valueTemp) > Number(items[keyProperties].max)) {
                                  items[keyProperties].max = Number(valueTemp)
                                }
                              }
                            }
                          }
                        }
                        const geometry = result.value.geometry

                        // Tạo đối tượng GeoJSON từ thông tin đối tượng
                        let feature = {
                          type: 'Feature',
                          properties: properties,
                          geometry: geometry,
                        }
                        if (fromProj) {
                          try {
                            let coordinatesConvertWS84 = geometry.coordinates
                            try {
                              if (coordinatesConvertWS84 && coordinatesConvertWS84.length) {
                                coordinatesConvertWS84 = self.transformCoordinatesCompress(geometry.coordinates, fromProj)
                              }
                            } catch {}
                            feature.geometry.coordinates = coordinatesConvertWS84
                          } catch {}
                        }

                        // Thêm đối tượng vào danh sách
                        features.push(feature)
                        return source.read().then(log)
                      })
                    )
                    .catch((error) => console.error(error.stack))
                }
                let fileName = 'geojson'
                try {
                  fileName = file.name.split('.')[0]
                } catch {}
                try {
                  if (features && features.length && (isEmptyID || isEmptyUID)) {
                    for (let k = 0; k < features.length; k++) {
                      if (!features[k].properties) {
                        features[k].properties = {}
                      }
                      if (isEmptyID) {
                        features[k].properties.UID = features[k].properties.LocationID || features[k].properties.ID || `id_${k}`
                      }
                      if (isEmptyUID) {
                        features[k].properties.UID = `uid_${k}`
                      }
                    }
                  }
                } catch {}
                geojsonDataZip = {
                  type: 'FeatureCollection',
                  features: features,
                  fileName: fileName,
                  isEmptyID: isEmptyID,
                  isEmptyUID: isEmptyUID,
                }
                let nameFile = file.name
                try {
                  nameFile = nameFile.replace('.zip', '.shp')
                } catch {
                  nameFile = `${key}.shp` || file.name
                }
                datasourceAdd = {
                  data: geojsonDataZip,
                  items,
                  encoding: null,
                  event: 'succeed',
                  file: '',
                  fileOrigin: file,
                  id: datasourceConfig && datasourceConfig.id ? datasourceConfig.id : uuidv4(),
                  name: nameFile,
                  nameOrigin: file.name,
                  path: '',
                  size: file.size,
                  storage_mode: STORAGE_MODE.RAM,
                  type: 'multi',
                  typeOrigin: 'shp',
                }
              }
            }
          }
        } catch {}
        if (!features.length || !datasourceAdd) {
          resultReturn = {
            event: 'succeed',
            errors: ['cannot_open_this_file'],
            id: datasourceAdd && datasourceAdd.id ? datasourceAdd.id : null,
          }
        } else {
          try {
            if (!Vue.prototype.$db[datasourceAdd.id]) Vue.prototype.$db[datasourceAdd.id] = {}
            Vue.prototype.$db[datasourceAdd.id]['data'] = [{ data: geojsonDataZip }]
            await this.addDatasource(datasourceAdd)
            resultReturn = {
              event: 'succeed',
              id: datasourceAdd && datasourceAdd.id ? datasourceAdd.id : null,
            }
          } catch {
            resultReturn = {
              event: 'succeed',
              errors: ['cannot_open_this_file'],
              id: datasourceAdd && datasourceAdd.id ? datasourceAdd.id : null,
            }
          }
        }
        return resultReturn
      }
    },
    setDataRootFromAppSettingsCompress(isEncrypted, awsAccessKeyId, awsSecretAccessKey, encryptedPassword) {
      try {
        if (isEncrypted) {
          try {
            this.$root.appSettings.awsAccessKeyId = this.decryptAESCompress(awsAccessKeyId, encryptedPassword)
          } catch {
            this.$root.appSettings.awsAccessKeyId = ''
          }
          try {
            this.$root.appSettings.awsSecretAccessKey = this.decryptAESCompress(awsSecretAccessKey, encryptedPassword)
          } catch {
            this.$root.appSettings.awsSecretAccessKey = ''
          }
        } else {
          this.$root.appSettings.awsAccessKeyId = awsAccessKeyId
          this.$root.appSettings.awsSecretAccessKey = awsSecretAccessKey
        }
        this.$root.appSettings.isEncrypted = isEncrypted
      } catch {
        this.$root.appSettings.awsAccessKeyId = ''
        this.$root.appSettings.awsSecretAccessKey = ''
        this.$root.appSettings.isEncrypted = isEncrypted ? true : false
      }
    },
    decryptAESCompress(encryptedBase64, password = 'ecoplotpro') {
      try {
        const decrypted = CryptoJS.AES.decrypt(encryptedBase64, password)
        if (decrypted) {
          try {
            const str = decrypted.toString(CryptoJS.enc.Utf8)
            if (str.length > 0) {
              return str
            } else {
              return
            }
          } catch (e) {
            return
          }
        }
      } catch {}
      return
    },
    encryptAESCompress(text, password = 'ecoplotpro') {
      try {
        return CryptoJS.AES.encrypt(text, password).toString()
      } catch {}
      return ''
    },
  },
}
