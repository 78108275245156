<template>
  <vue-select v-model="selected" :appendToBody="true" :calculatePosition="calculatePosition" :reduce="(data) => data.value" :options="options" class="select-size-sm v-select-layer-setting" :placeholder="typeLayer === 'netcdf' ? $t('group') : $t('item')">
    <template #no-options>{{ $t('no_data_sources') }}</template>
  </vue-select>
</template>

<script>
export default {
  props: ['value', 'timeseries', 'timeseriesShape', 'typeLayer'],
  components: {},
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    options() {
      let timeseries;
      if (this.typeLayer && this.typeLayer == 'multi') {
        try {
          if (this.timeseriesShape) {
            let timeseriesTemp = this.datasources.find((d) => d.id === this.timeseriesShape);
            if (timeseriesTemp && timeseriesTemp.type != 'multi') {
              timeseries = timeseriesTemp;
            }
          }
        } catch {}
      } else {
        try {
          timeseries = this.datasources.find((d) => d.id === this.timeseries);
        } catch {}
      }

      if (!timeseries) return [];
      let items = [];
      try {
        Object.keys(this.$db[timeseries.id].items).map((d) => {
          if (d != 'LATITUDE' && d != 'LONGITUDE') {
            items.push({ value: d, label: d });
          }
        });
        if (items && items.length !== 0) {
          items.sort((a, b) => {
            return a.value - b.value;
          });
        }
      } catch {}
      let itemFirst;
      try {
        itemFirst = items[0].value;
      } catch {}
      this.$emit('itemFirst', itemFirst);
      return items;
    },
  },
  watch: {
    options() {
      if (this.options.findIndex((o) => o.value === this.selected) === -1) {
        this.selected = null;
      }
    },
    selected() {
      this.$emit('input', this.selected);
    },
    value() {
      this.selected = this.value;
    },
  },
  methods: {
    calculatePosition(dropdownList, _component, { width, top, left }) {
      dropdownList.classList.add('width-fit-content');
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
  },
};
</script>
<style lang="scss">
.v-select-layer-setting {
  input,
  span {
    font-size: 0.75rem !important;
  }
  .vs__selected-options {
    align-items: center;
  }
}
</style>
