<template>
  <div class="sidebar-right-map-content">
    <div v-show="value === 'control-panel'">
      <ControlPanel v-show="!captureMode" :walk-player="walkPlayer" :cancel-walk="cancelWalk" />
    </div>
    <div v-show="value === 'select-layer'">
      <Sidebar :chartType="'chart'" :countValue="countValue" />
    </div>
    <div v-show="value === 'images-component'">
      <Sidebar :chartType="'image'" />
    </div>
    <div v-show="value === 'scene-component'">
      <SceneComponent />
    </div>
    <div v-show="value === 'aws-s3-component'">
      <AwsS3Component />
    </div>
    <div v-show="value === 'ai-component'">
      <AiComponent :activeComponent="value === 'ai-component'" />
    </div>
  </div>
</template>

<script>
import SceneComponent from './SceneComponent.vue'
import ControlPanel from '../control-panel/ControlPanel.vue'
import Sidebar from './Sidebar.vue'
import AwsS3Component from './AwsS3Component.vue'
import AiComponent from './AiComponent.vue'
export default {
  props: ['value'],
  components: {
    SceneComponent,
    ControlPanel,
    Sidebar,
    AwsS3Component,
    AiComponent,
  },
  data() {
    return {
      countValue: 0,
    }
  },
  computed: {
    captureMode() {
      return this.$store.state.ecoplot.captureMode
    },
    isActivateProduct() {
      return this.$store.state.ecoplot.isActivateProduct
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.countValue++
      },
    },
  },
  methods: {
    walkPlayer(callback) {
      this.$refs.timeplayer.walk(callback)
    },
    cancelWalk(callback) {
      this.$refs.timeplayer.cancelWalk(callback)
    },
  },
}
</script>
