<template>
  <b-modal :title="$t('delete_data_source')" centered ok-only ok-variant="danger" :ok-title="$t('delete')" ref="deleteDatasource" :body-class="'custom-body'" @ok="deleteDts">
    {{ $t('message_delete_datasource', { name: datasource.name }) }}
  </b-modal>
</template>

<script>
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  props: ['datasource'],
  methods: {
    open() {
      this.$refs.deleteDatasource.show();
    },
    deleteDts() {
      this.deleteDatasource(this.datasource);
      let messageTitle = 'delete_datasource';
      let messageLog = `: '${this.datasource.name}' (${this.datasource.size})`;
      let message = this.$t(messageTitle) + messageLog;
      this.log.info({ message, id: ACTION_LOG[9].id, messageTitle,  });
    },
  },
};
</script>
