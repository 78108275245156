import { render, staticRenderFns } from "./BorderlessImageComponent.vue?vue&type=template&id=4aff0b0e&scoped=true&"
import script from "./BorderlessImageComponent.vue?vue&type=script&lang=js&"
export * from "./BorderlessImageComponent.vue?vue&type=script&lang=js&"
import style0 from "./BorderlessImageComponent.vue?vue&type=style&index=0&id=4aff0b0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aff0b0e",
  null
  
)

export default component.exports