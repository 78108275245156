<template>
  <div class="context-menu-map" :style="`${styleContextMenu}`">
    <ul>
      <li>
        <button @click.stop="saveScene" class="d-flex">
          <span class="menu-icon"><feather-icon icon="SaveIcon" size="20" /></span>
          <span>{{ $t('save_scene') }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
const _ = require('lodash')
import { v4 as uuidv4 } from 'uuid'
import CaptureMixin from '@/mixins/CaptureMixin.js'
import html2canvas from 'html2canvas'
export default {
  mixins: [CaptureMixin],
  props: {
    styleContextMenu: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers
    },
    date() {
      return this.$store.state.map.date
    },
    scenes() {
      return this.$store.state.map.scenes
    },
    mapTiles() {
      return this.$store.state.map.mapTiles
    },
    clicking() {
      return this.$store.state.map.clicking
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    lockMaps() {
      return this.$store.state.map.lockMaps
    },
  },
  methods: {
    async saveScene() {
      this.showHideElement('hide')
      setTimeout(async () => {
        const id = uuidv4()

        // get bound map current -> FlytoBound
        let positionCamera = []
        for (let i = 0; i < this.$parent.$parent.$refs.mapItem.length; i++) {
          let mapComponent = this.$parent.$parent.$refs.mapItem[i]
          let bounds = mapComponent.map.getBounds()
          positionCamera.push([
            [bounds.getNorth(), bounds.getWest()],
            [bounds.getSouth(), bounds.getEast()],
          ])
        }
        // get image map current -> FlytoBound
        let imageScene = await this.screenMap()

        let objScene = {}
        objScene.layers = _.cloneDeep(this.layers)
        objScene.date = _.cloneDeep(this.date)
        objScene.mapTiles = _.cloneDeep(this.mapTiles)
        objScene.clicking = _.cloneDeep(this.clicking)
        objScene.numberOfMap = _.cloneDeep(this.numberOfMap)
        objScene.lockMaps = _.cloneDeep(this.lockMaps)
        objScene.positionCamera = positionCamera

        objScene.imageScene = imageScene
        objScene.name = 'Scene ' + Number(this.scenes.length + 1)
        objScene.id = id

        this.$store.commit('map/SAVE_SCENE', objScene)

        this.$emit('closeContextMenu')
        this.showHideElement('show')
      }, 100)
    },
    showHideElement(status) {
      if (status === 'hide') {
        if (document.querySelector('.sidebar-container')) document.querySelector('.sidebar-container').style.display = 'none'
        if (document.querySelector('.panel-container')) document.querySelector('.panel-container').style.display = 'none'
        if (document.querySelector('.context-menu-map.contextMenuMap')) document.querySelector('.context-menu-map.contextMenuMap').style.display = 'none'
      }

      if (status === 'show') {
        if (document.querySelector('.sidebar-container')) document.querySelector('.sidebar-container').style.display = 'block'
        if (document.querySelector('.panel-container')) document.querySelector('.panel-container').style.display = 'block'
      }
    },
    async screenMap() {
      let image = document.getElementById('map-list')
      if (!image) {
        image = document.querySelector('.content-body')
      }
      return await html2canvas(image, { backgroundColor: null, useCORS: true }).then((canvas) => {
        let buffer = canvas.toDataURL('image/png')
        return buffer
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-layout .context-menu-map {
  border: solid 1px #cccccc1a;
}
.context-menu-map {
  position: fixed;
  border: solid 1px #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 180px;
  min-width: 140px;
  border-radius: 2px;
  transition: none;
  background: #fff;
  z-index: 500;
  ul {
    padding: 0;
    padding-left: 0px !important;
    margin: 0.1rem 0;
    li {
      list-style: none;
      cursor: pointer;
      line-height: 1.8rem;
      button {
        text-align: left;
        border: none;
        width: 100%;
        background: inherit;
        color: inherit;
        &.ena {
          opacity: 1;
          &:hover {
            background: rgba(130, 134, 139, 0.12);
            color: black;
          }
        }
        &.dis {
          opacity: 0.6;
          cursor: not-allowed;
        }
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
  .menu-icon {
    display: inline-flex;
    width: 24px;
    height: 20px !important;
    align-self: center;
  }
}
.dark-layout {
  .context-menu-map {
    background: #161d31;
    ul {
      li {
        button {
          &.ena {
            &:hover {
              background: rgba(130, 134, 139, 0.12);
              color: #fbf0f0;
            }
          }
        }
      }
    }
    .menu-icon {
      display: inline-flex;
      width: 24px;
    }
  }
}
</style>
