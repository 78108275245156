<template>
  <vue-select :appendToBody="true" :calculatePosition="calculatePosition" v-model="selected" :reduce="(data) => data.value" :options="options" class="select-size-sm" :placeholder="$t('datasources')">
    <template v-slot:option="option">
      <span style="margin-right: 4px;">
        <PointIcon v-if="option.type === 'point'" style="width: 14px; height: 14px;" />
        <ImageIcon v-if="option.type === 'image'" style="width: 14px; height: 14px;" />
        <MultiIcon v-if="option.type === 'multi'" style="width: 14px; height: 14px;" />
        <TyphoonIcon v-if="option.type === 'typhoon'" style="width: 14px; height: 14px;" />
        <NetcdfIcon v-if="option.type === 'netcdf'" style="width: 14px; height: 14px;" />
      </span>
      {{ option.label }}
    </template>
    <template #no-options>{{ $t('no_data_sources') }}</template>
  </vue-select>
</template>

<script>
import PointIcon from '/static/images/layer-config/radio/type-point.svg'
import ImageIcon from '/static/images/layer-config/radio/type-image.svg'
import TyphoonIcon from '/static/images/layer-config/radio/type-typhoon.svg'
import MultiIcon from '/static/images/layer-config/radio/type-multi.svg'
import NetcdfIcon from '/static/images/layer-config/radio/type-netcdf.svg'

export default {
  props: ['value'],
  components: {  PointIcon, ImageIcon, TyphoonIcon, MultiIcon, NetcdfIcon },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    options() {
      return this.datasources.filter((d) => ['point', 'image', 'typhoon', 'multi', 'netcdf'].includes(d.type)).map((d) => ({ value: d.id, label: d.name, type: d.type }))
    },
  },
  watch: {
    options() {
      if (!this.options.some((o) => o.value === this.selected)) {
        this.selected = null
        this.$nextTick(() => {
          this.$emit('commit')
        })
      }
    },
  },
  methods: {
    calculatePosition(dropdownList, component, {width, top, left}) {
      dropdownList.classList.add("width-fit-content")
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
  },
}
</script>
