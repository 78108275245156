<template>
  <!-- <table style="min-width: 40% !important; margin-left: 5px">
    <thead>
      <tr>
        <th style="width: 20%">{{ $t('name') }}</th>
        <th style="width: 10%">{{ $t('type') }}</th>
        <th style="width: 10%">{{ $t('size') }}</th>
        <th style="width: 36px"></th>
      </tr>
    </thead>
    <tbody>
      <tr class="add-datasource" v-for="sample in samples" :key="sample.fullName" @click="chooseDatasource(sample.url)">
        <td>{{ sample.fullName | name }}</td>
        <td>{{ sample.fullName | extention }}</td>
        <td>{{ sample.size | bytes }}</td>
        <td>
          <feather-icon icon="FilePlusIcon" class="add-datasource"  />
        </td>
      </tr>
    </tbody>
  </table> -->
  <div class="custom-scrollbar" style="height: 370px; overflow: auto; padding: 0 5px">
    <div style="height: 20px"></div>
    <div class="d-flex justify-content-between align-items-center grid-datasource" v-for="sample in samples" :key="sample.fullName">
      <div class="d-flex align-items-center" style="width: 42%">
        <feather-icon icon="FileIcon" style="height: 20px; width: 20px; color: #ff9300; margin-right: 5px" />
        <span class="text-over-ellip">{{ sample.fullName | name }}</span>
      </div>
      <div class="status-file-name">{{ sample.fullName | extention }}</div>
      <div class="status-size">{{ sample.size | bytes }}</div>
      <div class="add-datasource btn btn-primary" @click="chooseDatasource(sample.url)" :title="$t('download_file')"><feather-icon icon="DownloadCloudIcon" style="height: 18px; width: 18px" /></div>
    </div>
  </div>
</template>

<script>
const path = require('path')
const axios = require('axios')
import FileMixin from '@/mixins/FileMixin.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

const OBJ_SAMPLES = [
  {
    fullName: '03_Covid-19_Station.csv',
    size: 7964,
    url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/03_Covid-19_Station.csv',
  },
  {
    fullName: '03_Covid-19_Timeseries.csv',
    size: 2305223,
    url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/03_Covid-19_Timeseries.csv',
  },
  {
    fullName: 'dam_stations.csv',
    size: 1417,
    url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/dam_stations.csv',
  },
  {
    fullName: 'dam_P_2008_ts.csv',
    size: 210354,
    url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/dam_P_2008_ts.csv',
  },
  {
    fullName: 'Grid_5x5_Station.csv',
    size: 1023,
    url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/Grid_5x5_Station.csv',
  },
  {
    fullName: 'Grid_5x5_Timeseries.csv',
    size: 1026175,
    url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/Grid_5x5_Timeseries.csv',
  },
  // {
  //   fullName: 'tos_O1_2001-2002.nc',
  //   size: 2949152,
  //   url: 'https://ecoplot.s3.ap-northeast-1.amazonaws.com/Prefix+Data/tos_O1_2001-2002.nc',
  // },
]

export default {
  components: { ToastificationContent },
  mixins: [FileMixin],
  mounted() {
    this.samples = []
    this.samples = OBJ_SAMPLES
  },
  filters: {
    name(value) {
      let ext = path.extname(value)
      return path.basename(value, ext)
    },
    extention(value) {
      return path.extname(value).slice(1)
    },
    bytes(value) {
      if (value === 0) return '0 Bytes'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(value) / Math.log(k))
      return parseFloat((value / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    },
  },
  data() {
    return {
      samples: [],
    }
  },
  methods: {
    async chooseDatasource(url) {
      axios.get(url).then((response) => {
        let data = typeof response.data === 'string' ? response.data : JSON.stringify(response.data)
        let file = new File([data], path.basename(url))
        this.AddDatasource(file)
      })
    },
    async AddDatasource(file) {
      let result = await this.determineTypeAndReadData(file)
      if (!result.name) result.name = file.name
      await this.addDatasource(result)
      this.$toast({ component: ToastificationContent, props: { title: 'Added successfully', icon: 'BellIcon', variant: 'success' } })
    },
  },
}
</script>

<style scoped>
/* table {
  min-width: 100%;
}

th {
  border: 1px solid #ebe9f1;
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer;
}

th:nth-child(3) {
  text-align: right;
}

td {
  border: 1px solid #ebe9f1;
  padding: 6px 10px;
  white-space: nowrap;
}

td:nth-child(3) {
  text-align: right;
} */

.add-datasource {
  border-radius: 50%;
  padding: 2px;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-datasource:hover {
  cursor: pointer;
  opacity: 0.75;
  /* background-color: #397bff;
  color: #fff; */
  /* box-shadow: 0 8px 25px -8px #397bff; */
}

/* DARK */
/* .dark-layout th {
  border-color: #3b4253;
}

.dark-layout td {
  border-color: #3b4253;
} */

.grid-datasource {
  display: block;
  width: 100%;
  height: 2.714rem;
  padding: 0.438rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.45;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  opacity: 0.75;
  user-select: none;
  margin-bottom: 5px;
}
.dark-layout .grid-datasource {
  border-color: #404656 !important;
  color: #fff;
}
.text-over-ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-file-name {
  width: 22%;
  text-align: center;
  border: 1px solid transparent;
  color: #ff9300;
  background-color: rgba(255, 147, 0, 0.16);
  border-radius: 0.358rem;
}
.status-size {
  width: 22%;
  text-align: center;
  border: 1px solid transparent;
  color: #397bff;
  background-color: rgba(57, 123, 255, 0.16);
  border-radius: 0.358rem;
}
</style>
