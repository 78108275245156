<template>
  <vue-select v-model="selected" :appendToBody="true" :calculatePosition="calculatePosition" :reduce="(data) => data.value" :options="options" class="select-size-sm" :placeholder="$t('item')">
    <template #no-options>{{ $t('no_data_sources') }}</template>
  </vue-select>
</template>

<script>

export default {
  props: ['value', 'timeseries'],
  components: {},
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    options() {
      let timeseries = this.datasources.find((d) => d.id === this.timeseries);
      if (!timeseries) return [];
      return Object.keys(this.$db[timeseries.id].listItems).map((d) => ({ value: d, label: d }));
    },
  },
  watch: {
    options() {
      if (this.options.findIndex((o) => o.value === this.selected) === -1) {
        this.selected = null;
      }
    },
    selected() {
      this.$emit('input', this.selected);
    },
  },
  methods: {
    calculatePosition(dropdownList, _component, { width, top, left }) {
      dropdownList.classList.add('width-fit-content');
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
  },
};
</script>
