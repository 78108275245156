<template>
  <div>
    <div v-for="(t, i) in lables" v-show="t.visible" :key="i" :class="`tooltip-container ${t.direction}`" :style="{ fontFamily: t.family, fontSize: t.size + 'px', color: t.color, borderColor: t.backgroundColor, opacity: t.opacity, backgroundColor: t.backgroundColor, top: t.position.y + 'px', left: t.position.x + 'px', '--data-color': t.backgroundColor }">
      <span>{{ t.text }}</span>
    </div>
  </div>
</template>

<script>
const _ = require('lodash')
export default {
  props: ['map', 'moveCount', 'mapIndex', 'countClusterGroup'],
  data() {
    return {
      lables: [],
    }
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers
    },
    toggleVisibleCount() {
      return this.$store.state.layer.toggleVisibleCount
    },
    tooltips() {},
  },
  watch: {
    layers() {
      this.initLabels()
    },
    map() {
      this.initLabels()
    },
    moveCount() {
      this.initLabels()
    },
    toggleVisibleCount() {
      this.initLabels()
    },
    lables: {
      deep: true,
      handler() {},
    },
    countClusterGroup() {
      this.initLabels()
    },
  },
  methods: {
    initLabels() {
      const self = this
      setTimeout(() => {
        self.lables = []
        let labelsTemp = []
        if (!self.map) return self.lables
        self.layers.forEach((layer) => {
          let leafletLayer = null
          self.map.eachLayer((l) => {
            if (l.id === layer.id) {
              leafletLayer = l
              return
            }
          })
          if (!layer.tooltip.visible || !layer.tooltip.permanent || !leafletLayer) return
          let useMarkerCluster
          try {
            useMarkerCluster = layer.type == 'point' && layer.markerCluster && layer.markerCluster.using
          } catch {}
          let idMultiSelected = 'ID'
          try {
            idMultiSelected = layer.idMultiSelected ? layer.idMultiSelected : 'ID'
          } catch {}
          if (useMarkerCluster) {
            let allCluster
            try {
              allCluster = leafletLayer._parentGroup._featureGroup._layers
              if (allCluster && Object.keys(allCluster).length) {
                for (let key in allCluster) {
                  try {
                    let countCluster = allCluster[key]._childCount
                    let isSpiderfied = allCluster[key].isSpiderfied
                    if (countCluster) {
                      if (!isSpiderfied) {
                        let allclusterChild = allCluster[key].getAllChildMarkers()
                        const pathFrist = allclusterChild[0]
                        let position
                        try {
                          position = self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                        } catch {}
                        const labelTemp = {
                          text: pathFrist.feature.properties[idMultiSelected],
                          latlng: pathFrist.latlng,
                          direction: layer.tooltip.direction,
                          family: layer.tooltip.family,
                          size: +layer.tooltip.size,
                          color: layer.tooltip.color,
                          opacity: +layer.tooltip.opacity,
                          backgroundColor: layer.tooltip.backgroundColor,
                          id: layer.id,
                          visible: layer.visible[self.mapIndex],
                          position,
                        }
                        labelsTemp.push(labelTemp)
                      }
                    } else {
                      const pathFrist = allCluster[key]
                      let position
                      try {
                        position = self.map.latLngToContainerPoint(pathFrist.getLatLng())
                      } catch {}
                      const labelTemp = {
                        text: pathFrist.feature.properties[idMultiSelected],
                        latlng: pathFrist.latlng,
                        direction: layer.tooltip.direction,
                        family: layer.tooltip.family,
                        size: +layer.tooltip.size,
                        color: layer.tooltip.color,
                        opacity: +layer.tooltip.opacity,
                        backgroundColor: layer.tooltip.backgroundColor,
                        id: layer.id,
                        visible: layer.visible[self.mapIndex],
                        position,
                      }
                      labelsTemp.push(labelTemp)
                    }
                  } catch {}
                }
              }
            } catch {}
          } else {
            leafletLayer.getLayers().map((path) => {
              try {
                let position
                try {
                  position = self.map.latLngToContainerPoint(path.latlng)
                } catch {}
                if (!path.feature.properties || !path.feature.properties[idMultiSelected]) {
                } else {
                  const label = {
                    text: path.feature.properties[idMultiSelected],
                    latlng: path.latlng,
                    direction: layer.tooltip.direction,
                    family: layer.tooltip.family,
                    size: +layer.tooltip.size,
                    color: layer.tooltip.color,
                    opacity: +layer.tooltip.opacity,
                    backgroundColor: layer.tooltip.backgroundColor,
                    id: layer.id,
                    visible: layer.visible[self.mapIndex],
                    position,
                  }
                  labelsTemp.push(label)
                }
              } catch {}
            })
          }
        })

        // // Loại bỏ các đối tượng giống nhau hoàn toàn
        // const isEqual = (obj1, obj2) => {
        //   return _.isEqual(obj1, obj2);
        // };
        // labelsTemp = _.uniqWith(labelsTemp, isEqual);
        self.lables = labelsTemp
      }, 0)
    },
  },
}
</script>
