export const METADATA_POPUP = [
    ['CLASSIFICATION', 'METADATA', 'VALUE'],
    ['A.管理データ', '研究年', ''],
    ['', '報告者', ''],
    ['', 'ファイル名', ''],
    ['', '図面番号', ''],
    ['', '掲載項', ''],
    ['B.調査対象データ', '緯度経度', ''],
    ['', '測定日時', ''],
    ['', '天然水の森', ''],
    ['', '機能', ''],
    ['', '場', ''],
    ['', 'プロセス', ''],
    ['', '状態変化', ''],
    ['', '測定項目', ''],
    ['', '単位', ''],
    ['C.記述データ', '記述', ''],
  ];