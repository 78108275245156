<template>
  <div class="image-viewport" :style="{ borderColor: isSelecting ? lineColor : 'transparent' }">
    <!-- Location badge -->
    <b-badge class="location-name badge-light-secondary" :style="isSelecting ? 'color: ' + lineColor + '!important' : ''" :variant="isSelecting ? 'warning' : 'secondary'" :title="source.locationItem ? source.locationItem : source.latlngPos ? source.latlngPos.toString() : ''" @click="clickLocation(source.locationItem, source.imageOnlyShow, source.latlngPos)">
      {{ source.locationItem ? source.locationItem : source.latlngPos ? source.latlngPos.toString() : '' }}
    </b-badge>

    <!-- Delete button -->
    <div class="delete-button btn-flat-danger" :title="$t('delete')" @click="deleteImage(source.id)">
      <feather-icon icon="Trash2Icon" size="16" class="cursor-pointer text-danger" />
    </div>

    <!-- Image -->
    <div class="image-background" @click="showLightBox(source.id)" :style="{ backgroundImage: `url(${getImageSourceById(source.id)})` }"></div>
  </div>
</template>

<script>
const _ = require('lodash')
import 'glightbox/dist/css/glightbox.css'
import ImageMixin from '@/mixins/ImageMixin'

export default {
  props: ['source', 'layerSelected', 'hovering', 'clicking', 'lightBoxSideBar'],
  mixins: [ImageMixin],
  computed: {
    lineColor() {
      return this.$store.state.settings.lineColor
    },
    layers() {
      return this.$store.state.layer.layers
    },
    layer() {
      if (!this.layerSelected) return null
      let layer = this.layers.find((l) => l.id === this.layerSelected)
      return layer
    },
    isSelecting() {
      let isSelect = null
      if (this.clicking) {
        for (let i = 0; i < this.clicking.length; i++) {
          if (this.clicking[i] && this.clicking[i].layerId === this.layerSelected && this.clicking[i].clickId === this.source.locationItem && (this.clicking[i].latlng && this.source.latlngPos ? JSON.stringify(this.source.latlngPos) === JSON.stringify(this.clicking[i].latlng) : true)) {
            isSelect = this.clicking[i]
            break
          }
        }
      }
      return isSelect
    },
  },
  methods: {
    deleteImage(id) {
      this.$store.commit('image/DELETE_POPUP_IMAGE', { id })
    },
    clickLocation(locationItem, imageOnlyShow, latlng) {
      if (!imageOnlyShow) {
        let multiShow = false
        multiShow = this.layer.popup.multiShow
        //SET CLICKING FOR 2 MAP VIEW
        for (let i = 0; i < 2; i++) {
          const storeData = {
            data: { layerId: this.layerSelected, clickId: locationItem, source: 'sidebar', multiShow: multiShow, latlng, pin: true },
            mapIndex: i,
          }
          this.$store.commit('map/SET_CLICKING', storeData)
        }
      }
    },
    showLightBox(id) {
      let href = this.getImageSourceById(id)
      const lightboxEles = this.lightBoxSideBar.elements
      let indexToShow = lightboxEles.findIndex((img) => img.href === href)
      this.lightBoxSideBar.openAt(indexToShow)
    },
  },
}
</script>

<style scoped>
.image-viewport {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 2px;
  border: 1px dashed;
  border-radius: 0.428rem;
  overflow: hidden;
  transition: border-color 0.2s ease;
}
.image-viewport:hover {
  border-color: #939bb3 !important;
}
.location-name {
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.delete-button {
  right: 5px;
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s ease;
}
.image-viewport:hover .delete-button {
  opacity: 1 !important;
}
.image-background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  border: 1px solid;
  border-color: transparent;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
</style>
