<template>
  <b-modal id="modal-autoevent" ref="autoEventModal" centered size="xl" title="Settings">
    <template #modal-header>
      <div class="d-flex align-items-center">
        <feather-icon icon="FlagIcon" style="margin-right: 10px" />
        <div>{{ $t('auto_event') }}</div>
      </div>
      <button type="button" @click="close" aria-label="Close" class="close">×</button>
    </template>
    <div>
      <b-card-text class="auto-event-modal">
        <div class="text-secondary mb-1 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <feather-icon icon="HelpCircleIcon" style="margin-right: 10px" />
            <div>{{ $i18n.locale == 'en' ? $t('auto_event_tips') + noteText : noteText + $t('auto_event_tips') }}</div>
          </div>
          <div>
            <b-button variant="outline-success" @click="addEvent">{{ $t('add') }}</b-button>
          </div>
        </div>
        <b-table-simple responsive small class="table-auto-event" sticky-header>
          <b-thead>
            <b-tr class="tr-separate">
              <b-th>{{ $t('layer') }}</b-th>
              <b-th>{{ $t('condition') }}</b-th>
              <b-th>{{ $t('value') }}</b-th>
              <b-th>{{ $t('style') }}</b-th>
              <b-th>{{ $t('message') }}</b-th>
              <b-th>{{ $t('duration') }}</b-th>
              <b-th>{{ $t('visible') }}</b-th>
              <b-th>{{ $t('multi_show') }}</b-th>
              <b-th class="event-add"> </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(autoEvent, index) of autoEventList" :key="index">
              <b-td class="vue-select-layer">
                <b-form-select v-model="autoEvent.layer" :options="layers" value-field="id" text-field="name" disabled-field="notEnabled"></b-form-select>
              </b-td>
              <b-td class="vue-select-condition">
                <b-form-select v-model="autoEvent.condition" :options="operator" size="sm"></b-form-select>
              </b-td>
              <b-td class="vue-input-value">
                <b-form-input type="number" v-model="autoEvent.value" :placeholder="$t('0')" />
              </b-td>
              <b-td class="event-title">
                <b-form-select v-model="autoEvent.style" :class="`event-color-text-${autoEvent.style}`">
                  <option :class="`event-color-${option.color}`" v-for="option in colorChoices" :value="option.color">
                    {{ $t(option.label) }}
                  </option>
                </b-form-select>
              </b-td>
              <b-td class="event-note">
                <b-form-input v-model="autoEvent.message" :placeholder="$t('Note')" />
              </b-td>
              <b-td class="vue-input-duration">
                <b-form-input type="number" v-model="autoEvent.duration" :placeholder="$t('1000')" />
              </b-td>
              <b-td class="radio-visible">
                <select v-model="autoEvent.visible">
                  <option :value="data.value" v-for="data in opAlertStyle">{{ $t(data.text) }}</option>
                </select>
              </b-td>
              <b-td class="radio-visible">
                <b-form-checkbox class="multi-show-popup" v-model="autoEvent.multiShowEvent"></b-form-checkbox>
              </b-td>
              <b-td class="text-danger event-delete">
                <feather-icon icon="Trash2Icon" @click="removeEvent(index)" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-text>
    </div>
    <template #modal-footer>
      <b-button variant="success" @click="save">{{ $t('save') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import { BTableSimple, BThead, BTh, BTd, BTr, BTbody, BModal, BButton, BCardText, BRow, BCol, BFormGroup, BFormTextarea } from 'bootstrap-vue';
const _ = require('lodash');

export default {
  components: { BTableSimple, BThead, BTh, BTd, BTr, BTbody, BModal, BButton, BCardText, BRow, BCol, BFormGroup, BFormTextarea, ToastificationContent },
  data() {
    return {
      autoEventList: [],
      colorChoices: [
        { label: 'primary', color: 'primary' },
        { label: 'purple', color: 'purple' },
        { label: 'success', color: 'success' },
        { label: 'warning', color: 'warning' },
        { label: 'danger', color: 'danger' },
        { label: 'information', color: 'info' },
        { label: 'secondary', color: 'secondary' },
      ],
      operator: ['>', '>=', '<', '<=', '==', '!='],
      opAlertStyle: [
        { value: 'popup', text: 'popup' },
        { value: 'toast', text: 'toast' },
      ],
      note: '[LOCATION]: [VALUE] ([UNIT])',
      noteText: '[LAYER], [ITEM], [LOCATION], [TIME], [VALUE], [UNIT], [CONDITION]',
    };
  },
  computed: {
    defaultAutoEvent() {
      return { layer: null, condition: this.operator[0], value: 1, style: this.colorChoices[0].color, message: this.note, duration: 2000, visible: 'popup', multiShowEvent: true };
    },
    layers() {
      return this.$store.state.layer.layers.filter((layer) => layer.type === 'point' || layer.type === 'multi');
    },
    conditionRule() {
      return this.$store.state.map.conditionRule;
    },
  },
  methods: {
    calculatePositionColor(dropdownList, component, { width, top, left }) {
      dropdownList.classList.add('auto-event-dropdown-menu');
      dropdownList.classList.add('color-menu');
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    calculatePosition(dropdownList, component, { width, top, left }) {
      dropdownList.classList.add('auto-event-dropdown-menu');
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    open() {
      this.autoEventList = _.cloneDeep(this.conditionRule);
      this.$refs.autoEventModal.show();
    },
    close() {
      this.$refs.autoEventModal.hide();
    },
    addEvent() {
      this.autoEventList.push({ ...this.defaultAutoEvent });
    },
    removeEvent(index) {
      try {
        this.autoEventList.splice(index, 1);
        this.save();
      } catch {}
    },
    save() {
      try {
        let arrAutoEventList = [];
        for (let i = 0; i < this.autoEventList.length; i++) {
          if (this.autoEventList[i].layer && typeof this.autoEventList[i].layer != 'object' && this.autoEventList[i].condition && this.autoEventList[i].value && this.autoEventList[i].style && this.autoEventList[i].message && this.autoEventList[i].duration > 0) {
            arrAutoEventList.push(this.autoEventList[i]);
          }
        }
        this.$store.commit('map/UPDATE_CONDITION_RULE', [...arrAutoEventList]);
        this.$toast({ component: ToastificationContent, props: { title: this.$t('update_successful'), icon: 'BellIcon', text: ``, variant: 'success' } });
        this.$refs.autoEventModal.hide();
      } catch {
        this.$toast({ component: ToastificationContent, props: { title: 'Error!', icon: 'BellIcon', text: ``, variant: 'error' } });
      }
    },
  },
};
</script>

<style lang="scss">
#modal-autoevent .modal-dialog .multi-show-popup {
  left: calc(50% - 10px);
}
#modal-autoevent .modal-dialog {
  max-width: 920px !important;
}
.color-menu li.vs__dropdown-option {
  padding-right: 0 !important;
  margin: 0.25rem 0.5rem;
  padding: 0;
  background-color: unset !important;
}
.color-menu li.vs__dropdown-option:hover {
  background-color: unset !important;
}
.color-menu li.vs__dropdown-option .event-color {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  height: 25px;
}
.event-color {
  &-primary {
    background-color: rgba(57, 123, 255, 0.12) !important;
    color: #397bff !important;
    &:hover {
      background-color: red !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-purple {
    background-color: rgba(24, 14, 138, 0.12) !important;
    color: rgb(114, 102, 240) !important;
    &:hover {
      background-color: rgb(114, 102, 240) !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-success {
    background-color: rgba(32, 159, 89, 0.12) !important;
    color: rgb(73, 218, 138) !important;
    &:hover {
      background-color: #28c76f !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-warning {
    background-color: rgba(164, 80, 0, 0.12) !important;
    color: rgb(255, 162, 72) !important;
    &:hover {
      background-color: #ff9f43 !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-danger {
    background-color: rgba(148, 18, 19, 0.12) !important;
    color: rgb(235, 90, 91) !important;
    &:hover {
      background-color: #ea5455 !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-info {
    background-color: rgba(0, 166, 186, 0.12) !important;
    color: rgb(42, 232, 255) !important;
    &:hover {
      background-color: #00cfe8 !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-secondary {
    background-color: rgba(92, 100, 104, 0.12) !important;
    color: rgb(156, 147, 134) !important;
    &:hover {
      background-color: #82868b !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
  &-text {
    &-primary {
      color: #397bff !important;
    }
    &-purple {
      color: rgb(114, 102, 240) !important;
    }
    &-success {
      color: rgb(73, 218, 138) !important;
    }
    &-warning {
      color: rgb(255, 162, 72) !important;
    }
    &-danger {
      color: rgb(235, 90, 91) !important;
    }
    &-info {
      color: rgb(42, 232, 255) !important;
    }
    &-secondary {
      color: rgb(156, 147, 134) !important;
    }
  }
}

.auto-event-dropdown-menu {
  z-index: 9999;
}
.auto-event-modal .vue-select-item input,
.auto-event-modal .vue-select-item input:hover {
  border: 1px solid transparent !important;
  padding: 0 !important;
}
.auto-event-modal input {
  padding-left: 0;
  border: 1px solid transparent !important;
}

.auto-event-modal .vs__clear {
  display: none;
}
.auto-event-modal .table-responsive {
  overflow-x: hidden;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.auto-event-modal .event-threshold {
  max-width: 85px;
}
.auto-event-modal input {
  padding: 6px;
}
.auto-event-modal .event-title {
  max-width: 70px;
}
.auto-event-modal .event-title .vs__dropdown-toggle {
  border: 1px solid transparent;
}
.auto-event-modal .event-title .vs__dropdown-toggle:hover {
  border: 1px solid #d8d6de;
}
.dark-layout .auto-event-modal .event-title .vs__dropdown-toggle:hover {
  border: 1px solid #404656;
}
.auto-event-modal .event-delete,
.auto-event-modal .event-add {
  text-align: center;
}
.auto-event-modal .event-delete svg,
.auto-event-modal .event-add svg {
  cursor: pointer;
}
.vue-select-layer input {
  border: none !important;
}
.vue-select-layer input:focus {
  border: none !important;
}
.vue-select-layer input:hover {
  border: none !important;
}
.table-auto-event {
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
.table-auto-event table {
  white-space: nowrap;
  max-height: 600px;
  overflow-y: scroll;
  td {
    border: solid 1px #ccc;
    padding: 3px 0 !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th {
    border: solid 1px #ccc;
    padding: 3px 5px !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(2) {
      width: 60px;
    }
    &:nth-child(3) {
      width: 100px;
    }
    &:nth-child(4) {
      width: 90px;
    }
    &:nth-child(6) {
      width: 80px;
    }
    &:nth-child(7) {
      width: 10px;
      margin: 0 auto;
      padding: 3px 10px !important;
    }
  }
  .event-delete {
    padding: 3px 0.5rem !important;
  }
  select {
    padding: 5px 3px !important;
    height: auto;
    border: none;
  }
  input {
    padding: 4px 3px !important;
    height: auto;
    &:hover {
      border-color: #397bff !important;
    }
  }
}
#btn-radios-auto-event {
  margin-left: 20px;
}
.auto-event-modal {
  .lb_popup,
  .lb_toast {
    padding: 3px 5px;
  }
  .popup-status,
  .toast-status {
    padding: 3px 5px;
    display: flex;
    align-items: center;
  }
}
.tbody-popuptoast {
  th,
  td {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
  }
}
.action-popup {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
#modal-autoevent___BV_modal_outer_ {
  z-index: 10000 !important;
}

.map-visible {
  padding: 5px;
}
.radio-visible {
  select {
    color: #6e6b7b;
    background: transparent;
    border: none;
    &:hover {
      border: none;
      background: transparent;
      outline: none;
    }
    &:focus {
      border: none;
      background: transparent;
      outline: none;
    }
  }
}
.dark-layout {
  .map-toast {
    color: #b4b7bd;
  }
  .map-visible {
    color: #d0d2d6 !important;
  }
  .radio-visible {
    color: #b4b7bd;
    select {
      color: #b4b7bd;
    }
  }
}
</style>
