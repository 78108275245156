<template>
  <table class="threshold-level-container threshold-table">
    <thead>
      <tr>
        <th>#</th>
        <th class="text-secondary t-header">MIN</th>
        <th class="color-input" style="padding: 0;">
          <ColorPicker v-model="fillColor" placeholder="Color" size="sm" :right="'-30px'" />
        </th>
        <th>
          <feather-icon icon="PlusIcon" size="14" style="cursor: pointer; vertical-align: -2px" @click="addLevel" title="New threshold" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(level, index) in levels" :key="level.id">
        <td class="text-secondary">{{ index + 1 }}</td>
        <td class="label-input">
          <b-form-input size="sm" placeholder="Value" :value="generalValues[index]" @input="updateGeneral(index, $event)" />
        </td>
        <td class="color-input">
          <ColorPicker :value="level.color" @input="updateColor(level.id, $event)" placeholder="Color" size="sm" :right="'-30px'" />
        </td>
        <td>
          <feather-icon icon="XIcon" size="14" style="cursor: pointer; vertical-align: -3px" @click="deleteLevel(level.id)" title="Delete threshold" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const _ = require('lodash')
import ColorPicker from './ColorPicker.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: ['value', 'thresholds', 'layer'],
  components: { ColorPicker },
  data() {
    return {
      fillColor: this.layer.fillColor.color,
    }
  },  
  computed: {
    levels: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    generalValues() {
      return this.thresholds['General']
    },
  },
  watch: {
    fillColor() {
      this.$emit('updateDefaultColor', this.fillColor)
    },
  },
  methods: {
    updateGeneral(index, value) {
      let newThresholds = _.merge({}, this.thresholds)
      newThresholds['General'][index] = value
      this.$emit('updateThresholds', newThresholds)
    },
    addLevel() {
      this.levels = [...this.levels, { id: uuidv4(), label: '', color: '#397BFF' }]
    },
    updateLabel(id, label) {
      let index = this.levels.findIndex((l) => l.id === id)
      let old = this.levels[index]
      this.levels.splice(index, 1, { ...old, label })
    },
    updateColor(id, color) {
      let index = this.levels.findIndex((l) => l.id === id)
      let old = this.levels[index]
      this.levels.splice(index, 1, { ...old, color })
    },
    deleteLevel(id) {
      this.levels = this.levels.filter((l) => l.id !== id)
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  border-color: #d8d6de;
}

.dark-layout table {
  border-color: #404656;
}

th {
  font-weight: bold;
  text-align: center;
  border: 1px solid;
  border-color: inherit;
  padding: 0 5px;
}

td {
  border: 1px solid;
  border-color: inherit;
  padding: 0;
}

td:first-child,
td:last-child {
  text-align: center;
}

.label-input input {
  border-color: transparent !important;
}
</style>

<style>
.threshold-level-container .color-input input {
  border-color: transparent !important;
}
</style>
