import { PALETTE_PICKER } from "./colors";

// NETCDF CONFIG
export const GRADIENT_COLOR = [
  { name: 'Gradient_rainbow', colorMap: 'rainbow', visualizeMode: 'gradient', title: 'gradient_rainbow_color' },
  { name: 'Gradient_plasma', colorMap: 'plasma', visualizeMode: 'gradient', title: 'gradient_plasma_color' },
  { name: 'Gradient_spectral', colorMap: 'Spectral', visualizeMode: 'gradient', title: 'gradient_spectral_color' },
  { name: 'Gradient_viridis', colorMap: 'viridis', visualizeMode: 'gradient', title: 'gradient_siridis_color' },
];
export const FILL_COLOR = [
  { name: 'Fill_rainbow', colorMap: 'rainbow', visualizeMode: 'fill', title: 'fill_rainbow_color' },
  { name: 'Fill_plasma', colorMap: 'plasma', visualizeMode: 'fill', title: 'fill_plasma_color' },
  { name: 'Fill_spectral', colorMap: 'Spectral', visualizeMode: 'fill', title: 'fill_spectral_color' },
  { name: 'Fill_viridis', colorMap: 'viridis', visualizeMode: 'fill', title: 'fill_siridis_color' },
];
export const FLAT_COLOR = [
  { name: 'Flat_rainbow', colorMap: 'rainbow', visualizeMode: 'flat', title: 'flat_rainbow_color' },
  { name: 'Flat_plasma', colorMap: 'plasma', visualizeMode: 'flat', title: 'flat_plasma_color' },
  { name: 'Flat_spectral', colorMap: 'Spectral', visualizeMode: 'flat', title: 'flat_spectral_color' },
  { name: 'Flat_viridis', colorMap: 'viridis', visualizeMode: 'flat', title: 'flat_siridis_color' },
];
export const GOURAUD_COLOR = [
  { name: 'Gouraud_rainbow', colorMap: 'rainbow', visualizeMode: 'gouraud', title: 'gouraud_rainbow_color' },
  { name: 'Gouraud_plasma', colorMap: 'plasma', visualizeMode: 'gouraud', title: 'gouraud_plasma_color' },
  { name: 'Gouraud_spectral', colorMap: 'Spectral', visualizeMode: 'gouraud', title: 'gouraud_spectral_color' },
  { name: 'Gouraud_viridis', colorMap: 'viridis', visualizeMode: 'gouraud', title: 'gouraud_siridis_color' },
];
export const LINE_COLOR = [
  { name: 'Line_rainbow', colorMap: 'rainbow', visualizeMode: 'line', title: 'line_rainbow_color' },
  { name: 'Line_plasma', colorMap: 'plasma', visualizeMode: 'line', title: 'line_plasma_color' },
  { name: 'Line_spectral', colorMap: 'Spectral', visualizeMode: 'line', title: 'line_spectral_color' },
  { name: 'Line_viridis', colorMap: 'viridis', visualizeMode: 'line', title: 'line_siridis_color' },
];
// TIMESERIES & SHAPE CONFIG
export const CIRCLE = [
  { name: 'Circle', colors: PALETTE_PICKER[0], shapeType: 'Circle', mode: 'solid', title: 'circle_blue_color' },
  { name: 'CircleColor1', colors: PALETTE_PICKER[0], shapeType: 'Circle', mode: 'linear', title: 'circle_rainbow_color' },
  { name: 'CircleColor2', colors: PALETTE_PICKER[1], shapeType: 'Circle', mode: 'linear', title: 'circle_blue_gradient_color' },
  { name: 'CircleColor3', colors: PALETTE_PICKER[2], shapeType: 'Circle', mode: 'linear', title: 'circle_rites_of_the_rain_color' },
  { name: 'CircleColor4', colors: PALETTE_PICKER[3], shapeType: 'Circle', mode: 'linear', title: 'circle_happy_colors_color' },
  { name: 'CircleColor5', colors: PALETTE_PICKER[4], shapeType: 'Circle', mode: 'linear', title: 'circle_vintage_color' },
  { name: 'CircleColor6', colors: PALETTE_PICKER[5], shapeType: 'Circle', mode: 'linear', title: 'circle_gradient_in_retro_color' },
  { name: 'CircleColor7', colors: PALETTE_PICKER[6], shapeType: 'Circle', mode: 'linear', title: 'circle_sunrise_in_the_city_color' },
  { name: 'CircleColor8', colors: PALETTE_PICKER[7], shapeType: 'Circle', mode: 'linear', title: 'circle_cycler_color' },
];
export const TRIANGLE = [
  { name: 'Triangle', colors: PALETTE_PICKER[0], shapeType: 'Triangle', mode: 'solid', title: 'triangle_blue_color' },
  { name: 'TriangleColor1', colors: PALETTE_PICKER[0], shapeType: 'Triangle', mode: 'linear', title: 'triangle_rainbow_color' },
  { name: 'TriangleColor2', colors: PALETTE_PICKER[1], shapeType: 'Triangle', mode: 'linear', title: 'triangle_blue_gradient_color' },
  { name: 'TriangleColor3', colors: PALETTE_PICKER[2], shapeType: 'Triangle', mode: 'linear', title: 'triangle_rites_of_the_rain_color' },
  { name: 'TriangleColor4', colors: PALETTE_PICKER[3], shapeType: 'Triangle', mode: 'linear', title: 'triangle_happy_colors_color' },
  { name: 'TriangleColor5', colors: PALETTE_PICKER[4], shapeType: 'Triangle', mode: 'linear', title: 'triangle_vintage_color' },
  { name: 'TriangleColor6', colors: PALETTE_PICKER[5], shapeType: 'Triangle', mode: 'linear', title: 'triangle_gradient_in_retro_color' },
  { name: 'TriangleColor7', colors: PALETTE_PICKER[6], shapeType: 'Triangle', mode: 'linear', title: 'triangle_sunrise_in_the_city_color' },
  { name: 'TriangleColor8', colors: PALETTE_PICKER[7], shapeType: 'Triangle', mode: 'linear', title: 'triangle_cycler_color' },
];
export const SQUARE = [
  { name: 'Square', colors: PALETTE_PICKER[0], shapeType: 'Square', mode: 'solid', title: 'square_blue_color' },
  { name: 'SquareColor1', colors: PALETTE_PICKER[0], shapeType: 'Square', mode: 'linear', title: 'square_rainbow_color' },
  { name: 'SquareColor2', colors: PALETTE_PICKER[1], shapeType: 'Square', mode: 'linear', title: 'square_blue_gradient_color' },
  { name: 'SquareColor3', colors: PALETTE_PICKER[2], shapeType: 'Square', mode: 'linear', title: 'square_rites_of_the_rain_color' },
  { name: 'SquareColor4', colors: PALETTE_PICKER[3], shapeType: 'Square', mode: 'linear', title: 'square_happy_colors_color' },
  { name: 'SquareColor5', colors: PALETTE_PICKER[4], shapeType: 'Square', mode: 'linear', title: 'square_vintage_color' },
  { name: 'SquareColor6', colors: PALETTE_PICKER[5], shapeType: 'Square', mode: 'linear', title: 'square_gradient_in_retro_color' },
  { name: 'SquareColor7', colors: PALETTE_PICKER[6], shapeType: 'Square', mode: 'linear', title: 'square_sunrise_in_the_city_color' },
  { name: 'SquareColor8', colors: PALETTE_PICKER[7], shapeType: 'Square', mode: 'linear', title: 'square_cycler_color' },
];
export const SPIKE = [
  { name: 'Spike', colors: PALETTE_PICKER[0], shapeType: 'Spike', mode: 'solid', title: 'spike_blue_color' },
  { name: 'SpikeColor1', colors: PALETTE_PICKER[0], shapeType: 'Spike', mode: 'linear', title: 'spike_rainbow_color' },
  { name: 'SpikeColor2', colors: PALETTE_PICKER[1], shapeType: 'Spike', mode: 'linear', title: 'spike_blue_gradient_color' },
  { name: 'SpikeColor3', colors: PALETTE_PICKER[2], shapeType: 'Spike', mode: 'linear', title: 'spike_rites_of_the_rain_color' },
  { name: 'SpikeColor4', colors: PALETTE_PICKER[3], shapeType: 'Spike', mode: 'linear', title: 'spike_happy_colors_color' },
  { name: 'SpikeColor5', colors: PALETTE_PICKER[4], shapeType: 'Spike', mode: 'linear', title: 'spike_vintage_color' },
  { name: 'SpikeColor6', colors: PALETTE_PICKER[5], shapeType: 'Spike', mode: 'linear', title: 'spike_gradient_in_retro_color' },
  { name: 'SpikeColor7', colors: PALETTE_PICKER[6], shapeType: 'Spike', mode: 'linear', title: 'spike_sunrise_in_the_city_color' },
  { name: 'SpikeColor8', colors: PALETTE_PICKER[7], shapeType: 'Spike', mode: 'linear', title: 'spike_cycler_color' },
];
// TYPHOON CONFIG
export const TYPHOON = [{ name: 'Default', title: 'default_color' }];
// IMAGE CONFIG
export const IMAGE = [{ name: 'Default', title: 'default_color' }];
