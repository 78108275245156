const _ = require('lodash')
const dayjs = require('dayjs')
const fs = require('fs')
import Vue from 'vue'
import Jimp from 'jimp/es'
import { EventBus } from '@/scripts/event-bus/event-bus.js'
const cachedJpegDecoder = Jimp.decoders['image/jpeg']
Jimp.decoders['image/jpeg'] = (data) => {
  const userOpts = { maxMemoryUsageInMB: 1024 }
  return cachedJpegDecoder(data, userOpts)
}
const proj4 = require('proj4').default
const proj4List = require('proj4-list')
import { v4 as uuidv4 } from 'uuid'
import Encoding from 'encoding-japanese'
import { DEFAULT_LAYER, DEFAULT_GROUP } from '@/constants/default-layer.js'
import { MAP_TILES, DEFAULT_TILES, DEFAULT_TABS } from '@/constants/app.js'
import { METADATA_POPUP } from '@/constants/metadata.js'
import { STORAGE_MODE } from '@/constants/repository.js'

export default {
  data() {
    return {
      arrWorkers: {},
    }
  },
  mounted() {
    EventBus.$on('cancel-import', () => {
      try {
        if (this.arrWorkers) {
          for (let idWorker in this.arrWorkers) {
            let myWorker = this.arrWorkers[idWorker]
            myWorker.postMessage({ file: null, encoding: 'terminate', items: null })
          }
        }
      } catch {}
    })
  },
  methods: {
    /** Convert from Native File to Datasource Object and store to indexedDB */
    determineTypeAndReadData(file, datasoucePreconfig = {}) {
      this.fileObject = file
      const self = this
      const { id, size, items } = datasoucePreconfig // Datasource preconfig information. No need to re-create
      return new Promise(async (resolve, reject) => {
        let myWorker = new Worker('/worker/WebWorker.js')
        let uuidWorker = uuidv4()
        self.arrWorkers[uuidWorker] = myWorker
        let datasource = {}
        datasource.id = id || uuidv4() // File Id, generate random if not enter
        datasource.size = file.size ? file.size : size // File Size, enter if cannot get file size like .zip|.eco case
        datasource.file = file.path ? '' : file // File Object
        datasource.name = file.name // File Name
        datasource.path = file.path // File Name
        datasource.storage_mode = STORAGE_MODE.RAM // Default Storage Mode for common data

        // Detech file encoding CSV file
        datasource.encoding = null // File Econding
        if (file.name.endsWith('.csv') || file.name.endsWith('.geojson')) {
          datasource.encoding = await new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = async function (e) {
              try {
                let codes = new Uint8Array(e.target.result)
                let encoding = Encoding.detect(codes) // Detech file encoding
                resolve(encoding)
              } catch {}
              resolve(null)
            }
            reader.readAsArrayBuffer(file)
          })
        }
        // Start transfer file to Worker
        myWorker.onmessage = async (e) => {
          // Event 'succeed', receive when complete read the file.
          if (e.data.event == 'terminate') {
            reject(`Cancelled`)
            myWorker.terminate()
            myWorker = undefined
            try {
              delete self.arrWorkers[uuidWorker]
            } catch {}
          } else if (e.data.event === 'succeed') {
            if (e.data && e.data.type == 'image') {
              let checkImage = true
              try {
                let bufFile = await new Promise((resolve, reject) => {
                  let reader = new FileReader()
                  reader.onload = async function (e) {
                    try {
                      resolve(e.target.result)
                    } catch {}
                    resolve(null)
                  }
                  reader.readAsArrayBuffer(file)
                })
                let bufImg = Buffer.from(bufFile)
                let base64 = bufImg.toString('base64')
                let dataBase64 = `data:image/png;base64,${base64}`
                checkImage = await self.checkImageCorruptedWithBase64(dataBase64, null, bufImg)
              } catch {}
              if (!checkImage) {
                reject(`cannot_open_this_file`)
                myWorker.terminate()
                myWorker = undefined
                try {
                  delete self.arrWorkers[uuidWorker]
                } catch {}
                return
              }
            }
            try {
              if (e.data && e.data.type == 'multi' && e.data.data && e.data.data.crs && e.data.data.crs.properties && e.data.data.crs.properties.name) {
                let fromProj = null
                proj4.defs(proj4List['EPSG:4326'][0], proj4List['EPSG:4326'][1])
                let toProj = proj4List['EPSG:4326'][0]
                let arrayEpsg = []
                try {
                  let stringT = e.data.data.crs.properties.name
                  let stringsplit = stringT.split(':')

                  for (let i = 0; i < stringsplit.length; i++) {
                    if (stringsplit[i] && self.isNumericString(stringsplit[i])) {
                      arrayEpsg.push(stringsplit[i])
                    }
                  }
                } catch {}
                let prEPSG = await new Promise((resolveEPSG, reject) => {
                  try {
                    for (let keyPrj4List in proj4List) {
                      if (arrayEpsg.length) {
                        for (let k = 0; k < arrayEpsg.length; k++) {
                          if (`EPSG:${arrayEpsg[k]}` == keyPrj4List) {
                            proj4.defs(proj4List[keyPrj4List][0], proj4List[keyPrj4List][1])
                            fromProj = proj4List[keyPrj4List][0]
                            resolveEPSG(true)
                            return
                          }
                        }
                      }
                    }
                  } catch {}
                  resolveEPSG(true)
                })
                if (fromProj && toProj && e.data.data.features && e.data.data.features.length) {
                  for (let i = 0; i < e.data.data.features.length; i++) {
                    let coordinatesTemp = self.transformCoordinatesMixins(e.data.data.features[i].geometry.coordinates, fromProj, toProj)
                    e.data.data.features[i].geometry.coordinates = coordinatesTemp
                  }
                }
              }
            } catch {}
            Object.assign(datasource, e.data)
            await self.storeData.call(self, datasource)
            resolve(datasource)
            myWorker.terminate()
            myWorker = undefined
            try {
              delete self.arrWorkers[uuidWorker]
            } catch {}
          }
          // Event 'failed', receive when file is invalid.
          else if (e.data.event === 'failed') {
            reject(e.data.message)
            myWorker.terminate()
            myWorker = undefined
            try {
              delete self.arrWorkers[uuidWorker]
            } catch {}
          }
        }
        myWorker.postMessage({ file, encoding: datasource.encoding, items })
      })
    },
    /** Store data into: RAM, indexedDB or temp folder */
    async storeData(datasource) {
      const self = this

      // Init $db key if not existed
      if (!Vue.prototype.$db[datasource.id]) Vue.prototype.$db[datasource.id] = {}
      // Store to RAM: $db[id][data_{item}_original]
      if (datasource.storage_mode === STORAGE_MODE.RAM) {
        if (datasource.type === 'point') {
          Vue.prototype.$db[datasource.id].data = _.sortBy(datasource.data, 'name')
        } else if (datasource.type === 'image') {
          Vue.prototype.$db[datasource.id].data = [{ data: datasource.data }]
        } else if (datasource.type === 'multi') {
          Vue.prototype.$db[datasource.id].data = [{ data: datasource.data }]
          try {
            if (datasource.data && datasource.data.features && datasource.data.features.length) {
              let itemsGeojson = {}
              for (let idxFeatures = 0; idxFeatures < datasource.data.features.length; idxFeatures++) {
                const properties = datasource.data.features[idxFeatures].properties
                if (properties && Object.keys(properties).length) {
                  for (let keyProperties in properties) {
                    let valueTemp = properties[keyProperties]
                    if (valueTemp && (self.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                      if (!itemsGeojson[keyProperties]) {
                        itemsGeojson[keyProperties] = {
                          min: Number(valueTemp),
                          max: Number(valueTemp),
                        }
                      } else {
                        if (Number(valueTemp) < Number(itemsGeojson[keyProperties].min)) {
                          itemsGeojson[keyProperties].min = Number(valueTemp)
                        }
                        if (Number(valueTemp) > Number(itemsGeojson[keyProperties].max)) {
                          itemsGeojson[keyProperties].max = Number(valueTemp)
                        }
                      }
                    }
                  }
                }
                Object.assign(datasource, { items: itemsGeojson })
              }
            }
          } catch {}
        } else if (datasource.type === 'typhoon') {
          Vue.prototype.$db[datasource.id].data = datasource.data
        } else if (datasource.type === 'timeseries') {
          for (const item in datasource.items) {
            const itemData = Object.keys(datasource.data)
              .filter((key) => key.endsWith(`-${item}`))
              .reduce((obj, key) => {
                obj[key] = datasource.data[key]
                return obj
              }, {})
            const tableName = `data_${item}_original`
            Vue.prototype.$db[datasource.id][tableName] = itemData
          }
        }
      }
    },

    /** Convert from configuration.json file to config object  */
    readConfig(file, datasourceMap) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader()
        fileReader.onload = async () => {
          let data = JSON.parse(fileReader.result)
          let config = {}
          let layers = []
          data.layers.forEach((oldLayer) => {
            if (oldLayer.isKeep) return
            let layer = this.mergeLayerFileSaveWithDefaultLayer(oldLayer, datasourceMap)
            if (layer.type != 'netcdf') layers.push(layer)
          })

          let date = []
          if (Array.isArray(data.date)) date = data.date

          let layerSelected = null
          if (data.layerSelected) layerSelected = data.layerSelected

          let groupItemsList = []
          if (data.groupItemsList) groupItemsList = data.groupItemsList

          let selectedGroupItems = null
          if (data.selectedGroupItems) selectedGroupItems = data.selectedGroupItems

          let currentRoute = null
          if (data.currentRoute) currentRoute = data.currentRoute

          let numberOfMap = 1
          if (data.numberOfMap === 1 || data.numberOfMap === 2) numberOfMap = data.numberOfMap

          let scenes = []
          if (data.scenes) scenes = data.scenes

          let mapBounds = null
          // if (Array.isArray(data.mapBounds) && data.mapBounds.length === numberOfMap) mapBounds = data.mapBounds;
          if (Array.isArray(data.mapBounds)) mapBounds = data.mapBounds

          let mapTiles = DEFAULT_TILES
          if (Array.isArray(data.mapTiles) && data.mapTiles.length === 2) {
            let firstTile = MAP_TILES.find((tile) => tile.url === data.mapTiles[0].url)
            let secondTile = MAP_TILES.find((tile) => tile.url === data.mapTiles[1].url)
            if (firstTile && secondTile) {
              mapTiles = [firstTile, secondTile]
            }
          }
          const pathLib = require('path')
          let popupImages = []
          if (Array.isArray(data.popupImages)) {
            for (let i = 0; i < data.popupImages.length; i++) {
              if (data.popupImages[i].image) {
                for (let j = 0; j < data.popupImages[i].image.length; j++) {
                  popupImages.push({
                    id: data.popupImages[i].image[j].id,
                    fileName: data.popupImages[i].image[j].filename || '',
                    imageOnlyShow: data.popupImages[i].image[j].imageOnlyShow || false,
                    latlngPos: data.popupImages[i].latlng,
                    layerId: data.popupImages[i].layerId,
                    locationItem: data.popupImages[i].clickId,
                    metadata: data.popupImages[i].image[j].metadata || METADATA_POPUP,
                  })
                  let pathImage = pathLib.join(`images\\${data.popupImages[i].image[j].id}-${data.popupImages[i].image[j].filename}`)
                  if (this.$imagesExtract && this.$imagesExtract[pathImage]) {
                    this.$imagesSrc[data.popupImages[i].image[j].id] = this.$imagesExtract[pathImage]
                  } else {
                    this.$imagesSrc[data.popupImages[i].image[j].id] = ''
                  }
                }
              } else {
                let pathImage = pathLib.join(`images\\${data.popupImages[i].id}-${data.popupImages[i].fileName}`)
                if (this.$imagesExtract && this.$imagesExtract[pathImage]) {
                  this.$imagesSrc[data.popupImages[i].id] = this.$imagesExtract[pathImage]
                } else {
                  this.$imagesSrc[data.popupImages[i].id] = ''
                }
              }
            }
            if (popupImages.length === 0) popupImages = data.popupImages
          }
          if (data.popupImagesProcessRate && Array.isArray(data.popupImagesProcessRate) && data.popupImagesProcessRate.length) {
            for (let i = 0; i < data.popupImagesProcessRate.length; i++) {
              let pathImage = pathLib.join(`images\\${data.popupImagesProcessRate[i].id}-${data.popupImagesProcessRate[i].fileName}`)
              if (this.$imagesExtract && this.$imagesExtract[pathImage]) {
                this.$imagesSrc[data.popupImagesProcessRate[i].id] = this.$imagesExtract[pathImage]
              } else {
                this.$imagesSrc[data.popupImagesProcessRate[i].id] = ''
              }
            }
          }

          let clicking = null
          if (data.clicking) {
            clicking = data.clicking
          }
          let dataFilter = [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: [] }]
          if (data.dataFilter && data.dataFilter.length !== 0) {
            if (data.dataFilter[0] && data.dataFilter[0].fixed) dataFilter = data.dataFilter
            else dataFilter = [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: data.dataFilter }]
          }
          let { timeseries, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning } = _.cloneDeep(DEFAULT_TABS)
          timeseries = _.merge(timeseries, data.timeseries)
          scatter = _.merge(scatter, data.scatter)
          scatter_d3 = _.merge(scatter_d3, data.scatter_d3)
          scatter_three = _.merge(scatter_three, data.scatter_three)
          boxplot = _.merge(boxplot, data.boxplot)
          histogram = _.merge(histogram, data.histogram)
          decomposition = _.merge(decomposition, data.decomposition)
          seasonal_analytics = _.merge(seasonal_analytics, data.seasonal_analytics)
          correlation = _.merge(correlation, data.correlation)
          machine_learning = _.merge(machine_learning, data.machine_learning)

          //VERSION 1.1.44: add tabs lines
          let { lines } = this.tabsMigrateFrom11411To1144(data)
          let scatterTabs = { scatter, scatter_d3, scatter_three }
          for (const tab in scatterTabs) {
            let tabData = scatterTabs[tab]
            for (let i = 0; i < tabData.datasourceSelected.length; i++) {
              for (const key in tabData.datasourceSelected[i]) {
                let oldValue = tabData.datasourceSelected[i][key]
                for (const data in datasourceMap) {
                  if (typeof tabData.datasourceSelected[i][key] === 'string') {
                    tabData.datasourceSelected[i][key] = tabData.datasourceSelected[i][key].replace(data, datasourceMap[data])
                  }
                }
              }
            }
          }

          let otherTabs = { timeseries, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning }
          for (const tab in otherTabs) {
            let tabData = otherTabs[tab]
            for (let i = 0; i < tabData.datasourceSelected.length; i++) {
              let oldValue = tabData.datasourceSelected[i].datasource
              tabData.datasourceSelected[i].datasource = datasourceMap[oldValue]
            }
            // add split layout for tab timeseries - v1.1.52.3
            if (tab === 'timeseries') {
              if (tabData.arrayDatasourceSelect && tabData.arrayDatasourceSelect.length !== 0) {
                for (let j = 0; j < tabData.arrayDatasourceSelect.length; j++) {
                  let datasourceSelected = tabData.arrayDatasourceSelect[j].datasourceSelected
                  for (let i = 0; i < datasourceSelected.length; i++) {
                    let oldValue = datasourceSelected[i].datasource
                    if (datasourceMap[oldValue]) datasourceSelected[i].datasource = datasourceMap[oldValue]
                  }

                  //Replace datasource Name => datasource ID for netcdf datasource
                  const netcdfTimeseriesData = tabData.arrayDatasourceSelect[j].netcdfMetadata
                  for (const name in netcdfTimeseriesData) {
                    netcdfTimeseriesData[datasourceMap[name]] = netcdfTimeseriesData[name]
                    delete netcdfTimeseriesData[name]
                  }
                }
              }
            }
          }

          // add tab line - v1.1.44
          let linesTabs = { lines }
          for (const tab in linesTabs) {
            let tabData = linesTabs[tab]
            for (let i = 0; i < tabData.datasourceSelected.length; i++) {
              for (const key in tabData.datasourceSelected[i]) {
                for (const data in datasourceMap) {
                  if (data.length > 0) {
                    tabData.datasourceSelected[i][key] = tabData.datasourceSelected[i][key].replace(data, datasourceMap[data])
                  }
                }
              }
            }
          }

          let theme = 'dark'
          if (data.theme) theme = data.theme

          let locale = 'en'
          if (data.locale) locale = data.locale

          config.appConfig = data && data.appConfig ? data.appConfig : null

          config.liveData = data.liveData

          let groupsLayerTemp = _.clone(data.groupsLayer)
          let groupLayer = []
          try {
            if (groupsLayerTemp && groupsLayerTemp.length) {
              for (let i = 0; i < groupsLayerTemp.length; i++) {
                if (groupsLayerTemp[i].isGroup) {
                  let groups = _.merge({}, DEFAULT_GROUP, groupsLayerTemp[i])
                  let groupData = []
                  if (groupsLayerTemp[i].groupData.length) {
                    for (let j = 0; j < groupsLayerTemp[i].groupData.length; j++) {
                      let layerTemp = _.merge({}, DEFAULT_LAYER, groupsLayerTemp[i].groupData[j])
                      try {
                        if (layers && layers.length) {
                          let idxLayer
                          for (let v = 0; v < layers.length; v++) {
                            if (layers[v].id == groupsLayerTemp[i].groupData[j].id) {
                              idxLayer = v
                              break
                            }
                          }
                          if (idxLayer >= 0 && layers[idxLayer]) {
                            layerTemp = _.merge(layerTemp, layers[idxLayer])
                          }
                        }
                      } catch {}
                      groupData.push(layerTemp)
                    }
                  }
                  groups.groupData = groupData
                  groupLayer.push(groups)
                } else {
                  let layerTemp = _.merge({}, DEFAULT_LAYER, groupsLayerTemp[i])
                  try {
                    if (layers && layers.length) {
                      let idxLayer
                      for (let v = 0; v < layers.length; v++) {
                        if (layers[v].id == groupsLayerTemp[i].id) {
                          idxLayer = v
                          break
                        }
                      }
                      if (idxLayer >= 0 && layers[idxLayer]) {
                        layerTemp = _.merge(layerTemp, layers[idxLayer])
                      }
                    }
                  } catch {}
                  groupLayer.push(layerTemp)
                }
              }
            }
          } catch {}

          config.layers = layers
          config.groupsLayer = groupLayer
          config.date = date
          config.layerSelected = layerSelected
          config.groupItemsList = groupItemsList
          config.selectedGroupItems = selectedGroupItems
          config.numberOfMap = numberOfMap
          config.scenes = scenes
          config.mapBounds = mapBounds
          config.mapTiles = mapTiles
          config.popupImages = popupImages
          config.popupImagesProcessRate = data.popupImagesProcessRate && data.popupImagesProcessRate.length ? data.popupImagesProcessRate : []
          config.clicking = clicking
          config.timeseries = timeseries
          config.scatter = scatter
          config.scatter_d3 = scatter_d3
          config.scatter_three = scatter_three
          config.boxplot = boxplot
          config.histogram = histogram
          config.decomposition = decomposition
          config.seasonal_analytics = seasonal_analytics
          config.correlation = correlation
          config.machine_learning = machine_learning
          config.currentRoute = currentRoute
          config.theme = theme
          config.locale = locale
          config.dataFilter = dataFilter

          config.lines = lines

          // config = this.groupsLayerMigrateToV11411(config, data, datasourceMap);
          resolve(config)
        }

        fileReader.readAsText(file)
      })
    },

    /** Convert from config object to configuration.json file's content  */
    writeConfig({ appConfig, layers, date, numberOfMap, scenes, mapBounds, mapTiles, popupImages, popupImagesProcessRate, clicking, timeseries, lines, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning, layerSelected, groupItemsList, selectedGroupItems, currentRoute, theme, locale, groupsLayer, dataFilter, liveData }, datasourceMap) {
      return new Promise((resolve, reject) => {
        let config = {}
        let ls = _.cloneDeep(layers)
        for (let i = 0; i < ls.length; i++) {
          if (ls[i].dataPoint) ls[i].dataPoint = datasourceMap[ls[i].dataPoint]
          if (ls[i].dataImage) ls[i].dataImage = datasourceMap[ls[i].dataImage]
          if (ls[i].dataTyphoon) ls[i].dataTyphoon = datasourceMap[ls[i].dataTyphoon]
          if (ls[i].dataMulti) ls[i].dataMulti = datasourceMap[ls[i].dataMulti]
          if (ls[i].dataNetcdf) ls[i].dataNetcdf = datasourceMap[ls[i].dataNetcdf]
          if (ls[i].dataTimeseries) ls[i].dataTimeseries = datasourceMap[ls[i].dataTimeseries]
          if (ls[i].dataTimeseriesShape) ls[i].dataTimeseriesShape = datasourceMap[ls[i].dataTimeseriesShape]
        }
        let gr = _.cloneDeep(groupsLayer)
        try {
          if (gr && gr.length) {
            for (let i = 0; i < gr.length; i++) {
              if (gr[i].isGroup) {
                if (gr[i].groupData.length) {
                  for (let j = 0; j < gr[i].groupData.length; j++) {
                    if (ls && ls.length) {
                      for (let k = 0; k < ls.length; k++) {
                        if (ls[k].id == gr[i].groupData[j].id) {
                          gr[i].groupData[j] = _.merge(gr[i].groupData[j], ls[k])
                          break
                        }
                      }
                    } else {
                      if (gr[i].groupData[j].dataPoint) gr[i].groupData[j].dataPoint = datasourceMap[gr[i].groupData[j].dataPoint]
                      if (gr[i].groupData[j].dataImage) gr[i].groupData[j].dataImage = datasourceMap[gr[i].groupData[j].dataImage]
                      if (gr[i].groupData[j].dataTyphoon) gr[i].groupData[j].dataTyphoon = datasourceMap[gr[i].groupData[j].dataTyphoon]
                      if (gr[i].groupData[j].dataMulti) gr[i].groupData[j].dataMulti = datasourceMap[gr[i].groupData[j].dataMulti]
                      if (gr[i].groupData[j].dataNetcdf) gr[i].groupData[j].dataNetcdf = datasourceMap[gr[i].groupData[j].dataNetcdf]
                      if (gr[i].groupData[j].dataTimeseries) gr[i].groupData[j].dataTimeseries = datasourceMap[gr[i].groupData[j].dataTimeseries]
                      if (gr[i].groupData[j].dataTimeseriesShape) gr[i].groupData[j].dataTimeseriesShape = datasourceMap[gr[i].groupData[j].dataTimeseriesShape]
                    }
                  }
                }
              } else {
                if (ls && ls.length) {
                  for (let k = 0; k < ls.length; k++) {
                    if (ls[k].id == gr[i].id) {
                      gr[i].groupData[j] = _.merge(gr[i], ls[k])
                      break
                    }
                  }
                } else {
                  if (gr[i].dataPoint) gr[i].dataPoint = datasourceMap[gr[i].dataPoint]
                  if (gr[i].dataImage) gr[i].dataImage = datasourceMap[gr[i].dataImage]
                  if (gr[i].dataTyphoon) gr[i].dataTyphoon = datasourceMap[gr[i].dataTyphoon]
                  if (gr[i].dataMulti) gr[i].dataMulti = datasourceMap[gr[i].dataMulti]
                  if (gr[i].dataNetcdf) gr[i].dataNetcdf = datasourceMap[gr[i].dataNetcdf]
                  if (gr[i].dataTimeseries) gr[i].dataTimeseries = datasourceMap[gr[i].dataTimeseries]
                  if (gr[i].dataTimeseriesShape) gr[i].dataTimeseriesShape = datasourceMap[gr[i].dataTimeseriesShape]
                }
              }
            }
          }
        } catch {}

        config.liveData = liveData

        config.appConfig = appConfig
        config.layers = ls
        config.date = date
        config.numberOfMap = numberOfMap
        config.scenes = scenes
        config.mapBounds = mapBounds
        config.mapTiles = mapTiles
        config.popupImages = popupImages
        config.popupImagesProcessRate = popupImagesProcessRate
        config.clicking = clicking
        config.layerSelected = layerSelected
        config.groupItemsList = groupItemsList
        config.selectedGroupItems = selectedGroupItems
        config.currentRoute = currentRoute
        config.theme = theme
        config.locale = locale
        config.groupsLayer = gr
        config.dataFilter = dataFilter
        let scatterTabs = { scatter, scatter_d3, scatter_three }
        for (const tab in scatterTabs) {
          let tabData = _.cloneDeep(scatterTabs[tab])
          for (let i = 0; i < tabData.datasourceSelected.length; i++) {
            for (const key in tabData.datasourceSelected[i]) {
              let oldValue = tabData.datasourceSelected[i][key]
              for (const data in datasourceMap) {
                if (typeof tabData.datasourceSelected[i][key] === 'string') {
                  tabData.datasourceSelected[i][key] = tabData.datasourceSelected[i][key].replace(data, datasourceMap[data])
                }
              }
            }
          }
          config[tab] = tabData
        }
        let otherTabs = { timeseries, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning }
        for (const tab in otherTabs) {
          let tabData = _.cloneDeep(otherTabs[tab])
          try {
            if (tabData.arrayDatasourceSelect && tabData.arrayDatasourceSelect.length) {
              for (let i = 0; i < tabData.arrayDatasourceSelect.length; i++) {
                //Replace datasource ID -> datasource name
                const netcdfTimeseriesData = tabData.arrayDatasourceSelect[i].netcdfMetadata
                tabData.arrayDatasourceSelect[i].netcdfDataChart = null
                for (const ID in netcdfTimeseriesData) {
                  netcdfTimeseriesData[datasourceMap[ID]] = netcdfTimeseriesData[ID]
                  delete netcdfTimeseriesData[ID]
                }
              }
            }
          } catch {}
          for (let i = 0; i < tabData.datasourceSelected.length; i++) {
            let oldValue = tabData.datasourceSelected[i].datasource
            tabData.datasourceSelected[i].datasource = datasourceMap[oldValue]
          }
          config[tab] = tabData
        }

        //add lines v1.1.44
        let linesTabs = { lines }
        for (const tab in linesTabs) {
          let tabData = _.cloneDeep(linesTabs[tab])
          for (let i = 0; i < tabData.datasourceSelected.length; i++) {
            for (const key in tabData.datasourceSelected[i]) {
              for (const data in datasourceMap) {
                tabData.datasourceSelected[i][key] = tabData.datasourceSelected[i][key].replace(data, datasourceMap[data])
              }
            }
          }
          config[tab] = tabData
        }
        resolve(config)
      })
    },
    /** Format timeseries metadata from Python */
    formatDataTimeseries(timeseriesData, csvPath) {
      let tmpLocation = null // temp variable
      let tmpHeader = timeseriesData.header // temp variable
      let tmpItem = null // temp variable
      let tmpUnit = null // temp variable
      let tmpName = null // temp variable
      let tmpDate = null // temp variable
      let columns = {} // All `location - item` pairs: return when complete
      let locationsItems = [] // Array format of 'columns' with correct order
      let items = {} // Items of timeseries: return when complete
      let errors = [] // Errors of timeseries: return when complete
      let lastValidDate = null // The last date to be valid, used to ensure the date order
      let locations = {} // Locations of timeseries: return when complete
      let validColumns = [] // Index array of all valid column
      let dates = [] // Dates of chunk: return every chunk
      let columnCount = timeseriesData.header[0].length

      // Get Header info
      for (let i = 1; i < columnCount; i++) {
        tmpLocation = tmpHeader[0][i]
        tmpItem = tmpHeader[1][i]
        tmpUnit = tmpHeader[2][i]
        tmpName = `${tmpLocation}*${tmpItem}`
        if (!tmpLocation || !tmpItem) {
          errors.push(`Skipped column ID=${tmpLocation || 'empty'} ITEM=${tmpItem || 'empty'}`)
        } else {
          validColumns.push(i)
          locationsItems.push(`${tmpLocation}-${tmpItem}`)
          columns[tmpName] = { nullCount: 0 }

          if (!items[tmpItem])
            items[tmpItem] = {
              min: timeseriesData.minMaxValues[tmpItem].min,
              max: timeseriesData.minMaxValues[tmpItem].max,
              mean: timeseriesData.minMaxValues[tmpItem].mean,
              std: timeseriesData.minMaxValues[tmpItem].std,
              unit: tmpUnit,
            }
          if (!locations[tmpLocation]) locations[tmpLocation] = {}
        }
      }

      // Get Dates info
      for (const date of timeseriesData.dates) {
        tmpDate = dayjs(date[0])
        if (tmpDate.isValid()) {
          if (!lastValidDate || tmpDate.isAfter(lastValidDate)) {
            dates.push(tmpDate.format('YYYY-MM-DD HH:mm:ss'))
            lastValidDate = tmpDate.clone()
          } else {
            errors.push(`Skipped row DATE=${date[0]}`)
          }
        } else {
          errors.push(`Skipped row DATE=${date[0]}`)
        }
      }
      return { type: 'timeseries', path: csvPath, dates: dates, items, locations, columns, errors, locationsItems }
    },
    /** Create file object from datasource */
    async createObjectFile(datasource) {
      let ids = ['ID']
      let items = ['ITEM']
      let units = ['UNIT']
      let groups = ['GROUP']
      let data = datasource.csvData
      //CREATE HEADER
      for (const key in datasource.columns) {
        let [location, item] = key.split('*')
        ids.push(location)
        items.push(item)
        groups.push(datasource.columns[key].autoGroup)
        units.push(datasource.columns[key].unit)
      }
      // Convert string data to File (blob), avoid overflow memory
      let textEncoder = new TextEncoder('utf-8')
      let arrayBuffers = []
      let csvString = ''
      let encodedArray = null
      let file = null
      // Metadata
      csvString = ids.join(',').concat('\r\n')
      encodedArray = textEncoder.encode(csvString)
      arrayBuffers.push(encodedArray)

      csvString = items.join(',').concat('\r\n')
      encodedArray = textEncoder.encode(csvString)
      arrayBuffers.push(encodedArray)

      csvString = units.join(',').concat('\r\n')
      encodedArray = textEncoder.encode(csvString)
      arrayBuffers.push(encodedArray)
      if (datasource.header.length === 4) {
        csvString = groups.join(',').concat('\r\n')
        encodedArray = textEncoder.encode(csvString)
        arrayBuffers.push(encodedArray)
      }

      // Data
      for (let x = 0; x < data.length; x++) {
        csvString = data[x].join(',').concat('\r\n')
        encodedArray = textEncoder.encode(csvString)
        arrayBuffers.push(encodedArray)
      }
      file = new File(arrayBuffers, datasource.name, { type: 'text/csv' })
      return file
    },
    mergeLayerFileSaveWithDefaultLayer(layerToRestore, datasourceMap) {
      // Base layer from default layer
      let layer = _.merge({}, DEFAULT_LAYER)
      //Type layer
      layer.type = layerToRestore.type
      // Visible
      if (Array.isArray(layerToRestore.visible) && layerToRestore.visible.length === 2 && layerToRestore.visible.every((v) => typeof v === 'boolean')) layer.visible = layerToRestore.visible
      if (layerToRestore.legend && layerToRestore.legend.position) layer.legend = layerToRestore.legend
      layer.tooltip = layerToRestore.tooltip
      if (layerToRestore.navalue) layer.navalue = layerToRestore.navalue
      if (layerToRestore.shape) layer.shape = layerToRestore.shape
      if (layerToRestore.typhoon) layer.typhoon = layerToRestore.typhoon

      // CONVERT OLD layer (web version) to NEW layer (desktop version)
      // Id
      if (layerToRestore.id) layer.id = layerToRestore.id
      // Name
      if (layerToRestore.name) layer.name = layerToRestore.name
      // Data
      if (layerToRestore.dataPoint) layer.dataPoint = datasourceMap[layerToRestore.dataPoint] // New
      if (layerToRestore.dataMulti) layer.dataMulti = datasourceMap[layerToRestore.dataMulti] // Diff: new layer combine 'polyline', 'polygon', 'shape', 'multi' into 'multi'
      if (layerToRestore.dataImage) layer.dataImage = datasourceMap[layerToRestore.dataImage]
      if (layerToRestore.dataTyphoon) layer.dataTyphoon = datasourceMap[layerToRestore.dataTyphoon] // New
      if (layerToRestore.dataNetcdf) layer.dataNetcdf = datasourceMap[layerToRestore.dataNetcdf]
      if (layerToRestore.dataTimeseries) layer.dataTimeseries = datasourceMap[layerToRestore.dataTimeseries]
      if (layerToRestore.dataTimeseriesShape) layer.dataTimeseriesShape = datasourceMap[layerToRestore.dataTimeseriesShape]
      if (layerToRestore.item) layer.item = layerToRestore.item
      if (layerToRestore.image) layer.image = layerToRestore.image
      if (layerToRestore.idMultiSelected) layer.idMultiSelected = layerToRestore.idMultiSelected
      if (layerToRestore.itemMultiSelected) layer.itemMultiSelected = layerToRestore.itemMultiSelected

      // Layer
      layer.fillOpacity = layerToRestore.fillOpacity
      layer.fillColor = {
        ...layer.fillColor,
        mode: layerToRestore.fillColor.mode, // Diff: move to outside
        color: layerToRestore.fillColor.color, // Diff: move to outside
        colors: layerToRestore.fillColor.colors, // Diff: move to outside
      }
      try {
        if (layerToRestore.fillColor) {
          layer.fillColor = _.merge(
            layer.fillColor,
            {
              mode: layerToRestore.fillColor.mode, // Diff: move to outside
              color: layerToRestore.fillColor.color, // Diff: move to outside
              colors: layerToRestore.fillColor.colors, // Diff: move to outside
            },
            layerToRestore.fillColor
          )
        }
      } catch {}
      layer.color = layerToRestore.color
      layer.weight = layerToRestore.weight
      layer.radius = layerToRestore.radius
      layer.popup = { ...layer.popup, ...layerToRestore.popup }
      layer.markerCluster = { ...layer.markerCluster, ...layerToRestore.markerCluster }
      //migrate old file save
      layer = this.layerMigrateTo11411(layer, layerToRestore, datasourceMap)
      //migrate version 11411 to v1144
      layer = this.layerMigrateFrom11411To1144(layer, layerToRestore, datasourceMap)
      try {
        layer.processRate = layerToRestore && layerToRestore.processRate ? true : false
        if (!layer.processRateData) {
          layer.processRateData = {}
        }
        if (layerToRestore && layerToRestore.processRateData) {
          layer.processRateData = _.merge(layer.processRateData, layerToRestore.processRateData)
        }
      } catch {}
      return layer
    },
    layerMigrateTo11411(layer, layerToRestore, datasourceMap) {
      if (layerToRestore.type === 'route') {
        // Diff: new layer change 'route' to 'typhoon'
        layer.type = 'typhoon'
      } else if (layerToRestore.type === 'polyline' || layerToRestore.type === 'polygon' || layerToRestore.type === 'shape') {
        layer.type = 'multi'
      }
      // Data
      if (layerToRestore.dataLocation) layer.dataPoint = datasourceMap[layerToRestore.dataLocation]
      // Old
      if (layerToRestore.dataPolyline) layer.dataMulti = datasourceMap[layerToRestore.dataPolyline]
      // Diff
      if (layerToRestore.dataPolygon) layer.dataMulti = datasourceMap[layerToRestore.dataPolygon]
      // Diff
      if (layerToRestore.dataShape) layer.dataMulti = datasourceMap[layerToRestore.dataShape]
      // Diff
      if (layerToRestore.dataRoute) layer.dataTyphoon = datasourceMap[layerToRestore.dataRoute]
      // Old
      // Layer
      if (layerToRestore.label) {
        if (layerToRestore.label.permanent === undefined) {
          // Diff: no 'permanent' in old version
          layerToRestore.label.visible = true
          layerToRestore.label.permanent = false
        }
        layer.tooltip = layerToRestore.label // Diff: 'label' into 'tooltip'
      }
      if (layerToRestore.tooltip) {
        if (layerToRestore.tooltip.permanent === undefined) {
          // Diff: no 'permanent' in old version
          layerToRestore.tooltip.visible = true
          layerToRestore.tooltip.permanent = false
        }
        layer.tooltip = layerToRestore.tooltip
      }

      if (layerToRestore.shapeType && layerToRestore.numSpikes) {
        // Diff: move 'shapeType', 'numSpikes' into 'shape'
        layer.shape.shapeType = layerToRestore.shapeType
        layer.shape.numSpikes = layerToRestore.numSpikes
      }

      if (layerToRestore.route && layerToRestore.route.icon && layerToRestore.route.line && layerToRestore.route.inner && layerToRestore.route.outer && layerToRestore.route.trait) {
        layer.typhoon.icon = layerToRestore.route.icon
        layer.typhoon.line = layerToRestore.route.line
        layer.typhoon.storm = layerToRestore.route.inner // Diff
        layer.typhoon.gale = layerToRestore.route.outer // Diff
        layer.typhoon.trail = layerToRestore.route.trait // Diff
      }
      return layer
    },
    tabsMigrateFrom11411To1144(data) {
      let { lines } = _.cloneDeep(DEFAULT_TABS)
      lines = { ...lines, ...data.lines }
      return { lines }
    },
    groupsLayerMigrateToV11411(config, dataOrigin, datasourceMap = {}) {
      if (!dataOrigin.groupsLayer) return config
      let groups = []
      dataOrigin.groupsLayer.forEach((data) => {
        if (data && data.isGroup) {
          let group = _.merge({}, DEFAULT_GROUP, data)
          if (data.groupData.length) {
            let groupMerge = []
            for (let i = 0; i < data.groupData.length; i++) {
              let layer = _.merge({}, DEFAULT_LAYER, data.groupData[i])
              if (layer.type != 'netcdf') groupMerge.push(layer)
            }
            group.groupData = groupMerge
          }
          groups.push(group)
        } else {
          let layer = _.merge({}, DEFAULT_LAYER, data)
          if (layer.type != 'netcdf') groups.push(layer)
        }
      })
      config.groupsLayer = groups
      return config
    },
    layerMigrateFrom11411To1144(layer, layerToRestore) {
      //add scale optin for layer
      if (layerToRestore.scale) layer.scale = layerToRestore.scale
      return layer
    },
    async checkImageCorruptedWithBase64(dataBase64, filePath, dataBuf) {
      return await new Promise(async (resolve, reject) => {
        let checkCorruptWithJimp = true
        if (filePath) {
          try {
            await Jimp.read(filePath)
            checkCorruptWithJimp = true
          } catch {
            checkCorruptWithJimp = false
          }
        } else {
          if (dataBuf) {
            try {
              await Jimp.read(dataBuf)
              checkCorruptWithJimp = true
            } catch (error) {
              checkCorruptWithJimp = false
            }
          }
        }
        if (!checkCorruptWithJimp) {
          resolve(false)
          return
        } else {
          let img = new Image()
          img.src = dataBase64
          img.onload = () => {
            // try {
            //   const canvas = document.createElement('canvas');
            //   const ctx = canvas.getContext('2d');
            //   canvas.width = img.width;
            //   canvas.height = img.height;
            //   ctx.drawImage(img, 0, 0);
            //   const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
            //   let transparentPixels = 0;
            //   const totalPixels = img.width * img.height;
            //   for (let i = 3; i < imageData.length; i += 4) {
            //     if (imageData[i] === 0) {
            //       transparentPixels++;
            //     }
            //     let transparentRatio = transparentPixels / totalPixels;
            //     if (transparentRatio > 0.99) {
            //       resolve(false);
            //       return;
            //     }
            //   }
            //   resolve(true);
            // } catch {
            //   resolve(false);
            // }
            resolve(true)
          }
          img.onerror = () => {
            resolve(false)
          }
        }
      })
    },
    transformCoordinatesMixins(coordinates, from, to) {
      // Nếu đây là mảng chứa các tọa độ (latitude, longitude)
      if (coordinates && coordinates.length == 2 && (typeof coordinates[0] === 'number' || typeof coordinates[0] === 'string')) {
        return proj4(from, to, coordinates)
      } else if (Array.isArray(coordinates[0]) && (typeof coordinates[0][0] === 'number' || typeof coordinates[0][0] === 'string')) {
        return coordinates.map((coordinate) => proj4(from, to, coordinate))
      }
      // Nếu đây là một mảng con
      else if (Array.isArray(coordinates[0])) {
        return coordinates.map((subArray) => this.transformCoordinatesMixins(subArray, from, to))
      }
      // Nếu đầu vào không phải là mảng chứa các tọa độ hoặc mảng con
      else {
        console.log('Định dạng mảng không hợp lệ')
        return []
      }
    },
    isNumericString(str) {
      try {
        const num = _.toNumber(str)
        return !_.isNaN(num) && _.isFinite(num)
      } catch {}
      return false
    },
  },
}
