<template>
  <div class="layer-container opening">
    <div class="layer-container_configuration" :class="`${type}`">
      <div class="layer-container_configuration_left">
        <div class="layer-container_addvanced_content_items d-flex align-items-center">
          <div class="layer-collapse-item open">
            <div class="select-tilte d-flex align-items-center">
              <feather-icon icon="EditIcon" style="margin-left: 1px; margin-right: 11px; min-width: 14px; transform: rotate(-90deg)" />
              <p class="m-0">{{ $t('name') }}</p>
            </div>
          </div>
          <div class="ip-rename-layer"><b-form-input placeholder="Layer Name" v-model="name" size="sm" class="layer-name select-layer" /></div>
        </div>
        <AppCollapse class="layer-collapse" :active="true">
          <AppCollapseItem :disabled="true" :active="true" :isVisible="true" @visible="selectIconic" :title="$t('datasource')" class="layer-collapse-item">
            <template #header>
              <div class="d-flex align-items-center select-tilte">
                <feather-icon title="Data source management" icon="DatabaseIcon" style="margin-left: 1px; margin-right: 11px; min-width: 14px" />
                <p class="mb-0" style="line-height: 22px">{{ $t('datasource') }}</p>
                <IconicSelect class="ml-1" v-show="type && type == 'multi'" ref="iconicSelect1" :options="types" v-model="type" :readonly="true" />
              </div>
            </template>
            <div class="form-layout">
              <b-form-group>
                <b-input-group size="sm">
                  <b-input-group-prepend is-text :title="$t('location_data_source')">
                    <location-icon style="width: 18px; height: 18px" />
                  </b-input-group-prepend>
                  <DataSelect v-model="dataSelected" />
                  <!-- <DataSelect v-model="dataSelected" @commit="updateLayer" /> -->
                </b-input-group>
              </b-form-group>
              <b-form-group class="add-id-item-multi-content" v-if="type && type == 'multi'">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text :title="$t('key')">
                    <feather-icon icon="KeyIcon" size="18" />
                    <!-- <div class="form-layout add-id-item-multi-content-icon"></div> -->
                  </b-input-group-prepend>
                  <vue-select class="v-select-attr-shape select-size-sm v-select-layer-setting" :placeholder="$t('item')" :options="idsItemsMulti" :reduce="(data) => data.value" v-model="idMultiSelected" />
                </b-input-group>
              </b-form-group>
              <b-form-group v-else>
                <IconicSelect ref="iconicSelect1" :options="types" v-model="type" :readonly="true" />
              </b-form-group>
            </div>
            <div class="form-layout" v-if="['point', 'multi'].includes(type)">
              <b-form-group>
                <b-input-group size="sm">
                  <b-input-group-prepend is-text :title="$t('timeseries_data_source')">
                    <datasource-1-icon style="width: 18px; height: 18px" />
                  </b-input-group-prepend>
                  <TimeseriesSelect v-if="dataSelected && type == 'multi'" v-model="dataTimeseriesShape" :dataSelected="dataSelected" />
                  <!-- <TimeseriesSelect v-model="dataTimeseries" @commit="updateLayer" /> -->
                  <TimeseriesSelect v-else v-model="dataTimeseries" />
                </b-input-group>
              </b-form-group>
              <b-form-group class="add-id-item-multi-content">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text :title="$t('measurement_unit')">
                    <item-icon style="width: 18px; height: 18px" />
                  </b-input-group-prepend>
                  <!-- <ItemSelect v-model="item" :timeseries="dataTimeseries" :typeLayer="type" /> -->
                  <vue-select class="v-select-attr-shape select-size-sm v-select-layer-setting" :placeholder="$t('item')" v-if="isShowItemShape" :options="idsItemsMulti" :reduce="(data) => data.value" v-model="itemMultiSelected" />
                  <ItemSelect v-else v-model="item" @itemFirst="setItemFirst" :timeseries="dataTimeseries" :timeseriesShape="dataTimeseriesShape" :typeLayer="type" />
                </b-input-group>
              </b-form-group>
            </div>
            <!-- <div class="add-id-item-multi-header" v-if="['multi'].includes(type)">{{ $t('choose_from_attribute') }}</div>
            <div class="form-layout add-id-item-multi-content" v-if="['multi'].includes(type)">
              <b-form-group>
                <b-input-group size="sm">
                  <b-input-group-prepend is-text :title="$t('key')">
                    <div class="form-layout add-id-item-multi-content-icon"></div>
                    <datasource-1-icon style="width: 18px; height: 18px" />
                  </b-input-group-prepend>
                  <vue-select :options="idsItemsMulti" :reduce="(data) => data.value" v-model="idMultiSelected" />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group size="sm">
                  <b-input-group-prepend is-text :title="$t('item')">
                    <div class="form-layout add-id-item-multi-content-icon"></div>
                    <item-icon style="width: 18px; height: 18px" />
                  </b-input-group-prepend>
                  <vue-select :options="idsItemsMulti" :reduce="(data) => data.value" v-model="itemMultiSelected" />
                </b-input-group>
              </b-form-group>
            </div> -->
          </AppCollapseItem>
        </AppCollapse>
        <div class="layer-container_addvanced_content_items" :style="`margin-top:5px`" v-if="['point', 'multi'].includes(type)">
          <div class="layer-collapse-item open">
            <div class="select-tilte d-flex align-items-center">
              <feather-icon icon="CircleIcon" style="margin-left: 1px; margin-right: 11px; min-width: 14px" />
              <p class="m-0">{{ $t('figure') }}</p>
            </div>
          </div>
          <div class="config_figure-shape-radius d-flex align-items-center">
            <div class="config_figure-shape-radius_left">
              <div class="form-layout">
                <b-form-group>
                  <label class="group-title">
                    <div class="d-flex" style="color: #397bff">
                      {{ $t('shape').toUpperCase() }}
                    </div>
                  </label>
                  <div class="d-flex align-items-center cf-figure-shape">
                    <IconicSelect class="cf-figure-shape1" ref="iconicSelect2" :options="shapeTypes" v-model="shapeType" />
                    <b-input-group class="cf-figure-shape2" size="sm">
                      <b-input-group-prepend is-text :title="$t('number_of_points')">
                        <spike-icon style="width: 18px; height: 18px" />
                      </b-input-group-prepend>
                      <b-form-input :disabled="shapeType !== 'Spike'" v-model="numSpikes" type="number" min="3" placeholder="Spikes" size="sm" />
                    </b-input-group>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="config_figure-shape-radius_right">
              <div class="form-layout">
                <b-form-group>
                  <label class="group-title">
                    <div class="d-flex" style="color: #397bff">
                      {{ $t('radius').toUpperCase() }}
                    </div>
                  </label>
                  <div class="cf-right-figure-shape" style="margin-top: 2px">
                    <radius-icon style="width: 18px; height: 18px" />
                    <div class="d-flex align-items-center shape-radius-range">
                      <span class="span-min">{{ rangeRadius[0] }}</span>
                      <vue-slider class="multi-range" v-model="rangeRadius" :min="0" :max="500"></vue-slider>
                      <span class="span-max">{{ rangeRadius[1] }}</span>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="layer-container_configuration_right">
        <div class="layer-container_addvanced_content_items" v-if="['point', 'multi'].includes(type)">
          <div class="layer-collapse-item open d-flex align-items-center">
            <div class="select-tilte d-flex align-items-center">
              <p class="mb-0" style="line-height: 22px">{{ $t('figure_color') }}</p>
            </div>
            <b-form-group class="b-form-group-figure-color mb-0">
              <b-input-group size="sm">
                <b-input-group-prepend is-text :title="$t('background_opacity')">
                  <opacity-icon style="width: 14px; height: 14px" />
                </b-input-group-prepend>
                <b-form-input :title="$t('title_figure_color')" v-model="fillOpacity" type="number" step="0.1" min="0" max="1" placeholder="Opacity" size="sm" />
              </b-input-group>
            </b-form-group>
          </div>
          <div class="config_figure-color">
            <PalettePickerV3 v-model="fillColors" :mode="fillMode" :fillColor="fillColor" :fillColors="fillColors" placeholder="Background" size="sm" @activeColorSolid="activeConfigFigureColor" :flagColorPalette="flagColorPalette" />
          </div>
        </div>
        <div class="layer-container_addvanced_content_items" v-else>
          <AppCollapse class="layer-collapse" :class="`${type}`" :active="true">
            <AppCollapseItem :disabled="true" :active="true" :isVisible="true" @visible="selectIconic" :title="$t('figure')" class="layer-collapse-item">
              <template #header>
                <div class="d-flex align-items-center select-tilte">
                  <feather-icon icon="CircleIcon" style="margin-left: 1px; margin-right: 11px; min-width: 14px" />
                  <p class="mb-0" style="line-height: 22px">{{ $t('figure') }}</p>
                </div>
              </template>

              <div v-if="['image'].includes(type)">
                <div class="form-layout">
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-input-group-prepend is-text>
                        <opacity-icon style="width: 18px; height: 18px" />
                      </b-input-group-prepend>
                      <b-form-input v-model="imageOpacity" type="number" step="0.1" min="0" max="1" placeholder="Opacity" size="sm" />
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>

              <div v-if="['typhoon'].includes(type)">
                <div class="form-layout">
                  <b-form-group>
                    <label class="group-title">{{ $t('radius').toUpperCase() }}</label>
                    <b-input-group size="sm">
                      <b-input-group-prepend is-text>
                        <radius-icon style="width: 18px; height: 18px" />
                      </b-input-group-prepend>
                      <div class="d-flex align-items-center shape-radius-range w-100">
                        <span class="span-min">{{ iconRadius }}</span>
                        <vue-slider class="multi-range" v-model="iconRadius" :min="5" :max="50"></vue-slider>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </AppCollapseItem>
          </AppCollapse>
        </div>
      </div>
    </div>
    <div class="layer-container_addvanced_content_items" v-if="['point', 'multi'].includes(type)">
      <div class="layer-collapse-item open d-flex align-items-center">
        <div class="select-tilte d-flex align-items-center">
          <feather-icon icon="DivideSquareIcon" style="margin-left: 1px; margin-right: 11px; min-width: 14px" />
          <p class="m-0">{{ $t('scale') }}</p>
        </div>
        <div class="form-layout scale-layer mb-0 ml-1">
          <b-form-group class="mb-0">
            <b-input-group class="background-color" size="sm">
              <RadioGroup :moveCount="`layeritem-scale-layer-${moveCountIconicSelect}`" :name="`layeritem-scale-layer${layer.id}`" class="select-scale-layer" :toggle="true" :hiddenLable="true" :label="`scaleLayer${layer.id}`" :options="scaleChoices" v-model="scaleValue" />
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="layer-container_configuration" :class="`${type}`">
      <div class="layer-container_addvanced_content_items addvanced_visibled">
        <div class="layer-collapse-item open" v-if="['point', 'multi', 'typhoon'].includes(type)">
          <div class="select-tilte d-flex align-items-center">
            <b-input-group>
              <b-form-checkbox v-model="popupVisible" class="custom-checkbox-icon" style="margin-right: 10px" switch @click.native.stop>
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right layer-name">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <p class="mb-0 select-tilte" style="line-height: 22px">{{ $t('popup') }}</p>
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
        <div class="layer-collapse-item open" v-if="['point', 'multi'].includes(type)">
          <div class="select-tilte d-flex align-items-center">
            <b-input-group>
              <b-form-checkbox v-model="tooltipVisible" class="custom-checkbox-icon" style="margin-right: 10px" switch @click.native.stop>
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right layer-name">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <p class="mb-0 select-tilte" style="line-height: 22px">{{ $t('label') }}</p>
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
        <div class="layer-collapse-item open" v-if="['point', 'multi'].includes(type)">
          <div class="select-tilte d-flex align-items-center">
            <b-input-group>
              <b-form-checkbox v-model="legendVisible" class="custom-checkbox-icon" switch style="margin-right: 10px" @click.native.stop>
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right layer-name">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <p class="mb-0 select-tilte" style="line-height: 22px">{{ $t('legend') }}</p>
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
        <div class="layer-collapse-item open" v-if="['point', 'multi'].includes(type)">
          <div class="select-tilte d-flex align-items-center">
            <b-input-group>
              <b-form-checkbox v-model="naVisible" class="custom-checkbox-icon" switch style="margin-right: 10px" @click.native.stop>
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right layer-name">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <p class="mb-0 select-tilte" style="line-height: 22px">{{ $t('na_value') }}</p>
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
        <div class="layer-collapse-item open" v-if="['point'].includes(type)">
          <div class="select-tilte d-flex align-items-center">
            <b-input-group>
              <b-form-checkbox v-model="clusterUsing" class="custom-checkbox-icon" switch style="margin-right: 10px" @click.native.stop>
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right layer-name">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <p class="mb-0 select-tilte" style="line-height: 22px">{{ $t('point_cluster') }}</p>
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
        <div class="layer-collapse-item open" v-if="['multi'].includes(type)">
          <div class="select-tilte d-flex align-items-center">
            <b-input-group>
              <b-form-checkbox v-model="processRate" class="custom-checkbox-icon" style="margin-right: 10px" switch @click.native.stop>
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right layer-name">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <p class="mb-0 select-tilte" style="line-height: 22px">{{ $t('process_rate') }}</p>
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div class="layer-container_addvanced" :class="`${type}`" v-if="['point', 'multi', 'typhoon'].includes(type)">
      <AppCollapse class="layer-collapse" :class="`${type}`">
        <AppCollapseItem class="layer-collapse-item collpase-addvanced-configurations" :title="$t('advanced_configurations')" @open-collapse="openCollapseAddvanced">
          <template #header>
            <div class="d-flex align-items-center select-tilte">
              <feather-icon title="Data source management" icon="SlidersIcon" style="margin-left: 1px; margin-right: 11px; min-width: 14px" />
              <p class="mb-0" style="line-height: 22px">{{ $t('advanced_configurations') }}</p>
            </div>
          </template>
          <div class="layer-container_addvanced_content b-tabs-layer-config">
            <b-tabs content-class="" v-model="bTabsActive">
              <b-tab :title="$t('Shape & Color')" active>
                <template #title>
                  <feather-icon icon="CircleIcon" size="16" />
                  <span>{{ $t('Shape & Color') }}</span>
                </template>
                <div class="layer-tab-item-content layer-container_addvanced_content">
                  <div class="layer-container_addvanced_content_left">
                    <div v-if="['point', 'multi'].includes(type)">
                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('shape').toUpperCase() }}</label>
                          <IconicSelect :moveCount="`iconic-select-shape-type-${moveCountIconicSelect}`" ref="iconicSelect2" :options="shapeTypes" v-model="shapeType" />
                        </b-form-group>
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text :title="$t('number_of_points')">
                              <spike-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input :disabled="shapeType !== 'Spike'" v-model="numSpikes" type="number" min="3" placeholder="Spikes" size="sm" />
                          </b-input-group>
                        </b-form-group>
                      </div>

                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('radius').toUpperCase() }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text :title="$t('radius_min_size')">
                              <radius-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="rangeRadius[0]" id="value-min-radius" type="number" min="0" :max="rangeRadius[1]" lazy @change="setMinvalueRadius" placeholder="Min" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text :title="$t('radius_max_size')">
                              <radius-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="rangeRadius[1]" type="number" id="value-max-radius" :min="rangeRadius[0]" lazy max="500" @change="setMaxvalueRadius" placeholder="Max" size="sm" />
                          </b-input-group>
                        </b-form-group>
                      </div>

                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('outline').toUpperCase() }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text :title="$t('outline_width')">
                              <weight-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="weight" type="number" min="0" placeholder="Width" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text :title="$t('outline_color')">
                              <stroke-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <color-picker v-model="color" placeholder="Color" size="sm" :right="'-4px'" />
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </div>
                    <div v-if="['typhoon'].includes(type)">
                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('inner_area').toUpperCase() }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <weight-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="stormWeight" type="number" min="0" placeholder="Width" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                            </b-input-group-prepend>
                            <ColorPicker v-model="stormColor" placeholder="Color" size="sm" :right="'-4px'" />
                          </b-input-group>
                        </b-form-group>
                      </div>

                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('outter_area').toUpperCase() }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <weight-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="galeWeight" type="number" min="0" placeholder="Width" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                            </b-input-group-prepend>
                            <ColorPicker v-model="galeColor" placeholder="Color" size="sm" :right="'-4px'" />
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="layer-container_addvanced_content_right">
                    <div v-if="['point', 'multi'].includes(type)">
                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('background').toUpperCase() }}</label>
                          <b-input-group class="background-color" size="sm">
                            <b-input-group-prepend is-text :title="$t('background_color_type')">
                              <background-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <IconicSelect :moveCount="`iconic-select-fill-mode-${moveCountIconicSelect}`" ref="iconicSelect3" :options="fillModes" v-model="fillMode" />
                          </b-input-group>
                        </b-form-group>
                      </div>
                      <div class="form-layout" style="align-items: center">
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text :title="$t('background_opacity')">
                              <opacity-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input :title="$t('title_figure_color')" v-model="fillOpacity" type="number" step="0.1" min="0" max="1" placeholder="Opacity" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group v-if="fillMode === 'solid'">
                          <b-input-group class="background-color" size="sm">
                            <b-input-group-prepend is-text :title="$t('background_color')">
                              <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                            </b-input-group-prepend>
                            <ColorPicker v-model="fillColor" placeholder="Background" size="sm" :right="'-4px'" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group v-if="fillMode === 'linear'">
                          <b-input-group class="background-color" size="sm">
                            <b-input-group-prepend is-text :title="$t('background_color')">
                              <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                            </b-input-group-prepend>
                            <PalettePicker v-model="fillColors" placeholder="Background" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group v-if="fillMode === 'threshold'">
                          <div class="threshold-setting">
                            <feather-icon icon="SettingsIcon" size="18" title="Setting threshold value of Location" @click="openModalThresholdValue" />
                          </div>
                        </b-form-group>
                      </div>
                      <div class="form-layout" v-if="fillMode === 'threshold'">
                        <b-form-group>
                          <ThresholdLevel @updateDefaultColor="(color) => (fillColor = color)" :layer="layer" v-model="fillLevels" :thresholds="fillThresholds" @updateThresholds="(v) => (fillThresholds = v)" />
                        </b-form-group>
                      </div>
                      <div class="form-layout" v-if="fillMode === 'threshold'">
                        <b-form-group>
                          <div class="d-flex align-items-center justify-content-between">
                            <ThresholdValue ref="refThresoldValue" v-model="fillThresholds" :idDatasourceValue="idDatasourceValue" :itemThreshold="itemThreshold" :layerOrigin="layer" :levels="fillLevels" :location="dataLocation" :individual="fillIndividual" />
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div v-if="['typhoon'].includes(type)">
                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('radius').toUpperCase() }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <radius-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input id="value-radius-typhoon" v-model="iconRadius" lazy @change="setRangeValueRadius" type="number" min="5" max="50" placeholder="Radius" size="sm" />
                          </b-input-group>
                        </b-form-group>
                      </div>

                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('line').toUpperCase() }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <weight-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="lineWeight" type="number" min="0" placeholder="Width" size="sm" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <stroke-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <ColorPicker v-model="lineColor" placeholder="Color" size="sm" :right="'-4px'" />
                          </b-input-group>
                        </b-form-group>
                      </div>

                      <div class="form-layout">
                        <b-form-group>
                          <label class="group-title">{{ $t('traits').toUpperCase() }}</label>
                          <b-form-checkbox v-model="trailKeep" switch>{{ $t('keep_traits') }}</b-form-checkbox>
                        </b-form-group>
                        <b-form-group v-if="trailKeep">
                          <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                              <opacity-icon style="width: 18px; height: 18px" />
                            </b-input-group-prepend>
                            <b-form-input v-model="trailOpacity" type="number" step="0.1" min="0" max="1" placeholder="Opacity" size="sm" />
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('popup')" v-if="['point', 'multi', 'typhoon'].includes(type)">
                <template #title>
                  <feather-icon icon="MessageSquareIcon" size="16" />
                  <span>{{ $t('popup') }}</span>
                </template>
                <div class="layer-tab-item-content layer-container_addvanced_content">
                  <div class="layer-container_addvanced_content_left">
                    <b-form-group>
                      <label class="group-title">{{ $t('type').toUpperCase() }}</label>
                      <IconicSelect :moveCount="`iconic-select-popup-type-${moveCountIconicSelect}`" ref="iconicSelect7" :options="popupTypes" v-model="popupType" />
                    </b-form-group>
                    <div class="form-layout" v-if="popupType === 'border'">
                      <b-form-group>
                        <label class="group-title">{{ $t('background').toUpperCase() }}</label>
                        <b-input-group class="background-color" size="sm">
                          <b-input-group-prepend is-text :title="$t('background_color')">
                            <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="popupBackgroundFrom" placeholder="Background From" size="sm" :left="'-42px'" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group class="background-color" size="sm">
                          <b-input-group-prepend is-text :title="$t('background_color')">
                            <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="popupBackgroundTo" placeholder="Background To" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="form-layout" v-if="popupType === 'borderless'">
                      <b-form-group>
                        <label class="group-title">{{ $t('popup_color').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text title="Line Color">
                            <stroke-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="popupLineColor" placeholder="Line Color" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('font').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('font_family')">
                            <family-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <vue-select v-model="popupFamily" :reduce="(data) => data.value" :options="popupFamilies" class="select-size-sm" :placeholder="$t('font_family')" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="layer-container_addvanced_content_right">
                    <b-form-group>
                      <label class="group-title">{{ $t('multi_show') }}</label>
                      <b-form-checkbox :style="`height:30px;`" v-model="popupMultiShow" class="custom-checkbox-icon" switch> </b-form-checkbox>
                    </b-form-group>
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('header').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('header_size')">
                            <size-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="popupHeaderSize" type="number" min="0" placeholder="Header Size" size="sm" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('header_color')">
                            <color-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <ColorPicker v-if="popupType === 'border'" v-model="popupHeaderColor" placeholder="Header Color" size="sm" :right="'-4px'" />
                          <ColorPicker v-if="popupType === 'borderless'" v-model="popupBorderlessHeader" placeholder="Header Color" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('content').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('content_size')">
                            <size-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="popupContentSize" type="number" min="0" placeholder="Content Size" size="sm" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('content_color')">
                            <color-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <ColorPicker v-if="popupType === 'border'" v-model="popupContentColor" placeholder="Content Color" size="sm" :right="'-4px'" />
                          <ColorPicker v-if="popupType === 'borderless'" v-model="popupBorderlessContentColor" placeholder="Content Color" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('label')" v-if="['point', 'multi'].includes(type)">
                <template #title>
                  <feather-icon icon="MessageCircleIcon" size="16" />
                  <span>{{ $t('label') }}</span>
                </template>
                <div class="layer-tab-item-content layer-container_addvanced_content">
                  <div class="layer-container_addvanced_content_left">
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('display').toUpperCase() }}</label>
                        <b-form-checkbox v-model="tooltipPermanent" switch>{{ $t('always_show') }}</b-form-checkbox>
                      </b-form-group>
                      <b-form-group>
                        <label class="group-title">{{ $t('position').toUpperCase() }}</label>
                        <IconicSelect :moveCount="`iconic-select-tooltip-directions-${moveCountIconicSelect}`" ref="iconicSelect6" :options="tooltipDirections" v-model="tooltipDirection" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="layer-container_addvanced_content_right">
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('opacity').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('background_opacity')">
                            <opacity-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="tooltipOpacity" type="number" step="0.1" min="0" max="1" placeholder="Opacity" size="sm" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <label class="group-title">{{ $t('background').toUpperCase() }}</label>
                        <b-input-group class="background-color" size="sm">
                          <b-input-group-prepend is-text :title="$t('background_color')">
                            <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="tooltipBackground" placeholder="Background" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('font').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('font_family')">
                            <family-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <vue-select v-model="tooltipFamily" :reduce="(data) => data.value" :options="tooltipFamilies" class="select-size-sm" :placeholder="$t('font_family')" />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="form-layout">
                      <b-form-group>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('font_size')">
                            <size-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="tooltipSize" type="number" min="0" placeholder="Font Size" size="sm" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('font_color')">
                            <color-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="tooltipColor" placeholder="Font Color" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('legend')" v-if="['point', 'multi'].includes(type)">
                <template #title>
                  <feather-icon icon="TrelloIcon" size="16" />
                  <span>{{ $t('legend') }}</span>
                </template>
                <div class="layer-tab-item-content layer-container_addvanced_content">
                  <div class="layer-container_addvanced_content_left">
                    <div class="form-layout">
                      <b-form-group v-if="type !== 'netcdf'">
                        <label class="group-title">
                          <div class="d-flex">
                            {{ $t('shape').toUpperCase() }}
                            <tooltip :title="$t('enable_shape')" />
                          </div>
                        </label>
                        <b-input-group>
                          <b-form-checkbox :disabled="fillMode === 'threshold'" v-model="legendShapeVisible" switch>
                            <div class="d-flex">{{ $t('shape') }}</div>
                          </b-form-checkbox>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <label class="group-title">
                          <div class="d-flex" v-if="fillMode === 'threshold' || !legendShapeVisible">
                            {{ $t('width').toUpperCase() }}
                            <tooltip :title="$t('width_of_Legend_bar')" />
                          </div>
                          <div class="d-flex" v-else>
                            {{ $t('size').toUpperCase() }}
                            <tooltip title="Enable to set Legend's radius equal to the Figure's min radius" />
                          </div>
                        </label>
                        <b-input-group v-if="fillMode === 'threshold' || !legendShapeVisible">
                          <b-input-group-prepend :title="$t('width_of_Legend_bar')" is-text>
                            <weight-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="legendBarWidth" type="number" min="0" placeholder="Bar width" size="sm" />
                        </b-input-group>
                        <b-input-group v-else>
                          <b-form-checkbox v-model="legendShapeSizeFixed" switch>Fixed Size</b-form-checkbox>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="layer-container_addvanced_content_right">
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">
                          <div class="d-flex">{{ $t('display').toUpperCase() }}</div>
                        </label>
                        <b-input-group>
                          <b-form-checkbox v-model="legendDisplayLayerName" class="custom-checkbox-icon" switch>
                            <span class="switch-icon-left">
                              <feather-icon icon="EyeIcon" />
                            </span>
                            <span class="switch-icon-right layer-name">
                              <feather-icon icon="EyeOffIcon" />
                            </span>
                            {{ $t('layer_name') }}
                          </b-form-checkbox>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group>
                          <b-form-checkbox v-model="legendDisplayItem" class="custom-checkbox-icon" switch>
                            <span class="switch-icon-left">
                              <feather-icon icon="EyeIcon" />
                            </span>
                            <span class="switch-icon-right item">
                              <feather-icon icon="EyeOffIcon" />
                            </span>
                            {{ $t('item') }}
                          </b-form-checkbox>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group>
                          <b-form-checkbox v-model="legendDisplayUnit" class="custom-checkbox-icon" switch>
                            <span class="switch-icon-left">
                              <feather-icon icon="EyeIcon" />
                            </span>
                            <span class="switch-icon-right unit">
                              <feather-icon icon="EyeOffIcon" />
                            </span>
                            {{ $t('unit') }}
                          </b-form-checkbox>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('na_value')" v-if="['point', 'multi'].includes(type)">
                <template #title>
                  <feather-icon icon="SlashIcon" size="16" />
                  <span>{{ $t('na_value') }}</span>
                </template>
                <div class="layer-tab-item-content layer-container_addvanced_content">
                  <div class="layer-container_addvanced_content_left">
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('background').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('opacity')">
                            <opacity-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="naOpacity" type="number" step="0.1" min="0" max="1" placeholder="Opacity" size="sm" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group class="background-color input-na-value" size="sm">
                          <b-input-group-prepend is-text :title="$t('background_color')">
                            <fill-icon style="width: 18px; height: 18px; transform: rotateY(180deg)" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="naBackground" placeholder="Background" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('outline').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('outline_width')">
                            <weight-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="naWeight" type="number" min="0" placeholder="Width" size="sm" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('outline_color')">
                            <stroke-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <ColorPicker v-model="naColor" placeholder="Color" size="sm" :right="'-4px'" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="layer-container_addvanced_content_right">
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('radius').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('radius')">
                            <radius-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="naRadius" type="number" min="0" placeholder="Radius" size="sm" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('point_cluster')" v-if="['point'].includes(type)">
                <template #title>
                  <feather-icon icon="FigmaIcon" size="16" />
                  <span>{{ $t('point_cluster') }}</span>
                </template>
                <div class="layer-tab-item-content layer-container_addvanced_content">
                  <div class="layer-container_addvanced_content_left d-flex">
                    <div class="form-layout">
                      <b-form-group>
                        <b-form-checkbox v-model="clusterShowCoverageOnHover" switch>{{ $t('cluster_show_coverage') }}</b-form-checkbox>
                      </b-form-group>
                    </div>
                    <div class="form-layout ml-1">
                      <b-form-group>
                        <b-form-checkbox v-model="clusterZoomToBoundOnClick" switch>{{ $t('cluster_zoom') }}</b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="layer-container_addvanced_content_right">
                    <div class="form-layout">
                      <b-form-group>
                        <label class="group-title">{{ $t('cluster_radius').toUpperCase() }}</label>
                        <b-input-group size="sm">
                          <b-input-group-prepend is-text :title="$t('radius')">
                            <radius-icon style="width: 18px; height: 18px" />
                          </b-input-group-prepend>
                          <b-form-input v-model="clusterRadius" type="number" min="0" placeholder="Radius" size="sm" />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('process_rate')" v-if="['multi'].includes(type)" :disabled="!processRate" @click.stop="clTabProcessRate">
                <template #title>
                  <feather-icon icon="FastForwardIcon" size="16" />
                  <span>{{ $t('process_rate') }}</span>
                </template>
                <div class="layer-tab-item-content">
                  <div class="process-rate-svm">
                    <b-row class="section-header ml-0" :style="{ width: '100%' }">
                      <b-col class="process-rate-svm-item-border process-rate-svm-item-border-geometry-type" cols="6" sm="6" lg="3">
                        <div class="process-rate-svm-item-border-main">
                          <div class="form-layout">
                            <b-form-group>
                              <label class="group-title">
                                <div class="d-flex fz-14px" style="color: #397bff">
                                  {{ $t('geometry_type').toUpperCase() }}
                                </div>
                              </label>
                              <div class="d-flex align-items-center">
                                <feather-icon class="process-rate-icon" icon="LayersIcon" size="16" />
                                <div class="process-rate-svm-item-method-vs">
                                  <vue-select :clearable="false" :appendToBody="true" v-model="processRateGeometryTypeValue" :reduce="(data) => data.value" :options="processRateGeometryTypeOptions" class="select-size-sm" :placeholder="$t('Method')">
                                    <template #selected-option="option">
                                      {{ $t(option.label) }}
                                    </template>
                                    <template v-slot:option="option">{{ $t(option.label) }}</template>
                                    <template #no-options>{{ $t('no_column') }}</template>
                                  </vue-select>
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                      <b-col v-if="processRateGeometryTypeValue && processRateGeometryTypeValue == 'polygon'" class="process-rate-svm-item-border process-rate-svm-item-border-value-column-polygon" cols="6" sm="6" lg="3">
                        <div class="process-rate-svm-item-border-main">
                          <div class="form-layout">
                            <b-form-group>
                              <label class="group-title">
                                <div class="d-flex fz-14px" style="color: #397bff">
                                  {{ $t('value_column').toUpperCase() }}
                                </div>
                              </label>
                              <div class="d-flex align-items-center">
                                <feather-icon class="process-rate-icon" icon="DatabaseIcon" size="16" />
                                <div class="process-rate-svm-item-value-column-vs">
                                  <vue-select :appendToBody="true" v-model="processRatePolygonValueColum" :reduce="(data) => data.value" :options="optionsProcessRateValueColumn" class="select-size-sm" :placeholder="$t('value_column')">
                                    <template v-slot:option="option"> </template>
                                    <template #no-options>{{ $t('no_column') }}</template>
                                  </vue-select>
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                      <b-col v-else class="process-rate-svm-item-border process-rate-svm-item-border-segment" cols="6" sm="6" lg="3">
                        <div class="process-rate-svm-item-border-main">
                          <div class="form-layout">
                            <b-form-group>
                              <label class="group-title">
                                <div class="d-flex fz-14px" style="color: #397bff">
                                  {{ $t('segment').toUpperCase() }}
                                </div>
                              </label>
                              <div class="d-flex align-items-center" :title="$t('title_segment')">
                                <feather-icon class="process-rate-icon" icon="GitCommitIcon" size="16" />
                                <div class="process-rate-svm-item-segment-ip">
                                  <b-form-input v-model.number="ipSegment" id="value-min-radius" type="number" min="0" lazy placeholder="1" size="sm" />
                                </div>
                                <div class="process-rate-svm-item-segment-split">
                                  <b-button @click.stop="splitSave" size="sm" variant="primary" block> {{ $t('split_process_rate') }} </b-button>
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>

                      <b-col class="process-rate-svm-item-border process-rate-svm-item-border-progress-column" cols="6" sm="6" lg="3">
                        <div class="process-rate-svm-item-border-main">
                          <div class="form-layout">
                            <b-form-group>
                              <label class="group-title">
                                <div class="d-flex fz-14px" style="color: #397bff">
                                  {{ $t('progress_column').toUpperCase() }}
                                </div>
                              </label>
                              <div class="d-flex align-items-center">
                                <feather-icon class="process-rate-icon" icon="DatabaseIcon" size="16" />
                                <div class="process-rate-svm-item-value-column-vs">
                                  <vue-select :appendToBody="true" v-model="processRageValueColumn" :reduce="(data) => data.value" :options="optionsProcessRateValueColumn" class="select-size-sm" :placeholder="$t('progress_column')">
                                    <template v-slot:option="option"> </template>
                                    <template #no-options>{{ $t('no_column') }}</template>
                                  </vue-select>
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                      <b-col class="process-rate-svm-item-border process-rate-svm-item-border-method" cols="6" sm="6" lg="3">
                        <div class="process-rate-svm-item-border-main">
                          <div class="form-layout">
                            <b-form-group>
                              <label class="group-title">
                                <div class="d-flex fz-14px" style="color: #397bff">
                                  {{ $t('method').toUpperCase() }}
                                </div>
                              </label>
                              <div class="d-flex align-items-center">
                                <feather-icon class="process-rate-icon" icon="DivideCircleIcon" size="16" />
                                <div class="process-rate-svm-item-method-vs">
                                  <vue-select :clearable="false" :appendToBody="true" v-model="processRageMethod" :reduce="(data) => data.value" :options="optionsProcessRateMethod" class="select-size-sm" :placeholder="$t('Method')">
                                    <template #selected-option="option">
                                      {{ $t(option.label) }}
                                    </template>
                                    <template v-slot:option="option">{{ $t(option.label) }}</template>
                                    <template #no-options>{{ $t('no_column') }}</template>
                                  </vue-select>
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="layer-tab-item-content" v-if="processRateGeometryTypeValue && processRateGeometryTypeValue == 'polyline'">
                  <div class="process-rate-svm">
                    <b-row class="section-header ml-0" :style="{ width: '100%' }">
                      <b-col class="process-rate-svm-item-border process-rate-svm-item-border-geometry-type" cols="6" sm="6" lg="3">
                        <div class="process-rate-svm-item-border-main">
                          <div class="form-layout">
                            <b-form-group>
                              <label class="group-title">
                                <div class="d-flex fz-14px" style="color: #397bff">
                                  {{ $t('progress_direction').toUpperCase() }}
                                </div>
                              </label>
                              <div class="d-flex align-items-center">
                                <div class="process-rate-svm-item-method-vs">
                                  <IconicSelect :moveCount="`iconic-revert-process-rate-line-${moveCountIconicSelect}`" ref="iconicSelect8" :options="processRateDirectionLine" v-model="progressDirection" />
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="process-rate-preview mt-1">
                  <b-form-group>
                    <label class="group-title mb-1">
                      <div class="d-flex fz-14px" style="color: #397bff">
                        {{ $t('preview').toUpperCase() }}
                      </div>
                    </label>
                    <div class="mt-5 process-preview-multi-range">
                      <vue-slider :disabled="true" class="multi-range process-preview-multi-range-divvs" v-model="rangePreviewProcess" :min="0" :tooltip="'none'" :max="maxMultiVueSliderProcessRate" :marks="true" :interval="1">
                        <template v-slot:mark="{ pos, label }">
                          <div class="custom-mark" :class="`custom-mark-${label % stepMarkMultiVueSliderProcessRate == 0 || label == maxMultiVueSliderProcessRate || label == 0 ? '1' : '0'}`" :style="{ left: `${pos}%` }">
                            <div v-if="label != 0 && getImgPreviewDot(label)" class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top custom-tooltip custom-tooltip-preview-image custom-tooltip-dot-preview-image">
                              <img :src="getImgPreviewDot(label)" class="tool-icon" />
                            </div>
                            <div class="d-flex flex-column align-items-center">
                              <div class="custom-mark-icon"></div>
                              <div>
                                {{ label % stepMarkMultiVueSliderProcessRate == 0 || label == maxMultiVueSliderProcessRate || label == 0 ? label : '' }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </vue-slider>
                    </div>
                  </b-form-group>
                </div>
                <div class="process-rate-image mt-5 mb-1">
                  <b-form-group>
                    <div class="group-title d-flex justify-content-between align-items-center">
                      <div class="fz-14px" style="color: #397bff">{{ $t('image').toUpperCase() }}</div>
                      <b-button @click.stop="toggleUploadImagesProcessRate" size="sm" variant="outline-secondary">+ {{ $t('add_image') }}</b-button>
                    </div>
                    <!-- addImageProcessRage -->

                    <div class="mt-1">
                      <table class="table process-rate-image-table">
                        <thead>
                          <tr>
                            <th scope="col">{{ $t('image').toUpperCase() }}</th>
                            <th scope="col">{{ $t('step').toUpperCase() }}</th>
                            <th scope="col">{{ $t('action').toUpperCase() }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="imagesPreviewProcessrateArray && imagesPreviewProcessrateArray.length">
                          <tr v-for="image in imagesPreviewProcessrateArray">
                            <th scope="row">
                              <div class="d-flex align-items-center">
                                <div class="row-image">
                                  <img :src="getImageSourceById(image.id)" class="tool-icon" />
                                </div>
                                <div class="row-name ml-1">{{ image.fileName }}</div>
                              </div>
                            </th>
                            <td>{{ image.stepFixed }}</td>
                            <td>
                              <feather-icon @click.stop="editImageProcessRate(image)" icon="EditIcon" size="16" />
                              <feather-icon @click.stop="showModalConfirmDelImageProcessRate(image)" color="red" icon="TrashIcon" size="16" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-form-group>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div style="height: 35px"></div>
        </AppCollapseItem>
      </AppCollapse>
    </div>
    <!-- <div class="d-flex justify-content-end btn-save-config" style="right: 80px;">
      <b-button :title="$t('save_layer')" variant="success" size="sm" style="padding: 6px 10px">Reset to default</b-button>
    </div> -->
    <div class="d-flex justify-content-end btn-save-config">
      <b-button variant="flat-secondary" size="sm" style="padding: 6px 10px" @click="resetDefaultLayer()" class="mr-1">{{ $t('reset_to_default') }}</b-button>
      <b-button :title="$t('save_layer')" variant="success" size="sm" @click="updateLayer(true)" style="padding: 6px 10px">{{ $t('save') }}</b-button>
    </div>
    <b-modal id="id-modal-edit-process-image-step" size="sm" :title="$t('edit_step')" centered ref="modalEditProcessImageStep">
      <div>
        <b-form-input type="number" @keydown="handlerInputNumberStep" :state="validateInputNumberStep" min="1" step="1" v-model.number="stepFixedUpdateProcessRate"></b-form-input>
      </div>
      <template #modal-footer>
        <div class="text-right" :style="{ width: '100%' }">
          <b-button @click.stop="saveUpdateProcessImage" variant="outline-primary" size="sm"> {{ $t('save') }}</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="id-modal-delete-process-image-step" size="sm" :title="$t('delete_image_process_rate')" centered ok-only ok-variant="danger" :ok-title="$t('delete')" ref="modalDelProcessImageStep" :body-class="'custom-body'" @ok="delImageProcessRate">
      <div>
        {{ $t('message_delete_image_process_rate') }}
      </div>
    </b-modal>
    <input type="file" ref="refUploadImageProcessRate" multiple style="display: none" @change="uploadImagesProcessRateEvt" accept=".png, .jpeg, .jpg, .zip" />
  </div>
</template>

<script>
import Vue from 'vue'
const _ = require('lodash')
const path = require('path')
const fs = require('fs')
import ImageMixin from '@/mixins/ImageMixin'
import * as SHAPES from '@/constants/shapes'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import ColorPicker from '../../../../common/ColorPicker.vue'
import PalettePicker from '../../../../common/PalettePicker.vue'
import PalettePickerV3 from '../../../../common/PalettePickerV3.vue'
import ShapeSelect from '../../../../common/ShapeSelect.vue'
import Tooltip from '../../../../common/Tooltip.vue'
import IconicSelect from '../../../../common/IconicSelect.vue'
import ThresholdLevel from '../../../../common/ThresholdLevel.vue'
import ThresholdValue from '../../../../common/ThresholdValue.vue'
import DataSelect from './controls/DataSelect.vue'
import TimeseriesSelect from './controls/TimeseriesSelect.vue'
import ItemSelect from './controls/ItemSelect.vue'
import ItemListSelect from './controls/ItemListSelect.vue'
import ImageSelect from './controls/ImageSelect.vue'
import TyphoonSelect from './controls/TyphoonSelect.vue'
import LayerItemDeleteConfirm from './tools/LayerItemDeleteConfirm.vue'
// Icons
import OpacityIcon from '/static/images/layer-config/prepend/opacity.svg'
import DatasourceIcon from '/static/images/layer-config/prepend/datasource.svg'
import Datasource1Icon from '/static/images/layer-config/prepend/datasource1.svg'
import RadiusIcon from '/static/images/layer-config/prepend/radiusb.svg'
import WeightIcon from '/static/images/layer-config/prepend/weight2.svg'
import FillIcon from '/static/images/layer-config/prepend/fill2.svg'
import StrokeIcon from '/static/images/layer-config/prepend/stroke1.svg'
import FamilyIcon from '/static/images/layer-config/prepend/font-family.svg'
import SizeIcon from '/static/images/layer-config/prepend/font-size.svg'
import ColorIcon from '/static/images/layer-config/prepend/font-color.svg'
import SpikeIcon from '/static/images/layer-config/prepend/spike.svg'
import LocationIcon from '/static/images/layer-config/prepend/location.svg'
import ItemIcon from '/static/images/layer-config/prepend/item.svg'
import BackgroundIcon from '/static/images/layer-config/prepend/background.svg'
import ResampleIcon from '/static/images/layer-config/prepend/resample.svg'
import VisualizeConfigIcon from '/static/images/layer-config/radio/type-image.svg'
import ScaleMixin from '@/mixins/ScaleMixin'
import RadioGroup from '../../../../common/RadioGroup.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

import { ACTION_LOG } from '@/constants/actionLog'
import { DEFAULT_LAYER } from '@/constants/default-layer.js'

const ListFont = [
  { label: 'Arial', value: 'arial' },
  { label: 'Times New Roman', value: 'times new roman' },
  { label: 'Helvetica', value: 'helvetica' },
  { label: 'Times', value: 'times' },
  { label: 'Courier New', value: 'courier new' },
  { label: 'Verdana', value: 'verdana' },
  { label: 'Courier', value: 'courier' },
  { label: 'Arial Narrow', value: 'arial narrow' },
  { label: 'Candara', value: 'candara' },
  { label: 'Geneva', value: 'geneva' },
  { label: 'Calibri', value: 'calibri' },
  { label: 'Noto Sans JP', value: 'Noto Sans JP' },
  { label: 'Optima', value: 'optima' },
  { label: 'Cambria', value: 'cambria' },
  { label: 'Garamond', value: 'garamond' },
  { label: 'Perpetua', value: 'perpetua' },
  { label: 'Monaco', value: 'monaco' },
  { label: 'Didot', value: 'didot' },
  { label: 'Brush Script MT', value: 'brush script mt' },
  { label: 'Lucida Bright', value: 'lucida bright' },
  { label: 'Copperplate', value: 'copperplate' },
]
const FIGURE_COLOR = ['#397BFF', 'red', 'blue', 'yellow']
import i18n from '@/libs/i18n'

export default {
  props: ['layer', 'layerMove'],
  mixins: [ScaleMixin, ImageMixin],
  components: {
    DataSelect,
    TimeseriesSelect,
    ItemSelect,
    ItemListSelect,
    ColorPicker,
    PalettePicker,
    PalettePickerV3,
    ImageSelect,
    TyphoonSelect,
    ShapeSelect,
    IconicSelect,
    ThresholdLevel,
    ThresholdValue,
    LayerItemDeleteConfirm,
    Tooltip,
    AppCollapse,
    AppCollapseItem,
    OpacityIcon,
    DatasourceIcon,
    Datasource1Icon,
    RadiusIcon,
    WeightIcon,
    FillIcon,
    StrokeIcon,
    FamilyIcon,
    SizeIcon,
    ColorIcon,
    SpikeIcon,
    LocationIcon,
    ItemIcon,
    BackgroundIcon,
    ResampleIcon,
    RadioGroup,
    VisualizeConfigIcon,
    VueSlider,
    ToastificationContent,
  },
  data() {
    return {
      open: true,

      id: this.layer.id,
      name: this.layer.name,
      type: this.layer.type,
      // inspectCount: this.layer.inspectCount,

      dataPoint: this.layer.dataPoint,
      dataImage: this.layer.dataImage,
      dataTyphoon: this.layer.dataTyphoon,
      dataMulti: this.layer.dataMulti,
      dataNetcdf: this.layer.dataNetcdf,
      dataTimeseries: this.layer.dataTimeseries,
      dataTimeseriesShape: this.layer.dataTimeseriesShape,
      item: this.layer.item,

      fillMode: this.layer.fillColor.mode,
      fillColor: this.layer.fillColor.color,
      fillColors: this.layer.fillColor.colors,
      fillOpacity: this.layer.fillOpacity,
      fillLevels: this.layer.fillColor.levels,
      // fillIndividual: this.layer.fillColor.individualThreshold,
      fillIndividual: true,
      fillThresholds: this.layer.fillColor.thresholds,

      weight: this.layer.weight,
      color: this.layer.color,

      rangeRadius: [this.layer.radius.min, this.layer.radius.max],

      naVisible: this.layer.navalue.visible,
      naRadius: this.layer.navalue.radius,
      naWeight: this.layer.navalue.weight,
      naColor: this.layer.navalue.color,
      naOpacity: this.layer.navalue.fillOpacity,
      naBackground: this.layer.navalue.fillColor,

      tooltipVisible: this.layer.tooltip.visible,
      tooltipDirection: this.layer.tooltip.direction,
      tooltipPermanent: this.layer.tooltip.permanent,
      tooltipFamilies: ListFont,
      tooltipFamily: this.layer.tooltip.family,
      tooltipSize: this.layer.tooltip.size,
      tooltipColor: this.layer.tooltip.color,
      tooltipOpacity: this.layer.tooltip.opacity,
      tooltipBackground: this.layer.tooltip.backgroundColor,

      popupVisible: this.layer.popup.visible,
      popupMultiShow: this.layer.popup.multiShow,
      popupType: this.layer.popup.type,
      popupBackgroundFrom: this.layer.popup.backgroundColorFrom,
      popupBackgroundTo: this.layer.popup.backgroundColorTo,
      popupLineColor: this.layer.popup.lineColor,
      popupFamilies: ListFont,
      popupFamily: this.layer.popup.family,
      popupHeaderSize: this.layer.popup.headerSize,
      popupHeaderColor: this.layer.popup.headerColor,
      popupContentSize: this.layer.popup.contentSize,
      popupContentColor: this.layer.popup.contentColor,
      popupBorderlessHeader: this.layer.popup.headerColorBorderless,
      popupBorderlessContentColor: this.layer.popup.contentColorBorderless,

      legendVisible: this.layer.legend.visible,
      legendShapeVisible: this.layer.legend.shapeVisible,
      legendDisplayLayerName: this.layer.legend.displayLayerName,
      legendDisplayItem: this.layer.legend.displayItem,
      legendDisplayUnit: this.layer.legend.displayUnit,
      legendBarWidth: this.layer.legend.barWidth,
      legendShapeSizeFixed: this.layer.legend.shapeSizeFixed,
      legendAutoUpdate: this.layer.legend.autoUpdate,

      imageOpacity: this.layer.image.opacity,

      iconRadius: this.layer.typhoon.icon.radius,
      lineWeight: this.layer.typhoon.line.weight,
      lineColor: this.layer.typhoon.line.color,
      stormWeight: this.layer.typhoon.storm.weight,
      stormColor: this.layer.typhoon.storm.color,
      galeWeight: this.layer.typhoon.gale.weight,
      galeColor: this.layer.typhoon.gale.color,
      trailKeep: this.layer.typhoon.trail.keep,
      trailOpacity: this.layer.typhoon.trail.opacity,

      shapeType: this.layer.shape.shapeType,
      numSpikes: this.layer.shape.numSpikes,
      isMSMLayer: false,

      //POINT CLUSTER FROM V1.1.43
      clusterUsing: this.layer.markerCluster.using,
      clusterShowCoverageOnHover: this.layer.markerCluster.showCoverageOnHover,
      clusterZoomToBoundOnClick: this.layer.markerCluster.zoomToBoundsOnClick,
      clusterRadius: this.layer.markerCluster.maxClusterRadius,
      //SCALE OPTION FROM V1.1.44
      scaleValue: this.layer.scale.value,

      oldName: this.layer.name,

      // Copy paste layer
      figureColor: FIGURE_COLOR,
      valueFigureColor: FIGURE_COLOR[0],
      moveCountIconicSelect: 0,
      flagColorPalette: 0,
      idsItemsMulti: [],
      idMultiSelected: this.layer.idMultiSelected,
      itemMultiSelected: this.layer.itemMultiSelected,

      itemFirstPoint: null,
      itemFirstMulti: null,

      bTabsActive: 0,

      processRate: this.layer.processRate,
      processRageValueColumn: this.layer.processRateData && this.layer.processRateData.progressValue ? this.layer.processRateData.progressValue : null,
      processRageMethod: this.layer.processRateData && this.layer.processRateData.progressMethod ? this.layer.processRateData.progressMethod : null,
      progressDirection: this.layer.processRateData && this.layer.processRateData.progressDirection ? this.layer.processRateData.progressDirection : 'default',
      processRatePolygonValueColum: this.layer.processRateData && this.layer.processRateData.polygonValue ? this.layer.processRateData.polygonValue : null,

      ipSegment: this.layer.processRateData && this.layer.processRateData.segment ? this.layer.processRateData.segment : 10,
      maxMultiVueSliderProcessRate: this.layer.processRateData && this.layer.processRateData.segment ? Number(this.layer.processRateData.segment) : 20,
      processRateImageDatatable: [],
      modalEditProcessImage: {
        id: null,
      },
      modalDelProcessImage: null,
      stepFixedUpdateProcessRate: null,
      optionsProcessRateValueColumn: [],
      rangePreviewProcess: [0, 0],
      imgPreviewEnd: 'static/images/tools/stationarity-tool.svg',
      imagesPreviewProcessrateArray: [],
      processRateGeometryTypeValue: this.layer.processRateData && this.layer.processRateData.geometryType ? this.layer.processRateData.geometryType : 'polyline', // polyline or polygon -> default:polyline
      processRateGeometryTypeOptions: [
        {
          label: 'polyline',
          value: 'polyline',
        },
        {
          label: 'polygon',
          value: 'polygon',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        try {
          let buttonClear = document.querySelectorAll('.vs__clear')
          for (let i = 0; i < buttonClear.length; i++) {
            if (this.locale !== 'en') buttonClear[i].title = 'クリア'
            else buttonClear[i].title = 'Clear Selected'
          }
        } catch {}
        this.moveCountIconicSelect++
      }, 500)
    })
    this.getOpitonIdsItemsMultil()
  },
  computed: {
    validateInputNumberStep() {
      return this.stepFixedUpdateProcessRate > 0
    },
    stepMarkMultiVueSliderProcessRate() {
      try {
        return Math.ceil(Number(this.maxMultiVueSliderProcessRate / 10))
      } catch {}
      return 20
    },
    optionsProcessRateMethod() {
      let data = [
        {
          label: 'Progress (Current)',
          value: 'progress-current',
        },
      ]
      if (this.processRateGeometryTypeValue && this.processRateGeometryTypeValue == 'polyline') {
        data.push({
          label: 'Distance (Current)',
          value: 'distance-current',
        })
      }
      return data
    },
    locale() {
      return i18n.locale
    },
    layoutSkin() {
      return this.$store.state.appConfig.layout.skin
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    types() {
      return [
        { value: 'point', title: this.$t('point_layer'), icon: require('/static/images/layer-config/radio/type-point.svg').default },
        { value: 'image', title: this.$t('image_layer'), icon: require('/static/images/layer-config/radio/type-image.svg').default },
        { value: 'typhoon', title: this.$t('typhoon_layer'), icon: require('/static/images/layer-config/radio/type-typhoon.svg').default },
        { value: 'multi', title: this.$t('multi_layer'), icon: require('/static/images/layer-config/radio/type-multi.svg').default },
      ]
    },
    shapeTypes() {
      return [
        { value: SHAPES.CIRCLE, title: this.$t('circle'), icon: require('/static/images/layer-config/radio/shape-circle.svg').default },
        { value: SHAPES.TRIANGLE, title: this.$t('triangle'), icon: require('/static/images/layer-config/radio/shape-triangle.svg').default },
        { value: SHAPES.SQUARE, title: this.$t('square'), icon: require('/static/images/layer-config/radio/shape-square.svg').default },
        { value: SHAPES.SPIKE, title: this.$t('star'), icon: require('/static/images/layer-config/radio/shape-spike.svg').default },
      ]
    },
    fillModes() {
      return [
        { value: 'solid', text: this.$t('solid') },
        { value: 'linear', text: this.$t('linear') },
        { value: 'threshold', text: this.$t('threshold') },
      ]
    },
    tooltipDirections() {
      return [
        { value: 'top', title: this.$t('top'), icon: require('/static/images/layer-config/radio/tooltip-top.svg').default },
        { value: 'bottom', title: this.$t('bottom'), icon: require('/static/images/layer-config/radio/tooltip-bottom.svg').default },
        { value: 'left', title: this.$t('left'), icon: require('/static/images/layer-config/radio/tooltip-left.svg').default },
        { value: 'right', title: this.$t('right'), icon: require('/static/images/layer-config/radio/tooltip-right.svg').default },
      ]
    },
    popupTypes() {
      return [
        { value: 'border', title: this.$t('border'), text: this.$t('border') },
        { value: 'borderless', title: this.$t('borderless'), text: this.$t('borderless') },
      ]
    },
    processRateDirectionLine() {
      return [
        { value: 'default', title: this.$t('default'), text: this.$t('default') },
        { value: 'reverse', title: this.$t('reverse'), text: this.$t('reverse') },
      ]
    },
    dataLocation() {
      if (this.type === 'point') {
        return this.dataPoint
      } else if (this.type === 'multi') {
        return this.dataMulti
      } else {
        return null
      }
    },
    idDatasourceValue() {
      if (this.dataSelected) {
        if (this.type && (this.type == 'multi' || this.type == 'point')) {
          if (this.type == 'multi') {
            return this.dataTimeseriesShape
          } else {
            return this.dataTimeseries
          }
        } else {
          return
        }
      } else return
    },
    itemThreshold() {
      if (this.type && (this.type == 'multi' || this.type == 'point')) {
        if (this.isShowItemShape) {
          return this.itemMultiSelected || this.itemFirstMulti
        } else {
          return this.item || this.itemFirstPoint
        }
      } else {
        return null
      }
    },
    dataSelected: {
      get() {
        if (this.type === 'point') {
          return this.dataPoint
        } else if (this.type === 'image') {
          return this.dataImage
        } else if (this.type === 'typhoon') {
          return this.dataTyphoon
        } else if (this.type === 'multi') {
          return this.dataMulti
        } else {
          return null
        }
      },
      set(value) {
        this.type = 'point'
        this.dataPoint = null
        this.dataImage = null
        this.dataTyphoon = null
        this.dataMulti = null
        this.dataNetcdf = null

        if (!value) return

        let data = this.datasources.find((d) => d.id === value)
        if (!data) return
        this.isMSMLayer = path.basename(data.name).startsWith('MSM')
        this.type = data.type
        if (this.type === 'point') {
          this.dataPoint = value
        } else if (this.type === 'image') {
          this.dataImage = value
        } else if (this.type === 'typhoon') {
          this.dataTyphoon = value
        } else if (this.type === 'multi') {
          this.dataMulti = value
        }
      },
    },
    isFillIndividual() {
      let isFillIndividual = false
      try {
        const thresholds = this.fillThresholds
        const locations = Object.keys(thresholds).filter((o) => o !== 'General')
        if (locations.length) {
          isFillIndividual = new Set(locations.map((location) => thresholds[location].join('_'))).size > 1
        }
      } catch {}
      return isFillIndividual
    },
    layerHasImage() {
      return this.$store.state.image.popupImages.findIndex((popup) => popup.layerId === this.layer.id)
    },
    resampleDimension() {
      return [
        { value: 1, text: this.$t('original') },
        { value: 2, text: this.$t('2_times') },
        { value: 5, text: this.$t('5_times') },
        { value: 10, text: this.$t('10_times') },
      ]
    },
    coordFuncOption() {
      return [
        { value: 'mean', text: this.$t('mean') },
        { value: 'max', text: this.$t('max') },
      ]
    },
    groupItemsList() {
      return this.$store.state.groupItems.groupItemsList
    },
    selectedGroupItems() {
      return this.$store.state.groupItems.selectedGroupItems
    },
    isShowItemShape() {
      try {
        if (this.dataSelected && this.type && this.type == 'multi' && this.dataTimeseriesShape) {
          for (let i = 0; i < this.datasources.length; i++) {
            if (this.datasources[i].id == this.dataTimeseriesShape && this.datasources[i].type == 'multi') {
              return this.datasources[i]
            }
          }
        }
      } catch {}
      return false
    },
    imagesPreviewProcessrate() {
      let imagesStore = this.$store.state.image.processRate
      let output = {}
      let opArray = []
      if (imagesStore && imagesStore.length) {
        for (let i = 0; i < imagesStore.length; i++) {
          if (imagesStore[i].layerId == this.layer.id) {
            if (!output[imagesStore[i].locationItem]) {
              output[imagesStore[i].locationItem] = []
            }
            opArray.push(imagesStore[i])
            output[imagesStore[i].locationItem].push(imagesStore[i])
          }
        }
      }
      this.imagesPreviewProcessrateArray = opArray
      return output
    },
  },
  watch: {
    idDatasourceValue: {
      deep: true,
      async handler() {
        this.getOptionsProcessRateValueColumn()
      },
    },
    'layer.updateStyle': {
      deep: true,
      handler() {
        this.updateDataLayer()
      },
    },
    dataSelected(dataSelected) {
      this.idMultiSelected = null
      this.itemMultiSelected = null
      this.dataTimeseriesShape = null
      if (dataSelected) {
        this.getOpitonIdsItemsMultil()
      }
    },
    dataTimeseriesShape: {
      deep: true,
      handler() {
        this.getOpitonIdsItemsMultil()
      },
    },
    bTabsActive: {
      deep: true,
      handler() {
        this.moveCountIconicSelect++
      },
    },
    processRateGeometryTypeValue: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal == 'polygon') {
          this.processRageMethod = 'progress-current'
        }
      },
    },
  },
  methods: {
    toggleUploadImagesProcessRate() {
      this.$refs.refUploadImageProcessRate.click()
    },
    async uploadImagesProcessRateEvt() {
      const clicking = {
        clickId: 1,
        layerId: this.layer.id,
        multiShow: false,
        pin: true,
        source: 'map',
        step: 1,
      }
      const imageData = await this.uploadImages(clicking, null, this.$refs.refUploadImageProcessRate.files)
      if (imageData && imageData.length) {
        for (let i = 0; i < imageData.length; i++) {
          imageData[i] = _.merge(imageData[i], {
            layerId: this.layer.id,
            locationItem: 1,
            stepFixed: 1,
            stepAdd: 1,
            precent: 1,
            weight: 1,
            distance: 1,
          })
        }
        this.$store.commit('image/ADD_PROCESS_RATE_IMAGE', imageData)
      }
    },
    handlerInputNumberStep(event) {
      if (event.key === '-' || event.key === 'e') {
        event.preventDefault()
      }
    },
    getImgPreviewDot(step) {
      let img
      try {
        if (this.imagesPreviewProcessrate && this.imagesPreviewProcessrate[step]) {
          img = this.getImageSourceById(this.imagesPreviewProcessrate[step][0].id)
        }
      } catch {}
      return img
    },
    async getOptionsProcessRateValueColumn() {
      let data = []
      if (this.idDatasourceValue) {
        try {
          let recordAll = await this.selectAll(this.idDatasourceValue)
          if (recordAll) {
            for (let key in recordAll) {
              if (key != 'dates') {
                data.push({
                  label: key,
                  value: key,
                })
              }
            }
          }
        } catch {}
      }
      this.optionsProcessRateValueColumn = data
    },
    splitSave() {
      try {
        if (this.ipSegment) {
          this.maxMultiVueSliderProcessRate = Number(_.cloneDeep(this.ipSegment))
        }
      } catch {}
    },
    clTabProcessRate() {
      try {
        this.maxMultiVueSliderProcessRate = this.layer.processRateData && this.layer.processRateData.segment ? Number(this.layer.processRateData.segment) : 20
      } catch {}
      this.getOptionsProcessRateValueColumn()
    },
    saveUpdateProcessImage() {
      try {
        let stepTemp = _.cloneDeep(this.stepFixedUpdateProcessRate)
        if (stepTemp) {
          let dataMerge = {
            id: this.modalEditProcessImage.id,
            locationItem: stepTemp,
            stepFixed: Number(stepTemp),
            stepAdd: Number(stepTemp),
            precent: Number(stepTemp),
            weight: Number(stepTemp),
            distance: Number(stepTemp),
          }
          this.$store.commit('image/UPDATE_STEP_PROCESS_RATE_IMAGE', dataMerge)
        }
      } catch {}
      this.$refs.modalEditProcessImageStep.hide()
    },
    editImageProcessRate(image) {
      this.modalEditProcessImage = _.cloneDeep(image)
      this.stepFixedUpdateProcessRate = _.cloneDeep(image.stepFixed)
      this.$refs.modalEditProcessImageStep.show()
    },
    showModalConfirmDelImageProcessRate(image) {
      this.modalDelProcessImage = _.cloneDeep(image)
      this.$refs.modalDelProcessImageStep.show()
    },
    delImageProcessRate() {
      if (!this.modalDelProcessImage || !this.modalDelProcessImage.id) {
        this.$refs.modalDelProcessImageStep.hide()
        return
      }
      this.$store.commit('image/DELETE_PROCESS_RATE_IMAGE', { id: this.modalDelProcessImage.id })
      this.modalDelProcessImage = null
    },
    async addImageProcessRage() {
      try {
        const exts = ['png', 'jpeg', 'jpg', 'zip']
        const clicking = {
          clickId: 1,
          layerId: this.layer.id,
          multiShow: false,
          pin: true,
          source: 'map',
          step: 1,
        }
        let imageData = await this.uploadImages(exts, clicking, null)
        if (imageData && imageData.length) {
          for (let i = 0; i < imageData.length; i++) {
            imageData[i] = _.merge(imageData[i], {
              layerId: this.layer.id,
              locationItem: 1,
              stepFixed: 1,
              stepAdd: 1,
              precent: 1,
              weight: 1,
              distance: 1,
            })
          }
          this.$store.commit('image/ADD_PROCESS_RATE_IMAGE', imageData)
        }
      } catch {}
    },
    setItemFirst(itemFirst) {
      this.itemFirstPoint = itemFirst
    },
    getOpitonIdsItemsMultil() {
      try {
        if (this.dataSelected && this.$db[this.dataSelected]) {
          let idsItemsMulti = []
          let properties = this.$db[this.dataSelected].data[0].data.features[0].properties
          if (properties && Object.keys(properties).length) {
            let isEmptyID, isEmptyUID
            try {
              isEmptyUID = this.$db[this.dataSelected].data[0].data.isEmptyUID
            } catch {}
            try {
              isEmptyID = this.$db[this.dataSelected].data[0].data.isEmptyID
            } catch {}
            for (let key in properties) {
              if (!((isEmptyUID && key == 'UID') || (isEmptyID && key == 'ID'))) {
                idsItemsMulti.push({
                  value: key,
                  label: key,
                })
              }
            }
          }
          this.idsItemsMulti = idsItemsMulti
        }
      } catch {
        this.idMultiSelected = null
        this.itemMultiSelected = null
        this.idsItemsMulti = []
      }
      let itemFirst
      try {
        itemFirst = this.idsItemsMulti[0].value
      } catch {}
      this.itemFirstMulti = itemFirst
    },
    resetDefaultLayer() {
      let layerDefault = _.cloneDeep(DEFAULT_LAYER)
      // this.idMultiSelected = layerDefault.idMultiSelected;
      // this.itemMultiSelected = layerDefault.itemMultiSelected;
      this.fillMode = layerDefault.fillColor.mode
      this.fillColor = layerDefault.fillColor.color
      this.fillColors = layerDefault.fillColor.colors
      this.flagColorPalette++
      this.fillOpacity = layerDefault.fillOpacity
      this.fillLevels = layerDefault.fillColor.levels
      this.fillThresholds = layerDefault.fillColor.thresholds
      this.weight = layerDefault.weight
      this.color = layerDefault.color
      this.rangeRadius = [layerDefault.radius.min, layerDefault.radius.max]
      this.naVisible = layerDefault.navalue.visible
      this.naRadius = layerDefault.navalue.radius
      this.naWeight = layerDefault.navalue.weight
      this.naColor = layerDefault.navalue.color
      this.naOpacity = layerDefault.navalue.fillOpacity
      this.naBackground = layerDefault.navalue.fillColor
      this.tooltipVisible = layerDefault.tooltip.visible
      this.tooltipDirection = layerDefault.tooltip.direction
      this.tooltipPermanent = layerDefault.tooltip.permanent
      this.tooltipFamily = layerDefault.tooltip.family
      this.tooltipSize = layerDefault.tooltip.size
      this.tooltipColor = layerDefault.tooltip.color
      this.tooltipOpacity = layerDefault.tooltip.opacity
      this.tooltipBackground = layerDefault.tooltip.backgroundColor
      this.popupVisible = layerDefault.popup.visible
      this.popupMultiShow = layerDefault.popup.multiShow
      this.popupType = layerDefault.popup.type
      this.popupBackgroundFrom = layerDefault.popup.backgroundColorFrom
      this.popupBackgroundTo = layerDefault.popup.backgroundColorTo
      this.popupLineColor = layerDefault.popup.lineColor
      this.popupFamily = layerDefault.popup.family
      this.popupHeaderSize = layerDefault.popup.headerSize
      this.popupHeaderColor = layerDefault.popup.headerColor
      this.popupContentSize = layerDefault.popup.contentSize
      this.popupContentColor = layerDefault.popup.contentColor
      this.popupBorderlessHeader = layerDefault.popup.headerColorBorderless
      this.popupBorderlessContentColor = layerDefault.popup.contentColorBorderless
      this.legendVisible = layerDefault.legend.visible
      this.legendShapeVisible = layerDefault.legend.shapeVisible
      this.legendDisplayLayerName = layerDefault.legend.displayLayerName
      this.legendDisplayItem = layerDefault.legend.displayItem
      this.legendDisplayUnit = layerDefault.legend.displayUnit
      this.legendBarWidth = layerDefault.legend.barWidth
      this.legendShapeSizeFixed = layerDefault.legend.shapeSizeFixed
      this.autoUpdate = layerDefault.legend.autoUpdate
      this.imageOpacity = layerDefault.image.opacity
      this.iconRadius = layerDefault.typhoon.icon.radius
      this.lineWeight = layerDefault.typhoon.line.weight
      this.lineColor = layerDefault.typhoon.line.color
      this.stormWeight = layerDefault.typhoon.storm.weight
      this.stormColor = layerDefault.typhoon.storm.color
      this.galeWeight = layerDefault.typhoon.gale.weight
      this.galeColor = layerDefault.typhoon.gale.color
      this.trailKeep = layerDefault.typhoon.trail.keep
      this.trailOpacity = layerDefault.typhoon.trail.opacity
      this.shapeType = layerDefault.shape.shapeType
      this.numSpikes = layerDefault.shape.numSpikes
      this.clusterUsing = layerDefault.markerCluster.using
      this.clusterShowCoverageOnHover = layerDefault.markerCluster.showCoverageOnHover
      this.clusterZoomToBoundOnClick = layerDefault.markerCluster.zoomToBoundsOnClick
      this.clusterRadius = layerDefault.markerCluster.maxClusterRadius
      this.scaleValue = layerDefault.scale.value
      this.processRate = layerDefault.processRate
      this.ipSegment = layerDefault.processRateData && layerDefault.processRateData.segment ? layerDefault.processRateData.segment : 10
      this.progressValue = layerDefault.processRateData && layerDefault.processRateData.progressValue ? layerDefault.processRateData.progressValue : null
      this.progressMethod = layerDefault.processRateData && layerDefault.processRateData.progressMethod ? layerDefault.processRateData.progressMethod : null
      this.processRatePolygonValueColum = layerDefault.processRateData && layerDefault.processRateData.polygonValue ? layerDefault.processRateData.polygonValue : null
      this.processRateGeometryTypeValue = layerDefault.processRateData && layerDefault.processRateData.geometryType ? layerDefault.processRateData.geometryType : null
    },
    setRangeValueRadius() {
      if (Number(document.querySelector('#value-radius-typhoon').value) < 5) {
        document.querySelector('#value-radius-typhoon').value = 5
        this.iconRadius = 5
      }
      if (Number(document.querySelector('#value-radius-typhoon').value) > 50) {
        document.querySelector('#value-radius-typhoon').value = 50
        this.iconRadius = 50
      }
    },
    setMinvalueRadius(e) {
      if (Number(document.querySelector('#value-min-radius').value) < 0) {
        document.querySelector('#value-min-radius').value = 0
        this.rangeRadius[0] = 0
      }
      if (Number(document.querySelector('#value-min-radius').value) > Number(this.rangeRadius[1])) {
        document.querySelector('#value-min-radius').value = Number(this.rangeRadius[1])
        this.rangeRadius[0] = Number(this.rangeRadius[1])
      }
      if (Number(this.rangeRadius[0]) > Number(this.rangeRadius[1])) {
        this.rangeRadius[0] = Number(this.rangeRadius[1])
      }
    },
    setMaxvalueRadius() {
      if (Number(document.querySelector('#value-max-radius').value) > 500) {
        document.querySelector('#value-max-radius').value = 500
        this.rangeRadius[1] = 500
      }
      if (Number(document.querySelector('#value-max-radius').value) < Number(this.rangeRadius[0])) {
        document.querySelector('#value-max-radius').value = Number(this.rangeRadius[0])
        this.rangeRadius[1] = Number(this.rangeRadius[0])
      }

      if (Number(this.rangeRadius[1]) < Number(this.rangeRadius[0])) {
        this.rangeRadius[1] = Number(this.rangeRadius[0])
      }
      if (Number(this.rangeRadius[1]) > 500) this.rangeRadius[1] = 500
    },
    openModalThresholdValue() {
      this.$refs.refThresoldValue.openModal()
    },
    openLayerItemDeleteConfirm() {
      this.$refs.layerItemDeleteConfirm.open()
    },
    updateLayer(check = false) {
      this.updateLayerStore(check)
      this.$store.commit('map/CLEAR_CLICKING')
    },
    updateLayerStore(check = false) {
      //Update layer to store
      let dataTimeseries
      let item = this.item
      let dataTimeseriesShape
      if (this.type && this.type == 'multi') {
        dataTimeseriesShape = this.dataTimeseriesShape
        if (this.isShowItemShape) {
          dataTimeseries = null
        } else {
          dataTimeseries = this.dataTimeseriesShape
        }
      } else {
        dataTimeseries = this.dataTimeseries
      }
      if (this.isShowItemShape) {
        item = null
      }

      let dataCommit = {
        id: this.id,
        type: this.type,
        name: this.name,
        // inspectCount: ++this.inspectCount,

        dataPoint: this.dataPoint,
        dataImage: this.dataImage,
        dataTyphoon: this.dataTyphoon,
        dataMulti: this.dataMulti,
        dataNetcdf: this.dataNetcdf,
        dataTimeseries: dataTimeseries,
        idMultiSelected: this.dataSelected ? this.idMultiSelected : null,
        itemMultiSelected: this.dataSelected ? this.itemMultiSelected : null,
        dataTimeseriesShape: dataTimeseriesShape,
        item: item,

        fillOpacity: this.fillOpacity,
        fillColor: {
          mode: this.fillMode,
          color: this.fillColor,
          colors: this.fillColors,
          levels: this.fillLevels,
          individualThreshold: this.isFillIndividual,
          thresholds: this.fillThresholds,
        },

        weight: this.weight,
        color: this.color,

        radius: {
          min: this.rangeRadius[0],
          max: this.rangeRadius[1],
        },

        navalue: {
          visible: this.naVisible,
          radius: this.naRadius,
          weight: this.naWeight,
          color: this.naColor,
          fillOpacity: this.naOpacity,
          fillColor: this.naBackground,
        },

        tooltip: {
          visible: this.tooltipVisible,
          direction: this.tooltipDirection,
          permanent: this.tooltipPermanent,
          family: this.tooltipFamily,
          size: this.tooltipSize,
          color: this.tooltipColor,
          opacity: this.tooltipOpacity,
          backgroundColor: this.tooltipBackground,
        },

        popup: {
          visible: this.popupVisible,
          multiShow: this.popupMultiShow,
          type: this.popupType,
          backgroundColorFrom: this.popupBackgroundFrom,
          backgroundColorTo: this.popupBackgroundTo,
          lineColor: this.popupLineColor,
          family: this.popupFamily,
          headerSize: this.popupHeaderSize,
          headerColor: this.popupHeaderColor,
          contentSize: this.popupContentSize,
          contentColor: this.popupContentColor,
          headerColorBorderless: this.popupBorderlessHeader,
          contentColorBorderless: this.popupBorderlessContentColor,
        },

        legend: {
          visible: this.legendVisible,
          shapeVisible: this.legendShapeVisible,
          displayLayerName: this.legendDisplayLayerName,
          displayItem: this.legendDisplayItem,
          displayUnit: this.legendDisplayUnit,
          barWidth: this.legendBarWidth,
          shapeSizeFixed: this.legendShapeSizeFixed,
          autoUpdate: this.legendAutoUpdate,
        },

        image: {
          opacity: this.imageOpacity,
          corners: null,
        },

        typhoon: {
          icon: {
            radius: this.iconRadius,
          },
          line: {
            weight: this.lineWeight,
            color: this.lineColor,
          },
          storm: {
            weight: this.stormWeight,
            color: this.stormColor,
          },
          gale: {
            weight: this.galeWeight,
            color: this.galeColor,
          },
          trail: {
            keep: this.trailKeep,
            opacity: this.trailOpacity,
          },
        },

        shape: {
          shapeType: this.shapeType,
          numSpikes: this.numSpikes,
        },
        markerCluster: {
          using: this.clusterUsing,
          showCoverageOnHover: this.clusterShowCoverageOnHover,
          zoomToBoundsOnClick: this.clusterZoomToBoundOnClick,
          maxClusterRadius: this.clusterRadius,
        },

        scale: {
          value: this.scaleValue ? this.scaleValue.toLowerCase() : null,
        },

        processRate: this.processRate,
        processRateData: {
          segment: this.ipSegment,
          progressValue: this.processRageValueColumn,
          progressMethod: this.processRageMethod,
          polygonValue: this.processRatePolygonValueColum,
          geometryType: this.processRateGeometryTypeValue,
          progressDirection: this.progressDirection,
        },
      }
      if (this.type == 'multi' || this.type == 'point') {
        try {
          const groupItemsList = _.cloneDeep(this.groupItemsList)
          if (dataCommit.dataMulti) {
            if ((!this.layer.dataTimeseries && dataCommit.idMultiSelected != this.layer.idMultiSelected) || this.layer.dataTimeseries != dataCommit.dataTimeseries) {
              if (groupItemsList && groupItemsList.length) {
                for (let i = 0; i < groupItemsList.length; i++) {
                  if (groupItemsList[i].layerID == this.layer.id) {
                    this.$store.commit('groupItems/REMOVE_GROUP', groupItemsList[i])
                  }
                }
              }
              if (this.selectedGroupItems && this.selectedGroupItems.layerID == this.layer.id) {
                const map0 = (document.getElementById('leaflet-map-0') || {})._leaflet_map
                if (map0) {
                  map0.eachLayer((groupLayer) => {
                    if (groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.selectedGroupItems.layerID}_highlight-marker`) {
                      groupLayer.options.opacity = 0
                    }
                  })
                }
                const map1 = (document.getElementById('leaflet-map-1') || {})._leaflet_map
                if (map1) {
                  map1.eachLayer((groupLayer) => {
                    if (groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.selectedGroupItems.layerID}_highlight-marker`) {
                      groupLayer.options.opacity = 0
                    }
                  })
                }
                this.$store.commit('map/SET_SELECTED_GROUP', null)
                this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', null)
              }
            }
          } else {
            const map0 = (document.getElementById('leaflet-map-0') || {})._leaflet_map
            if (map0) {
              map0.eachLayer((groupLayer) => {
                if (groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.selectedGroupItems.layerID}_highlight-marker`) {
                  groupLayer.options.opacity = 0
                }
              })
            }
            const map1 = (document.getElementById('leaflet-map-1') || {})._leaflet_map
            if (map1) {
              map1.eachLayer((groupLayer) => {
                if (groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.selectedGroupItems.layerID}_highlight-marker`) {
                  groupLayer.options.opacity = 0
                }
              })
            }
            this.$store.commit('map/SET_SELECTED_GROUP', null)
            this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', null)
          }
        } catch {}
      }

      this.$store.commit('layer/UPDATE_LAYER', dataCommit)
      if (check) {
        // this.$store.commit('layer/INSPECT_LAYER', { layerId: this.layer.id });
        this.layer.firstCreate = false
        if (!this.layer.dataImage && !this.layer.dataMulti && !this.layer.dataNetcdf && !this.layer.dataPoint && !this.layer.dataTimeseries && !this.layer.dataTyphoon) {
          let messageTitle = 'save_layer'
          let messageLog = `: '${this.layer.name}'`
          let message = this.$t(messageTitle) + messageLog
          this.log.info({ message, layerId: this.layer.id, id: ACTION_LOG[17].id, messageTitle, messageLog })
        }
      }
      this.$toast({ component: ToastificationContent, props: { title: this.$i18n.t('save_config_layer_success'), icon: 'BellIcon', variant: 'success' } })
      this.$parent.$parent.$parent.$refs.modalConfigLayer.hide()
    },
    selectIconic(visible) {
      if (!visible) return
      let selects = [1, 2, 3, 4, 5, 6, 7, 8]
      this.$nextTick(() => {
        selects.forEach((n) => {
          if (this.$refs[`iconicSelect${n}`]) this.$refs[`iconicSelect${n}`].moveIndicator()
        })
      })
    },
    visibleLayerItem(layer) {
      let messageTitle = layer.visible[0] ? 'show_layer' : 'hide_layer'
      let messageLog = `: '${layer.name}'`
      let message = this.$t(messageTitle) + messageLog
      this.log.info({ message, id: ACTION_LOG[18].id, messageTitle, messageLog })
      this.$store.commit('layer/TOGGLE_VISIBLE', { layerId: layer.id, mapIndex: 0 })
    },
    openCollapse(nameRefs) {
      this.$nextTick(() => {
        try {
          this.$refs[nameRefs].moveIndicator()
        } catch {}
      })
    },
    updateDataLayer() {
      this.id = this.layer.id
      this.name = this.layer.name
      this.type = this.layer.type
      this.dataPoint = this.layer.dataPoint
      this.dataImage = this.layer.dataImage
      this.dataTyphoon = this.layer.dataTyphoon
      this.dataMulti = this.layer.dataMulti
      this.dataNetcdf = this.layer.dataNetcdf
      this.dataTimeseries = this.layer.dataTimeseries
      this.dataTimeseriesShape = this.layer.dataTimeseriesShape
      this.idMultiSelected = this.idMultiSelected
      this.itemMultiSelected = this.itemMultiSelected
      this.item = this.layer.item
      this.fillMode = this.layer.fillColor.mode
      this.fillColor = this.layer.fillColor.color
      this.fillColors = this.layer.fillColor.colors
      this.fillOpacity = this.layer.fillOpacity
      this.fillLevels = this.layer.fillColor.levels
      this.fillThresholds = this.layer.fillColor.thresholds
      this.weight = this.layer.weight
      this.color = this.layer.color
      this.rangeRadius = [this.layer.radius.min, this.layer.radius.max]
      this.naVisible = this.layer.navalue.visible
      this.naRadius = this.layer.navalue.radius
      this.naWeight = this.layer.navalue.weight
      this.naColor = this.layer.navalue.color
      this.naOpacity = this.layer.navalue.fillOpacity
      this.naBackground = this.layer.navalue.fillColor
      this.tooltipVisible = this.layer.tooltip.visible
      this.tooltipDirection = this.layer.tooltip.direction
      this.tooltipPermanent = this.layer.tooltip.permanent
      this.tooltipFamily = this.layer.tooltip.family
      this.tooltipSize = this.layer.tooltip.size
      this.tooltipColor = this.layer.tooltip.color
      this.tooltipOpacity = this.layer.tooltip.opacity
      this.tooltipBackground = this.layer.tooltip.backgroundColor
      this.popupVisible = this.layer.popup.visible
      this.popupMultiShow = this.layer.popup.multiShow
      this.popupType = this.layer.popup.type
      this.popupBackgroundFrom = this.layer.popup.backgroundColorFrom
      this.popupBackgroundTo = this.layer.popup.backgroundColorTo
      this.popupLineColor = this.layer.popup.lineColor
      this.popupFamily = this.layer.popup.family
      this.popupHeaderSize = this.layer.popup.headerSize
      this.popupHeaderColor = this.layer.popup.headerColor
      this.popupContentSize = this.layer.popup.contentSize
      this.popupContentColor = this.layer.popup.contentColor
      this.popupBorderlessHeader = this.layer.popup.headerColorBorderless
      this.popupBorderlessContentColor = this.layer.popup.contentColorBorderless
      this.legendVisible = this.layer.legend.visible
      this.legendShapeVisible = this.layer.legend.shapeVisible
      this.legendDisplayLayerName = this.layer.legend.displayLayerName
      this.legendDisplayItem = this.layer.legend.displayItem
      this.legendDisplayUnit = this.layer.legend.displayUnit
      this.legendBarWidth = this.layer.legend.barWidth
      this.legendShapeSizeFixed = this.layer.legend.shapeSizeFixed
      this.autoUpdate = this.layer.legend.autoUpdate
      this.imageOpacity = this.layer.image.opacity
      this.iconRadius = this.layer.typhoon.icon.radius
      this.lineWeight = this.layer.typhoon.line.weight
      this.lineColor = this.layer.typhoon.line.color
      this.stormWeight = this.layer.typhoon.storm.weight
      this.stormColor = this.layer.typhoon.storm.color
      this.galeWeight = this.layer.typhoon.gale.weight
      this.galeColor = this.layer.typhoon.gale.color
      this.trailKeep = this.layer.typhoon.trail.keep
      this.trailOpacity = this.layer.typhoon.trail.opacity
      this.shapeType = this.layer.shape.shapeType
      this.numSpikes = this.layer.shape.numSpikes
      this.clusterUsing = this.layer.markerCluster.using
      this.clusterShowCoverageOnHover = this.layer.markerCluster.showCoverageOnHover
      this.clusterZoomToBoundOnClick = this.layer.markerCluster.zoomToBoundsOnClick
      this.clusterRadius = this.layer.markerCluster.maxClusterRadius
      this.scaleValue = this.layer.scale.value
      this.oldName = this.layer.name
      this.processRate = this.layer.processRate
      this.ipSegment = this.layer.processRateData && this.layer.processRateData.segment ? this.layer.processRateData.segment : 10
      this.progressValue = this.layer.processRateData && this.layer.processRateData.progressValue ? this.layer.processRateData.progressValue : null
      this.progressMethod = this.layer.processRateData && this.layer.processRateData.progressMethod ? this.layer.processRateData.progressMethod : null
      this.processRatePolygonValueColum = this.layer.processRateData && this.layer.processRateData.polygonValue ? this.layer.processRateData.polygonValue : null
      this.processRateGeometryTypeValue = this.layer.processRateData && this.layer.processRateData.geometryType ? this.layer.processRateData.geometryType : null
    },
    openCollapseAddvanced() {
      this.moveCountIconicSelect++
    },
    activeConfigFigureColor(data) {
      if (data && data.mode == 'linear') {
        this.fillMode = data.mode
        this.fillColors = _.cloneDeep(data.data)
      }
      if (data && data.mode == 'solid') {
        this.fillMode = data.mode
        this.fillColor = data.data
      }
    },
  },
}
</script>

<style lang="scss">
.scale-layer .select-scale-layer .radio-container {
  border: none;
}
.scale-layer .select-scale-layer .radio-container label {
  margin: 0px;
  padding: 0px 6px;
}
.input-na-value .color-picker-container .color-picker {
  top: 100%;
  bottom: unset;
}
.input-na-value .color-picker-container .color-table {
  top: calc(100% + 10px);
  bottom: unset;
}
.dark-layout .layer-collapse-item.open .select-tilte {
  color: #fff;
}
.layer-collapse-item.open .select-tilte {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.layer-container_addvanced .card-header.collapsed .select-tilte {
  font-weight: 500;
  font-size: 15px;
}
.layer-container_addvanced .card-header.collapsed {
  max-width: 260px;
}
.layer-container {
  border-radius: 0.357rem;
  transition: background 0.25s linear;
  position: relative;
}
.layer-container.opening {
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dark-layout .layer-container.opening {
  background-color: #283046;
}

.layer-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.357rem;
  padding: 0;
}

.layer-item:hover,
.opening .layer-item {
  background: #f0f0f0;
}
.dark-layout .layer-item:hover,
.dark-layout .opening .layer-item {
  background: #161d31;
}

.layer-accordion {
  background: #fafafa;
}
.dark-layout .layer-accordion {
  background: #161d31;
}

.layer-item .layer-name {
  background-color: transparent !important;
  border-color: transparent !important;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  width: 60% !important;
}

.layer-item .layer-name:hover,
.layer-item .layer-name:focus {
  border-color: #404656 !important;
}

/* small layer type select => enough width for 6 type of layer */
.type-select label {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.handle {
  cursor: grab;
}
/* custom CSS for layer collapse */
.layer-collapse-item .card-header {
  padding: 5px 0 !important;
  border-radius: 0;
}
.layer-collapse-item .card-body {
  padding: 0 !important;
}

.layer-collapse-item .custom-control-label {
  margin-bottom: 1px !important;
  user-select: none;
  cursor: pointer;
}

.layer-collapse .layer-collapse-item {
  background-color: transparent !important;
  box-shadow: none !important;
}

.layer-collapse .layer-collapse-item .card-header:after {
  transform: rotate(-90deg) !important;
  right: 0;
}

.layer-collapse .layer-collapse-item.open .card-header:after {
  transform: rotate(0deg) !important;
}

.layer-collapse input {
  background-color: transparent !important;
}

.layer-collapse .vs__dropdown-toggle {
  background-color: transparent !important;
}

.layer-collapse .input-group-text {
  background-color: transparent !important;
}

.layer-collapse .v-select {
  flex-basis: 100%;
  min-width: 0;
}

.layer-collapse .input-group .vs__dropdown-toggle {
  transition: border-color 0.15s ease-in-out;
}

.layer-collapse .input-group .vs--open .vs__dropdown-toggle {
  border-color: #397bff;
}

.layer-collapse .form-layout {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: flex-end;
}
.layer-collapse .form-layout > * {
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
}
.layer-collapse .form-layout > :nth-child(2) {
  margin-left: 15px;
}
.layer-collapse .form-layout > .form-group {
  margin-bottom: 0;
}
.layer-collapse .form-layout .input-group {
  flex-wrap: nowrap;
}
.layer-collapse .form-layout .custom-switch {
  border: none;
  margin: 3px 0;
}
.layer-collapse .input-group .input-group-text {
  border: none;
  padding: 0 12px 0 0;
}
.layer-collapse .input-group > .form-control:not(:first-child) {
  border-radius: 0.357rem;
}

.layer-collapse .group-title {
  margin-bottom: 0;
  font-size: 11px;
  color: #397bff;
}

.layer-collapse .need-redraw {
  color: #ff9f43;
}

.threshold-setting {
  display: flex;
  justify-content: flex-end;
}
.threshold-setting svg {
  cursor: pointer;
}
.custom-checkbox-icon .switch-icon-left svg,
.custom-checkbox-icon .switch-icon-right svg {
  margin-bottom: 1px;
}
.custom-checkbox-icon .switch-icon-right.item,
.custom-checkbox-icon .switch-icon-right.layer-name,
.custom-checkbox-icon .switch-icon-right.unit {
  left: 22px;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
}

.netcdf-visualize-mode img {
  filter: hue-rotate(183deg);
}

// style new scss by dev18-quan
.modal.layer-config-modal {
  .modal-dialog {
    max-width: 1000px !important;
    width: 80%;
  }
}
.layer-container {
  &_addvanced {
    position: relative;
    .layer-container_addvanced_content {
      max-height: 500px;
      overflow-y: auto;
      padding-bottom: 10px;
      .layer-collapse-item {
        .card-header::after {
          display: none !important;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888888;
      }
    }
    .layer-collapse-item {
      .card-header::after {
        // right: 0 !important;
        left: 215px !important;
        margin-top: -7px;
      }
    }
  }
  .multi-range {
    flex: 1;
    max-width: inherit !important;
    padding: 0 8px !important;
    .vue-slider-dot-tooltip-inner {
      background: #6c757d !important;
      border-color: #6c757d !important;
    }
  }
}
.layer-container_configuration {
  .layer-collapse-item {
    .card-header::after {
      display: none !important;
    }
  }
}
@media (max-width: 1160px) {
  .modal.layer-config-modal .modal-dialog {
    width: 90%;
  }
}
@media (max-width: 992px) {
  .modal.layer-config-modal .modal-dialog {
    .layer-container_configuration_left {
      width: 60%;
      flex: inherit;
    }
    .layer-container_configuration_right {
      flex: inherit;
      width: 40%;
    }
  }
}
@media (max-width: 850px) {
  .modal.layer-config-modal .modal-dialog {
    .layer-container_configuration_left {
      width: 50%;
      flex: inherit;
    }
    .layer-container_configuration_right {
      flex: inherit;
      width: 50%;
    }
  }
  .config_figure-shape-radius {
    flex-wrap: wrap;
    .config_figure-shape-radius_left {
      width: 100%;
      flex: inherit !important;
      margin-right: 0px !important;
    }
    .config_figure-shape-radius_right {
      width: 100%;
      flex: inherit !important;
      .cf-right-figure-shape {
        margin-left: 7px;
      }
    }
  }
}
@media (max-width: 768px) {
  .modal.layer-config-modal .modal-dialog {
    .layer-container_configuration {
      flex-wrap: wrap;
    }
    .layer-container_configuration_left {
      width: 100%;
      flex: inherit;
      margin-right: 0px;
    }
    .layer-container_configuration_right {
      flex: inherit;
      width: 100%;
      margin-top: 1rem;
    }
  }
  .layer-container_addvanced .layer-container_addvanced_content {
    max-height: 200px;
    flex-wrap: wrap !important;
    &_left {
      width: 100% !important;
      margin-right: 0 !important;
    }
    &_right {
      width: 100% !important;
    }
  }
}
.config_figure-color {
  .sample-palette {
    height: 194px !important;
  }
  .live-edit {
    .form-group {
      margin-bottom: 5px !important;
    }
  }
  .color-picker-sm {
    height: 22px !important;
    width: 22px !important;
  }
}
.datasource-collapse-item {
  .form-group {
    & > div {
      width: 100% !important;
    }
  }
}
.layer-container {
  fieldset {
    & > div {
      width: 100%;
    }
  }
}
</style>

<style scoped lang="scss">
.min-max-setting {
  color: #ff9f43;
}
.color-picker-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: default;
}
.color-picker-container .color-display {
  position: absolute;
  height: calc(100% - 10px);
  top: 5px;
  right: 5px;
  pointer-events: none;
  width: 29px;
}
.color-picker-container.color-picker-sm .color-display {
  width: 20px;
}

// style new scss by dev18-quan
.layer-container {
  &_configuration {
    display: flex;
    flex-wrap: nowrap;
    &_left {
      flex: 7;
      margin-right: 20px;
      .config_figure-shape-radius {
        margin-top: 5px;
        &_left {
          flex: 1;
          margin-right: 12px;
        }
        &_right {
          flex: 1;
          .cf-right-figure-shape {
            display: flex;
            align-items: center;
            .shape-radius-range {
              display: flex;
              align-items: center;
              flex: 1;
              margin-left: 5px;
              span {
                margin-top: -4px;
                padding: 0 4px;
                display: inline-block;
                // min-width: 30px;
                &.span-min {
                  text-align: right;
                  width: 32px;
                }
                &.span-max {
                  text-align: left;
                  width: 40px;
                }
              }
            }
          }
        }
      }
      .cf-figure-shape {
        .input-group-text {
          padding: 5px 10px;
        }
        &1 {
          flex: 1;
        }
        &2 {
          max-width: 120px;
          margin-right: 2px;
        }
      }
    }
    &_right {
      flex: 4;
      .b-form-group-figure-color {
        .input-group-text {
          border: none;
          padding: 0 8px 0 15px;
        }
        input {
          height: 24px;
          padding: 5px;
          border-radius: 6px;
        }
      }
    }
  }
  &_addvanced {
    margin-top: 10px;
    &_content {
      display: flex;
      padding-bottom: 22px;
      &_left {
        width: 50%;
        margin-right: 20px;
      }
      &_right {
        width: 50%;
      }
      &_items {
        flex: 1;
      }
      & > .tabs {
        width: 100%;
      }
    }
    &.typhoon {
      .layer-container_addvanced_content {
        .layer-container_addvanced_content_left {
          order: 2 !important;
          margin-right: 0px;
        }
        .layer-container_addvanced_content_right {
          order: 1 !important;
          margin-right: 20px;
        }
      }
    }
  }
}
.config_figure-color {
  margin-top: 10px;
  display: flex;
  &_left {
    width: 60px;
    .label-color {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 2px;
      cursor: pointer;
    }
    .ip-label-color {
      svg {
        margin-left: 5px;
        color: green;
      }
    }
  }
  &_right {
    flex: 1;
  }
}
.addvanced_visibled {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .layer-collapse-item {
    margin-right: 10px;
    margin-top: 10px;
  }
}
.ip-rename-layer {
  flex: 1;
  margin-left: 10px;
  input {
    width: 100%;
  }
  .layer-name.select-layer {
    border: none;
    background-color: transparent;
    padding-left: 0px;
    font-size: 14px;
    width: 100%;
    color: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: solid 0.01px transparent;
    transition: all linear 0.2s;
    &:focus,
    &:hover {
      border: solid 0.01px #397bff;
      padding-left: 5px;
    }
  }
}
.btn-save-config {
  position: absolute;
  bottom: 0px;
  right: 0px;
  button {
    padding: 10px 20px !important;
  }
}
.add-id-item-multi {
  &-header {
    margin-left: 27px;
    margin-top: 5px;
    margin-bottom: 2px;
  }
  &-content {
    &-icon {
      width: 18px;
      height: 18px;
    }
  }
}
.process-rate-icon {
  margin-right: 6px;
}
.process-rate-svm {
  display: flex;
  flex-wrap: wrap;
  &-item {
    // width: 33.3333333%;
    width: 25%;
    min-width: 300px;
    &-content {
    }
    &-segment {
      text-align: left;
      &-ip {
        flex: 1;
        .input-group {
          display: flex;
          flex-wrap: no-wrap !important;
        }
        input {
          // width: 230px;
        }
      }
      &-split {
      }
    }
    &-value-column {
      display: flex;
      justify-content: center;
      &-vs {
        flex: 1;
        overflow: hidden;
        .v-select {
          // width: 230px;
        }
      }
    }
    &-method {
      display: flex;
      justify-content: flex-end;
      &-vs {
        flex: 1;
        .v-select {
          // width: 230px;
        }
      }
    }
  }
}
@media (max-width: 1173px) {
  .process-rate-svm {
    &-item {
      &-method {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}
</style>
<style lang="scss">
.add-id-item-multi-content {
  .vs__dropdown-toggle {
    height: 30px !important;
    padding: 0 !important;
  }
  .vs__dropdown-menu {
    max-height: 150px !important;
  }
}
.v-select-attr-shape {
  input,
  span {
    font-size: 0.75rem !important;
  }
  .vs__selected-options {
    align-items: center;
  }
}

.layer-tab-item-content {
}
.b-tabs-layer-config {
  .nav-item:first-child {
    .nav-link {
      padding-left: 0;
    }
  }
}
.process-preview-multi-range {
  // max-width: calc(100% - 12px);
  width: 100%;
  padding: 0 21px;
  .custom-mark {
    position: absolute;
    top: 8px;
    transform: translateX(-50%);
    white-space: nowrap;
    &.custom-mark-0 {
      width: 1px;
      height: 8px;
      background: #6e6b7b;
    }
    &.custom-mark-1 {
      .custom-mark-icon {
        width: 2px;
        height: 12px;
        background: #6e6b7b;
      }
    }
  }
}
.process-rate-image-table {
  border-collapse: collapse;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  tr td {
    &:nth-child(2) {
      width: 120px;
      text-align: center;
    }
    &:nth-child(3) {
      width: 120px;
      text-align: right;
      cursor: pointer;
    }
  }
  tr th {
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
.fz-14px {
  font-size: 14px;
}
.process-preview-multi-range-divvs {
  pointer-events: none !important;
}
.custom-tooltip-preview-image {
  // border:solid 1px #ccc;
  // color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 45px;
  background: #d6ecff !important;
  border-color: #d6ecff !important;
  pointer-events: none !important;
  img {
    max-width: 55px;
    max-height: 45px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.custom-tooltip-dot-preview-image {
  position: absolute;
  bottom: calc(100% + 20px);
  left: calc(50% - 4px);
  transform: translateX(-50%);
  padding: 3px !important;
}

// style mới cập nhật
.process-rate-svm-item-border {
  padding: 1px;
  &:not(:last-child) {
    padding-right: 15px;
  }
  &-main {
    // border: solid 1px #ccc;
  }
  &-geometry-type {
  }
  &-segment {
  }
  &-progess-column {
  }
  &-method {
  }
}
@media (max-width: 992px) {
  .process-rate-svm-item-border {
    padding-right: 15px;
    &-geometry-type {
      order: 1;
    }
    &-segment {
      order: 3;
    }
    &-value-column-polygon {
      order: 3;
    }
    &-progress-column {
      order: 2;
    }
    &-method {
      order: 4;
    }
  }
  .process-rate-svm {
    &-item {
      &-segment {
        &-ip {
          flex: none !important;
          input {
            max-width: 180px;
          }
        }
      }
      &-value-column {
        &-vs {
          .v-select {
            max-width: 180px;
          }
        }
      }
      &-method {
        &-vs {
          .v-select {
            max-width: 180px;
          }
        }
      }
    }
  }
}
</style>
