<template>
  <div>
    <b-modal :title="$t('add_multi_layer')" hide-footer centered ref="modalConfigLayer" :body-class="'custom-body tools-body'" modal-class="layer-config-modal">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between flex-grow-1 modal-title">
          <h4 class="modal-title d-flex align-items-center justify-content-between">
            <feather-icon icon="ToolIcon" size="20" />
            <label class="layer-name-config" >{{ $t('config_layer') }}</label>
          </h4>
        </div>
        <button type="button" class="close" :title="$t('close')" @click="close">&times;</button>
      </template>
      <LayerItem :layer="layer" />
    </b-modal>
  </div>
</template>

<script>
import LayerItem from './LayerItem.vue';
export default {
  props: ['layer'],
  components: { LayerItem },
  data() {
    return {
      oldName: this.layer.name,
    };
  },
  methods: {
    open() {
      this.$refs.modalConfigLayer.show();
    },
    close() {
      this.$refs.modalConfigLayer.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-layer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 420;
}
</style>
<style lang="scss">
.layer-config-modal {
  .layer-container {
    box-shadow: none !important;
    background: inherit !important;
    .layer-item,
    .layer-accordion {
      background: inherit !important;
      padding: 0 !important;
    }
  }
  .modal-title {
    width: 100%;
    svg {
      margin-right: 5px;
      margin-top: 2px;
    }
    .layer-name-config {
      border: none;
      background-color: transparent;
      padding-left: 0px;
      font-size: 18px;
      width: 100%;
      color: inherit;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.vs__dropdown-menu {
  z-index: 9999 !important;
}
</style>
