<template>
  <div class="radio-group" v-if="options.length">
    <div class="radio-container" ref="radioContainer">
      <template v-for="(option, index) in options">
        <label :title="option.title" :for="`input-${radioID}-${index}`" :key="`label-${radioID}-${index}`" ref="radioLabel" :style="{ cursor: readonly === true ? 'default' : 'pointer' }">
          <component :is="option.icon" :style="{ color: checked === option.value ? 'inherit' : '#808080', width: '18px', height: '18px' }" v-if="option.icon && !iconImage" />
          <img :src="option.icon" :style="{ width: '18px', height: '18px' }" v-if="option.icon && iconImage" />
          <span :style="{ color: checked === option.value ? 'inherit' : '#808080' }" v-else>{{ option.text }}</span>
        </label>
        <input :id="`input-${radioID}-${index}`" type="radio" :key="`input-${radioID}-${index}`" :value="option.value" v-model="checked" />
      </template>
      <div class="indicator" v-show="checked !== null" :style="{ left: left + 'px', width: width + 'px' }"></div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    options: {
      default: null,
    },
    value: {
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    iconImage: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    moveCount: {
      type: String,
      default: 'move-count-null',
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.moveIndicator();
    });
  },
  data() {
    return {
      left: 0,
      width: 0,
      radioID: uuidv4(),
    };
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.readonly) return;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    checked() {
      this.$nextTick(() => {
        this.moveIndicator();
      });
    },
    moveCount() {
      this.moveIndicator();
    },
  },
  methods: {
    moveIndicator() {
      try {
        setTimeout(() => {
          if (!this.checked) return;
          if (!this.$refs.radioContainer || !this.$refs.radioLabel) return;
          let index = this.options.findIndex((option) => option.value === this.checked);
          let radioRect = this.$refs.radioContainer.getBoundingClientRect();
          let labelRect = this.$refs.radioLabel[index].getBoundingClientRect();
          this.left = labelRect.left - radioRect.left;
          this.width = labelRect.width;
        }, 50);
      } catch {}
    },
  },
};
</script>

<style scoped>
.radio-group {
  width: fit-content;
}

.radio-container {
  border-radius: 0.357rem;
  display: flex;
  position: relative;
  user-select: none;
}

.radio-container input {
  display: none;
}

.radio-container label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin: 0;
  padding: 0 6px;
  border-radius: 0.357rem;
  white-space: nowrap;
  width: fit-content;
}

.radio-container label:last-of-type {
  margin-right: 0;
}

.radio-container .indicator {
  position: absolute;
  height: 30px;
  background-color: rgba(57, 123, 255, 0.12);
  border-radius: 0.357rem;
  pointer-events: none;
  transition: left 0.25s, width 0.25s;
}

.radio-container svg,
.radio-container span {
  transition: color 0.25s;
}
</style>
