<template>
  <div class="popup-container" :style="{ top: position.y + 'px', left: position.x + 'px', fontFamily: layer.popup.family, background: gradient }">
    <div class="popup-content" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }">
      <div v-if="layer.popup.multiShow" style="text-align: right">
        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="closePopup(clicking.layerId, clicking.clickId, clicking.clickUid, layer.type)" />
      </div>
      <div class="popup-info">
        <span>Date</span>
        <span>{{ location.summary.date }}</span>
      </div>
      <div class="popup-info">
        <span>Position</span>
        <span>{{ location.latlng[0] }}, {{ location.latlng[1] }}</span>
      </div>
      <div class="popup-info">
        <span>Pressure</span>
        <span>{{ location.summary.pressure }}hPa</span>
      </div>
      <div class="popup-info">
        <span>Wind</span>
        <span>{{ Math.floor(location.summary.wind / 1.944) }}m/s ({{ location.summary.wind }}kt)</span>
      </div>
      <div class="popup-info" v-if="location.summary.storm">
        <span>Storm Radius</span>
        <span>{{ Math.floor(location.summary.storm * 1.852) }}km ({{ location.summary.storm }}nm)</span>
      </div>
      <div class="popup-info" v-if="location.summary.gale">
        <span>Gale Radius</span>
        <span>{{ Math.floor(location.summary.gale * 1.852) }}km ({{ location.summary.gale }}nm)</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['map', 'moveCount', 'layer', 'leafletLayer', 'clicking', 'mapIndex'],
  computed: {
    location() {
      return this.leafletLayer.getLayers().find((l) => l.type === 'marker' && l.date === this.clicking.clickId);
    },
    position() {
      this.moveCount; // position cũng compute lại khi move map
      return this.map.latLngToContainerPoint(this.location.latlng);
    },
    gradient() {
      let color1 = this.layer.popup.backgroundColorFrom;
      let color2 = this.layer.popup.backgroundColorTo;
      return 'linear-gradient(to bottom, ' + color1 + ', ' + color2 + ')';
    },
  },
  methods: {
    closePopup(layerId, clickId, clickUid, type) {
      const storeData = {
        data: { layerId, clickId, clickUid, type },
        mapIndex: this.mapIndex,
      };
      this.$store.commit('map/CLOSE_POPUP', storeData);
    },
  },
};
</script>

<style scoped>
.popup-container {
  width: fit-content;
  padding: 10px;
  min-width: 240px;
  position: absolute;
  z-index: 401;
  white-space: nowrap;
  box-shadow: 0 3px 6px 1px rgb(0 0 0 / 15%);
  border-radius: 0.428rem;
}

.popup-container .popup-content {
  user-select: none;
}

.popup-container .popup-info {
  display: flex;
  justify-content: space-between;
}

.popup-container .popup-info span:first-child {
  font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}
</style>
