<template>
  <div class="card tile-setting html2canvas-ignore">
    <b-img fluid rounded :src="require(`/static/images/${mapSelected.image}.png`)" :title="$t('background_selection_panel')" :alt="$t('background_selection_panel')" class="tile-image" />
    <div class="tile-panel">
      <div class="tile-panel-bound card">
        <b-card class="tile-card mb-0" :id="`mini-tile-card-${mapIndex}`">
          <b-img fluid v-for="map in mapList" :key="map.url" :src="require(`/static/images/${map.image}.png`)" :title="$t(map.name)" :alt="$t(map.name)" class="tile-option" @click="$emit('input', map)" rounded />
          <div class="div-add-width"></div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { MAP_TILES } from '@/constants/app.js'

export default {
  props: ['value', 'mapIndex'],
  data() {
    return {
      mapList: MAP_TILES,
    }
  },
  mounted() {
    this.$nextTick(() => {
      let mapDom = document.getElementById(`leaflet-map-${this.mapIndex}`)
      if (mapDom) {
        new ResizeObserver(() => {
          try {
            let boudMap = mapDom.getBoundingClientRect()
            let widthMap = boudMap.width
            let maxWidthTile = widthMap - 150
            let titleCartDom = document.getElementById(`mini-tile-card-${this.mapIndex}`)
            titleCartDom.style.maxWidth = `${maxWidthTile}px`
            titleCartDom.style.overflowX = `auto`
          } catch {}
        }).observe(mapDom)
      }
    })
  },
  computed: {
    mapSelected() {
      return this.value
    },
  },
}
</script>

<style scoped lang="scss">
.tile-setting {
  display: flex;
  position: absolute;
  bottom: 26.5px;
  right: 10px;
  z-index: 418;
  cursor: default;
  padding: 4px;
  /* background-color: #ffffff; */
  border-radius: 0.428rem;
  opacity: 0.8;
  transition: opacity 0.25s;
  margin-bottom: 0;
}

/* .dark-layout .tile-setting {
  background-color: #283046;
} */

.tile-setting .tile-image {
  width: 52px;
  height: 52px;
  cursor: pointer;
}

.tile-setting:hover {
  opacity: 1;
}

.tile-setting .tile-panel {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 10px;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: 0px 40px;
  transition: transform 0.25s, opacity 0.25s;
}
.tile-setting .tile-panel .tile-card {
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

.tile-setting:hover .tile-panel {
  opacity: 1;
  transform: scaleX(-1);
}

.tile-setting .tile-card .card-body {
  padding: 10px;
  display: flex;
}

.tile-setting .tile-option {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.tile-setting .tile-option:hover {
  box-shadow: 0 0 0 2px #397bff;
}

.tile-setting .tile-option:last-child {
  margin-right: 0;
}
.tile-panel-bound {
  padding-left: 10px;
  .tile-card {
    padding-left: 2px;
    padding-right: 2px;
  }
}
.tile-panel {
  .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.div-add-width {
  width: 1px;
  height: 1px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  flex-shrink: 0;
}
#mini-tile-card {
  &-0,
  &-1 {
    width: 506px;
  }
}
</style>
