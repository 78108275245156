<template>
  <div class="palette-picker-container palette-picker-v3">
    <div class="palette-picker">
      <div class="live-edit">
        <b-form-group>
          <div class="d-flex">
            <div class="icon-solid-sample-palette" :class="{ active: mode == 'solid' && fillColor == colors[0] }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
            <ColorPicker :value="colors[0]" @input="colors.splice(0, 1, $event)" placeholder="Color 1" size="sm" :key="colorPickerKey" :left="'-20px'" @clickBtnColorSolid="clickColorInput(colors[0])" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex">
            <div class="icon-solid-sample-palette" :class="{ active: mode == 'solid' && fillColor == colors[1] }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
            <ColorPicker :value="colors[1]" @input="colors.splice(1, 1, $event)" placeholder="Color 2" size="sm" :key="colorPickerKey" :left="'-20px'" @clickBtnColorSolid="clickColorInput(colors[1])" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex">
            <div class="icon-solid-sample-palette" :class="{ active: mode == 'solid' && fillColor == colors[2] }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
            <ColorPicker :value="colors[2]" @input="colors.splice(2, 1, $event)" placeholder="Color 3" size="sm" :key="colorPickerKey" :left="'-20px'" @clickBtnColorSolid="clickColorInput(colors[2])" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex">
            <div class="icon-solid-sample-palette" :class="{ active: mode == 'solid' && fillColor == colors[3] }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
            <ColorPicker :value="colors[3]" @input="colors.splice(3, 1, $event)" placeholder="Color 4" size="sm" :key="colorPickerKey" :left="'-20px'" @clickBtnColorSolid="clickColorInput(colors[3])" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex">
            <div class="icon-solid-sample-palette" :class="{ active: mode == 'solid' && fillColor == colors[4] }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
            <ColorPicker :value="colors[4]" @input="colors.splice(4, 1, $event)" placeholder="Color 5" size="sm" :key="colorPickerKey" :left="'-20px'" @clickBtnColorSolid="clickColorInput(colors[4])" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex">
            <div class="icon-solid-sample-palette" :class="{ active: mode == 'solid' && fillColor == colors[5] }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
            <ColorPicker :value="colors[5]" @input="colors.splice(5, 1, $event)" placeholder="Color 6" size="sm" :key="colorPickerKey" :left="'-20px'" @clickBtnColorSolid="clickColorInput(colors[5])" />
          </div>
        </b-form-group>
      </div>
      <div class="sample-palette">
        <div class="custom-scrollbar">
          <div v-for="(sample, index) in samples" :key="index" class="d-flex align-items-center s">
            <div class="sample-palette-item" :style="{ flex: 1, background: `linear-gradient(to right, ${sample[0]} 0%, ${sample[0]} 16.67%, ${sample[1]} 16.67%, ${sample[1]} 33.33%, ${sample[2]} 33.33%, ${sample[2]} 50%, ${sample[3]} 50%, ${sample[3]} 66.67%, ${sample[4]} 66.67%, ${sample[4]} 83.33%, ${sample[5]} 83.33%, ${sample[5]} 100%)` }" @click="applySample(sample)"></div>
            <div class="icon-linear-sample-palette" :class="{ active: mode == 'linear' && checkArrColor(fillColors, sample) }">
              <feather-icon icon="CheckCircleIcon" size="13" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ColorPicker from './ColorPicker.vue';
import { PALETTE_PICKER } from '@/constants/colors';

export default {
  props: ['placeholder', 'size', 'value', 'mode', 'fillColor', 'fillColors','flagColorPalette'],
  components: { ColorPicker, VuePerfectScrollbar },
  data() {
    return {
      colors: this.value,
      samples: PALETTE_PICKER,
      colorPickerKey: 1,
    };
  },
  watch: {
    colors(colors) {
      this.$emit('input', colors);
    },
    flagColorPalette(){
      this.colors = this.value
    },
  
  },
  methods: {
    applySample(sample) {
      this.colors = sample.slice();
      this.colorPickerKey++;
      this.$emit('activeColorSolid', { mode: 'linear', data: this.colors });
    },
    clickColorInput(color) {
      this.$emit('activeColorSolid', { mode: 'solid', data: color });
    },
    checkArrColor(arrColor1, arrColor2) {
      try {
        if (arrColor1.length && arrColor1.toString() === arrColor2.toString()) return true;
        return false;
      } catch {
        return false;
      }
    },
  },
};
</script>

<style>
.palette-picker-container .ColorPicker-container input {
  border-radius: 0.357rem !important;
}
</style>

<style scoped lang="scss">
.palette-picker-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: default;
  outline: none;
}

.palette-picker-container .palette-picker {
  display: flex;
  right: 0;
  top: 100%;
  z-index: 2;
  border-radius: 4px;
  background: transparent !important;
}

.palette-picker .live-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  max-width: 45px;
  padding-top: 3px;
}
.palette-picker .live-edit .form-group:last-child {
  margin-bottom: 0;
}

.palette-picker .sample-palette {
  flex: 1;
  height: 230px;
}

.palette-picker .custom-scrollbar {
  height: 100%;
  padding: 3px 5px 0 5px;
  overflow-y: auto;
}

.palette-picker .sample-palette-item {
  height: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.palette-display {
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 12px;
  right: 12px;
  pointer-events: none;
}
.icon-linear-sample-palette {
  margin-top: -10px;
  margin-left: 5px;
  color: #0fb358;
  opacity: 0;
  display: flex;
  &.active {
    opacity: 1;
  }
}
.icon-solid-sample-palette {
  margin-right: 4px;
  opacity: 0;
  color: #0fb358;
  &.active {
    opacity: 1;
  }
}
</style>
