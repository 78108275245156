<template>
  <Draggable v-bind="dragOptions" tag="div" class="item-container" :list="list" handle=".handle" :move="checkMove" :animation="200" :value="value" @input="emitter" @start="drag = true" @end="endDrag">
    <div :class="[`sk-${el.id}`, 'item-group']" :key="el.id" v-for="el in realValue">
      <GroupItem v-if="el.isGroup" class="item" :group="el" :move="checkMove" :layerMove="layerMove" @contextmenu="openMenu('group', el, $event)" />
      <LayerComponent v-if="!el.isGroup" class="item" :layer="el" :move="checkMove" :layerMove="layerMove" @contextmenu="openMenu('layer', el, $event)" />
      <GroupLayers class="item-sub" v-if="el.groupData != null" :list="el.groupData" @contextmenu="openMenuSub" />
    </div>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import LayerComponent from './LayerComponent.vue';
import GroupItem from './GroupItem.vue';
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
  components: { Draggable, GroupItem, LayerComponent },
  name: 'GroupLayers',
  data() {
    return {
      drag: false,
      layerMove: '',
    };
  },
  computed: {
    dragOptions() {
      return {
        group: 'folderlayers',
        put: () => true,
        pull: false,
      };
    },
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  watch: {
    drag(value) {
      if (!value) this.layerMove = '';
    },
  },
  methods: {
    endDrag(evt) {
      this.drag = false;
      let elementFrom = evt.from;
      let elementTo = evt.to;
      let nameLayerDrag = this.nameLayerDrag;
      if (elementFrom.classList.contains('item-sub') && elementTo.classList.contains('item-sub')) {
        let nameGroupFrom = elementFrom.parentElement.children[0].children[0].children[1].value;
        let nameGroupTo = elementTo.parentElement.children[0].children[0].children[1].value;

        let messageTitle = 'move_from_to';
        let message = this.$t(messageTitle, { move: nameLayerDrag, from: nameGroupFrom, to: nameGroupTo });

        this.log.info({ message, id: ACTION_LOG[11].id, messageTitle, move: nameLayerDrag, from: nameGroupFrom, to: nameGroupTo });
      } else {
        let messageTitle = 'move_to';
        if (!elementFrom.classList.contains('item-sub') && elementTo.classList.contains('item-sub')) {
          let nameGroupTo = elementTo.parentElement.children[0].children[0].children[1].value;
          let message = this.$t(messageTitle, { move: nameLayerDrag, to: nameGroupTo });
          this.log.info({ message, id: ACTION_LOG[11].id, messageTitle, move: nameLayerDrag, to: nameGroupTo });
        } else {
          let message = this.$t(messageTitle, { move: nameLayerDrag });
          if (elementFrom.classList.contains('item-sub') && !elementTo.classList.contains('item-sub')) {
            this.log.info({ message, id: ACTION_LOG[11].id, messageTitle, move: nameLayerDrag });
          }
        }
      }
    },
    emitter(value) {
      value.map((val) => {
        // If draging item is 'group' then put to last
        if (val.isGroup) {
          let idx = val.groupData.findIndex((gr) => gr.isGroup);
          if (idx !== -1) {
            value.splice(this.oldIndexDrag, 0, val.groupData[idx]);
            val.groupData.splice(idx, 1);
          }
        }
      });
      this.$emit('input', value);
    },
    checkMove(evt) {
      this.$store.commit('layer/SET_STATUS_LAYER_MOVE', true);
      this.layerMove = evt.draggedContext.element.id;
      this.nameLayerDrag = evt.draggedContext.element.name;
    },
    groupStartDrag(evt) {
      this.drag = true;
      this.oldIndexDrag = evt.oldIndex;
    },
    openMenu(method, layer, $event) {
      this.$emit('openContextMenu', { method, layer, event: $event });
      this.$emit('contextmenu', { method, layer, event: $event });
    },
    openMenuSub($event) {
      this.$emit('openContextMenu', $event);
    },
  },
};
</script>

<style scoped>
.item-sub {
  margin: 0 0 0 16px;
}
.item-group {
  max-height: 100%;
}
.sortable-ghost {
  border: 1px dashed #397bff;
  background-color: rgba(57, 123, 255, 0.12) !important;
  opacity: 0.69;
  z-index: 10;
}
</style>
<style lang="scss">
.context-menu {
  visibility: hidden;
  opacity: 0;
  &.vis-opa {
    visibility: visible;
    opacity: 1;
  }
}
</style>
