<template>
  <b-dropdown-item link-class="d-flex align-items-center" @click="exportPng">
    <feather-icon size="16" icon="ImageIcon" class="mr-50" />
    <span>{{ $t('as_png') }}</span>
  </b-dropdown-item>
</template>

<script>
import CaptureMixin from '@/mixins/CaptureMixin.js'
import { BDropdownItem } from 'bootstrap-vue'
import html2canvas from 'html2canvas'

export default {
  mixins: [CaptureMixin],
  components: { BDropdownItem },
  methods: {
    async exportPng() {
      try {
        let elementCapture
        try {
          elementCapture = document.getElementsByClassName('map-list')[0]
        } catch {
          elementCapture = document.querySelector('.content-body')
        }
        html2canvas(elementCapture, { backgroundColor: null, useCORS: true }).then((canvas) => {
          canvas.toBlob(async (blob) => {
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'Map.png'
            a.click()
            URL.revokeObjectURL(url)
          })
        })
      } catch {}
    },
  },
}
</script>
