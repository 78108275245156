<template>
  <div class="legend-shape-container" ref="legend" :style="{ visibility: layer.visible[0] && layer.legend.visible ? 'visible' : 'hidden', top: top + 'px' }">
    <b-card>
      <template #header v-if="layer.legend.displayLayerName || layer.legend.displayItem || (layer.legend.displayUnit && item.unit)">
        <div class="legend-header" v-if="layer.legend.displayLayerName" :title="layer.name">{{ layer.name }}</div>
        <div class="legend-header" v-if="item">
          <span class="text-primary" v-if="layer.legend.displayItem">{{ nameLegend }}</span>
          <small class="text-secondary" v-if="layer.legend.displayUnit && item.unit">{{ item.unit ? `(${item.unit})` : '' }}</small>
        </div>
      </template>
      <div class="legend-body">
        <div v-if="['linear', 'solid'].includes(layer.fillColor.mode)" :style="{ display: 'flex' }">
          <div class="legend-shape-list">
            <component v-for="(shape, index) in Array(6)" :key="index" :layer="layer" :is="layerShape" :background="rangeColors[index]" :opacity="layer.fillOpacity" :radius="rangeShapeSizes[index]" :border="layerBorderStyles" :spikes="layer.shape.numSpikes" />
          </div>
          <div class="legend-shape-value">
            <div class="legend-shape-value-text" :style="textStyles(index)" v-for="(value, index) in rangeValues" :key="index">
              {{ value }}
            </div>
          </div>
        </div>
        <div v-if="layer.fillColor.mode === 'threshold'" :style="{ display: 'flex' }">
          <div class="legend-shape-list">
            <div class="legend-shape-item" v-for="(color, index) in rangeThresholdColors" :key="index" :class="rangeThreshold.length ? `shape-4` : `shape-${5 - index}`">
              <component :style="{ color: color }" :is="layerShape" />
            </div>
          </div>
          <div class="legend-shape-value">
            <div class="legend-shape-value-text" v-for="(value, index) in rangeThresholdValues" :key="index">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
const _ = require('lodash');
import interact from 'interactjs';
import MathMixin from '@/mixins/MathMixin';
import * as SHAPES from '@/constants/shapes';
import ShapeCircle from './shapes/Circle';
import ShapeSquare from './shapes/Square';
import ShapeTriangle from './shapes/Triangle';
import ShapeSpikes from './shapes/Spikes';
import { scaleLog10, scaleLog10Plus, scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js';

export default {
  props: ['layer', 'index'],
  components: { ShapeCircle, ShapeSquare, ShapeTriangle, ShapeSpikes },
  mixins: [MathMixin],
  mounted() {
    // set CSS top
    this.top += this.index * 210;

    this.$nextTick(() => {
      this.draggable = interact(this.$refs.legend).draggable({
        inertia: false,
        allowFrom: '.card',
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: document.querySelector('.map-container .map-list'),
            endOnly: true,
          }),
        ],
        listeners: {
          move(event) {
            let x = (parseFloat(event.target.dataset.x) || 0) + event.dx;
            let y = (parseFloat(event.target.dataset.y) || 0) + event.dy;
            event.target.style.transform = `translate(${x}px, ${y}px)`;
            Object.assign(event.target.dataset, { x, y });
          },
        },
      });
      new ResizeObserver(() => {
        this.draggable.reflow({ name: 'drag', axis: 'xy' });
      }).observe(document.querySelector('.map-container .map-list'));

      // init first
      let x = this.layer.legend.position.x;
      let y = this.layer.legend.position.y;
      this.$refs.legend.style.transform = `translate(${x}px, ${y}px)`;
      Object.assign(this.$refs.legend.dataset, { x, y });

      // then listen to event
      this.draggable.on('dragend', this.updatePosition);

      // and reflow if outside
      this.draggable.reflow({ name: 'drag', axis: 'xy' });
    });
  },
  destroyed() {
    this.draggable.off('dragend', this.updatePosition);
  },
  data() {
    return {
      top: 30 + 90.3 + 14 + 10 + 40,
    };
  },
  computed: {
    layerShape() {
      const map = {
        [SHAPES.CIRCLE]: 'shape-circle',
        [SHAPES.SQUARE]: 'shape-square',
        [SHAPES.TRIANGLE]: 'shape-triangle',
        [SHAPES.SPIKE]: 'shape-spikes',
      };
      return map[this.layer.shape.shapeType];
    },
    layerBorderStyles() {
      if (this.layer.weight === '0') return { color: 'transparent', width: this.layer.weight };
      else return { color: this.layer.color, width: this.layer.weight };
    },
    rangeShapeSizes() {
      if (this.layer.legend.shapeSizeFixed) {
        return this.generateRange(Number(this.layer.radius.min), Number(this.layer.radius.min));
      } else {
        return this.generateRange(Number(this.layer.radius.min), Number(this.layer.radius.max));
      }
    },
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    timeseries() {
      if (!this.layer.dataTimeseries) return null;
      let timeseries = this.datasources.find((d) => d.id === this.layer.dataTimeseries);
      if (!timeseries) return null;
      return { ...timeseries, ...this.$db[timeseries.id] };
    },
    item() {
      let item;
      if (this.layer.arrayIdGroup && this.layer.arrayIdGroup.length) {
        for (let i = 0; i < this.layer.arrayIdGroup.length; i++) {
          if (!this.layer.arrayItemGroup[i] || !this.layer.arrayDataTimeseries[i]) continue;
          let timeseries = this.datasources.find((d) => d.id === this.layer.arrayDataTimeseries[i]);
          if (!timeseries) {
            continue;
          } else {
            timeseries = _.cloneDeep({ ...timeseries, ...this.$db[timeseries.id] });
          }
          let itemTemp = timeseries.items[this.layer.arrayItemGroup[i]];
          if (!item) {
            item = itemTemp;
          } else {
            item.min = item.min > itemTemp.min ? itemTemp.min : item.min;
            item.max = item.max < itemTemp.max ? itemTemp.max : item.max;
          }
        }
      } else {
        if (!this.timeseries || !this.layer.item) return null;
        let timeseries = _.cloneDeep(this.timeseries);
        item = timeseries.items[this.layer.item];
      }
      return item;
    },
    thresholdColors() {
      let defaultColor = { color: this.layer.fillColor.color, value: null };
      let levelValues = this.layer.fillColor.thresholds.General;
      let levelColors = this.layer.fillColor.levels.reduce((result, element, index) => {
        if (levelValues[index] !== '') {
          result.push({ color: element.color, value: levelValues[index] });
        }
        return result;
      }, []);
      return [defaultColor, ...levelColors];
    },
    thresholdGradient() {
      let gradient = `linear-gradient(to top`;
      let length = this.thresholdColors.length;
      this.thresholdColors.forEach((obj, index) => {
        gradient += `, ${obj.color} ${Math.round((index / length) * 140)}px, ${obj.color} ${Math.round(((index + 1) / length) * 140)}px`;
      });
      gradient += `)`;
      return gradient;
    },
    rangeValues() {
      if (!this.layer.dataMulti && !this.layer.dataNetcdf && !this.layer.dataPoint && !this.layer.arrayIdGroup ) return [];
      if (this.layer && this.layer.scale.value) {
        let arrayMinMax = this.generateRange(this.item.min, this.item.max);
        let arrData = this.generateScale(arrayMinMax, this.layer.scale.value);
        return this.autoFormatNumbers(arrData, { addComma: true });
      }
      return this.autoFormatNumbers(this.generateRange(this.item.min, this.item.max), { addComma: true });
    },
    rangeColors() {
      const mode = this.layer.fillColor.mode;
      if (mode === 'solid') {
        return Array(6).fill(this.layer.fillColor.color);
      } else if (mode === 'linear') {
        return [...this.layer.fillColor.colors].reverse();
      }
    },
    rangeThreshold() {
      let validLevels = [];
      const thresholds = this.layer.fillColor.thresholds;
      const levelValues = thresholds[Object.keys(thresholds)[0]];
      this.layer.fillColor.levels.forEach((level, index) => {
        if (levelValues[index] !== '' && !isNaN(levelValues[index]) && levelValues[index] !== null) {
          validLevels.push({ ...level, value: levelValues[index] });
        }
      });
      return validLevels.reverse();
    },
    rangeThresholdColors() {
      if (this.rangeThreshold.length) {
        return [...this.rangeThreshold.map((o) => o.color), this.layer.fillColor.color];
      } else {
        return Array(6).fill(this.layer.fillColor.color);
      }
    },
    rangeThresholdValues() {
      if (this.rangeThreshold.length) {
        const values = this.rangeThreshold.map((o) => Number(o.value));
        return [...this.autoFormatNumbers(values, { addComma: true }), 'Default'];
      } else {
        return this.rangeValues;
      }
    },
    valuesSolid() {
      return this.autoFormatNumbers([this.item.min, this.item.max], { addComma: true });
    },
    valuesLinear() {
      return this.autoFormatNumbers([this.item.min + 0.0 * (this.item.max - this.item.min), this.item.min + 0.2 * (this.item.max - this.item.min), this.item.min + 0.4 * (this.item.max - this.item.min), this.item.min + 0.6 * (this.item.max - this.item.min), this.item.min + 0.8 * (this.item.max - this.item.min), this.item.min + 1.0 * (this.item.max - this.item.min)], { addComma: true });
    },
    valuesThreshold() {
      return this.autoFormatNumbers(
        this.thresholdColors.slice(1).map((t) => t.value),
        { addComma: true }
      );
    },
    nameLegend() {
      let result = ``;
      if (this.layer.arrayItemGroup && this.layer.arrayItemGroup.length) {
        let count = 0;
        for (let i = 0; i < this.layer.arrayItemGroup.length; i++) {
          if (this.layer.arrayItemGroup[i]) {
            if (count == 0) {
              result = `${this.layer.arrayItemGroup[i]}`;
              count++;
            } else {
              result += `-${this.layer.arrayItemGroup[i]}`;
            }
          }
        }
      } else {
        result = this.layer.item;
      }
      return result;
    },
  },
  methods: {
    generateScale(data, scale) {
      if (scale === 'log10') {
        data = scaleLog10(data);
      } else if (scale === 'log10+') {
        data = scaleLog10Plus(data);
      } else if (scale === 'normalize') {
        data = scaleNormalize(data);
      } else if (scale === 'standardize') {
        data = scaleStandardize(data);
      }
      return data;
    },
    textStyles(index) {
      return {
        height: `${this.rangeShapeSizes[index] + Number(this.layer.weight)}px`,
      };
    },
    generateRange(min, max, N = 6) {
      const range = [];
      const delta = (max - min) / (N - 1);

      for (const i of Array(N - 1).keys()) {
        range.push(min + delta * i);
      }
      return [...range, max].reverse();
    },
    marginBottom(index) {
      let length = this.thresholdColors.length;
      if (index === 0) {
        return Math.round(((index + 1) / length) * 140) - 5;
      }
      return Math.round(((index + 1) / length) * 140) - Math.round((index / length) * 140) - 12;
    },
    updatePosition(event) {
      let x = parseFloat(event.target.dataset.x) || 0;
      let y = parseFloat(event.target.dataset.y) || 0;
      this.$store.commit('layer/MERGE_LAYER', { id: this.layer.id, legend: { position: { x, y } } });
    },
  },
};
</script>

<style scoped>
.legend-shape-container {
  position: fixed;
  right: calc(1rem + 1rem + 10px);
  z-index: 401;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%);
  text-align: center;
  user-select: none;
  border-radius: 0.428rem;
  overflow: hidden;
}

.legend-shape-container .card {
  margin: 0;
  background-color: #fff;
  backdrop-filter: blur(0px);
  transition: all 0.25s, backdrop-filter 0s;
}

.dark-layout .legend-shape-container .card {
  background-color: rgba(22, 29, 49, 0.4);
  backdrop-filter: blur(0px);
}

.legend-shape-container:hover .card {
  background-color: #fff;
  backdrop-filter: blur(4px);
}

.dark-layout .legend-shape-container:hover .card {
  background-color: rgba(22, 29, 49, 0.7);
  backdrop-filter: blur(4px);
}

.legend-shape-container .card-header {
  padding: 2px 10px;
  background-color: #f8f8f8;
  display: block;
}

.dark-layout .legend-shape-container .card-header {
  padding: 2px 10px;
  background-color: #161d31;
  display: block;
}

.legend-shape-container .legend-header {
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.legend-shape-container .legend-header span {
  font-size: 12px;
}
.legend-shape-container .legend-header small {
  font-size: 10px;
}

.legend-shape-container .card-body {
  padding: 0;
}

.legend-shape-container .legend-body {
  padding: 10px;
}

.legend-shape-container .color-bar {
  position: relative;
  height: 136px;
  width: 16px;
  flex-shrink: 0;
}

.legend-shape-container .scale.solid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1;
  margin: -5px 0 -5px;
  overflow: hidden;
  white-space: nowrap;
}
.legend-shape-container .scale.solid > div {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.legend-shape-container .scale.solid > div::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}

.legend-shape-container .scale.linear {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1;
  margin: -5px 0 -5px;
  overflow: hidden;
  white-space: nowrap;
}
.legend-shape-container .scale.linear > div {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.legend-shape-container .scale.linear > div::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}

.legend-shape-container .scale.threshold {
  display: flex;
  flex-direction: column-reverse;
  font-size: 12px;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
}
.legend-shape-container .scale.threshold > div {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.legend-shape-container .scale.threshold > div::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 6px;
  height: 2px;
  background-color: var(--tick-color);
}

/* SHAPE STYLES */
.legend-shape-item {
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legend-shape-item.shape-0 svg {
  width: 5px;
  height: 5px;
}
.legend-shape-item.shape-1 svg {
  width: 8px;
  height: 8px;
}
.legend-shape-item.shape-2 svg {
  width: 11px;
  height: 11px;
}
.legend-shape-item.shape-3 svg {
  width: 14px;
  height: 14px;
}
.legend-shape-item.shape-4 svg {
  width: 17px;
  height: 17px;
}
.legend-shape-item.shape-5 svg {
  width: 20px;
  height: 20px;
}
.legend-shape-value {
  margin-left: 5px;
}
.legend-shape-value-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
}
</style>
